import { useEffect } from 'react'
import { addDays, format, compareAsc } from 'date-fns'
import { capitalize, pick } from 'lodash'
import { AuditLog, AuditOperation, AuditRefData, EntityType, Role, User } from 'common/api/v1/types'
import { useAuditSelector, usePageParams, usePageParamsFilteredSelector, useUser } from '../../utils'
import { Api } from '../../store'
import Table, { TableConfig } from '../common/Table'
import { MissingContent } from '../common/MissingContent'
import Wrapper from '../common/Wrapper'
import Filter, { FilterType, FilterProps } from '../common/Filters'
import { DATE_FORMAT_LONG, getEntityTypeName } from 'common/api/v1/helpers'
import { AuditOperationIndicator } from '../common/Indicator'

const { userApi } = Api

const pastTense = (operation: string) => {
  switch (operation) {
    case AuditOperation.login:
      return 'logged in'
    case AuditOperation.logout:
      return 'logged out'
    default:
      return `${operation}d`
  }
}

const AuditList = () => {
  const [pageParams, setPageParams] = usePageParams()
  const { fromDate, toDate } = pageParams

  useEffect(() => {
    if (!fromDate) {
      setPageParams({ fromDate: addDays(new Date(), -1).toISOString() })
    }
  }, [fromDate, setPageParams])

  const minDate = fromDate ? new Date(fromDate) : new Date('2020-01-01')
  const maxDate = toDate ? new Date(toDate) : new Date()
  const validDateRange = compareAsc(minDate, maxDate) === -1

  if (!fromDate) {
    return <></>
  }

  return (
    <Wrapper name="Audit log">
      <AuditListFilter minDate={minDate} maxDate={maxDate} />
      {validDateRange && <AuditListTable />}
    </Wrapper>
  )
}

const AuditListFilter = ({ minDate, maxDate }: Pick<FilterProps, 'minDate' | 'maxDate'>) => {
  const user = useUser()
  const maxDateMessage = 'Cannot be after "To date"'
  const minDateMessage = 'Cannot be before "From date"'

  return (
    <Filter
      filters={[
        { label: 'From date', paramName: 'fromDate', type: FilterType.datetime, maxDate, maxDateMessage },
        { label: 'To date', paramName: 'toDate', type: FilterType.datetime, minDate, minDateMessage },
        {
          label: 'Operation',
          paramName: 'operation',
          type: FilterType.select,
          options: Object.values(AuditOperation).map(item => ({ name: auditOperationName(item), value: item })),
        },
        {
          label: 'Result',
          paramName: 'result',
          type: FilterType.select,
          options: [
            { name: 'Success', value: 'success' },
            { name: 'Error', value: 'error' },
          ],
          hidden: user.role != Role.super,
        },
        {
          label: 'Entity',
          paramName: 'entity',
          type: FilterType.select,
          options: Object.values(
            pick(
              EntityType,
              'input',
              'output',
              'group',
              'user',
              'appliance',
              'outputRecipientList',
              'groupRecipientList',
              'ipMapping',
            ),
          ).map(item => ({
            name: getEntityTypeName(item),
            value: item,
          })),
        },
        {
          label: 'Entity name',
          paramName: 'entityName',
          type: FilterType.text,
        },
        {
          label: 'User',
          paramName: 'username',
          type: FilterType.autocomplete,
          autocomplete: {
            api: userApi.getUsers.bind(userApi),
            getOptionValue: ({ username }: User) => username,
            getOptionLabel: ({ username }: User) => username,
          },
        },
      ]}
    />
  )
}

const AuditListTable = () => {
  const { audit, total, loading } = usePageParamsFilteredSelector(useAuditSelector, false)
  const [pageParams] = usePageParams()
  const { pageNumber } = pageParams

  const tableConfig: TableConfig<AuditLog> = [
    {
      title: 'user',
      getValue: ({ username, impersonatorUsername }) => {
        if (!impersonatorUsername) return username
        return (
          <>
            {impersonatorUsername} <span style={{ opacity: 0.5 }}>(as {username})</span>
          </>
        )
      },
    },
    {
      title: 'action',
      getValue: formatAuditOperation,
    },
    {
      title: 'result',
      getValue: ({ error }) => <AuditOperationIndicator error={error} />,
    },
    {
      title: 'time',
      getValue: ({ createdAt }) => (createdAt ? format(createdAt, DATE_FORMAT_LONG) : null),
    },
  ]

  return (
    <Table<AuditLog>
      emptyMessageComponent={<MissingContent message="No log available for the interval" />}
      config={tableConfig}
      data={audit.map((item, idx) => ({ ...item, id: `${pageNumber || 0}_${idx}${item.id}` }))}
      pending={loading}
      pagination={{ total, useUrlSearchParams: true }}
    />
  )
}

function formatAuditOperation({ operation, entity, entityName, refData }: AuditLog) {
  let operationPastTense = formatOperationPastTense(operation, refData)
  let entityTypeName = getEntityTypeName(entity as EntityType)
  let entityNameString = entityName ? ` "${entityName}"` : ''
  let suffix = ''
  switch (operation) {
    case AuditOperation.switchInput:
      if (refData && refData.inputName) {
        suffix = ` to "${refData.inputName}"`
      }
      break
    case AuditOperation.toggle:
      if (refData.action) {
        operationPastTense = defaultPastTense(refData.action)
      }
      try {
        const entityNames = JSON.parse(`[${entityName}]`)
        if (entityNames.length > 1) {
          const pluralEntityTypeName = entityTypeName + 's'
          entityTypeName = pluralEntityTypeName
        }
      } catch {
        // entityName was not parseable as a list of strings -> use singular
      }

      entityNameString = ` ${entityName}`
      break
  }

  const auditOp = `${operationPastTense} ${entityTypeName}${entityNameString}`
  return `${auditOp}${suffix}`
}

function auditOperationName(operation: AuditOperation) {
  switch (operation) {
    case AuditOperation.switchInput:
      return 'switch input'
    case AuditOperation.updateInputRecipients:
      return 'update input recipients'
    default:
      return operation
  }
}

function formatOperationPastTense(operation: AuditOperation, context?: AuditRefData) {
  switch (operation) {
    case AuditOperation.switchInput:
      return `${context?.inputName ? 'Switched' : 'Cleared'} input for`
    case AuditOperation.updateInputRecipients:
      return `Updated recipients for`
    case AuditOperation.sendToOutputs:
      return `Updated outputs for`
    default:
      return defaultPastTense(operation)
  }
}

function defaultPastTense(operation: string) {
  return capitalize(pastTense(operation))
}

export default AuditList
