import { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Theme } from '@mui/material/styles'
import { parseISO } from 'date-fns'

import { Paper } from '../../common/Form'
import Wrapper from '../../common/Wrapper'
import Filter from './Filter'
import Total from './Total'
import Details from './Details'
import { endOfDayUTC, startOfMonthUTC } from 'common/api/v1/helpers'
import { readUsage } from '../../../redux/actions/usageActions'
import { usePageParams, withDefaultPagination } from '../../../utils'
import { AppDispatch, GlobalState } from '../../../store'

const classes = {
  paper: {
    padding: (theme: Theme) => theme.spacing(1, 2, 3),
    marginBottom: (theme: Theme) => theme.spacing(2),
    overflowX: 'auto',
  },
}

const Report = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [pageParams] = usePageParams()
  const { startDate, endDate, rowsPerPage, pageNumber, type } = pageParams
  const { usage, loading, error } = useSelector(({ usageReducer }: GlobalState) => usageReducer, shallowEqual)

  useEffect(() => {
    const params = {
      ...withDefaultPagination(pageParams),
      startDate: startDate ? parseISO(startDate) : startOfMonthUTC(new Date()),
      endDate: endDate ? parseISO(endDate) : endOfDayUTC(new Date()),
      type: type as 'ingress' | 'egress' | undefined,
    }
    dispatch(readUsage(params))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, type, rowsPerPage, pageNumber, dispatch])

  return (
    <Wrapper name="Usage">
      <Paper title="Filter">
        <Filter />
      </Paper>
      {!error ? (
        <>
          <Paper sx={classes.paper} title="Total">
            <Total usage={usage} loading={loading} />
          </Paper>
          <Paper sx={classes.paper} title="Details">
            <Details usage={usage} loading={loading} />
          </Paper>
        </>
      ) : (
        'Failed to load usage data'
      )}
    </Wrapper>
  )
}

export default Report
