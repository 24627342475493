import { useState } from 'react'
import { useFormikContext } from 'formik'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'

import { Group, GroupRecipientList, GroupRecipientListUpdate } from 'common/api/v1/types'
import { Api } from '../../../store'
import PaginatedList from '../../common/SelfStatePaginatedList'
import { Table } from '../../common/Table'
import { useUser } from '../../../utils'
import { GroupsRequestParams } from '../../../api/nm-types'

const styles = {
  button: {
    color: (theme: Theme) => theme.palette.error.main,
    borderColor: (theme: Theme) => theme.palette.error.main,
  },
  actions: {
    margin: (theme: Theme) => theme.spacing(1, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minWidth: '140px',
  },
}

const { groupListsApi, groupsApi } = Api

const AvailableGroups = () => {
  const [hideFilter, setHideFilter] = useState(true)
  const { values, setFieldValue } = useFormikContext<GroupRecipientList & Pick<GroupRecipientListUpdate, 'addGroups'>>()
  const { group } = useUser()

  const onAdd = (id: Group['id']) => setFieldValue('addGroups', values.addGroups.concat(id))
  const onCancel = (id: Group['id']) =>
    setFieldValue(
      'addGroups',
      values.addGroups.filter(groupId => groupId !== id),
    )

  return (
    <Grid item xs={12}>
      <PaginatedList<GroupsRequestParams, Group>
        api={
          values.id
            ? function(params) {
                return groupListsApi.getGroups(values.id, false, params)
              }
            : groupsApi.getGroups.bind(groupsApi)
        }
        emptyMessage="no available groups"
        hideSearch={hideFilter}
        notFoundMessage="no matching groups"
        otherParams={{ userGroup: group }}
        onListChange={({ filter, total = 0 }) => setHideFilter(!filter && total < 10)}
        searchPlaceholder="Find specific group…"
        List={({ list }) => (
          <Table
            id="availablegroups-table"
            data={list}
            isSmall
            hasBorders
            noHeader
            config={[
              {
                title: 'name',
                getValue: ({ name }) => <Typography>{name}</Typography>,
              },
              {
                title: 'add',
                getValue: ({ id }) => (
                  <Box sx={styles.actions}>
                    {values.addGroups.includes(id) ? (
                      <Button sx={styles.button} onClick={() => onCancel(id)} variant="outlined" size="small">
                        Cancel adding
                      </Button>
                    ) : (
                      <Button onClick={() => onAdd(id)} variant="outlined" size="small" name="add-group">
                        Add
                      </Button>
                    )}
                  </Box>
                ),
                props: {
                  align: 'right',
                },
              },
            ]}
          />
        )}
      />
    </Grid>
  )
}

export default AvailableGroups
