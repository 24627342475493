import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import { Api } from '../../store'
import { Video } from './Video'
import axios from '../../utils/edgeAxios'

const styles = {
  videoWrapper: {
    width: '500px',
    height: '290px',
  },
  previewWrapper: {
    textAlign: 'center',
  },
}

interface PreviewProps {
  inputId: string
  thumbnailUrl?: string
  isOnDemand: boolean
  isAudioOnly: boolean
}

const PREVIEW_KEEPALIVE_INTERVAL = 3000
const PREVIEW_PLAYLIST_READY_CHECK_INTERVAL = 500

const PreviewLoading = () => {
  return (
    <Box sx={styles.previewWrapper}>
      <CircularProgress />
      <p>Preparing preview...</p>
    </Box>
  )
}

export const Preview: React.FC<PreviewProps> = ({ inputId, thumbnailUrl, isOnDemand, isAudioOnly }) => {
  const [playlistReady, setPlaylistReady] = useState(false)
  const playlistUrl = `/hls/${inputId}/playlist.m3u8`
  const initiallyMuted = !isAudioOnly

  useEffect(() => {
    if (isOnDemand) {
      // enable generating preview and keep it alive
      const feedWatchdog = () => Api.inputApi.enableInputPreview(inputId)

      feedWatchdog()
      const interval = setInterval(() => {
        feedWatchdog()
      }, PREVIEW_KEEPALIVE_INTERVAL)
      return () => clearInterval(interval)
    }
  }, [isOnDemand])

  useEffect(() => {
    const checkPlaylistReady = () => {
      return axios
        .head(playlistUrl)
        .then(() => {
          setPlaylistReady(true)
          return true
        })
        .catch(() => false)
    }

    checkPlaylistReady()
    const interval = setInterval(() => {
      if (playlistReady) {
        clearInterval(interval)
        return
      }
      checkPlaylistReady().then(res => {
        if (res) {
          clearInterval(interval)
        }
      })
    }, PREVIEW_PLAYLIST_READY_CHECK_INTERVAL)

    return () => clearInterval(interval)
  }, [])

  return (
    <Box sx={styles.videoWrapper}>
      {playlistReady ? (
        <Video url={playlistUrl} poster={thumbnailUrl} autoplay={true} initiallyMuted={initiallyMuted}></Video>
      ) : (
        <PreviewLoading />
      )}
    </Box>
  )
}
