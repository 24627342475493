import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'

import { Input, Output } from 'common/api/v1/types'
import { Api } from '../../store'
import { InputHealthIndicator } from '../common/Indicator'
import Title from '../common/Dialog/Title'
import { Table } from '../common/Table'
import PaginatedList from '../common/SelfStatePaginatedList'
import Thumbnail from '../common/Thumbnail'
import { inputOutputColor } from '../../utils'
import { EnrichedInput, EnrichedOutput, InputsRequestParams } from '../../api/nm-types'
import { getFormattedTransportStreamContent } from 'common/api/v1/helpers'

const { inputApi } = Api

const styles = {
  thumbnail: {
    padding: (theme: Theme) => theme.spacing(1),
    minWidth: '80px',
    maxWidth: '120px',
    minHeight: '50px',
  },
  name: {
    fontWeight: 'bold',
    fontSize: (theme: Theme) => theme.typography.h4.fontSize,
  },
  row: {
    cursor: 'pointer',
  },
}

interface InputsTableProps {
  output: Output
  closeDialog: () => void
  onInputSelect: (output: Output, input: Input) => void
}
const InputsTable = ({ output, closeDialog, onInputSelect }: InputsTableProps) => {
  const onSendClick = (output: Output, input: Input) => () => {
    closeDialog()
    return onInputSelect(output, input)
  }

  return (
    <PaginatedList<InputsRequestParams, EnrichedInput>
      api={inputApi.getInputs.bind(inputApi)}
      otherParams={{ canSubscribe: true }}
      searchPlaceholder="Find input…"
      emptyMessage="no inputs are available"
      notFoundMessage="no matching inputs"
      List={({ list }) => (
        <Table
          id="input-switch"
          data={list}
          props={{ size: 'small' }}
          noHeader
          rowProps={input => ({
            onClick: onSendClick(output, input),
            selected: output.input === input.id,
            sx: styles.row,
          })}
          config={[
            {
              getValue: input => (
                <Box sx={styles.thumbnail}>
                  <Thumbnail input={input} linkToPlaylist={false} />
                </Box>
              ),
            },
            {
              getValue: ({ name, _owner, adminStatus }) => (
                <>
                  <Typography data-test-id="input-name" sx={styles.name} {...inputOutputColor({ adminStatus })}>
                    {name}
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {_owner ? _owner.name : ''}
                  </Typography>
                </>
              ),
            },
            {
              getValue: ({ tsInfo }) => (
                <Typography color="textSecondary" variant="body2">
                  {getFormattedTransportStreamContent((tsInfo || [])[0])}
                </Typography>
              ),
            },
            {
              getValue: input => <InputHealthIndicator inputId={input.id} />,
            },
          ]}
        />
      )}
    />
  )
}

interface SelectInputDialogProps extends InputsTableProps {
  show: boolean
  onClearInput: (output: EnrichedOutput) => void
}
export const SelectInputDialog = ({
  output,
  show,
  closeDialog,
  onInputSelect,
  onClearInput,
}: SelectInputDialogProps) => {
  const clearInputOnOutput = (output: EnrichedOutput) => {
    closeDialog()
    onClearInput(output)
  }

  return (
    <Dialog open={Boolean(show && output)} onClose={closeDialog} maxWidth="sm" fullWidth id="switch-input-dialog">
      <Title title={`Switch input of "${output.name}"`} onClose={closeDialog} />
      <DialogContent>
        <DialogContentText component="div">
          {output.input && (
            <Button
              fullWidth
              variant="outlined"
              onClick={() => clearInputOnOutput(output)}
              data-test-id="clear-input-btn"
            >
              Clear output
            </Button>
          )}
          {show && <InputsTable output={output} closeDialog={closeDialog} onInputSelect={onInputSelect} />}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
