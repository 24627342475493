import { useEffect, useRef } from 'react'
import { FormikProps } from 'formik'
import { get } from 'lodash'

import {
  Address,
  ApplianceType,
  ApplianceVersion,
  Input,
  InputAdminStatus,
  InputPort,
  IpInputPort,
  IpPortMode,
} from 'common/api/v1/types'

import { Select } from '../../../../common/Form'
import UdpForm, { UdpFields } from './UdpForm'
import RtpForm, { RtpFields } from './RtpForm'
import SrtForm, { getSrtFieldsToSave } from './SrtForm'
import ZixiForm, { getZixiFieldsToSave } from './ZixiForm'
import RistForm, { RistFields } from './RistForm'
import GeneratorForm, { GeneratorFields } from './GeneratorForm'
import RtmpForm, { RtmpFields } from '../../../../inputs/Edit/PortForm/IpPortForm/RtmpForm'
import { OccupiedPort } from 'common/ports'

export { udpDefaults } from './UdpForm'
export { rtpDefaults } from './RtpForm'
export { srtDefaults } from './SrtForm'
export { ristDefaults } from './RistForm'
export { zixiDefaults } from './ZixiForm'
export { generatorDefaults } from './GeneratorForm'

export enum CommonFields {
  mode = 'mode',
  physicalPort = 'physicalPort',
  copies = 'copies',
  region = 'region',
  allocatedPortId = 'allocatedPortId',
}

export const getIpPortFormFields = (port: InputPort) => {
  const common = [
    'id',
    CommonFields.mode,
    CommonFields.physicalPort,
    CommonFields.copies,
    CommonFields.region,
    CommonFields.allocatedPortId,
  ]
  if (port.mode === IpPortMode.udp) return [...common, ...Object.keys(UdpFields)]
  if (port.mode === IpPortMode.generator) return [...common, ...Object.keys(GeneratorFields)]
  if (port.mode === IpPortMode.rtp) return [...common, ...Object.keys(RtpFields)]
  if (port.mode === IpPortMode.rist) return [...common, ...Object.keys(RistFields)]
  if (port.mode === IpPortMode.zixi) return [...common, ...getZixiFieldsToSave(port)]
  if (port.mode === IpPortMode.rtmp) return [...common, ...Object.keys(RtmpFields)]
  if (port.mode === IpPortMode.srt) {
    if (port.srtMode) return [...common, ...getSrtFieldsToSave(port)]
  }
  return []
}

export type IpInput = Input

interface IpPortFormProps {
  form: FormikProps<IpInput>
  applianceType: ApplianceType
  applianceVersion?: ApplianceVersion
  index: number
  namePrefix: string
  addresses: Address[]
  occupiedPorts: OccupiedPort[]
  allocatedPort?: { addresses: Address[]; portNumber: number }
  isModeDisabled: boolean
  onModeChanged?: () => void
}

const IpPortForm = ({
  form,
  addresses,
  applianceType,
  namePrefix,
  index,
  occupiedPorts,
  allocatedPort,
  isModeDisabled,
  onModeChanged,
}: IpPortFormProps) => {
  const { values, setFieldValue } = form
  const logicalPort: IpInputPort = get(values, namePrefix)
  const adminStatus = get(values, 'adminStatus') ? InputAdminStatus.on : InputAdminStatus.off
  const handoverMethod = get(form.values, 'handoverMethod')

  const currentMode = logicalPort.mode as '' | IpInputPort['mode']
  const previousMode = useRef(currentMode)

  const isEdgeConnect = applianceType === ApplianceType.edgeConnect
  const isRistSimpleProfileSupported = [ApplianceType.edgeConnect, ApplianceType.core].includes(applianceType)
  const isRtmpSupported = [ApplianceType.edgeConnect, ApplianceType.core].includes(applianceType)
  const modes: string[] = [IpPortMode.udp, IpPortMode.rtp, IpPortMode.srt]
    .concat(isRistSimpleProfileSupported ? [IpPortMode.rist] : [])
    .concat(isRtmpSupported ? [IpPortMode.rtmp] : [])
    .concat(isEdgeConnect ? [IpPortMode.generator] : [IpPortMode.zixi])
    .sort((m1, m2) => m1.localeCompare(m2))

  const modeKey = `${namePrefix}.mode`
  useEffect(() => {
    const isValidMode = modes.includes(currentMode)
    if (!isValidMode && currentMode !== '') {
      setFieldValue(modeKey, '', false)
    }
  }, [modes, currentMode, setFieldValue, modeKey])

  useEffect(() => {
    const isValidMode = modes.includes(currentMode)
    if (isValidMode && currentMode !== previousMode.current) {
      previousMode.current = currentMode
      onModeChanged?.()
    }
  }, [currentMode, modes, onModeChanged])

  const occupiedUdpPorts = occupiedPorts.filter(o => o.protocol == 'udp')
  const occupiedTcpPorts = occupiedPorts.filter(o => o.protocol == 'tcp')
  return (
    <>
      <Select
        label="Mode"
        name={modeKey}
        newLine
        required
        disabled={isModeDisabled}
        options={modes}
        validators={{
          oneOf: { validValues: new Set(modes) },
        }}
      />
      {currentMode === IpPortMode.udp && (
        <UdpForm
          namePrefix={namePrefix}
          addresses={addresses}
          form={form}
          applianceType={applianceType}
          occupiedPorts={occupiedUdpPorts}
          allocatedPort={allocatedPort}
          adminStatus={adminStatus}
        />
      )}
      {currentMode === IpPortMode.rtp && (
        <RtpForm
          namePrefix={namePrefix}
          addresses={addresses}
          applianceType={applianceType}
          index={index}
          occupiedPorts={occupiedUdpPorts}
          allocatedPort={allocatedPort}
          adminStatus={adminStatus}
          form={form}
        />
      )}
      {currentMode === IpPortMode.srt && (
        <SrtForm
          namePrefix={namePrefix}
          applianceType={applianceType}
          addresses={addresses}
          onModeChanged={onModeChanged}
          occupiedPorts={occupiedUdpPorts}
          allocatedPort={allocatedPort}
          adminStatus={adminStatus}
          form={form}
        />
      )}
      {currentMode === IpPortMode.rist && (
        <RistForm
          namePrefix={namePrefix}
          addresses={addresses}
          applianceType={applianceType}
          occupiedPorts={occupiedUdpPorts}
          allocatedPort={allocatedPort}
          adminStatus={adminStatus}
          form={form}
        />
      )}
      {currentMode === IpPortMode.rtmp && (
        <RtmpForm
          namePrefix={namePrefix}
          addresses={addresses}
          applianceType={applianceType}
          occupiedPorts={occupiedTcpPorts}
          allocatedPort={allocatedPort}
          adminStatus={adminStatus}
          form={form}
        />
      )}
      {currentMode === IpPortMode.zixi && (
        <ZixiForm
          applianceType={applianceType}
          allocatedPort={allocatedPort}
          namePrefix={namePrefix}
          addresses={addresses}
          form={form}
        />
      )}
      {currentMode === IpPortMode.generator && (
        <GeneratorForm
          namePrefix={namePrefix}
          addresses={addresses}
          occupiedPorts={occupiedUdpPorts}
          adminStatus={adminStatus}
          form={form}
          handoverMethod={handoverMethod!}
        />
      )}
    </>
  )
}

export default IpPortForm
