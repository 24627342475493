import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'

import { ActiveService, InputAdminStatus, OutputAdminStatus } from 'common/api/v1/types'
import { setSelectedActiveServices } from '../../../redux/actions/activeServicesActions'
import { usePageParamsFilteredSelector, useActiveServiceSelector, isInput } from '../../../utils'
import { Table } from '../../common/Table'
import { InputIndicatorWrapper, OutputIndicatorWrapper } from '../../common/Indicator'
import { AppDispatch } from 'src/store'

const styles = {
  table: {
    maxHeight: '50vh',
    overflow: 'auto',
  },
  row: {
    cursor: 'pointer',
  },
  selected: {
    background: (theme: Theme) => `${theme.palette.primary.main} !important`,
  },
  streamName: {
    fontWeight: 'bold',
  },
}

/**
 * List of active services for the Geo Overview page
 */
const ActiveServices = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { activeServices, total, loading, selected } = usePageParamsFilteredSelector(useActiveServiceSelector)

  const setSelected = (activeServices: Array<ActiveService>) => dispatch(setSelectedActiveServices(activeServices))

  return (
    <Box sx={styles.table}>
      <Table
        data={activeServices}
        isSmall
        noHeader
        pending={loading}
        emptyMessageComponent="No active services"
        pagination={{ total, useUrlSearchParams: true }}
        rowProps={service => ({
          onClick: () =>
            setSelected(!selected.length || !selected.find(({ id }) => id === service.id) ? [service] : []),
          sx: selected.find(({ id }) => id === service.id) ? { ...styles.row, ...styles.selected } : styles.row,
        })}
        config={[
          {
            getValue: item => (
              <>
                <Typography component="span" sx={styles.streamName}>
                  {isInput(item) ? item.name : item.activeServiceInput.name}
                </Typography>
                {!isInput(item) && (
                  <Typography color="textSecondary" variant="body2" component="span">
                    {` / ${item.name}`}
                  </Typography>
                )}
              </>
            ),
          },
          {
            getValue: item =>
              isInput(item) ? (
                <InputIndicatorWrapper status={item.health} disabled={item.adminStatus === InputAdminStatus.off} />
              ) : (
                <OutputIndicatorWrapper status={item.health} disabled={item.adminStatus === OutputAdminStatus.off} />
              ),
          },
          {
            getValue: item => (
              <Typography color="textSecondary" variant="body2">
                {isInput(item) ? item.ownerName : item.groupName}
              </Typography>
            ),
            props: {
              align: 'right',
              noTypography: true,
            },
          },
        ]}
      />
    </Box>
  )
}

export default ActiveServices
