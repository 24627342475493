import { ApplianceFilter, ApplianceType, NdiSource } from 'common/api/v1/types'
import { IApplianceApi } from './api'
import { MOCK_DELAY_MS, MOCK_APPLIANCES, MOCK_GROUPS } from '../MockData'
import { Appliance, ListResult, GeoAppliance, ApplianceConfiguration } from 'common/api/v1/types'
import { EnrichedAppliance, EnrichedApplianceWithOwner } from '../nm-types'

export class MockApplianceApi implements IApplianceApi {
  listApplianceNdiSources(_id: string): Promise<ListResult<NdiSource>> {
    return new Promise(resolve =>
      setTimeout(() => {
        return resolve({ items: [{ name: 'ndi source 1' }], total: 1 })
      }, MOCK_DELAY_MS),
    )
  }
  recreateTunnels(id: string): Promise<Appliance> {
    void id
    throw new Error('Method not implemented.')
  }
  removeAppliance(id: string): Promise<Pick<Appliance, 'id'>> {
    return new Promise(resolve =>
      setTimeout(() => {
        return resolve({ id })
      }, MOCK_DELAY_MS),
    )
  }
  updateAppliance(): Promise<Appliance> {
    return new Promise(resolve =>
      setTimeout(() => {
        return resolve(MOCK_APPLIANCES[0])
      }, MOCK_DELAY_MS),
    )
  }
  getAppliance(): Promise<EnrichedAppliance> {
    return new Promise(resolve =>
      setTimeout(
        () =>
          resolve({
            ...MOCK_APPLIANCES[0],
            _physicalPorts: MOCK_APPLIANCES[0].physicalPorts.map(port => ({ ...port, _owner: MOCK_GROUPS[0] })),
          }),
        MOCK_DELAY_MS,
      ),
    )
  }
  getAppliances(): Promise<ListResult<EnrichedApplianceWithOwner>> {
    return new Promise(resolve =>
      setTimeout(
        () =>
          resolve({
            items: MOCK_APPLIANCES.map(a => ({ ...a, _owner: MOCK_GROUPS[0] })),
            total: MOCK_APPLIANCES.length,
          }),
        MOCK_DELAY_MS,
      ),
    )
  }
  getBareAppliances(filter: ApplianceFilter): Promise<ListResult<Appliance>> {
    void filter
    return this.getAppliances()
  }
  getGeoAppliances(): Promise<ListResult<GeoAppliance>> {
    return new Promise(resolve =>
      setTimeout(
        () => resolve({ items: MOCK_APPLIANCES as Array<GeoAppliance>, total: MOCK_APPLIANCES.length }),
        MOCK_DELAY_MS,
      ),
    )
  }
  restart(): Promise<Appliance> {
    return new Promise(resolve => setTimeout(() => resolve(MOCK_APPLIANCES[0]), MOCK_DELAY_MS))
  }
  getConfig(): Promise<ApplianceConfiguration> {
    return new Promise(resolve =>
      setTimeout(
        () =>
          resolve({
            type: ApplianceType.core,
            commands: [],
            vaInput: [
              {
                systemVersion: '1',
                meta: {
                  purpose: 'edge: first',
                },
                logicalPortId: '31eaf33b-848f-4d9f-8e8b-c248010166f8',
                udpSink: {
                  purpose: 'edge',
                  adminStatus: 'up',
                  enableTR101: 'yes',
                  recvPort: '1236',
                  localIf: '10.101.201.158',
                },
                edgeSource: {
                  adminStatus: 'up',
                  rtp: 'yes',
                  remotePort: '32018',
                  remoteHost: '127.0.0.1',
                  localIf: '127.0.0.1',
                },
                type: 'udp',
              },
              {
                systemVersion: '1',
                meta: {
                  purpose: 'edge: second input',
                },
                logicalPortId: '85b7a48f-ce60-4af4-8e8f-87b4cd5cf856',
                sdiInput: {
                  adminStatus: 'up',
                  purpose: 'edge',
                  index: '1',
                  portMode: 'sdi-input',
                },
                encoder: {
                  adminStatus: 'up',
                  purpose: 'edge',
                  enableTR101: 'yes',
                  enableScte35: 'no',
                  enableSmpte2038: 'no',
                  gopSize: '150',
                  totalBitrate: '5000000',
                  videoCodec: 'h.264',
                  audioStreams: [],
                },
                edgeSource: {
                  adminStatus: 'up',
                  rtp: 'yes',
                  remotePort: '32014',
                  remoteHost: '127.0.0.1',
                  localIf: '127.0.0.1',
                },
                type: 'sdi',
              },
              {
                systemVersion: '1',
                meta: {
                  purpose: 'edge: third input',
                },
                logicalPortId: '9029ab3f-aa88-4da3-80d5-f4b1f5a307bf',
                udpSink: {
                  purpose: 'edge',
                  adminStatus: 'up',
                  enableTR101: 'yes',
                  recvPort: '1236',
                  localIf: '127.0.0.1',
                },
                edgeSource: {
                  adminStatus: 'up',
                  rtp: 'yes',
                  remotePort: '32016',
                  remoteHost: '127.0.0.1',
                  localIf: '127.0.0.1',
                },
                type: 'udp',
              },
              {
                systemVersion: '1',
                meta: {
                  purpose: 'edge: test 1',
                },
                logicalPortId: 'b5398a92-9f31-4116-8fee-0ef42061df0c',
                udpSink: {
                  purpose: 'edge',
                  adminStatus: 'up',
                  enableTR101: 'yes',
                  recvPort: '2222',
                  localIf: '10.101.201.158',
                },
                edgeSource: {
                  adminStatus: 'up',
                  rtp: 'yes',
                  remotePort: '32013',
                  remoteHost: '127.0.0.1',
                  localIf: '127.0.0.1',
                },
                type: 'udp',
              },
              {
                systemVersion: '1',
                meta: {
                  purpose: 'edge: qwef2',
                },
                logicalPortId: 'c8854106-2f10-47a5-8dcc-ef4f5e4baabf',
                srtSink: {
                  purpose: 'edge',
                  adminStatus: 'up',
                  localPort: '1235',
                  localIf: '10.101.200.158',
                  latency: '120',
                  passphrase: '',
                  pbkeylen: '-1',
                  remoteHost: '',
                  remotePort: '0',
                  reducedBitrateDetection: 'on',
                  reducedBitrateThreshold: '2000000',
                  unrecoveredPacketsDetection: 'off',
                  unrecoveredPacketsThreshold: '0',
                  srtMode: 'listener',
                },
                edgeSource: {
                  adminStatus: 'up',
                  rtp: 'yes',
                  remotePort: '32019',
                  remoteHost: '127.0.0.1',
                  localIf: '127.0.0.1',
                },
                type: 'srt',
              },
              {
                systemVersion: '1',
                meta: {
                  purpose: 'edge: qwef',
                },
                logicalPortId: 'edf4be8c-b645-4845-959f-ab1bacf890d3',
                srtSink: {
                  purpose: 'edge',
                  adminStatus: 'up',
                  localPort: '1238',
                  localIf: '10.101.200.158',
                  latency: '120',
                  passphrase: '',
                  pbkeylen: '-1',
                  remoteHost: '',
                  remotePort: '0',
                  reducedBitrateDetection: 'on',
                  reducedBitrateThreshold: '2000000',
                  unrecoveredPacketsDetection: 'off',
                  unrecoveredPacketsThreshold: '0',
                  srtMode: 'listener',
                },
                edgeSource: {
                  adminStatus: 'up',
                  rtp: 'yes',
                  remotePort: '32017',
                  remoteHost: '127.0.0.1',
                  localIf: '127.0.0.1',
                },
                type: 'srt',
              },
              {
                systemVersion: '1',
                meta: {
                  purpose: 'edge: qqr',
                },
                logicalPortId: 'fce82d37-f9ae-4605-9e10-5d17cf84de75',
                pushSink: {
                  purpose: 'edge',
                  adminStatus: 'up',
                  enableTR101: 'yes',
                  password: '',
                  remoteIp: '',
                  reducedBitrateDetection: 'on',
                  reducedBitrateThreshold: '11000',
                  unrecoveredPacketsDetection: 'off',
                  unrecoveredPacketsThreshold: '0',
                  decryptKey: '1324aa3',
                  decryptType: 'none',
                  streamId: 'weqr',
                },
                edgeSource: {
                  adminStatus: 'up',
                  rtp: 'yes',
                  remotePort: '32020',
                  remoteHost: '127.0.0.1',
                  localIf: '127.0.0.1',
                },
                type: 'zixi-push',
              },
            ],
            vaOutput: [],
            tunnels: [
              {
                type: 'tunnel-client',
                tunnelId: 503,
                localIp: '10.101.201.158',
                localPort: 20001,
                remoteIp: '192.168.0.2',
                remotePort: 20001,
                secret: '2303d0e2c0abd531464e7cb1ec228bc4',
                authentication: 'keepAlive',
              },
            ],
            channels: [
              {
                channelId: 1,
                description: '',
                bufferDurationMs: 6000,
                inputMultipathPolicy: 'redundancy',
                tr101290Enabled: true,
              },
              {
                channelId: 2,
                description: '',
                bufferDurationMs: 6000,
                inputMultipathPolicy: 'redundancy',
                tr101290Enabled: true,
              },
              {
                channelId: 3,
                description: '',
                bufferDurationMs: 6000,
                inputMultipathPolicy: 'redundancy',
                tr101290Enabled: true,
              },
              {
                channelId: 492,
                description: '',
                bufferDurationMs: 6000,
                inputMultipathPolicy: 'redundancy',
                tr101290Enabled: true,
              },
              {
                channelId: 493,
                description: '',
                bufferDurationMs: 6000,
                inputMultipathPolicy: 'redundancy',
                tr101290Enabled: true,
              },
              {
                channelId: 564,
                description: '',
                bufferDurationMs: 6000,
                inputMultipathPolicy: 'redundancy',
                tr101290Enabled: true,
              },
              {
                channelId: 566,
                description: '',
                bufferDurationMs: 6000,
                inputMultipathPolicy: 'redundancy',
                tr101290Enabled: true,
              },
            ],
            inputs: [
              {
                streamId: 2803,
                channelId: 564,
                type: 'udp-input',
                localIp: '127.0.0.1',
                localPort: 32013,
                useExtendedSequenceNumber: false,
                parseAsTs: false,
              },
              {
                streamId: 2813,
                channelId: 2,
                type: 'udp-input',
                localIp: '127.0.0.1',
                localPort: 32014,
                useExtendedSequenceNumber: false,
                parseAsTs: false,
              },
              {
                streamId: 2825,
                channelId: 3,
                type: 'udp-input',
                localIp: '127.0.0.1',
                localPort: 32016,
                useExtendedSequenceNumber: false,
                parseAsTs: false,
              },
              {
                streamId: 2831,
                channelId: 492,
                type: 'udp-input',
                localIp: '127.0.0.1',
                localPort: 32017,
                useExtendedSequenceNumber: false,
                parseAsTs: false,
              },
              {
                streamId: 2837,
                channelId: 1,
                type: 'udp-input',
                localIp: '127.0.0.1',
                localPort: 32018,
                useExtendedSequenceNumber: false,
                parseAsTs: false,
              },
              {
                streamId: 2857,
                channelId: 493,
                type: 'udp-input',
                localIp: '127.0.0.1',
                localPort: 32019,
                useExtendedSequenceNumber: false,
                parseAsTs: false,
              },
              {
                streamId: 7411,
                channelId: 566,
                type: 'udp-input',
                localIp: '127.0.0.1',
                localPort: 32020,
                useExtendedSequenceNumber: false,
                parseAsTs: false,
              },
            ],
            outputs: [
              {
                channelId: 564,
                tunnelId: 503,
                streamId: 2838,
                type: 'push-rist-output',
                remoteIp: '127.0.0.1',
                remotePort: 1128,
                localIp: '127.0.0.1',
                localPort: 1128,
                useOriginTiming: true,
                useReducedOverhead: false,
              },
              {
                channelId: 2,
                tunnelId: 503,
                streamId: 2848,
                type: 'push-rist-output',
                remoteIp: '127.0.0.1',
                remotePort: 4,
                localIp: '127.0.0.1',
                localPort: 4,
                useOriginTiming: true,
                useReducedOverhead: false,
              },
              {
                channelId: 493,
                tunnelId: 503,
                streamId: 2854,
                type: 'push-rist-output',
                remoteIp: '127.0.0.1',
                remotePort: 986,
                localIp: '127.0.0.1',
                localPort: 986,
                useOriginTiming: true,
                useReducedOverhead: false,
              },
              {
                channelId: 3,
                tunnelId: 503,
                streamId: 2860,
                type: 'push-rist-output',
                remoteIp: '127.0.0.1',
                remotePort: 6,
                localIp: '127.0.0.1',
                localPort: 6,
                useOriginTiming: true,
                useReducedOverhead: false,
              },
              {
                channelId: 492,
                tunnelId: 503,
                streamId: 2866,
                type: 'push-rist-output',
                remoteIp: '127.0.0.1',
                remotePort: 984,
                localIp: '127.0.0.1',
                localPort: 984,
                useOriginTiming: true,
                useReducedOverhead: false,
              },
              {
                channelId: 1,
                tunnelId: 503,
                streamId: 2872,
                type: 'push-rist-output',
                remoteIp: '127.0.0.1',
                remotePort: 2,
                localIp: '127.0.0.1',
                localPort: 2,
                useOriginTiming: true,
                useReducedOverhead: false,
              },
              {
                channelId: 566,
                tunnelId: 503,
                streamId: 8018,
                type: 'push-rist-output',
                remoteIp: '127.0.0.1',
                remotePort: 1132,
                localIp: '127.0.0.1',
                localPort: 1132,
                useOriginTiming: true,
                useReducedOverhead: false,
              },
            ],
            logLevel: 3,
            firewall: { whitelists: [] },
          } as ApplianceConfiguration),
        MOCK_DELAY_MS,
      ),
    )
  }
}
