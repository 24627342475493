import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'
import { Warning } from '@mui/icons-material'
import Stack from '@mui/material/Stack'

const styles = {
  title: {
    margin: (theme: Theme) => theme.spacing(2, 0),
  },
}

export interface Affected {
  outputs: AffectedOutput[]
  hasMoreOutputs: boolean
}

export interface AffectedOutput {
  id: string
  name: string
}

interface ChangeRegionDialogProps {
  applianceName: string
  newRegionName: string
  affected: Affected
}

export const ChangeRegionDialog = ({ applianceName, newRegionName, affected }: ChangeRegionDialogProps) => {
  return (
    <>
      <Typography variant="h3" color="textPrimary" sx={styles.title}>
        You are about to change region for an appliance currently in use
      </Typography>
      <Stack direction="row" alignItems="center" gap={1}>
        <Warning />
        <Typography variant="h3">Warning!</Typography>
      </Stack>

      <Typography paragraph>
        Changing region to &apos;{newRegionName}&apos; for appliance &apos;{applianceName}&apos; may cause disruptions
        on any output dependent on the appliance.
      </Typography>

      <Typography paragraph>The following outputs are affected.</Typography>

      {affected.outputs && affected.outputs.length > 0 && (
        <>
          <Typography variant="h4">Outputs:</Typography>
          <ul>
            {affected.outputs.map(output => (
              <li key={output.id}>{output.name}</li>
            ))}
            {affected.hasMoreOutputs && <li>⋯</li> /* more items than listed */}
          </ul>
        </>
      )}

      {affected.hasMoreOutputs && (
        <Typography paragraph fontStyle="italic">
          Note that there are more outputs affected than the ones listed above.
        </Typography>
      )}

      <Typography paragraph>Are you sure you accept possible disruptions on these outputs?</Typography>
    </>
  )
}
