import { useSelector, shallowEqual } from 'react-redux'
import cn from 'classnames'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { ExpandMore } from '@mui/icons-material'
import { styled, Theme } from '@mui/material/styles'

import { Link } from './Link'
import { TopLeftLogo } from './LogoFetcher'
import { getIsStreamManager } from '../../utils'
import { GlobalState } from '../../store'
import { Role } from 'common/api/v1/types'
import routes from '../../utils/routes'
import { useLocation } from 'react-router-dom'
import { EnrichedUser } from '../../api/nm-types'

const styles = {
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  system: {
    lineHeight: '14px',
  },
  group: {
    fontSize: '10px',
    lineHeight: '12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  buttonWrapper: {
    textTransform: 'none',
    overflow: 'hidden',
  },
  icon: {
    marginLeft: '4px',
    color: (theme: Theme) => theme.palette.text.secondary,
  },
}

const StyledLink = styled(Link)({
  marginLeft: '8px',
  overflow: 'hidden',
})

interface LogoProps {
  className?: string
}

/**
 * Logo for both network manager and Stream manager top left corner
 * @param className - some specific css class for it
 */
const Logo = ({ className }: LogoProps) => {
  const { pathname } = useLocation()
  const isStreamManager = getIsStreamManager(pathname)
  const user = useSelector(({ userReducer }: GlobalState) => userReducer.user as EnrichedUser, shallowEqual)
  return (
    <Box className={cn(className)} sx={styles.logoWrapper}>
      <TopLeftLogo />
      <StyledLink to={isStreamManager ? routes.overview() : routes.stream()}>
        {user.role !== Role.basic && (
          <Button sx={{ width: '100%' }}>
            <Box sx={styles.buttonWrapper}>
              <Typography component="div" sx={styles.system} variant="caption" color="textPrimary" align="left">
                {isStreamManager ? 'Stream manager' : 'Network Manager'}
              </Typography>
              <Typography component="div" sx={styles.group} variant="caption" color="textSecondary" align="left">
                {!!user._group && user._group.name}
              </Typography>
            </Box>
            <ExpandMore sx={styles.icon} />
          </Button>
        )}
      </StyledLink>
    </Box>
  )
}

export default Logo
