import { IInputApi, PermissionAction } from './api'
import { MOCK_APPLIANCES, MOCK_DELAY_MS, MOCK_INPUTS, MOCK_GROUPS, MOCK_PORTS } from '../MockData'
import { listResult } from '../../utils'
import { EnrichedInput, EnrichedInputWithPorts, OutputsRequestParams } from '../nm-types'
import {
  DeletedInput,
  GroupInputPermission,
  Input,
  InputFilter,
  InputInit,
  InputOperStatus,
  InputStatus,
  ListResult,
  ServiceOverview,
} from 'common/api/v1/types'
import { inputStatus } from 'common/api/v1/helpers'

export class MockInputApi implements IInputApi {
  rerouteInput(_id: string): Promise<Input> {
    throw new Error('Method not implemented.')
  }
  getOverview(inputId: string, params: Partial<OutputsRequestParams>): Promise<ServiceOverview> {
    void inputId
    void params
    throw new Error('Method not implemented.')
  }
  getBareInputs(filter: InputFilter, limit?: number): Promise<ListResult<Input>> {
    void filter
    void limit
    return this.getInputs()
  }
  createInput(input: InputInit): Promise<Input> {
    const newInput = {
      ...input,
      id: 'MockedInputId-' + new Date().getTime(),
      canSubscribe: false,
      numOutputs: 0,
      channelId: MOCK_INPUTS.length,
      createdAt: new Date(),
      updatedAt: new Date(),
      downstreamAppliances: [],
    }
    MOCK_INPUTS.push(newInput)
    return new Promise(resolve => setTimeout(() => resolve(newInput), MOCK_DELAY_MS))
  }

  updateInput(_id: string, input: Input): Promise<Input> {
    const index = MOCK_INPUTS.findIndex((i: Input) => i.id === input.id)
    MOCK_INPUTS[index] = input
    return new Promise(resolve => setTimeout(() => resolve(input), MOCK_DELAY_MS))
  }

  removeInput(id: string): Promise<DeletedInput> {
    const index = MOCK_INPUTS.findIndex((i: Input) => i.id === id)
    const removed = MOCK_INPUTS[index]
    MOCK_INPUTS.splice(index, 1)
    return new Promise(resolve => setTimeout(() => resolve(removed), MOCK_DELAY_MS))
  }

  removeInputs(ids: Array<string>): Promise<{ deleted: Array<Input['id']>; notDeleted: Array<Input['id']> }> {
    return new Promise(resolve => setTimeout(() => resolve({ deleted: ids, notDeleted: [] }), MOCK_DELAY_MS))
  }

  readInputDistribution(): Promise<GroupInputPermission[]> {
    return new Promise(resolve => setTimeout(() => resolve([]), MOCK_DELAY_MS))
  }

  updateInputDistribution(
    _id: string,
    permissions: Array<{ permission: GroupInputPermission; action: PermissionAction }>,
  ): Promise<GroupInputPermission[]> {
    return new Promise(resolve =>
      setTimeout(() => resolve(permissions.map(({ permission }) => permission)), MOCK_DELAY_MS),
    )
  }

  updateInputRecipients(): Promise<void> {
    return new Promise(resolve => setTimeout(() => resolve(), MOCK_DELAY_MS))
  }

  getInputHealth(): Promise<InputStatus> {
    return new Promise(resolve =>
      setTimeout(() => resolve(inputStatus(InputOperStatus.allOk, 'All is ok')), MOCK_DELAY_MS),
    )
  }

  getInput(): Promise<EnrichedInputWithPorts> {
    return new Promise(resolve =>
      setTimeout(
        () =>
          resolve({
            ...MOCK_INPUTS[1],
            _owner: MOCK_GROUPS[0],
            ports: (MOCK_INPUTS[1].ports || []).map(port => ({
              ...port,
              _port: { ...MOCK_PORTS[0], _appliance: MOCK_APPLIANCES[0] },
            })),
          }),
        MOCK_DELAY_MS,
      ),
    )
  }

  getInputs(): Promise<ListResult<EnrichedInput>> {
    return new Promise(resolve => setTimeout(() => resolve(listResult(MOCK_INPUTS)), MOCK_DELAY_MS))
  }

  enableInputPreview(): Promise<void> {
    return new Promise(resolve => setTimeout(() => resolve(), MOCK_DELAY_MS))
  }

  enableInputs(): Promise<EnrichedInput[]> {
    return new Promise(resolve => setTimeout(() => resolve(MOCK_INPUTS), MOCK_DELAY_MS))
  }

  disableInputs(): Promise<EnrichedInput[]> {
    return new Promise(resolve => setTimeout(() => resolve(MOCK_INPUTS), MOCK_DELAY_MS))
  }
}
