import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'

import { Output, OutputRecipientList } from 'common/api/v1/types'
import { Api } from '../../../store'
import Title from '../../common/Dialog/Title'
import { Table } from '../../common/Table'
import PaginatedList from '../../common/SelfStatePaginatedList'
import { PaginatedRequestParams } from '../../../api/nm-types'

const { outputListsApi } = Api

const styles = {
  name: {
    fontWeight: 'bold',
    fontSize: (theme: Theme) => theme.typography.h4.fontSize,
  },
  row: {
    cursor: 'pointer',
  },
}

interface InputsTableProps {
  output: Output
  closeDialog: () => void
  onListSelect: (output: Output, list: OutputRecipientList) => void
}

const InputsTable = ({ output, closeDialog, onListSelect }: InputsTableProps) => {
  const onAddToListClick = (output: Output, list: OutputRecipientList) => () => {
    closeDialog()
    return onListSelect(output, list)
  }

  return (
    <PaginatedList<PaginatedRequestParams, OutputRecipientList>
      api={params => outputListsApi.getOutputLists({ ...params, group: output.group })}
      searchPlaceholder="Find output list…"
      emptyMessage="no output lists are available"
      notFoundMessage="no matching output lists"
      List={({ list }) => (
        <Table
          data={list}
          emptyMessageComponent="no output lists are available"
          props={{ size: 'small' }}
          noHeader
          rowProps={outputList => ({
            onClick: onAddToListClick(output, outputList),
            sx: styles.row,
          })}
          config={[
            {
              getValue: ({ name }) => <Typography sx={styles.name}>{name}</Typography>,
            },
            {
              getValue: ({ description }) => (
                <Typography variant="body2" color="textSecondary">
                  {description}
                </Typography>
              ),
            },
          ]}
        />
      )}
    />
  )
}

interface SelectInputDialogProps extends InputsTableProps {
  show: boolean
}

export const SelectOutputListDialog = ({ output, show, closeDialog, onListSelect }: SelectInputDialogProps) => {
  return (
    <Dialog open={show && !!output} onClose={closeDialog} maxWidth="sm" fullWidth>
      <Title title={`Add "${output?.name}" to output list`} onClose={closeDialog} />
      <DialogContent>
        <DialogContentText component="div">
          {show && <InputsTable output={output} closeDialog={closeDialog} onListSelect={onListSelect} />}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
