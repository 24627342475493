import React from 'react'
import Typography from '@mui/material/Typography'
import { useTheme, Theme } from '@mui/material/styles'

import { Appliance, Input, Output } from 'common/api/v1/types'
import { Api } from '../../../../store'
import { Link } from '../../../common/Link'
import { GridItem, Paper } from '../../../common/Form'
import { Table } from '../../../common/Table'
import PaginatedList from '../../../common/SelfStatePaginatedList'
import routes from '../../../../utils/routes'
import { InputsRequestParams, OutputsRequestParams } from '../../../../api/nm-types'

const { inputApi, outputApi } = Api

export interface InputsOutputsProps {
  appliance: Appliance
}
const InputsOutputs: React.FunctionComponent<InputsOutputsProps> = ({ appliance }) => {
  const theme: Theme = useTheme()

  return (
    <Paper title="Inputs / Outputs" collapsible>
      <GridItem xs={12} lg={6}>
        <div style={{ width: '100%' }}>
          <Typography variant="h3">Inputs</Typography>
          <PaginatedList<InputsRequestParams, Input>
            api={inputApi.getInputs.bind(inputApi)}
            otherParams={{ applianceId: appliance.id }}
            hideSearch
            emptyMessage="no inputs found"
            notFoundMessage="no matching inputs"
            List={({ list }) => (
              <Table
                data={list}
                emptyMessageComponent="no inputs found"
                props={{ size: 'small' }}
                noHeader
                config={[
                  {
                    getValue: ({ name, id, adminStatus }) => (
                      <Link
                        underline="hover"
                        to={routes.inputsUpdate({ id })}
                        muiStyle={{
                          color: adminStatus ? theme.palette.text.primary : theme.palette.text.secondary,
                        }}
                      >
                        {name}
                      </Link>
                    ),
                  },
                ]}
              />
            )}
          />
        </div>
      </GridItem>
      <GridItem xs={12} lg={6}>
        <div style={{ width: '100%' }}>
          <Typography variant="h3">Outputs</Typography>
          <PaginatedList<OutputsRequestParams, Output>
            api={outputApi.getOutputs.bind(outputApi)}
            otherParams={{ applianceId: appliance.id }}
            hideSearch
            emptyMessage="no outputs found"
            notFoundMessage="no matching outputs"
            List={({ list }) => (
              <Table
                data={list}
                emptyMessageComponent="no outputs found"
                props={{ size: 'small' }}
                noHeader
                config={[
                  {
                    getValue: ({ name, id, adminStatus }) => (
                      <Link
                        underline="hover"
                        to={routes.outputsUpdate({ id })}
                        muiStyle={{
                          color: adminStatus ? theme.palette.text.primary : theme.palette.text.secondary,
                        }}
                      >
                        {name}
                      </Link>
                    ),
                  },
                ]}
              />
            )}
          />
        </div>
      </GridItem>
    </Paper>
  )
}

export default InputsOutputs
