import Paper from '@mui/material/Paper'
import { Theme } from '@mui/material/styles'

import Wrapper from '../../common/Wrapper'
import Alarms from './Alarms'
import { usePageParams } from '../../../utils'

export enum AlarmSeverityPicker {
  mostSevereWithAlarms = 'mostSevereWithAlarms',
}

const styles = {
  paper: {
    padding: (theme: Theme) => theme.spacing(2),
  },
}

const AlarmsTable = () => {
  const [{ applianceName }] = usePageParams()

  return (
    <Wrapper name="Alarms" entityName={applianceName}>
      <Paper sx={styles.paper}>
        <Alarms showTexts />
      </Paper>
    </Wrapper>
  )
}

export default AlarmsTable
