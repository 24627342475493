import {
    GeneratorFrameRate,
    GeneratorResolutionPreset,
    GeneratorScanMode,
    GeneratorTimestampResolution,
} from 'common/api/v1/types'

export const defaultTimestampResolution: GeneratorTimestampResolution = 'seconds'
export const defaultFrameRate: GeneratorFrameRate = '30'
export const defaultResolution: GeneratorResolutionPreset = '1280x720'
export const defaultScanMode: GeneratorScanMode = 'progressive'
