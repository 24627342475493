import React, { ChangeEvent } from 'react'
import cn from 'classnames'
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase'
import { Search } from '@mui/icons-material'
import { alpha, Theme } from '@mui/material/styles'

const styles = {
  searchWrapper: {
    width: '100%',
    height: '100%',
    borderRadius: (theme: Theme) => (theme.shape.borderRadius as number) * 50, // TODO: Remove as
    backgroundColor: (theme: Theme) => alpha(theme.palette.common.white, theme.palette.mode === 'dark' ? 0.1 : 1),
    '&:hover': {
      backgroundColor: (theme: Theme) => alpha(theme.palette.common.white, theme.palette.mode === 'dark' ? 0.2 : 0.6),
    },
    '&.stretchable': {
      width: 'auto',
    },
  },
  searchIcon: {
    margin: '0 16px 0 16px',
    opacity: (theme: Theme) => (theme.palette.mode === 'dark' ? 1 : 0.54),
  },
  stretchable: {
    '& .MuiInputBase-input': (theme: Theme) => ({
      [theme.breakpoints.up('sm')]: {
        transition: (theme: Theme) => theme.transitions.create('width'),
        width: 130,
        '&:focus': {
          width: 200,
        },
      },
    }),
  },
}

export interface SearchInputProps {
  initialValue?: string
  onChange: (input: string) => void
  placeholder?: string
  stretchable?: boolean
}

/**
 * Component with no logic to show special 'search' input
 * @param initialValue - the initial value for the input
 * @param onChange - callback to call on value _change_
 * @param placeholder - the text inside the empty input
 * @param stretchable - whether we stretch it on focus
 */
const SearchInput: React.FunctionComponent<SearchInputProps> = ({
  initialValue,
  onChange,
  placeholder = 'Search…',
  stretchable,
}) => {
  const onInputChangeHandler = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange(event.target.value)
  }
  return (
    <Box className={cn(stretchable && 'stretchable')} sx={styles.searchWrapper}>
      <InputBase
        value={initialValue}
        onChange={onInputChangeHandler}
        placeholder={placeholder}
        sx={stretchable ? styles.stretchable : {}}
        startAdornment={<Search sx={styles.searchIcon} />}
      />
    </Box>
  )
}
export default SearchInput
