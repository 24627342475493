import { useEffect } from 'react'
import { FormikProps } from 'formik'

import { Address, Output } from 'common/api/v1/types'

import { Select, TextInput } from '../../../../common/Form'
import { createDefaultFiledValues, makeAddressOptions } from '../../../../../utils'
import { get } from 'lodash'

export enum UdpFields {
  sourceAddress = 'sourceAddress',
  address = 'address',
  port = 'port',
  ttl = 'ttl',
}
export const udpDefaults = createDefaultFiledValues(Object.keys(UdpFields), [], { ttl: 64 })
export const getUdpFieldsToSave = () => [UdpFields.sourceAddress, UdpFields.address, UdpFields.port, UdpFields.ttl]

interface UdpFormProps {
  form: FormikProps<Output>
  addresses: Array<Address>
  namePrefix: string
  allocatedPort?: { addresses: Address[]; portNumber: number }
}
const UdpForm = ({ form: { setFieldValue, values }, addresses, namePrefix, allocatedPort }: UdpFormProps) => {
  const sourceAddressSelector = `${namePrefix}.${UdpFields.sourceAddress}`
  const selectedSourceAddress = get(values, sourceAddressSelector)

  const addressesAndEmptyAddress = [{ address: '', netmask: '' }, ...addresses]
  const addressOptions = [{ name: 'any', value: '' }, ...makeAddressOptions(selectedSourceAddress, addresses)]

  const allocatedAddress = allocatedPort?.addresses[0].address
  useEffect(() => {
    if (allocatedAddress && selectedSourceAddress !== allocatedAddress) {
      setFieldValue(sourceAddressSelector, allocatedAddress, false)
    }
  }, [setFieldValue, selectedSourceAddress, allocatedAddress, sourceAddressSelector])

  return (
    <>
      <Select
        name={sourceAddressSelector}
        label="Source Address"
        options={addressOptions}
        disabled={!!allocatedPort}
        newLine
        validators={{
          addressIn: { addresses: addressesAndEmptyAddress },
        }}
      />
      <TextInput
        name={`${namePrefix}.${UdpFields.address}`}
        label="Destination address"
        required
        type="text"
        validators={{ ip: {} }}
        newLine
      />
      <TextInput
        name={`${namePrefix}.${UdpFields.port}`}
        label="Destination Port"
        required
        type="number"
        noNegative
        validators={{
          port: {},
        }}
      />
      <TextInput
        name={`${namePrefix}.${UdpFields.ttl}`}
        label="TTL"
        required
        type="number"
        validators={{ number: { greaterThanOrEqualTo: 1, lessThanOrEqualTo: 255 } }}
      />
    </>
  )
}

export default UdpForm
