import Toolbar from '@mui/material/Toolbar'
import { Theme } from '@mui/material/styles'

import { FilterProps, FilterType } from './utils'
import { DateTimePicker } from './DatePicker'
import Select from './Select'
import FilterAutocomplete from './Autocomplete'
import TextInput from './TextInput'

export * from './utils'

const classes = {
  container: {
    margin: (theme: Theme) => theme.spacing(1, 0, 2),
    display: 'flex',
    gap: (theme: Theme) => theme.spacing(2),
    flexWrap: 'wrap',
  },
}

interface FiltersProps {
  filters: Array<FilterProps>
}

/**
 * If we need any filter other than just name substring we can add this component (see Audit Log for example)
 * @param filters - array of filters to be added, four types are implemented so far - date, text, autocomplete and select
 */
const Filter = ({ filters }: FiltersProps) => {
  return (
    <Toolbar disableGutters sx={classes.container}>
      {filters
        .filter(f => !f.hidden)
        .map(params => {
          if (params.type === FilterType.datetime)
            return <DateTimePicker key={`filter-${params.paramName}`} {...params} />
          if (params.type === FilterType.select) return <Select key={`filter-${params.paramName}`} {...params} />
          if (params.type === FilterType.text) return <TextInput key={`filter-${params.paramName}`} {...params} />
          if (params.type === FilterType.autocomplete && !!params.autocomplete)
            return (
              <FilterAutocomplete
                key={`filter-${params.paramName}`}
                {...(params as FilterProps & { autocomplete: NonNullable<FilterProps['autocomplete']> })}
              />
            )
          return null
        })}
    </Toolbar>
  )
}

export default Filter
