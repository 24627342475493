import { shallowEqual, useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { GraphNodeType, Tr101290Metrics } from 'common/api/v1/types'
import DataSet from '../../common/DataSet'
import { GlobalState } from '../../../store'
import { Paper } from '../../common/Form'
import { SelectedGraphItem } from '../Graph/ServiceOverviewGraph'
import { extractTr101290Metrics, isApplianceStandby } from './utils'

type EmptyTr101290 = Pick<Tr101290Metrics, 'prio1' | 'prio2'> & { time: undefined; window: undefined }
const METRICS_MISSING = -1

const Tr101290Page = () => {
  const { input, outputs, selected } = useSelector(
    ({ serviceOverviewReducer }: GlobalState) => serviceOverviewReducer,
    shallowEqual,
  )
  const outputs1m = useSelector(({ outputsReducer }: GlobalState) => outputsReducer.outputs, shallowEqual)
  if (!selected) {
    return null
  }
  if (input?.tr101290Enabled === false) {
    return <Paper title={<div style={{ display: 'flex', justifyContent: 'space-between' }}>TR 101 290 disabled</div>} />
  }
  const allMetrics = extractTr101290Metrics(input, outputs.items)
  const stats = getTr101290StatsForType(allMetrics, selected)
  if (isApplianceStandby(selected.id, input, outputs1m)) {
    return (
      <Paper
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>TR 101 290 not available in standby</div>
        }
      />
    )
  }
  const titleRow = <div style={{ display: 'flex', justifyContent: 'space-between' }}>TR 101 290</div>
  return <Paper title={titleRow}>{<Tr101202Stats {...(stats || makeEmptyTr101290Stats())} />}</Paper>
}

/**
 * @return tr101290: present metrics for the selected type
 * @return null: if info that should be there is missing
 * @return undefined: if info is not expected
 */
const getTr101290StatsForType = (
  allMetrics: Tr101290Metrics[],
  selected: SelectedGraphItem,
): Tr101290Metrics | undefined | null => {
  switch (selected.type) {
    case GraphNodeType.inputEdgeAppliance:
    case GraphNodeType.inputRegionOutputEdgeAppliance:
    case GraphNodeType.outputRegionOutputEdgeAppliance:
    case GraphNodeType.inputRegionCore:
    case GraphNodeType.outputRegionCore:
      return allMetrics.find(m => m.applianceId === selected.id) || null
    default:
      return null
  }
}

const Tr101202Stats = (tr101290: Tr101290Metrics | EmptyTr101290) => {
  const formatStats = (obj: { [key: string]: number }) => {
    return Object.entries(obj).reduce(
      (acc, [key, value]) => ({
        ...acc,
        ...{ [key.split('_').join(' ')]: value === METRICS_MISSING ? 'N/A' : value },
      }),
      {},
    )
  }

  const { prio1, prio2 } = tr101290
  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          {prio1 && (
            <Grid item xs={4} sx={{ paddingTop: '12px' }}>
              <Typography variant="h3">Prio 1</Typography>
              <DataSet values={formatStats(prio1)} />
            </Grid>
          )}
          {prio2 && (
            <Grid item xs={4} sx={{ paddingTop: '12px' }}>
              <Typography variant="h3">Prio 2</Typography>
              <DataSet values={formatStats(prio2)} />
            </Grid>
          )}
        </Grid>

        <div style={{ marginTop: '12px' }}>
          <Typography variant="body2">Window size: {tr101290.window || 'N/A'}</Typography>
          <Typography variant="body2">Showing stats since: {tr101290.time?.toLocaleString() || 'N/A'}</Typography>
        </div>
      </Grid>
    </>
  )
}

/* eslint-disable */
const makeEmptyTr101290Stats = (): EmptyTr101290 => ({
  time: undefined,
  window: undefined,
  prio1: {
    TS_sync_loss: METRICS_MISSING,
    Sync_byte_error: METRICS_MISSING,
    PAT_error: METRICS_MISSING,
    Continuity_count_error: METRICS_MISSING,
    PMT_error: METRICS_MISSING,
    PID_error: METRICS_MISSING,
  },
  prio2: {
    Transport_error: METRICS_MISSING,
    CRC_error: METRICS_MISSING,
    PCR_error: METRICS_MISSING,
    PCR_accuracy_error: METRICS_MISSING,
    PTS_error: METRICS_MISSING,
    CAT_error: METRICS_MISSING,
  },
})
/* eslint-enable */

export default Tr101290Page
