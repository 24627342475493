import { useEffect } from 'react'
import { FormikProps } from 'formik'

import {
  Address,
  ApplianceType,
  Output,
  OutputAdminStatus,
  SrtKeylen,
  SrtMode,
  SrtOutputPort,
  SrtRateLimiting,
} from 'common/api/v1/types'

import { Select, TextInput } from '../../../../common/Form'
import { createDefaultFiledValues, isCoreNode, makeAddressOptions } from '../../../../../utils'
import { isVaApplianceType } from '../../../../appliances/utils'
import { get } from 'lodash'
import { OccupiedPort } from 'common/ports'
import { CIDRToolTip } from '../../../../../texts'

export type SrtOutput = Output & { ports: SrtOutputPort[] }

const LATENCY_MAX = 6 * 60 * 1000
const TTL_MAX = 255
const MTU_MIN = 76

export enum SrtFields {
  srtMode = 'srtMode',
  remoteIp = 'remoteIp',
  remotePort = 'remotePort',
  localIp = 'localIp',
  localPort = 'localPort',
  rateLimiting = 'rateLimiting',
  maxBw = 'maxBw',
  inputBw = 'inputBw',
  oheadBw = 'oheadBw',
  latency = 'latency',
  pbkeylen = 'pbkeylen',
  passphrase = 'passphrase',
  ipttl = 'ipttl',
  mss = 'mss',
  streamId = 'streamId',
  whitelistCidrBlock = 'whitelistCidrBlock',
}

export const srtDefaults = createDefaultFiledValues(Object.keys(SrtFields), [], {
  [SrtFields.latency]: 120,
  [SrtFields.pbkeylen]: SrtKeylen.none,
  [SrtFields.oheadBw]: 25,
  [SrtFields.rateLimiting]: SrtRateLimiting.notEnforced,
})
export const getSrtFieldsToSave = (port: SrtOutputPort) => [
  SrtFields.srtMode,
  SrtFields.latency,
  SrtFields.rateLimiting,
  SrtFields.pbkeylen,
  SrtFields.ipttl,
  SrtFields.mss,
  SrtFields.streamId,
  ...(port.pbkeylen === SrtKeylen.none ? [] : [SrtFields.passphrase]),
  ...(function() {
    switch (port.srtMode) {
      case SrtMode.listener:
        return [SrtFields.localIp, SrtFields.localPort, SrtFields.whitelistCidrBlock]
      case SrtMode.caller:
        return [SrtFields.remoteIp, SrtFields.remotePort, SrtFields.localPort]
      default:
        return [SrtFields.remoteIp, SrtFields.remotePort, SrtFields.localIp, SrtFields.whitelistCidrBlock]
    }
  })(),
  ...(function() {
    switch (port.rateLimiting) {
      case SrtRateLimiting.absolute:
        return [SrtFields.maxBw]
      case SrtRateLimiting.relativeToInput:
        return [SrtFields.inputBw, SrtFields.oheadBw]
      default:
        return []
    }
  })(),
]

interface SrtFormProps {
  form: FormikProps<SrtOutput>
  addresses: Array<Address>
  namePrefix: string
  occupiedPorts: OccupiedPort[]
  adminStatus: OutputAdminStatus
  applianceType: ApplianceType
  onModeChanged?: () => void
  allocatedPort?: { addresses: Address[]; portNumber: number }
}

const SrtForm = ({
  form,
  addresses,
  namePrefix,
  occupiedPorts,
  adminStatus,
  applianceType,
  allocatedPort,
  onModeChanged,
}: SrtFormProps) => {
  const port: SrtOutputPort = get(form.values, namePrefix)
  const localAddressSelector = `${namePrefix}.${SrtFields.localIp}`
  const disableLocalPortFields = !!allocatedPort
  const allocatedPortNumber = allocatedPort?.portNumber
  const allocatedAddress = allocatedPort?.addresses[0].address
  useEffect(() => {
    if (allocatedPortNumber && allocatedAddress) {
      const shouldPopulateField = (field: SrtFields) => getSrtFieldsToSave(port).includes(field)
      if (
        port?.srtMode === SrtMode.rendezvous &&
        get(form.values, `${namePrefix}.${SrtFields.remotePort}`) != allocatedPortNumber
      ) {
        form.setFieldValue(`${namePrefix}.${SrtFields.remotePort}`, allocatedPortNumber, false)
      }
      if (
        port.srtMode !== SrtMode.caller &&
        shouldPopulateField(SrtFields.localPort) &&
        get(form.values, `${namePrefix}.${SrtFields.localPort}`) != allocatedPortNumber
      ) {
        form.setFieldValue(`${namePrefix}.${SrtFields.localPort}`, allocatedPortNumber, false)
      }

      if (shouldPopulateField(SrtFields.localIp) && get(form.values, localAddressSelector) != allocatedAddress) {
        form.setFieldValue(localAddressSelector, allocatedAddress, false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocatedPortNumber, allocatedAddress, port, namePrefix, localAddressSelector])

  useEffect(() => {
    // EDGE-2874 When switching from Regional SRT caller to Regional SRT listener/rendezvous we need to allocate new port because portNumber is 0
    const shouldAllocatePort =
      !!port?.srtMode && port?.srtMode !== SrtMode.caller && !port.region?.allocatedPort?.portNumber
    if (shouldAllocatePort) {
      onModeChanged?.()
    }
  }, [port?.srtMode])

  return (
    <>
      <Select
        name={`${namePrefix}.${SrtFields.srtMode}`}
        label="Connection mode"
        options={Object.values(SrtMode)}
        required
      />
      {port && [SrtMode.caller, SrtMode.rendezvous].includes(port.srtMode) && (
        <>
          <TextInput
            name={`${namePrefix}.${SrtFields.remoteIp}`}
            label="Remote host"
            newLine
            required
            validators={{ ipOrHostname: {} }}
          />
          <TextInput
            name={`${namePrefix}.${SrtFields.remotePort}`}
            label={port?.srtMode === SrtMode.rendezvous ? 'Local and remote port' : 'Remote port'}
            required
            disabled={disableLocalPortFields && port.srtMode === SrtMode.rendezvous}
            type="number"
            noNegative
            validators={{
              port: {},
              isPortAvailable: {
                occupiedPorts: port.srtMode == SrtMode.rendezvous ? occupiedPorts : [],
                isMulticast: false,
                isPortDisabled: adminStatus === OutputAdminStatus.off,
              },
            }}
          />
        </>
      )}
      {port.srtMode && [SrtMode.listener, SrtMode.rendezvous].includes(port.srtMode) && (
        <Select
          name={localAddressSelector}
          label="Local address"
          required
          disabled={disableLocalPortFields}
          options={makeAddressOptions(get(form.values, localAddressSelector), addresses)}
          newLine
          validators={{
            addressIn: { addresses },
          }}
        />
      )}
      {(port?.srtMode === SrtMode.listener || (port?.srtMode === SrtMode.caller && !disableLocalPortFields)) && (
        <TextInput
          name={`${namePrefix}.${SrtFields.localPort}`}
          label="Local port"
          required={port.srtMode == SrtMode.listener}
          disabled={disableLocalPortFields}
          type="number"
          tooltip={port.srtMode == SrtMode.caller ? 'The local outgoing port' : undefined}
          noNegative
          validators={{
            port: { disallowInternal: true },
            isPortAvailable: {
              occupiedPorts,
              isMulticast: false,
              isPortDisabled: adminStatus === OutputAdminStatus.off,
            },
          }}
        />
      )}
      <Select
        name={`${namePrefix}.${SrtFields.rateLimiting}`}
        label="Output rate limiting"
        options={Object.values(SrtRateLimiting)}
        required
        newLine
      />
      {port.rateLimiting === SrtRateLimiting.absolute && (
        <TextInput
          name={`${namePrefix}.${SrtFields.maxBw}`}
          label="Maximum bandwidth (Mbps)"
          type="number"
          required
          noNegative
        />
      )}
      {port.rateLimiting === SrtRateLimiting.relativeToInput && (
        <>
          <TextInput
            name={`${namePrefix}.${SrtFields.inputBw}`}
            label="Input bandwidth (Mbps)"
            required
            tooltip="Set to '0' for automatic input bandwidth sensing"
            type="number"
            noNegative
          />
          <TextInput
            name={`${namePrefix}.${SrtFields.oheadBw}`}
            label="Overhead bandwidth (%)"
            tooltip="The allowed overhead of output bandwidth in relation to input bandwidth. Minimum 5%"
            required
            type="number"
            noNegative
            validators={{
              number: {
                greaterThanOrEqualTo: 5,
                lessThanOrEqualTo: 100,
                message: 'Must be 5 - 100',
              },
            }}
          />
        </>
      )}
      <TextInput
        name={`${namePrefix}.${SrtFields.latency}`}
        label="Retransmission buffer (ms)"
        type="number"
        noNegative
        required
        newLine
        tooltip="Contains packets that may need to be retransmitted. Shall be at least the same size as the retransmission buffer on the receiver."
        validators={{
          number: {
            lessThanOrEqualTo: LATENCY_MAX,
            message: `Must be 0 - ${LATENCY_MAX}`,
          },
        }}
      />
      {!isVaApplianceType(applianceType) && (
        <TextInput
          name={`${namePrefix}.${SrtFields.ipttl}`}
          label="TTL"
          type="number"
          noNegative
          tooltip='Defines IP socket "time to live" option.'
          validators={{
            number: {
              lessThanOrEqualTo: TTL_MAX,
              message: `Must be no more than ${TTL_MAX}`,
            },
          }}
        />
      )}
      {!isVaApplianceType(applianceType) && (
        <TextInput
          name={`${namePrefix}.${SrtFields.mss}`}
          label="MTU"
          type="number"
          noNegative
          newLine
          tooltip="MTU size"
          validators={{
            number: {
              greaterThanOrEqualTo: MTU_MIN,
              message: `Must be more than ${MTU_MIN}`,
            },
          }}
        />
      )}
      <Select
        name={`${namePrefix}.${SrtFields.pbkeylen}`}
        label="Encryption type"
        options={Object.entries(SrtKeylen).map(([name, value]) => ({ name, value }))}
        required
        newLine
      />
      {!!port.pbkeylen && port.pbkeylen !== SrtKeylen.none && (
        <TextInput
          name={`${namePrefix}.${SrtFields.passphrase}`}
          label="Passphrase"
          tooltip="Same passphrase must be configured at SRT Input to decrypt the stream"
          validators={{
            alphanumeric: {},
            len: {
              minimum: 10,
            },
          }}
        />
      )}
      {port?.srtMode == SrtMode.caller && (
        <TextInput name={`${namePrefix}.${SrtFields.streamId}`} label="Stream Id" newLine tooltip="Stream ID" />
      )}
      {!isVaApplianceType(applianceType) &&
        port.srtMode &&
        [SrtMode.listener, SrtMode.rendezvous].includes(port.srtMode) && (
          <TextInput
            name={`${namePrefix}.${SrtFields.whitelistCidrBlock}`}
            label="Whitelist CIDR block"
            tooltip={CIDRToolTip(isCoreNode(applianceType), 'Output')}
            required={isCoreNode(applianceType)}
            validators={{
              ipv4CidrBlock: {},
            }}
          />
        )}
    </>
  )
}

export default SrtForm
