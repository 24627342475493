import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import { Grid } from '@mui/material'
import { GetApp } from '@mui/icons-material'
import { Theme } from '@mui/material/styles'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { parseISO, endOfMonth } from 'date-fns'

import { BillingPayload, readBilling } from '../../../redux/actions/billingActions'
import { api } from '../../../utils/routes'
import { usePageParams } from '../../../utils'
import { endOfMonthUTC, startOfDayUTC, startOfMonthUTC } from 'common/api/v1/helpers'
import { AppDispatch } from 'src/store'

const styles = {
  buttonContainer: {
    margin: '0 0 0 auto',
  },
  input: {
    marginRight: (theme: Theme) => theme.spacing(2),
  },
  buttonText: (theme: Theme) => ({
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  }),
  icon: (theme: Theme) => ({
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  }),
}

enum DateField {
  'startDate' = 'startDate',
  'endDate' = 'endDate',
}
const getRequestParams = (from: Date, to: Date): BillingPayload => ({
  [DateField.startDate]: from,
  [DateField.endDate]: to,
})

const PeriodFilter = () => {
  const [{ startDate, endDate }, setPageParams] = usePageParams<{ startDate: string; endDate: string }>()
  const [openFrom, setOpenFrom] = useState(false)
  const [openTo, setOpenTo] = useState(false)
  const [from, setFrom] = useState<Date>(startDate ? parseISO(startDate) : startOfMonthUTC(new Date()))
  const [to, setTo] = useState<Date>(endDate ? parseISO(endDate) : new Date())
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    startDate && setFrom(parseISO(startDate))
    endDate && setTo(parseISO(endDate))
  }, [startDate, endDate])
  useEffect(() => {
    dispatch(readBilling(getRequestParams(from, to)))
  }, [dispatch, to, from])

  const setDate = (dateField: DateField, value: Date | null) => {
    if (!value || !value.getTime()) return
    if (dateField === DateField.endDate) {
      value = endOfMonthUTC(value)
    } else {
      value = startOfMonthUTC(value)
    }
    const params = {
      [DateField.startDate]: dateField === DateField.startDate ? value.toISOString() : startDate,
      [DateField.endDate]: dateField === DateField.endDate ? value.toISOString() : endDate,
    }
    setPageParams(params)
  }
  return (
    <>
      <Grid item>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker<Date>
            open={openFrom}
            onOpen={() => setOpenFrom(true)}
            onClose={() => setOpenFrom(false)}
            label="From month:"
            views={['year', 'month']}
            inputFormat="yyyy MMM"
            value={startOfDayUTC(from)}
            onChange={value => setDate(DateField.startDate, value)}
            minDate={new Date('2020-01')}
            maxDate={to}
            disableFuture
            renderInput={params => (
              <TextField {...params} variant="standard" sx={styles.input} onClick={() => setOpenFrom(true)} />
            )}
          />
          <DatePicker<Date>
            open={openTo}
            onOpen={() => setOpenTo(true)}
            onClose={() => setOpenTo(false)}
            label="To month:"
            views={['year', 'month']}
            inputFormat="yyyy MMM"
            value={startOfDayUTC(to)}
            onChange={value => setDate(DateField.endDate, value)}
            minDate={from}
            maxDate={endOfMonth(new Date())}
            renderInput={params => (
              <TextField {...params} variant="standard" sx={styles.input} onClick={() => setOpenTo(true)} />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item sx={styles.buttonContainer}>
        <Link
          underline="none"
          href={api.billing({
            ...getRequestParams(from, to),
            format: 'pdf',
          })}
        >
          <Button variant="outlined">
            <Box sx={styles.buttonText}>Download</Box>
            <GetApp sx={styles.icon} />
          </Button>
        </Link>
      </Grid>
    </>
  )
}

export default PeriodFilter
