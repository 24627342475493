import { HttpError, HttpStatusCode } from './http/httpError'

export enum ErrorCode {
    unknownError = 'unknownError',
    internalServerError = 'internalServerError',
    serviceUnavailableError = 'serviceUnavailable',
    originIsUnreachableError = 'originIsUnreachableError',
    portIsTaken = 'portIsTaken',
    notFound = 'notFound',
    timeout = 'timeout',
    conflict = 'conflict',
    badRequest = 'badRequest',
    unsupportedMediaType = 'unsupportedMediaType',
    unauthorized = 'unauthorized',
    forbidden = 'forbidden',
    payloadTooLarge = 'payloadTooLarge',
    gatewayTimeout = 'gatewayTimeout',
    missingOutputPorts = 'missingOutputPorts',
    outputDeniedNoServiceAccess = 'outputDeniedNoServiceAccess',
    outputDeniedNoPortAccess = 'outputDeniedNoPortAccess',
    outputInvalidNoSuchPort = 'outputInvalidNoSuchPort',
    outputConflictPortTaken = 'outputConflictPortTaken',

    distributionSystemIsInUse = 'distributionSystemIsInUse',
    noDistributionSystemAvailable = 'noDistributionSystemAvailable',

    serviceNotFound = 'serviceNotFound',
    invalidApplianceId = 'invalidApplianceId',
    portNotFound = 'portNotFound',
    notAuthorizedToChangePortOwner = 'notAuthorizedToChangePortOwner',
    portPreviousOwnerMismatch = 'portPreviousOwnerMismatch',

    userNotFound = 'userNotFound',
    invalidGroupId = 'invalidGroupId',

    missingParameter = 'missingParameter',
    usernameTaken = 'usernameTaken',
    nameTaken = 'nameTaken',

    channelAllocationFailedNoDistributionSystem = 'channelAllocationFailedNoDistributionSystem',
    invalidParameters = 'invalidParameters',
    tooManyLoginAttempts = 'tooManyLoginAttempts',
}

export function errorTitle(errorCode: ErrorCode) {
    return errorTitles[errorCode]
}

const errorTitles: { [key in ErrorCode]: string } = {
    [ErrorCode.portIsTaken]: 'The port is already taken',
    [ErrorCode.unknownError]: 'Unknown error',
    [ErrorCode.internalServerError]: 'Internal server error',
    [ErrorCode.serviceUnavailableError]: 'Service is unavailable',
    [ErrorCode.originIsUnreachableError]: 'Origin is unreachable',
    [ErrorCode.notFound]: 'The resource could not be found',
    [ErrorCode.timeout]: 'The request timed out',
    [ErrorCode.conflict]: 'The request resulted in a conflict',
    [ErrorCode.badRequest]: 'The request was invalid',
    [ErrorCode.unsupportedMediaType]: 'The media type is not supported',
    [ErrorCode.unauthorized]: 'Authentication required',
    [ErrorCode.forbidden]: 'Access denied',
    [ErrorCode.payloadTooLarge]: 'Payload too large',
    [ErrorCode.gatewayTimeout]: 'Gateway timeout',

    [ErrorCode.missingOutputPorts]: 'Missing output ports',
    [ErrorCode.outputDeniedNoServiceAccess]:
        'The subscription was denied because the user group does not have access to the service',
    [ErrorCode.outputDeniedNoPortAccess]:
        'The subscription was denied because user group does not have access to the port',

    [ErrorCode.outputInvalidNoSuchPort]:
        'The subscription request was invalid because the specified port does not exist',
    [ErrorCode.outputConflictPortTaken]:
        'The subscription request resulted in a conflict because the specified port is busy',
    [ErrorCode.serviceNotFound]: 'The service could not be found',
    [ErrorCode.invalidApplianceId]: 'The provided appliance id was invalid',

    [ErrorCode.portNotFound]: 'The specified port was not found',
    [ErrorCode.notAuthorizedToChangePortOwner]: 'You are not authorized to set the owner of this port',
    [ErrorCode.portPreviousOwnerMismatch]: 'The change of port owner conflicted with another request',

    [ErrorCode.userNotFound]: 'The user was not found',

    [ErrorCode.invalidGroupId]: 'Invalid group id',
    [ErrorCode.missingParameter]: 'One or more parameters are missing',
    [ErrorCode.usernameTaken]: 'The specified username was already taken',
    [ErrorCode.nameTaken]: 'The specified name was already taken',
    [ErrorCode.distributionSystemIsInUse]:
        'Cannot delete this distribution system while it is in use by an input or output',

    [ErrorCode.channelAllocationFailedNoDistributionSystem]:
        'Cannot allocate a stream for a service without a distribution system',
    [ErrorCode.noDistributionSystemAvailable]:
        'The action cannot be completed because no distribution system was available',

    [ErrorCode.invalidParameters]: 'One or more parameters are invalid.',
    [ErrorCode.tooManyLoginAttempts]: 'Too many login attempts.',
}

function defaultTitle(type: ErrorCode) {
    return errorTitles[type]
}

export class EdgeApiHttpError extends HttpError {
    constructor(
        status: HttpStatusCode,
        public type: ErrorCode,
        title: string,
        detail: string | InvalidParameter[] = ''
    ) {
        super(status, type, title, detail)
    }
    toJSON() {
        const { status, title, detail, type } = this
        return { status, title: title || defaultTitle(type), detail, type }
    }

    toString() {
        return `Error: HTTP ${this.status} ${this.title}${
            this.detail ? ` (${typeof this.detail === 'object' ? JSON.stringify(this.detail) : this.detail})` : ''
        }`
    }
}

const errors: Map<
    HttpStatusCode,
    new (type?: ErrorCode, detail?: string, title?: string) => EdgeApiHttpError
> = new Map()

export interface InvalidParameter {
    name: string
    reason: string
}

function createError(httpCode: HttpStatusCode, errorCode: ErrorCode) {
    const Cls = class extends EdgeApiHttpError {
        constructor(type: ErrorCode = errorCode, detail: string | InvalidParameter[] = '', title: string = '') {
            super(httpCode, type, title || defaultTitle(type), detail)
        }
    }
    Object.defineProperty(Cls, 'name', { value: errorCode })
    errors.set(httpCode, Cls)
    return Cls
}

export function throwApiError(e: Error, apiError: EdgeApiHttpError) {
    if (e instanceof EdgeApiHttpError) {
        throw e
    } else {
        throw apiError
    }
}

export const NotFoundError = createError(HttpStatusCode.NotFound, ErrorCode.notFound)
export const TimeoutError = createError(HttpStatusCode.GatewayTimeout, ErrorCode.gatewayTimeout)
export const ConflictError = createError(HttpStatusCode.Conflict, ErrorCode.conflict)
export const ServerError = createError(HttpStatusCode.ServerError, ErrorCode.internalServerError)
export const BadRequestError = createError(HttpStatusCode.BadRequest, ErrorCode.badRequest)
export const UnsupportedMediaTypeError = createError(
    HttpStatusCode.UnsupportedMediaType,
    ErrorCode.unsupportedMediaType
)
export const UnauthorizedError = createError(HttpStatusCode.Unauthorized, ErrorCode.unauthorized)
export const ForbiddenError = createError(HttpStatusCode.Forbidden, ErrorCode.forbidden)
export const PayloadTooLargeError = createError(HttpStatusCode.PayloadTooLarge, ErrorCode.payloadTooLarge)
export const InvalidParametersError = createError(HttpStatusCode.BadRequest, ErrorCode.invalidParameters)
export const ServiceUnavailableError = createError(HttpStatusCode.ServiceUnavailable, ErrorCode.serviceUnavailableError)
export const OriginIsUnreachableError = createError(
    HttpStatusCode.OriginIsUnreachable,
    ErrorCode.originIsUnreachableError
)

export const parseError = (obj: any, status?: number, stackConstructor?: any) => {
    const httpStatus = (obj && obj.status) || status
    if (httpStatus) {
        const ErrorCls = errors.get(httpStatus)
        if (ErrorCls) {
            const errObj = new ErrorCls(obj.type, obj.detail, obj.title)
            Error.captureStackTrace(errObj, stackConstructor || parseError)
            return errObj
        }
    }
    // TODO: What is reasonable to do here?
    return new EdgeApiHttpError(status || 0, ErrorCode.unknownError, 'Unknown error', JSON.stringify(obj))
}
