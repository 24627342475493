import { useEffect, useRef } from 'react'
import { FormikProps } from 'formik'
import { get } from 'lodash'

import {
  Address,
  ApplianceType,
  FecLimits,
  IpOutputPort,
  IpPortMode,
  Output,
  OutputAdminStatus,
  PortMode,
} from 'common/api/v1/types'

import { Select } from '../../../../common/Form'
import UdpForm, { getUdpFieldsToSave } from './UdpForm'
import RtpForm, { getRtpFieldsToSave } from './RtpForm'
import SrtForm, { getSrtFieldsToSave, SrtOutput } from './SrtForm'
import ZixiForm, { getZixiFieldsToSave } from './ZixiForm'
import RistForm, { getRistFieldsToSave } from './RistForm'
import RtmpForm, { getRtmpFieldsToSave } from './RtmpForm'
import { OccupiedPort } from 'common/ports'

export { srtDefaults } from './SrtForm'
export { rtpDefaults } from './RtpForm'
export { udpDefaults } from './UdpForm'
export { zixiDefaults } from './ZixiForm'
export { ristDefaults } from './RistForm'
export { rtmpDefaults } from './RtmpForm'

export enum CommonFields {
  id = 'id',
  mode = 'mode',
  physicalPort = 'physicalPort',
  copies = 'copies',
  region = 'region',
  allocatedPortId = 'allocatedPortId',
}

export const getIpPortFormFields = (port: IpOutputPort) => {
  const common = [
    CommonFields.id,
    CommonFields.mode,
    CommonFields.physicalPort,
    CommonFields.copies,
    CommonFields.region,
    CommonFields.allocatedPortId,
  ]
  switch (port.mode) {
    case IpPortMode.udp:
      return [...common, ...getUdpFieldsToSave()]
    case IpPortMode.rtp:
      return [...common, ...getRtpFieldsToSave()]
    case IpPortMode.zixi:
      return [...common, ...getZixiFieldsToSave(port)]
    case IpPortMode.rist:
      return [...common, ...getRistFieldsToSave()]
    case IpPortMode.srt:
      return [...common, ...getSrtFieldsToSave(port)]
    case IpPortMode.rtmp:
      return [...common, ...getRtmpFieldsToSave()]
  }
  return []
}

export type IpOutput = Output

interface IpPortFormProps {
  form: FormikProps<IpOutput>
  applianceType: ApplianceType
  applianceFecLimits?: FecLimits
  index: number
  namePrefix: string
  addresses: Address[]
  occupiedPorts: OccupiedPort[]
  allocatedPort?: { addresses: Address[]; portNumber: number }
  isModeDisabled: boolean
  onModeChanged?: () => void
  enforcedPortMode?: PortMode
}

const IpPortForm = ({
  form,
  addresses,
  applianceType,
  applianceFecLimits,
  index,
  namePrefix,
  occupiedPorts,
  allocatedPort,
  isModeDisabled,
  onModeChanged,
  enforcedPortMode,
}: IpPortFormProps) => {
  const isEdgeConnect = applianceType === ApplianceType.edgeConnect
  const isRistSimpleProfileSupported = [ApplianceType.edgeConnect, ApplianceType.core].includes(applianceType)
  const isRtmpSupported = [ApplianceType.edgeConnect, ApplianceType.core].includes(applianceType)
  const modes = [IpPortMode.udp, IpPortMode.rtp, IpPortMode.srt]
    .concat(isRistSimpleProfileSupported ? [IpPortMode.rist] : [])
    .concat(isRtmpSupported ? [IpPortMode.rtmp] : [])
    .concat(isEdgeConnect ? [] : [IpPortMode.zixi])
    .sort((m1, m2) => m1.localeCompare(m2))

  const adminStatus = get(form.values, 'adminStatus') ? OutputAdminStatus.on : OutputAdminStatus.off

  const modeKey = `${namePrefix}.mode`
  const currentIpPortMode: IpPortMode = get(form.values, modeKey)
  const isValidMode = enforcedPortMode ? currentIpPortMode === enforcedPortMode : modes.includes(currentIpPortMode)
  const oldIpPortMode = useRef(currentIpPortMode)

  useEffect(() => {
    if (!isValidMode) {
      // Disallow mixing of port modes for outputs with multiple appliances
      const newMode = enforcedPortMode ?? ''
      if (currentIpPortMode !== newMode) {
        form.setFieldValue(modeKey, newMode, false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidMode, enforcedPortMode, currentIpPortMode, modeKey])

  useEffect(() => {
    if (isValidMode && currentIpPortMode !== oldIpPortMode.current) {
      onModeChanged?.()
    }
    oldIpPortMode.current = currentIpPortMode
  }, [isValidMode, currentIpPortMode, onModeChanged])

  return (
    <>
      <Select
        label="Mode"
        name={modeKey}
        newLine
        required
        disabled={isModeDisabled}
        options={modes}
        validators={{
          oneOf: { validValues: new Set(modes) },
        }}
      />
      {currentIpPortMode === IpPortMode.udp && (
        <UdpForm form={form} namePrefix={namePrefix} addresses={addresses} allocatedPort={allocatedPort} />
      )}
      {currentIpPortMode === IpPortMode.rtp && (
        <RtpForm
          namePrefix={namePrefix}
          form={form}
          applianceType={applianceType}
          index={index}
          addresses={addresses}
          allocatedPort={allocatedPort}
          applianceFecLimits={applianceFecLimits}
        />
      )}
      {currentIpPortMode === IpPortMode.rtmp && <RtmpForm form={form} namePrefix={namePrefix} />}
      {currentIpPortMode === IpPortMode.srt && (
        <SrtForm
          namePrefix={namePrefix}
          form={form as FormikProps<SrtOutput>}
          adminStatus={adminStatus}
          addresses={addresses}
          applianceType={applianceType}
          occupiedPorts={occupiedPorts.filter(o => o.protocol == 'udp')}
          allocatedPort={allocatedPort}
          onModeChanged={onModeChanged}
        />
      )}
      {currentIpPortMode === IpPortMode.zixi && (
        <ZixiForm
          namePrefix={namePrefix}
          form={form}
          addresses={addresses}
          applianceType={applianceType}
          allocatedPort={allocatedPort}
        />
      )}
      {currentIpPortMode === IpPortMode.rist && (
        <RistForm form={form} namePrefix={namePrefix} addresses={addresses} allocatedPort={allocatedPort} />
      )}
    </>
  )
}

export default IpPortForm
