import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Toolbar from '@mui/material/Toolbar'

import { Input, Role } from 'common/api/v1/types'

import { usePageParamsFilteredSelector, useInputsSelector, useUser, useSettingsSelector } from '../../../utils'
import routes from '../../../utils/routes'

import { MissingContent } from '../../common/MissingContent'
import Wrapper from '../../common/Wrapper'
import { Link } from '../../common/Link'
import Table from '../../common/Table'

import SendToOutputDialog from './SendToOutput'
import CommonActions from './CommonActions'
import DeleteDialog from './DeleteDialog'
import SharingDialog from './SharingDialog'
import getConfig from './tableConfig'
import { AppDispatch } from 'src/store'

export const List = () => {
  const history = useHistory()
  const { inputs, total, loading } = usePageParamsFilteredSelector(useInputsSelector)
  const user = useUser()
  const dispatch = useDispatch<AppDispatch>()
  const { settings } = useSettingsSelector()
  const expFeatures = settings?.expFeatures

  const [selected, setSelected] = useState<Array<Input['id']>>([])
  useEffect(() => {
    setSelected([])
  }, [inputs.map(i => i.id).join(',')])

  const handleSelect = (id: Input['id']) => {
    if (selected.includes(id)) {
      setSelected([...selected.filter(item => item !== id)])
    } else {
      setSelected([...selected, id])
    }
  }

  const handleSelectAll = () => {
    if (selected.length === inputs.length) {
      setSelected([])
    } else {
      setSelected(inputs.reduce<Array<Input['id']>>((acc, { id }) => acc.concat(id), []))
    }
  }

  return (
    <Wrapper name="Inputs">
      <Toolbar disableGutters>
        <Box sx={{ marginLeft: 'auto' }}>
          <Link to={routes.inputsNew()}>
            <Button variant="contained" color="primary">
              Create input
            </Button>
          </Link>
        </Box>
      </Toolbar>
      <CommonActions selected={selected} setSelected={setSelected} user={user} />
      <Table<Input>
        id="inputs"
        emptyMessageComponent={
          <MissingContent message="No inputs available" buttonText="Create input" url={routes.inputsNew()} />
        }
        config={getConfig({
          dispatch,
          handleSelect,
          handleSelectAll,
          inputs,
          selected,
          user,
          experimentalFeatures: expFeatures,
        })}
        rowProps={({ id, owner }) => {
          const isItemSelected = Boolean(id && selected.includes(id))
          return {
            onDoubleClick: () =>
              user.group === owner || user.role === Role.super ? history.push(routes.inputsUpdate({ id })) : null,
            'aria-checked': isItemSelected,
            tabIndex: -1,
            selected: isItemSelected,
          }
        }}
        data={inputs}
        pagination={{ useUrlSearchParams: true, total }}
        pending={loading}
      />
      <DeleteDialog setSelected={setSelected} />
      <SharingDialog />
      <SendToOutputDialog />
    </Wrapper>
  )
}
