import { useDispatch } from 'react-redux'
import { useDrop } from 'react-dnd'
import Typography from '@mui/material/Typography'

import { OutputRedundancyMode } from 'common/api/v1/types'
import { setInputOfOutput } from '../../../../../redux/actions/outputsActions'
import { updateInputRecipients } from '../../../../../redux/actions/inputsActions'
import { useConfirmationDialog, isOutput } from '../../../../../utils'

import OutputList from './OutputList'
import Output from './Output'
import { EnrichedInput, EnrichedOutput, EnrichedOutputRecipientList } from '../../../../../api/nm-types'
import { inputRedundancy } from 'common/utils'
import { AppDispatch } from 'src/store'

type DropPayload = { output: EnrichedOutput | EnrichedOutputRecipientList; input: EnrichedInput }

interface OutputProps {
  output: EnrichedOutput | EnrichedOutputRecipientList
}

const Item = ({ output }: OutputProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const setConfirmation = useConfirmationDialog()

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: 'input',
      drop: (item: EnrichedInput) => {
        onInputDrop({ output, input: item })
      },
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [output],
  )

  const onInputDrop = ({ output, input }: DropPayload) => {
    if (!isOutput(output)) {
      const action = () =>
        dispatch(
          updateInputRecipients({
            id: input.id,
            listsAdded: [output.id],
            outputsAdded: [],
            outputsRemoved: [],
          }),
        )
      if (output._hasOutputsInUse)
        return setConfirmation(
          () => void action(),
          'At least one of the outputs in the list is already streaming an input, are you sure you want to proceed?',
        )
      return action()
    }
    const action = () => dispatch(setInputOfOutput({ output, inputId: input.id }))

    const isOutputStreamingOtherInput = output._input && output._input!.id !== input.id
    const isRedundantOutput = output.redundancyMode != OutputRedundancyMode.none
    const isRedundantInput = inputRedundancy(input)
    const isOutputRedundancyBeingRemoved = isRedundantOutput && !isRedundantInput
    const showPrompt = isOutputStreamingOtherInput || isOutputRedundancyBeingRemoved
    if (showPrompt) {
      return setConfirmation(
        () => void action(),
        <>
          {isOutputStreamingOtherInput && (
            <Typography>- Current output is already streaming the input {output._input!.name} </Typography>
          )}
          {isOutputRedundancyBeingRemoved && (
            <Typography>- You have selected a non-redundant input - output redundancy will be disabled </Typography>
          )}
          <Typography> Proceed? </Typography>
        </>,
      )
    }
    return action()
  }

  const isHovered = canDrop && isOver

  return (
    <div ref={drop}>
      {isOutput(output) ? (
        <Output output={output} isHovered={isHovered} />
      ) : (
        <OutputList output={output} isHovered={isHovered} />
      )}
    </div>
  )
}

export default Item
