import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { SnackbarProvider } from 'notistack'
import { CheckCircle, Warning, Error, Info } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

import FaviconPng from './img/favicon.png'
import { AppDispatch, GlobalState } from './store'
import { styles } from './Common'
import Snackbar from './components/common/Snackbar'
import { Login } from './components/login'
import LoggedIn from './components/loggedin'
import { ImageName, User } from 'common/api/v1/types'
import { init } from './redux/actions/uiActions'
import { useFetchedImage } from './components/common/LogoFetcher'

const StyledSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
  '&.SnackbarItem-variantSuccess': styles.success(theme),
  '&.SnackbarItem-variantError': styles.error(theme),
  '&.SnackbarItem-variantWarning': styles.warning(theme),
  '&.SnackbarItem-variantInfo': styles.info(theme),
}))

const iconProps = {
  style: {
    opacity: 0.6,
    marginRight: '12px',
  },
}

/**
 * Used just to add history to store middleware to use in redux epics
 */
const Initializer = () => {
  const history = useHistory()
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    dispatch(init(history))
  }, [dispatch])
  return null
}

function App() {
  const { source, type } = useFetchedImage(ImageName.favicon)

  useEffect(() => {
    if (source === '') return // fetch not complete yet

    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      if (source === null) {
        // use default
        link.href = FaviconPng
        link.type = 'image/png'
      } else {
        link.href = source
        link.type = type
      }
      document.getElementsByTagName('head')[0].appendChild(link)
    }
  }, [source])

  const user = useSelector(({ userReducer }: GlobalState) => userReducer.user, shallowEqual) as User
  const isLoggedIn = 'id' in user
  return (
    <>
      <Initializer />

      <StyledSnackbarProvider
        dense={!isLoggedIn}
        maxSnack={3}
        iconVariant={{
          success: <CheckCircle {...iconProps} />,
          error: <Error {...iconProps} />,
          warning: <Warning {...iconProps} />,
          info: <Info {...iconProps} />,
        }}
      >
        <>
          <Snackbar />
          {isLoggedIn ? <LoggedIn /> : <Login />}
        </>
      </StyledSnackbarProvider>
    </>
  )
}

export default App
