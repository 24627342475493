import { get } from 'lodash'
import { useAsync } from 'react-async-hook'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'
import { Warning } from '@mui/icons-material'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'

import { Api } from '../../../store'
import { Appliance, ApplianceType, Group } from 'common/api/v1/types'
import { FailedFetchingContent } from '../../common/FailedFetchingContent'
import routes from '../../../utils/routes'
import { Link } from '../../common/Link'

const styles = {
  title: {
    margin: (theme: Theme) => theme.spacing(2, 0),
  },
}

interface RemoveApplianceProps {
  appliance: Appliance
  hideConfirmDialog: () => void
}
export const RemoveDialog = ({ appliance, hideConfirmDialog }: RemoveApplianceProps) => {
  const owner = appliance.owner as Group

  const { result: inputs, loading: inputsLoading, error: inputsError } = useAsync(
    async () =>
      Api.inputApi.getInputs({
        applianceId: appliance.id,
        pageNumber: '0',
        rowsPerPage: '10',
      }),
    [appliance.id],
  )

  const { result: outputs, loading: outputsLoading, error: outputsError } = useAsync(
    async () =>
      Api.outputApi.getOutputs({
        applianceId: appliance.id,
        pageNumber: '0',
        rowsPerPage: '10',
      }),
    [appliance.id],
  )

  return (
    <>
      <Typography variant="h2" color="textPrimary" sx={styles.title}>
        You are about to delete appliance &apos;{appliance.name}&apos;, owned by &apos;{get(owner, 'name')}&apos;&nbsp;
      </Typography>
      {appliance.type === ApplianceType.core && (
        <>
          <Stack direction="row" alignItems="center" gap={1}>
            <Warning />
            <Typography variant="h3">Warning!</Typography>
          </Stack>

          <p>This is a core appliance, deleting it might cause service disruptions.</p>

          {inputsError && <FailedFetchingContent message={'Failed to fetch inputs'} />}
          {((inputs && inputs.total > 0) || (outputs && outputs.total > 0)) && (
            <p>
              The following inputs and outputs are dependent on this core appliance and needs to be removed or
              reconfigured before this appliance can be deleted.
            </p>
          )}

          {inputs && inputs.total > 0 && (
            <>
              <Typography variant="h4">Inputs:</Typography>
              <ul>
                {inputs.items.map(input => (
                  <li key={input.id}>
                    <Link underline="hover" to={routes.inputsUpdate({ id: input.id })} onClick={hideConfirmDialog}>
                      {input.name}
                    </Link>
                  </li>
                ))}
                {inputs.items.length !== inputs.total && <li>⋯</li> /* more items than listed */}
              </ul>
            </>
          )}

          {outputsError && <FailedFetchingContent message={'Failed to fetch outputs'} />}
          {outputs && outputs.total > 0 && (
            <>
              <Typography variant="h4">Outputs:</Typography>
              <ul>
                {outputs.items.map(output => (
                  <li key={output.id}>
                    <Link underline="hover" to={routes.outputsUpdate({ id: output.id })} onClick={hideConfirmDialog}>
                      {output.name}
                    </Link>
                  </li>
                ))}
                {outputs.items.length !== outputs.total && <li>⋯</li> /* more items than listed */}
              </ul>
            </>
          )}

          {(inputsLoading || outputsLoading) && <CircularProgress />}

          <p>
            Streams without redundancy currently routed through this appliance will experience a brief disruption while
            the system reconfigures.
          </p>
        </>
      )}
    </>
  )
}
