import { RistMetricType, MetricWindow, ApplianceConfiguration } from 'common/api/v1/types'
import {
  RsInput,
  RistMainProfileOutput,
  RsOutput,
  TunnelClient,
  Tunnel,
  VaInputPipeConfig,
  VaOutputPipeConfig,
  RistMainProfileInput,
} from 'common/messages'
import { SimulationNodeDatum, SimulationLinkDatum } from 'd3-force'
import { EnrichedInput, EnrichedOutput } from '../../../api/nm-types'
import { EnhancedInputConfig, InputConfig, StreamIdToOutputMap } from 'common/api/v1/internal'
import { inputToTr101290Output, isOutput, notUndefined, tr101290Enabled } from 'common/api/v1/helpers'
import { GraphType } from './index'

export interface GraphNode extends SimulationNodeDatum {
  id: string | number
  name: string
  metadata?: RsInput | RsOutput | { type: 'va' } | { tunnels: Tunnel[]; type: 'tunnel' }
  inputName?: string
  outputName?: string
  targetApplianceName?: string
  metadataText?: string
  fontWeight?: 'bold'

  // Used by d3
  fixed?: boolean
  hidden?: boolean
}

export interface GraphLink extends SimulationLinkDatum<GraphNode> {
  source: GraphNode | string | number
  target: GraphNode | string | number
  distance: number
  hide: boolean
  width: number
}

export const createGraphNodesAndLinks = (
  input: EnhancedInputConfig,
  inputs: EnrichedInput[],
  outputs: EnrichedOutput[],
  channelId: number,
  selectedOutputId: string,
  graphType: GraphType,
  tr101290EnabledState: boolean,
): { nodes: GraphNode[]; links: GraphLink[] } => {
  let nodes: GraphNode[] = []
  const links: GraphLink[] = []

  const { appliances, streamIdToOutputMap } = input
  Object.entries(appliances).forEach(([applianceName, config]) => {
    if (applianceHasObjectInChannel(config, channelId)) {
      if (graphType === GraphType.All || graphType === GraphType.Config) {
        addApplianceNode(nodes, applianceName)
      }

      if (graphType === GraphType.All || graphType === GraphType.Tunnels) {
        addTunnelApplianceNode(applianceName, config, channelId, inputs, outputs, nodes)
      }
    }

    if (graphType === GraphType.All || graphType === GraphType.Config) {
      if (includedInNodes(nodes, applianceName)) {
        addConfigNodes(config, channelId, selectedOutputId, streamIdToOutputMap, applianceName, inputs, outputs, nodes)
        addTr101290ConfigNodes(config, channelId, inputs, outputs, nodes, tr101290EnabledState)
      }
    }
  })

  nodes = removeNodesWithoutRistConnections(nodes)

  if (graphType === GraphType.All || graphType === GraphType.Tunnels) {
    Object.entries(appliances).map(([applianceName, config]) => {
      addTunnelApplianceLinks(nodes, applianceName, config, channelId, appliances, links)
    })
  }

  if (graphType === GraphType.All || graphType === GraphType.Config) {
    addLinksForAllNodesWithTargetApplianceNames(nodes, links)

    Object.entries(appliances).map(([applianceName, config]) => {
      if (includedInNodes(nodes, applianceName)) {
        config.inputs.forEach(input => {
          addLinksFromInputsToTr101290Output(
            config,
            input,
            channelId,
            links,
            tr101290EnabledState &&
              (inputs.find(enrichedInput => enrichedInput.channelId === input.channelId)?.tr101290Enabled ?? true),
          )
          addLinksFromInputToOutputs(config, input, channelId, selectedOutputId, streamIdToOutputMap, nodes, links)
        })
      }
    })
    addRistOutputToRistInputLinks(appliances, nodes, channelId, links)
  }

  // Handle vaInputs and vaOutput
  if (graphType === GraphType.All || graphType === GraphType.Config) {
    addVaInputsAndOutputs(appliances, nodes, channelId, inputs, links, selectedOutputId, streamIdToOutputMap)
  }

  // console.log(nodes)
  // console.log(links)
  return { nodes, links }
}

function addApplianceNode(nodes: GraphNode[], nodeName: string) {
  nodes.push({
    id: nodeName,
    name: (nodeName || '').split('::').pop() || '', // Don't include the name prefix since it clutters everything
    fontWeight: 'bold',
  })
}

function addTunnelApplianceNode(
  nodeName: string,
  config: ApplianceConfiguration,
  channelId: number,
  inputs: EnrichedInput[],
  outputs: EnrichedOutput[],
  nodes: GraphNode[],
) {
  const node: GraphNode = {
    id: `${nodeName}_tunnel`,
    name: nodeName,
    metadata: {
      type: 'tunnel',
      tunnels: config.tunnels
        .filter(
          tunnel =>
            channelId === -1 ||
            [...config.inputs, ...config.outputs].some(
              obj =>
                obj.channelId === channelId &&
                (obj.type === 'push-rist-input' || obj.type === 'push-rist-output') &&
                obj.tunnelId === tunnel.tunnelId,
            ),
        )
        .sort((a, b) => (a.tunnelId <= b.tunnelId ? 1 : -1)),
    },
    get metadataText() {
      return formatMetadataAsText(this.metadata, inputs, outputs)
    },
  }
  nodes.push(node)
}

function addConfigNodes(
  config: ApplianceConfiguration,
  channelId: number,
  selectedOutputId: string,
  streamIdToOutputMap: StreamIdToOutputMap,
  applianceName: string,
  inputs: EnrichedInput[],
  outputs: EnrichedOutput[],
  nodes: GraphNode[],
) {
  const objects = [...config.inputs, ...config.outputs]
  objects.forEach(obj => {
    if (includedInChannel(channelId, obj) && isNotExcludedOutput(selectedOutputId, streamIdToOutputMap, obj)) {
      const node: GraphNode = {
        id: obj.streamId,
        name: `${obj.type}`,
        targetApplianceName: applianceName,
        inputName: (
          ((obj.type === 'udp-input' || obj.type === 'udp-output') &&
            inputs.find(input => input.channelId === obj.channelId)) || { name: '' }
        ).name,
        outputName: streamIdToOutputMap[obj.streamId]?.outputName,
        metadata: obj,
        metadataText: formatMetadataAsText(obj, inputs, outputs),
      }
      nodes.push(node)
    }
  })
}

function addTr101290ConfigNodes(
  config: ApplianceConfiguration,
  channelId: number,
  inputs: EnrichedInput[],
  outputs: EnrichedOutput[],
  nodes: GraphNode[],
  tr101290EnabledState: boolean,
) {
  if (!tr101290EnabledState) {
    return
  }
  config.inputs.forEach(input => {
    if (includedInChannel(channelId, input)) {
      if (
        tr101290Enabled({
          isTr101290Enabled: inputs.find(i => i.channelId === input.channelId)?.tr101290Enabled ?? true,
          input,
          isFirstInput: config.inputs.find(i => i.channelId === input.channelId)?.streamId === input.streamId,
        })
      ) {
        const output = inputToTr101290Output(input, '<socketPath>', null) // TODO: TR101 will currently always look like it is using unix sockets
        const node: GraphNode = {
          id: output.streamId + 1000000, // Made up ID because the streamId for the udpOutput is the same as the corresponding input
          name: `${output.type}: tr101`,
          metadata: output,
          metadataText: formatMetadataAsText(output, inputs, outputs),
        }
        nodes.push(node)
      }
    }
  })
}

function removeNodesWithoutRistConnections(nodes: GraphNode[]) {
  nodes = nodes.filter(outputNode => {
    return (
      outputNode.metadata?.type !== 'push-rist-output' ||
      nodes.some(
        inputNode =>
          inputNode.metadata?.type === 'push-rist-input' &&
          outputNode.metadata?.type === 'push-rist-output' &&
          isRistInputAndOutputConnected(
            inputNode.metadata,
            outputNode.metadata,
            outputNode.metadata?.channelId,
            inputNode.id,
            outputNode.id,
          ),
      )
    )
  })
  return nodes
}

function addTunnelApplianceLinks(
  nodes: GraphNode[],
  applianceName: string,
  config: ApplianceConfiguration,
  channelId: number,
  appliances: { [p: string]: ApplianceConfiguration },
  links: GraphLink[],
) {
  if (includedInNodes(nodes, `${applianceName}_tunnel`)) {
    config.tunnels.forEach(tunnel => {
      if (
        tunnel.type === 'tunnel-client' &&
        [...config.inputs, ...config.outputs].some(
          obj =>
            (obj.channelId === channelId &&
              ['push-rist-input', 'push-rist-output'].includes(obj.type) &&
              (obj as RistMainProfileInput).tunnelId === tunnel.tunnelId) ||
            channelId === -1,
        )
      ) {
        const target = findTargetNodeOfTunnelClient(appliances, tunnel)
        if (target) {
          links.push({ source: `${applianceName}_tunnel`, target, distance: 200, width: 10, hide: false })
        }
      }
    })
  }
}

function addLinksForAllNodesWithTargetApplianceNames(nodes: GraphNode[], links: GraphLink[]) {
  nodes.forEach(node => {
    if (node.targetApplianceName) {
      links.push({
        source: node.id,
        target: node.targetApplianceName,
        distance: 60,
        width: 1,
        hide: true,
      })
    }
  })
}

function addLinksFromInputsToTr101290Output(
  config: ApplianceConfiguration,
  input: RsInput,
  channelId: number,
  links: GraphLink[],
  tr101290EnabledState: boolean,
) {
  if (!tr101290EnabledState) {
    return
  }
  // Add link from tr 101 290 udp-output to input (or all inputs in case of redundant rtp inputs)
  const firstInputOfChannel = config.inputs.find(i => i.channelId === input.channelId)
  if (
    tr101290Enabled({
      isTr101290Enabled: true,
      input,
      isFirstInput: firstInputOfChannel?.channelId === input.channelId,
    }) &&
    includedInChannel(channelId, input) &&
    firstInputOfChannel
  ) {
    links.push({
      source: input.streamId,
      target: firstInputOfChannel.streamId + 1000000,
      distance: 100,
      width: 1,
      hide: false,
    })
  }
}

function addLinksFromInputToOutputs(
  config: ApplianceConfiguration,
  input: RsInput,
  channelId: number,
  selectedOutputId: string,
  streamIdToOutputMap: StreamIdToOutputMap,
  nodes: GraphNode[],
  links: GraphLink[],
) {
  config.outputs.forEach(output => {
    if (
      input.channelId === output.channelId &&
      includedInChannel(channelId, output) &&
      isNotExcludedOutput(selectedOutputId, streamIdToOutputMap, output) &&
      includedInNodes(nodes, input.streamId) &&
      includedInNodes(nodes, output.streamId)
    ) {
      links.push({ source: input.streamId, target: output.streamId, distance: 100, width: 1, hide: false })
    }
  })
}

function addRistOutputToRistInputLinks(
  appliances: { [p: string]: ApplianceConfiguration },
  nodes: GraphNode[],
  channelId: number,
  links: GraphLink[],
) {
  Object.entries(appliances).map(([sourceApplianceName, sourceApplianceConfig]) => {
    if (includedInNodes(nodes, sourceApplianceName)) {
      sourceApplianceConfig.outputs.forEach(output => {
        if (output.type === 'push-rist-output') {
          Object.entries(appliances).map(([targetApplianceName, targetApplianceConfig]) => {
            if (includedInNodes(nodes, targetApplianceName)) {
              targetApplianceConfig.inputs.forEach(input => {
                if (isRistInputAndOutputConnected(input, output, channelId, sourceApplianceName, targetApplianceName)) {
                  links.push({
                    source: output.streamId,
                    target: input.streamId,
                    distance: 200,
                    width: 1,
                    hide: false,
                  })
                }
              })
            }
          })
        }
      })
    }
  })
}

function addVaInputsAndOutputs(
  appliances: InputConfig,
  nodes: GraphNode[],
  channelId: number,
  inputs: EnrichedInput[],
  links: GraphLink[],
  selectedOutputId: string,
  streamIdToOutputMap: StreamIdToOutputMap,
) {
  Object.entries(appliances).forEach(([applianceName, config]) => {
    if (includedInNodes(nodes, applianceName)) {
      if (config.vaInput?.length) {
        addVaInputConfig(config, channelId, nodes, inputs, links)
      }

      if (config.vaOutput?.length) {
        addVaOutputConfig(config, channelId, selectedOutputId, streamIdToOutputMap, nodes, applianceName, inputs, links)
      }
    }
  })
}

function addVaInputConfig(
  config: EnhancedInputConfig['appliances'][string],
  channelId: number,
  nodes: GraphNode[],
  inputs: EnrichedInput[],
  links: GraphLink[],
) {
  config.vaInput?.forEach(vaInput => {
    const keys = Object.keys(vaInput)
    const pipelineKeys: string[] = [
      keys.find(k => k.includes('Input') || k.includes('Sink')),
      keys.find(k => k === 'encoder'),
      'edgeSource',
    ].filter(notUndefined)

    const ristserverInput = config.inputs.find(
      input =>
        input.type != 'unix-input' &&
        input.localIp === vaInput.edgeSource.remoteHost &&
        input.localPort === parseInt(vaInput.edgeSource.remotePort),
    )
    const inputPipeChannelId = ristserverInput?.channelId

    if (includedInChannel(channelId, ristserverInput)) {
      for (let i = 0; i < pipelineKeys.length; i++) {
        const key = pipelineKeys[i]
        const conf = vaInput[key as keyof VaInputPipeConfig]
        nodes.push({
          id: inputPipeChannelId + key,
          inputName: (inputs.find(input => input.channelId === inputPipeChannelId) || { name: '' }).name,
          name: key,
          metadata: { type: 'va' },
          metadataText: stringifyVaConf(conf),
        })
        if (ristserverInput) {
          links.push({
            source: inputPipeChannelId + key,
            target:
              pipelineKeys[i + 1] !== undefined ? inputPipeChannelId + pipelineKeys[i + 1] : ristserverInput.streamId,
            distance: 100,
            width: 1,
            hide: false,
          })
        }
      }
    }
  })
}

function addVaOutputConfig(
  config: EnhancedInputConfig['appliances'][string],
  channelId: number,
  selectedOutputId: string,
  streamIdToOutputMap: StreamIdToOutputMap,
  nodes: GraphNode[],
  applianceName: string,
  inputs: EnrichedInput[],
  links: GraphLink[],
) {
  config.vaOutput?.forEach(vaOutput => {
    const keys = Object.keys(vaOutput)
    const pipelineKeys: string[] = [
      keys.find(k => k.includes('Output') || k.includes('Source')),
      keys.find(k => k === 'decoder'),
      'edgeSink',
    ].filter(notUndefined)

    const ristserverOutput = config.outputs.find(
      output =>
        output.type != 'unix-output' &&
        output.remoteIp === vaOutput.edgeSink.localIf &&
        output.remotePort === parseInt(vaOutput.edgeSink.recvPort),
    )
    const outputPipeChannelId = ristserverOutput?.channelId

    const idForPipeObject = (
      applianceName: string,
      channelId: number | undefined,
      pipelineKey: string,
      edgeSink: { recvPort: string },
    ) => applianceName + channelId + pipelineKey + edgeSink.recvPort
    if (
      ristserverOutput &&
      includedInChannel(channelId, ristserverOutput) &&
      isNotExcludedOutput(selectedOutputId, streamIdToOutputMap, ristserverOutput)
    ) {
      for (let i = 0; i < pipelineKeys.length; i++) {
        const key = pipelineKeys[i]
        const conf = vaOutput[key as keyof VaOutputPipeConfig]
        nodes.push({
          id: idForPipeObject(applianceName, outputPipeChannelId, key, vaOutput.edgeSink),
          name: key,
          inputName: (inputs.find(input => input.channelId === outputPipeChannelId) || { name: '' }).name,
          outputName: ristserverOutput && streamIdToOutputMap[ristserverOutput.streamId]?.outputName,
          metadata: { type: 'va' },
          metadataText: stringifyVaConf(conf),
        })
        if (ristserverOutput) {
          const target = idForPipeObject(applianceName, outputPipeChannelId, key, vaOutput.edgeSink)
          const source =
            pipelineKeys[i + 1] !== undefined
              ? idForPipeObject(applianceName, outputPipeChannelId, pipelineKeys[i + 1], vaOutput.edgeSink)
              : ristserverOutput.streamId
          links.push({
            target,
            source,
            distance: 100,
            width: 1,
            hide: false,
          })
        }
      }
    }
  })
}

function formatMetadataAsText(
  metadata: GraphNode['metadata'],
  inputs: EnrichedInput[],
  outputs: EnrichedOutput[],
): string {
  if (metadata) {
    if (metadata.type && metadata.type !== 'va' && metadata.type !== 'tunnel') {
      let str = `streamId: ${metadata.streamId}`

      if (metadata.type === 'udp-output' || metadata.type === 'zixi-output' || metadata.type === 'srt-output') {
        if (metadata.delayMs) {
          str += `, delayMs: ${metadata.delayMs}`
        }

        if (metadata.delayMode) {
          str += `, delayMode: ${metadata.delayMode}`
        }
      }

      if (metadata.type === 'push-rist-input' || (metadata.type === 'push-rist-output' && metadata.tunnelId)) {
        str += `, tunnelId: ${metadata.tunnelId}`
      }

      if (metadata.streamId) {
        const handledMetricSet = new Set<string>()
        const io = [...inputs, ...outputs]
        for (const i of io) {
          for (const m of i.metrics?.ristMetrics || []) {
            const metricHash = `${m.type}#${m.streamId}#${m.window}`
            if (handledMetricSet.has(metricHash)) {
              continue
            }
            handledMetricSet.add(metricHash)
            if (m.streamId == metadata.streamId) {
              switch (m.type) {
                case RistMetricType.udpInput:
                case RistMetricType.rtpInput:
                  str += `, receiveBitrate: ${m.receiveBitrate}`
                  break
                case RistMetricType.ristOutput:
                  str += `, sendBitrate: ${m.sendBitrate}`
                  break
                case RistMetricType.udpOutput:
                  if (metadata.type != 'udp-output') {
                    // Do not set tr101 data on corresponding input
                    continue
                  }
                  switch (m.window) {
                    case MetricWindow.s10:
                      str += `, sendBitrate: ${m.sendBitrate}`
                      break
                  }
                  break
                case RistMetricType.ristInput:
                  switch (m.window) {
                    case MetricWindow.m1:
                      break
                    case MetricWindow.s10:
                      str += `, receiveBitrate: ${m.receiveBitrate}`
                      break
                    default:
                  }
                // str += `, packetsLost: ${m.packetsLost}`
              }
              break
            }
          }
        }
      }

      if (metadata.type != 'unix-output' && metadata.type != 'unix-input') {
        str += `, ${metadata.localIp}:${metadata.localPort}`
      }
      if (isOutput(metadata) && metadata.type != 'unix-output' && metadata.remotePort) {
        str += `→${metadata.remoteIp}:${metadata.remotePort}`
      }

      return str
    } else if (metadata.type === 'tunnel') {
      return metadata.tunnels
        .map(
          (tunnel: Tunnel) =>
            `id: ${tunnel.tunnelId}, ${tunnel.localIp}:${tunnel.localPort}` +
            (tunnel.type === 'tunnel-client' && tunnel.remoteIp ? `→${tunnel.remoteIp}:${tunnel.remotePort}` : '') +
            `, s: ${tunnel.secret}`,
        )
        .join('\n')
    }
  }
  return 'nothing'
}

function includedInChannel(channelId: number, obj?: { channelId: number }) {
  return obj?.channelId === channelId || channelId === -1
}

function isNotExcludedOutput(
  selectedOutputId: string,
  streamIdToOutputMap: StreamIdToOutputMap,
  obj: RsInput | RsOutput,
) {
  return (
    selectedOutputId === '-1' ||
    /* streamId is only set for egress sinks, so everything else should be included since it's not an output that can be filtered out */
    streamIdToOutputMap[obj.streamId] === undefined ||
    streamIdToOutputMap[obj.streamId].outputId === selectedOutputId
  )
}

function includedInNodes(nodes: GraphNode[], id: string | number): boolean {
  return nodes.map(n => n.id).includes(id)
}

function applianceHasObjectInChannel(config: ApplianceConfiguration, channelId: number) {
  return [...config.inputs, ...config.outputs].some(obj => includedInChannel(channelId, obj))
}

function findTargetNodeOfTunnelClient(
  input: EnhancedInputConfig['appliances'],
  tunnelClient: TunnelClient,
): string | undefined {
  const target = Object.entries(input).find(([name, value]) => {
    return value.tunnels.some(tunnel => {
      if (tunnel.type === 'tunnel-server' && tunnel.tunnelId === tunnelClient.tunnelId) {
        return name
      }
    })
  })

  if (target) {
    return `${target[0]}_tunnel`
  }
}

function isRistInputAndOutputConnected(
  input: RsInput | undefined,
  output: RistMainProfileOutput | undefined,
  channelId: number,
  sourceName: string | number,
  targetName: string | number,
) {
  return (
    input !== undefined &&
    output !== undefined &&
    input.type === 'push-rist-input' &&
    output.type === 'push-rist-output' &&
    input.tunnelId === output.tunnelId &&
    input.channelId === output.channelId &&
    includedInChannel(channelId, input) &&
    sourceName !== targetName &&
    output.remoteIp === input.localIp &&
    output.remotePort === input.localPort
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function stringifyVaConf(conf: any | undefined): string | undefined {
  return JSON.stringify(conf, null, 2)
    ?.replace(/^{\n|\n}$/g, '')
    .split('\n')
    .reverse()
    .join('\n')
}
