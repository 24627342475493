import { useRef } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import cn from 'classnames'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'
import { FormikProps } from 'formik'
import { get } from 'lodash'
import * as uuid from 'uuid'

import {
  EnrichedApplianceWithOwner,
  EnrichedInputPort,
  EnrichedOutputPort,
  PaginatedRequestParams,
} from '../../../api/nm-types'
import { Api, GlobalState } from '../../../store'
import {
  Address,
  Appliance,
  ApplianceType,
  ApplianceVersion,
  BuildInfo,
  IpPortMode,
  ListResult,
  PortBase,
  PortMode,
  Region,
  RegionalPort,
} from 'common/api/v1/types'
import { BackendValidation, GridItem, Paper } from '../../common/Form'

import { AutoComplete } from '../AutoComplete'
import { EnrichedOutputWithEnrichedPorts } from '../../outputs/Edit'
import { EnrichedInputWithEnrichedPorts } from '../../inputs/Edit'
import { RegionalInterfaceSection } from './Regional'
import { ApplianceInterfaceSection } from './Appliance'
import { runningDifferentSoftwareVersion } from '../../../utils'

export const interfaceSectionPaper = {
  padding: (theme: Theme) => theme.spacing(0, 0, 2),
  margin: (theme: Theme) => theme.spacing(4, 0, 0),
}

export const styles = {
  warningText: {
    marginLeft: (theme: Theme) => theme.spacing(1),
    color: (theme: Theme) => theme.palette.warning.light,
  },
}

export const INTERFACE_SECTION_FORM_PREFIX = '_interfaceSection'

export type InterfaceSectionSelectionData<T extends EnrichedInputPort | EnrichedOutputPort> = {
  region?: Pick<Region, 'id' | 'name'>
  appliance?: Pick<Appliance, 'id' | 'name' | 'type'> & Partial<Pick<Appliance, 'settings'>>
  ports: T[]
}

export const formatInterfaceAddress = (address: Address) => {
  const hasPublicAddress = !!address.publicAddress
  return `${address.address}${hasPublicAddress ? ` Public: ${address.publicAddress}` : ''}`
}

function getPortAppliance<T extends EnrichedInputPort | EnrichedOutputPort>(port: T) {
  return isRegionalPort(port) ? port.region!.allocatedPort!.appliance : port._port.appliance
}

export const groupPortsByApplianceOrRegion = <T extends EnrichedInputPort | EnrichedOutputPort>(
  ports: T[],
): { [key: string]: InterfaceSectionSelectionData<T> } => {
  const portsGroupedByApplianceOrRegion = ports.reduce(
    (portsGroupedByApplianceOrRegion: { [key: string]: InterfaceSectionSelectionData<T> }, port: T) => {
      const isRegion = isRegionalPort(port)
      // Prefer '_appliance' to 'appliance' since it contains settings
      const applianceOrRegion = isRegion ? port.region! : port._port._appliance ?? port._port.appliance
      const portAppliance = getPortAppliance(port)
      const existingEntry = Object.entries(portsGroupedByApplianceOrRegion).find(([_, v]) => {
        return !!v.ports.find(p => getPortAppliance(p).id === portAppliance.id)
      })
      if (existingEntry) {
        existingEntry[1].ports.push(port)
      } else {
        const [key, entry] = makeInterfaceSection({
          region: isRegion ? applianceOrRegion : undefined,
          appliance: isRegion ? undefined : (applianceOrRegion as any),
        })
        entry.ports.push(port)
        portsGroupedByApplianceOrRegion[key] = entry as any
      }
      return portsGroupedByApplianceOrRegion
    },
    {},
  )
  if (Object.keys(portsGroupedByApplianceOrRegion).length === 0) {
    // Ensure at least 1 interface section
    const [key, interfaceSection] = makeInterfaceSection<T>({ region: undefined, appliance: undefined })
    portsGroupedByApplianceOrRegion[key] = interfaceSection
  }
  return portsGroupedByApplianceOrRegion
}

export const makeInterfaceSection = <T extends EnrichedInputPort | EnrichedOutputPort>({
  region,
  appliance,
}: {
  region: Pick<Region, 'id' | 'name'> | undefined
  appliance: (Pick<Appliance, 'id' | 'name' | 'type'> & Partial<Pick<Appliance, 'settings'>>) | undefined
}): [string, InterfaceSectionSelectionData<T>] => [
  `${INTERFACE_SECTION_FORM_PREFIX}-${uuid.v4()}`,
  {
    region,
    appliance,
    ports: [],
  },
]

export const collectPortsFromInterfaceSections = <T extends EnrichedInputPort | EnrichedOutputPort>(
  values: object,
): T[] => {
  return collectInterfaceSectionEntries(values).flatMap(([_, value]) => value.ports as T[])
}

export const collectInterfaceSectionEntries = <T extends EnrichedInputPort | EnrichedOutputPort>(
  values: object,
): Array<[string, InterfaceSectionSelectionData<T>]> =>
  Object.entries(values).filter(([key]) => key.startsWith(INTERFACE_SECTION_FORM_PREFIX))

/// Business logic regarding multi-appliance inputs and outputs.
export const isApplianceOrRegionSelectable = (applianceOrRegion: ApplianceOrRegion, values: InterfaceSectionForm) => {
  const interfaceSectionEntries = collectInterfaceSectionEntries(values)
  const numberOfInterfaceSections = interfaceSectionEntries.length
  if (numberOfInterfaceSections <= 1) {
    // Single interface section:
    // User may or may not yet have selected an appliance or region - either way he/she is free to select/change it.
    return true
  }

  // Multiple interface sections:
  const selectedAppliancesAndRegions = interfaceSectionEntries.map(([_k, v]) => v.region ?? v.appliance)
  const firstSelectedApplianceOrRegion = selectedAppliancesAndRegions.find(Boolean)
  if (!firstSelectedApplianceOrRegion) {
    // User has two empty interface sections but with no appliance or region selected (can happen if they clear the selected value)
    // Must select a region or a core appliance
    return !isAppliance(applianceOrRegion) || applianceOrRegion.type === ApplianceType.core
  }
  if (isAppliance(firstSelectedApplianceOrRegion)) {
    // User has previously selected an appliance - the second selection must be a different appliance of the same type
    const isApplianceAlreadySelected = !!selectedAppliancesAndRegions.find(
      a => a && isAppliance(a) && a.id === applianceOrRegion.id,
    )
    return (
      isAppliance(applianceOrRegion) &&
      applianceOrRegion.type === firstSelectedApplianceOrRegion.type &&
      !isApplianceAlreadySelected
    )
  }
  // User has previously selected a region - the second selection must also be a region
  return !isAppliance(applianceOrRegion)
}

export type ApplianceOrRegion = Pick<Appliance, 'id' | 'name' | 'type' | 'settings'> | Pick<Region, 'id' | 'name'>
export type InterfaceSectionForm = EnrichedInputWithEnrichedPorts | EnrichedOutputWithEnrichedPorts

interface FormProps {
  index: number
  namePrefix: string
  title: string
  onRemove?: (namePrefix: string) => void
  isInputForm: boolean
  isEditingExistingEntity: boolean
  isCopyingExistingEntity: boolean
  inputId: string | undefined
  outputId: string | undefined
  initialApplianceOrRegionId: string | undefined
  enforcedPortMode: PortMode | undefined
  isModeSelectionDisabled: boolean
  isApplianceOrRegionSelectable: (applianceOrRegion: ApplianceOrRegion) => boolean
  onApplianceOrRegionSelected: (applianceOrRegion: ApplianceOrRegion | null) => void
}

export const isRegionalPort = (port?: PortBase): port is RegionalPort =>
  !!port && 'region' in port && !!(port as RegionalPort).region

export const isAppliance = (
  applianceOrRegion: ApplianceOrRegion,
): applianceOrRegion is Pick<Appliance, 'id' | 'name' | 'type' | 'settings'> => 'type' in applianceOrRegion

export function isCoreNode(values: InterfaceSectionForm) {
  for (const { region, appliance } of collectInterfaceSectionEntries(values).map(([_, value]) => value)) {
    if (region) return true
    if (appliance?.type === ApplianceType.core) return true
  }
  return false
}

const getApplianceSoftwareString = (
  version: ApplianceVersion,
  buildInfo: BuildInfo | undefined,
): string | undefined => {
  const isApplianceRunningOutdatedSoftware =
    runningDifferentSoftwareVersion(version.controlSoftwareVersion, buildInfo) ||
    runningDifferentSoftwareVersion(version.dataSoftwareVersion, buildInfo)
  return isApplianceRunningOutdatedSoftware ? '(appliance software is outdated)' : undefined
}

export const areMultipleInterfacesPerApplianceSupported = ({
  portMode,
  region,
  appliance,
  isInput,
}: {
  portMode?: string
  region?: Pick<Region, 'id' | 'name'>
  appliance?: Pick<Appliance, 'type'>
  isInput: boolean
}) => {
  const supportedModes: string[] = isInput ? [IpPortMode.rtp, IpPortMode.udp] : [IpPortMode.rtp]
  const isModeSupported = supportedModes.includes(portMode ?? '')
  const isTypeSupported =
    region || ([ApplianceType.edgeConnect, ApplianceType.core] as string[]).includes(appliance?.type ?? '')
  return isModeSupported && isTypeSupported
}

export const InterfaceSection = <T extends InterfaceSectionForm>(form: FormikProps<T> & FormProps) => {
  const {
    namePrefix,
    onRemove,
    title,
    status,
    isApplianceOrRegionSelectable,
    onApplianceOrRegionSelected,
    enforcedPortMode,
    isModeSelectionDisabled,
    values,
    isInputForm,
  } = form
  const { buildInfo } = useSelector(({ buildInfoReducer }: GlobalState) => buildInfoReducer, shallowEqual)

  const childRef = useRef<{
    onAddInterfaceButtonClicked: Function
  }>(null)

  const { region, appliance, ports }: InterfaceSectionSelectionData<EnrichedInputPort | EnrichedOutputPort> = get(
    values,
    namePrefix,
  )

  const primarySelectedInterface = ports[0]
  const areMultipleInterfacesSupported = areMultipleInterfacesPerApplianceSupported({
    isInput: isInputForm,
    appliance,
    region,
    portMode: primarySelectedInterface?.mode,
  })
  const maxNumberOfInterfacesAllowed = areMultipleInterfacesSupported ? 2 : 1
  const canAddMoreInterfaces = (region || appliance) && ports.length < maxNumberOfInterfacesAllowed

  return (
    <Paper
      id={`interfaceSectionContainer-${form.index}`}
      title={title}
      className={cn('outlined', status?.port && 'error')}
      actionsPane={[
        ...(canAddMoreInterfaces
          ? [
              <>
                <Button
                  data-test-id={`add-interface-${form.index}`}
                  variant="contained"
                  color="secondary"
                  onClick={() => childRef.current?.onAddInterfaceButtonClicked()}
                >
                  Add interface
                </Button>
                <BackendValidation name="port" form={form} />
              </>,
            ]
          : []),
        ...(onRemove
          ? [
              <Button
                key={'remote-input-appliance'}
                variant="outlined"
                color="primary"
                onClick={() => onRemove(namePrefix)}
              >
                {` Remove ${isInputForm ? 'input' : 'output'} appliance `}
              </Button>,
            ]
          : []),
      ]}
    >
      <Paper>
        <GridItem>
          <AutoComplete<ApplianceOrRegion>
            placeholder={`${isInputForm ? 'Input' : 'Output'} appliance or region`}
            groupBy={option => (isAppliance(option) ? 'Appliance' : 'Region')}
            isClearable={true}
            initialValue={
              isRegionalPort(primarySelectedInterface) ? primarySelectedInterface.region! : region ?? appliance ?? null
            }
            onValueSelected={(selected: ApplianceOrRegion | null) => {
              const didReselectCurrentValue =
                (region && selected?.id === region.id) ||
                (appliance && selected?.id === appliance.id) ||
                (!selected && !region && !appliance)
              if (didReselectCurrentValue) return
              onApplianceOrRegionSelected(selected)
            }}
            getOptionLabel={(value: ApplianceOrRegion) => {
              if (!isAppliance(value)) return value.name
              const appliance = value as EnrichedApplianceWithOwner
              const applianceSoftwareOutdatedString =
                appliance.version && getApplianceSoftwareString(appliance.version, buildInfo)
              return `${appliance.name}${applianceSoftwareOutdatedString ? ` ${applianceSoftwareOutdatedString}` : ''}`
            }}
            renderOption={(props, option) => {
              const applianceSoftwareOutdatedString =
                isAppliance(option) &&
                'version' in option &&
                getApplianceSoftwareString((option as EnrichedApplianceWithOwner).version, buildInfo)
              return (
                <li {...props}>
                  <Typography component="div" variant="body2" color="textSecondary">
                    <>
                      {option.name}
                      {applianceSoftwareOutdatedString && (
                        <Box sx={styles.warningText}>{applianceSoftwareOutdatedString}</Box>
                      )}
                    </>
                  </Typography>
                </li>
              )
            }}
            isOptionDisabled={option => !isApplianceOrRegionSelectable(option)}
            api={async (
              params: PaginatedRequestParams<any>,
            ): Promise<ListResult<EnrichedApplianceWithOwner | Region>> => {
              const hasInputCapabilities = (type: ApplianceType) =>
                ![ApplianceType.thumb, ApplianceType.matroxMonarchEdgeD4].includes(type)
              const hasOutputCapabilities = (type: ApplianceType) =>
                ![
                  ApplianceType.thumb,
                  ApplianceType.matroxMonarchEdgeE4_8Bit,
                  ApplianceType.matroxMonarchEdgeE4_10Bit,
                ].includes(type)
              const regions = await Api.regionApi.getRegions(params)
              const appliances = await Api.appliancesApi.getAppliances({
                ...params,
                types: Object.values(ApplianceType).filter(isInputForm ? hasInputCapabilities : hasOutputCapabilities),
              })
              return {
                items: [...regions.items, ...appliances.items],
                total: regions.total + appliances.total,
                skip: Number(params.pageNumber) * Number(params.rowsPerPage),
                limit: Number(params.rowsPerPage),
              }
            }}
            dataTestId={namePrefix}
          />
        </GridItem>

        {region && (
          <RegionalInterfaceSection
            {...form}
            selectedRegion={region}
            enforcedAppliance={appliance}
            enforcedPortMode={enforcedPortMode}
            isModeSelectionDisabled={isModeSelectionDisabled}
            ports={ports}
            myRef={childRef}
          />
        )}

        {!region && appliance && (
          <ApplianceInterfaceSection
            {...form}
            selectedAppliance={appliance}
            ports={ports}
            enforcedPortMode={enforcedPortMode}
            isModeSelectionDisabled={isModeSelectionDisabled}
            myRef={childRef}
          />
        )}
      </Paper>
    </Paper>
  )
}
