import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { capitalize } from '@mui/material'

import {
  Appliance,
  CoaxPortMode,
  ComprimatoPortMode,
  GraphNodeType,
  Input,
  InputPort,
  IpcPortMode,
  IpPortMode,
  LimitedAppliance,
  MatroxPortMode,
  MetricWindow,
  Output,
  OutputPort,
  PortMode,
  RistInputMetrics,
  RistOutputMetrics,
  RistSimpleProfileOutputPort,
  Role,
  RtmpOutputPort,
  RtpInputMetrics,
  RtpInputPort,
  RtpOutputMetrics,
  RtpOutputPort,
  SrtCallerInputPort,
  SrtCallerOutputPort,
  SrtListenerInputPort,
  SrtListenerOutputPort,
  SrtMode,
  SrtRendezvousInputPort,
  SrtRendezvousOutputPort,
  StreamMetrics,
  UdpInputMetrics,
  UdpInputPort,
  UdpOutputMetrics,
  UdpOutputPort,
  User,
  VideonPortMode,
  ZixiMode,
  ZixiPullInputPort,
  ZixiPushOutputPort,
} from 'common/api/v1/types'

import { GlobalState } from '../../../store'
import DataSet from '../../common/DataSet'
import { isGraphNodeApplianceType, packets, TsInfo } from './Statistics'
import { vaInputStatistics, VaOutputStatistics } from './VaStatistics'
import { InfoSection } from './InfoSection'
import {
  InputHealthIndicator,
  OutputHealthIndicator,
  ServiceOverviewApplianceHealthIndicator,
} from '../../common/Indicator'
import { SelectedGraphItem } from '../Graph/graph'
import routes from '../../../utils/routes'
import { ExternalLink, Link } from '../../common/Link'
import { hasAccessToAppliance, inputType, outputType, pluralize, pluralizeWord } from '../../../utils'
import { applyUnit, makeFormatter } from './utils'
import { isVaApplianceType, isMatroxApplianceType } from '../../appliances/utils'
import {
  ageSeconds,
  formatBitrate,
  getFormattedTransportStreamContent,
  isComprimatoPortMode,
  isMatroxPortMode,
  isIpcPortMode,
  isMptsMetrics,
  isOutputMetricsWithPacketsLost,
  isRistInputMetrics,
  isRistOutputMetrics,
  isRistServerPortMode,
  isRistSimpleProfileInputMetrics,
  isRistSimpleProfileOutputMetrics,
  isRtmpInputMetric,
  isRtmpOutputMetric,
  isRtpInputMetrics,
  isRtpOutputMetrics,
  isSrtInputMetric,
  isSrtOutputMetric,
  isUdpInputMetrics,
  isUdpOutputMetrics,
  isUnixInputMetrics,
  isUnixOutputMetrics,
  isVideonPortMode,
  isZixiInputMetrics,
  isZixiOutputMetric,
} from 'common/api/v1/helpers'
import { TunnelLabels } from 'common/api/v1/internal'
import Tr101290Page from './TR101290'
import { ServiceOverviewState } from '../../../redux/reducers/serviceOverviewReducers'
import { distinct } from 'common/utils'

const portModesWithUdpMetrics: (InputPort | OutputPort)['mode'][] = [
  IpPortMode.udp,
  MatroxPortMode.matroxSdi,
  VideonPortMode.videonAuto,
  VideonPortMode.videonHdmi,
  VideonPortMode.videonSdi,
]

const portModesWithRtpMetrics: (InputPort | OutputPort)['mode'][] = [
  IpPortMode.rtp,
  ComprimatoPortMode.comprimatoNdi,
  ComprimatoPortMode.comprimatoSdi,
]

function udpOutputInfo(output: Output, logicalPortId?: string, streamId?: string) {
  const outputPort = output.ports?.find(port => port.id === logicalPortId)
  if (!outputPort || outputPort.mode == IpPortMode.rtp) {
    return null
  }
  const portMode = outputPort.mode

  const metrics = output.metrics?.ristMetrics
    .filter(isUdpOutputMetrics)
    .filter(m => m.isEgress)
    .find(m => m.streamId === streamId)

  if (!metrics && !portModesWithUdpMetrics.includes(portMode)) {
    // This is likely to be an upgraded appliance that should not have udp info
    // It will however potentially miss issues where unupdated appliances are missing udp metrics for some reason
    return null
  }

  const metric = makeFormatter(metrics)
  return {
    'Send bitrate': metric(s => formatBitrate(s.sendBitrate)),
    'Packet rate': metric(s => Math.round(s.sendPacketrate)),
    'Playout margin': metric(s => applyUnit(s.playoutMarginMs, 'ms')),
    'Internal Stream ID': metric(s => s.streamId),
    ...(portMode === IpPortMode.udp ? getOutputConnectionDetails(output, logicalPortId) : null),
  }
}

function unixOutputInfo(output: Output, logicalPortId?: string, streamId?: string) {
  const outputApplianceType = output.appliances?.[0]?.type
  const portMode = output.ports?.find(port => port.id === logicalPortId)?.mode
  const portModesWithUnixOutput: OutputPort['mode'][] = [IpcPortMode.unix, IpPortMode.rtmp, IpPortMode.srt]
  const applianceUsesUnixHandover =
    !isVaApplianceType(outputApplianceType) && !isMatroxApplianceType(outputApplianceType)
  if (!applianceUsesUnixHandover || !portModesWithUnixOutput.includes(portMode!)) {
    return null
  }
  const matchingEgressMetrics = output.metrics?.ristMetrics.filter(m => m.isEgress).filter(m => m.streamId === streamId)

  // TODO: remove this when all appliances are upgraded with unix socket support
  // (it ensures that we will not show double handover information for unupgraded appliances)
  const metrics = matchingEgressMetrics?.find(isUnixOutputMetrics)
  if (matchingEgressMetrics?.[0] && !metrics) {
    return null
  }

  const metric = makeFormatter(metrics)
  return {
    'Send bitrate': metric(s => formatBitrate(s.sendBitrate)),
    'Packet rate': metric(s => Math.round(s.sendPacketrate)),
    'Playout margin': metric(s => applyUnit(s.playoutMarginMs, 'ms')),
    'Internal Stream ID': metric(s => s.streamId),
    ...(portMode === IpcPortMode.unix ? getOutputConnectionDetails(output, logicalPortId) : null),
  }
}

function rtmpInputInfo(input: Input, logicalPortId?: string, streamId?: string): { 'Receive bitrate'?: string } | null {
  const port = input.ports?.find(port => port.id === logicalPortId)
  if (port?.mode != IpPortMode.rtmp) {
    return null
  }
  const metrics = input.metrics?.ristMetrics.filter(isRtmpInputMetric).find(metric => metric.streamId === streamId)
  return {
    'Receive bitrate': formatBitrate(metrics ? metrics.receiveBitrateKbps * 1000 : null),
    ...getInputConnectionDetails(input, logicalPortId),
  }
}

function rtmpOutputInfo(output: Output, logicalPortId?: string, streamId?: string): { [key: string]: string } | null {
  const port = output.ports?.find(port => port.id === logicalPortId)
  if (port?.mode != IpPortMode.rtmp) {
    return null
  }
  const metrics = output.metrics?.ristMetrics.filter(isRtmpOutputMetric).find(metric => metric.streamId === streamId)

  const metric = makeFormatter(metrics)
  return {
    Restarts: metric(s => s.restartCount),
    'Send bitrate': metric(s => formatBitrate(s.sendBitrateKbps * 1000)),
    ...getOutputConnectionDetails(output, logicalPortId),
  }
}

function unixInputInfo(input: Input, logicalPortId?: string, streamId?: string) {
  const port = input.ports?.find(port => port.id === logicalPortId)
  const appliance = input.appliances?.[0]
  const portModesWithUnixInput: Exclude<Input['ports'], undefined>[number]['mode'][] = [
    IpcPortMode.unix,
    IpPortMode.srt,
    IpPortMode.rtmp,
    IpPortMode.generator,
  ]
  if (!portModesWithUnixInput.includes(port?.mode!) || isVaApplianceType(appliance?.type!)) {
    return null
  }
  const receiveMetrics = input.metrics?.ristMetrics
    .filter(isUnixInputMetrics)
    .find(metric => metric.streamId === streamId)

  // TODO: remove this when all appliances support unix sockets
  const udpReceiveMetrics = input.metrics?.ristMetrics
    .filter(isUdpInputMetrics)
    .find(metric => metric.streamId === streamId)
  if (!receiveMetrics && udpReceiveMetrics) {
    return null
  }

  const receiveMetric = makeFormatter(receiveMetrics)
  return {
    'Receive bitrate': receiveMetric(s => formatBitrate(s.receiveBitrate)),
    'Packet rate': receiveMetric(s => Math.round(s.receivePacketrate)),
    'Packets discarded during standby': receiveMetric(s => s.packetsWhileInactive),
    'Internal Stream ID': receiveMetric(s => s?.streamId),
    Status: receiveMetric(s => s?.status),
    ...getInputConnectionDetails(input, logicalPortId),
  }
}

function udpInputInfo(input: Input, logicalPortId?: string, streamId?: string) {
  const port = input.ports?.find(port => port.id === logicalPortId)
  // having mode rtp and failoverPriority means it will be a udp-input with format: rtp used for source failover

  const isUdpInputWithRtpFormat = port?.mode === IpPortMode.rtp && port.failoverPriority !== undefined

  const receiveMetrics = isUdpInputWithRtpFormat
    ? undefined
    : input.metrics?.ristMetrics.filter(isUdpInputMetrics).find(metric => metric.streamId === streamId)

  if (!portModesWithUdpMetrics.includes(port?.mode!) && !receiveMetrics) {
    return null
  }

  const receiveMetric = makeFormatter(receiveMetrics)
  return {
    'Receive bitrate': receiveMetric(s => formatBitrate(s.receiveBitrate)),
    'Packet rate': receiveMetric(s => Math.round(s.receivePacketrate)),
    'Malformed packets discarded': receiveMetric(s => s.packetsDiscarded),
    'Packets discarded during standby': receiveMetric(s => s.packetsWhileInactive),
    'Internal Stream ID': receiveMetric(s => s?.streamId),
    Status: receiveMetric(s => s?.status),
    ...(port?.mode === IpPortMode.udp ? getInputConnectionDetails(input, logicalPortId) : null),
  }
}

function rtpInputInfo(input: Input, logicalPortId?: string, streamId?: string): { [key: string]: string } | null {
  const port = input.ports?.find(port => port.id === logicalPortId)
  const applianceType = input.appliances && input.appliances[0].type
  const isVaApplianceInput = applianceType && isVaApplianceType(applianceType)
  const modesWithRtpInfo: Exclude<Input['ports'], undefined>[number]['mode'][] = [
    IpPortMode.rtp,
    IpPortMode.zixi,
    ComprimatoPortMode.comprimatoNdi,
    ComprimatoPortMode.comprimatoSdi,
  ]
  if (!modesWithRtpInfo.includes(port?.mode!) && !isVaApplianceInput) {
    return null
  }

  const isRtpInputMetricsOrUdpInputMetrics = (m: StreamMetrics): m is RtpInputMetrics | UdpInputMetrics =>
    isRtpInputMetrics(m) || (isUdpInputWithFormatRtp && isUdpInputMetrics(m))
  const isUdpInputWithFormatRtp = !!(port?.mode == IpPortMode.rtp && port?.failoverPriority)
  const receiveMetrics = input.metrics?.ristMetrics
    .filter(isRtpInputMetricsOrUdpInputMetrics)
    .find(metric => metric.streamId === streamId)

  if (isVaApplianceInput && !receiveMetrics) {
    // TODO: Remove this when all VA inputs have likely moved
    // over to RTP for handover
    return null
  }

  const receiveMetric = makeFormatter(receiveMetrics)
  return {
    'Receive bitrate': receiveMetric(s => formatBitrate(s.receiveBitrate)),
    'Packet rate': receiveMetric(s => Math.round(s.receivePacketrate)),
    'Packets discarded': receiveMetric(s => s.packetsDiscarded),
    ...(receiveMetrics && isRtpInputMetrics(receiveMetrics)
      ? { SSRC: receiveMetrics.ssrc ? receiveMetrics.ssrc.toString() : 'N/A' }
      : {}),
    ...(isVaApplianceInput ? {} : getInputConnectionDetails(input, logicalPortId)),
  }
}

function mptsInputInfo(input: Input, streamId?: string): { [key: string]: string } | null {
  if (!input.deriveFrom) {
    return null
  }
  const receiveMetrics = input.metrics?.ristMetrics.filter(isMptsMetrics).find(metric => metric.streamId === streamId)
  const receiveMetric = makeFormatter(receiveMetrics)
  return {
    'Udp packets': receiveMetric(s => s.udpPackets),
    'Udp packet rate': receiveMetric(s => Math.round(s.udpPacketsRate)),
    'Ts packets': receiveMetric(s => s.tsPackets),
    'Ts packet rate': receiveMetric(s => Math.round(s.tsPacketsRate)),
  }
}

function rtpOutputInfo(output: Output, logicalPortId?: string, streamId?: string) {
  const applianceType = output.appliances && output.appliances[0].type
  const isVaApplianceOutput = applianceType && isVaApplianceType(applianceType)
  const port = output.ports?.find(port => port.id === logicalPortId)
  const portMode = port?.mode
  if (!portModesWithRtpMetrics.includes(portMode!) && !isVaApplianceOutput) {
    return null
  }

  const isUdpOrRtpMetrics = (m: StreamMetrics): m is UdpOutputMetrics | RtpOutputMetrics => {
    return isRtpOutputMetrics(m) || isUdpOutputMetrics(m)
  }
  const receiveMetrics = output.metrics?.ristMetrics
    .filter(isUdpOrRtpMetrics)
    .find(metric => metric.streamId === streamId)

  if (isVaApplianceOutput && !receiveMetrics) {
    // TODO: remove this when all VA outputs
    // have moved to RTP for handover
    return null
  }

  const receiveMetric = makeFormatter(receiveMetrics)
  return {
    'Send bitrate': receiveMetric(s => formatBitrate(s.sendBitrate)),
    'Packet rate': receiveMetric(s => Math.round(s.sendPacketrate)),
    'Packets lost': receiveMetric(s => s.packetsLost),
    'Internal Stream ID': receiveMetric(s => s.streamId),
    ...getOutputConnectionDetails(output, logicalPortId),
  }
}

function ristInputInfo(input: Input, logicalPortId?: string, streamId?: string): { [key: string]: string } | null {
  const port = input.ports?.find(port => port.id === logicalPortId)
  if (port?.mode != IpPortMode.rist) {
    return null
  }
  const receiveMetrics = input.metrics?.ristMetrics
    .filter(isRistSimpleProfileInputMetrics)
    .find(metric => metric.streamId === streamId)
  const receiveMetric = makeFormatter(receiveMetrics)

  return {
    'Receive bitrate': receiveMetric(s => formatBitrate(s.receiveBitrate)),
    'Packet rate': receiveMetric(s => Math.round(s.receivePacketrate)),
    'Packet send errors': receiveMetric(s => s.packetSendErrors),
    'Packets discarded': receiveMetric(s => s.packetsDiscarded),
    'Retransmission bitrate': receiveMetric(s => formatBitrate(s.retransmitReceiveBitrate)),
    'Retransmission packet rate': receiveMetric(s => Math.round(s.retransmitReceivePacketrate)),
    Roundtrip: receiveMetric(s => applyUnit(s.roundtripMs, 'ms')),
    'Propagation delay': receiveMetric(s => applyUnit(s.propagationDelayMs, 'ms')),
    'Packets lost': receiveMetric(s => s.packetsLost),
    'Longest burst loss': receiveMetric(s => s.longestBurstLoss),
    'RTP packets received': receiveMetric(s => s.rtpPacketsReceived),
    'Malformed RTCP Packets Received': receiveMetric(s => s.malformedRtcpPacketsReceived),
    'Unsupported RTCP Packets Received': receiveMetric(s => s.unsupportedRtcpPacketsReceived),
    ...getInputConnectionDetails(input, logicalPortId),
  }
}

function ristOutputInfo(output: Output, logicalPortId?: string, streamId?: string) {
  const port = output.ports?.find(port => port.id === logicalPortId)
  if (port?.mode != IpPortMode.rist) {
    return null
  }
  const receiveMetrics = output.metrics?.ristMetrics
    .filter(isRistSimpleProfileOutputMetrics)
    .find(metric => metric.streamId === streamId)

  const sendMetric = makeFormatter(receiveMetrics)

  return {
    'Send bitrate': sendMetric(s => formatBitrate(s.sendBitrate)),
    'Packet rate': sendMetric(s => Math.round(s.sendPacketrate)),
    'Packet send errors': sendMetric(s => s.packetSendErrors),
    'Retransmission bitrate': formatBitrate(sendMetric(s => s.retransmitSendBitrate)),
    'Retransmission packet rate': sendMetric(s => Math.round(s.retransmitSendPacketrate)),
    'RTP packets sent': sendMetric(s => s.rtpPacketsSent),
    'Reported packet loss': sendMetric(s => applyUnit(s.reportedPacketLossPercent, '%')),
    Roundtrip: sendMetric(s => applyUnit(s.roundtripMs, 'ms')),
    'Malformed RTCP Packets Received': sendMetric(s => s.malformedRtcpPacketsReceived),
    'Unsupported RTCP Packets Received': sendMetric(s => s.unsupportedRtcpPacketsReceived),
    'Internal Stream ID': sendMetric(s => s.streamId),
    ...getOutputConnectionDetails(output, logicalPortId),
  }
}

function zixiInputInfo(
  input: Input,
  logicalPortId?: string,
  streamId?: string,
): { [key: string]: string | null } | null {
  const port = input.ports?.find(port => port.id === logicalPortId)
  if (port?.mode != IpPortMode.zixi) {
    return null
  }
  const zixiInputMetrics = input.metrics?.ristMetrics
    .filter(isZixiInputMetrics)
    .find(metric => metric.streamId === streamId)
  const receiveMetric = makeFormatter(zixiInputMetrics)
  return {
    'Zixi bitrate': receiveMetric(m => formatBitrate(m.bitrate)),
    'Zixi connection': receiveMetric(m => m.connectionStatus),
    ...getInputConnectionDetails(input, logicalPortId),
  }
}

function zixiOutputInfo(output: Output, logicalPortId?: string, streamId?: string) {
  const port = output.ports?.find(port => port.id === logicalPortId)
  if (port?.mode != IpPortMode.zixi) {
    return null
  }
  const receiveMetrics = output.metrics?.ristMetrics
    .filter(isZixiOutputMetric)
    .find(metric => metric.streamId === streamId)
  const receiveMetric = makeFormatter(receiveMetrics)
  return {
    'Zixi bitrate': receiveMetric(m => m.sinkStats.outBitrate),
    'Zixi connection': receiveMetric(m => m.connectionStatus),
    'Zixi rtt': receiveMetric(m => m.sinkStats.rtt),
    'Zixi jitter': receiveMetric(m => m.sinkStats.jitter),
    'Zixi packets': receiveMetric(m => m.sinkStats.totalPackets),
    'Zixi packet rate': receiveMetric(m => m.sinkStats.packetRate),
    'Zixi dropped': receiveMetric(m => m.sinkStats.droppedPackets),
    'Zixi recovered': receiveMetric(m => m.sinkStats.recoveredPackets),
    'Zixi not recovered': receiveMetric(m => m.sinkStats.notRecoveredPackets),
    'Zixi failed sends': receiveMetric(m => m.sinkStats.failedSends),
    'Zixi FEC packets': receiveMetric(m => m.sinkStats.fecPackets),
    'Zixi ARQ requests': receiveMetric(m => m.sinkStats.arqRequests),
    'Zixi ARQ recovered': receiveMetric(m => m.sinkStats.arqRecovered),
    'Zixi ARQ duplicates': receiveMetric(m => m.sinkStats.arqDuplicates),
    'Zixi overflows': receiveMetric(m => m.sinkStats.overflows),
    ...getOutputConnectionDetails(output, logicalPortId),
  }
}

function srtInputInfo(
  input: Input,
  logicalPortId?: string,
  streamId?: string,
): { [key: string]: number | string } | null {
  const port = input.ports?.find(port => port.id === logicalPortId)
  if (port?.mode != IpPortMode.srt) {
    return null
  }
  const srtInputMetrics = input.metrics?.ristMetrics
    .filter(isSrtInputMetric)
    .find(metric => metric.streamId === streamId)

  const receiveMetric = makeFormatter(srtInputMetrics)
  return {
    'SRT bitrate': receiveMetric(m => formatBitrate(m.bitrate)),
    'SRT rtt': receiveMetric(m => applyUnit(m.rtt, 'ms')),
    'SRT packets lost': receiveMetric(m => m.packetsLost),
    'SRT packets dropped': receiveMetric(m => m.packetsDropped),
    'SRT packets retransmitted': receiveMetric(m => m.packetsRetransmitted),
    'SRT receive buffer, acknowledged': receiveMetric(m => applyUnit(m.msRecvBuffer, 'ms')),
    'Remote address': receiveMetric(m => m.remoteAddress),
    ...getInputConnectionDetails(input, logicalPortId),
  }
}

function srtOutputInfo(
  output: Output,
  logicalPortId?: string,
  streamId?: string,
): { [key: string]: number | string } | null {
  const port = output.ports?.find(port => port.id === logicalPortId)
  if (port?.mode != IpPortMode.srt) {
    return null
  }
  const srtOutputMetrics = output.metrics?.ristMetrics
    .filter(isSrtOutputMetric)
    .find(metric => metric.streamId === streamId)

  const receiveMetric = makeFormatter(srtOutputMetrics)
  const srtInfo: { [key: string]: number | string } = {
    'SRT bitrate': receiveMetric(m => formatBitrate(m.bitrate)),
    'SRT rtt': receiveMetric(m => applyUnit(m.rtt, 'ms')),
    'SRT packets dropped': receiveMetric(m => m.packetsDropped),
    'SRT packets retransmitted': receiveMetric(m => m.packetsRetransmitted),
    'SRT send buffer, unacknowledged': receiveMetric(m => applyUnit(m.msSendBuffer, 'ms')),
    ...getOutputConnectionDetails(output, logicalPortId),
  }
  if (srtOutputMetrics?.remoteAddress) {
    srtInfo['Remote address'] = receiveMetric(m => m.remoteAddress)
  }
  return srtInfo
}

function getInputConnectionDetails(input: Input, logicalPortId?: string) {
  const inputPorts = input.ports
  if (!inputPorts?.length) return undefined

  const port = logicalPortId ? inputPorts.find(port => port.id === logicalPortId) || inputPorts[0] : inputPorts[0]
  const ports = logicalPortId ? [port] : inputPorts
  switch (port.mode) {
    case IpPortMode.rtmp: // fallthrough
    case IpPortMode.rist: // fallthrough
    case IpPortMode.generator: // fallthrough
      return makeServerConnectionDetails(
        (ports as typeof port[]).map(port => ({
          listenAddress: port.address,
          listenPort: port.port,
        })),
      )

    case IpPortMode.udp:
      return makeServerConnectionDetails(
        (ports as UdpInputPort[]).map(port => ({
          listenAddress: port.address,
          listenPort: port.port,
          multicastGroupAddress: port.multicastAddress,
          multicastSource: port.multicastSource,
        })),
      )

    case IpPortMode.rtp:
      return makeServerConnectionDetails(
        (ports as RtpInputPort[]).map(port => ({
          listenAddress: port.address,
          listenPort: port.port,
          multicastGroupAddress: port.multicastAddress,
          multicastSource: port.multicastSource,
        })),
      )

    case IpPortMode.srt: {
      switch (port.srtMode) {
        case SrtMode.caller:
          return makeClientConnectionDetails(
            (ports as SrtCallerInputPort[]).map(port => ({
              fromPort: port.localPort,
              toAddress: port.remoteIp,
              toPort: port.remotePort,
            })),
          )

        case SrtMode.listener:
          return makeServerConnectionDetails(
            (ports as SrtListenerInputPort[]).map(port => ({
              listenAddress: port.localIp,
              listenPort: port.localPort,
            })),
          )

        case SrtMode.rendezvous: {
          return {
            ...makeClientConnectionDetails(
              (ports as SrtRendezvousInputPort[]).map(port => ({
                fromAddress: port.localIp,
                fromPort: port.remotePort,
                toAddress: port.remoteIp,
                toPort: port.remotePort,
              })),
            ),
            ...makeServerConnectionDetails(
              (ports as SrtRendezvousInputPort[]).map(port => ({
                listenAddress: port.localIp,
                listenPort: port.remotePort,
              })),
            ),
            'Connection type': 'Client and server',
          }
        }
      }
    }

    case IpPortMode.zixi:
      switch (port.zixiMode) {
        case ZixiMode.pull:
          return makeClientConnectionDetails(
            (ports as ZixiPullInputPort[]).flatMap(port =>
              [port.remotePrimaryIp, port.remoteSecondaryIp].filter(Boolean).map(toAddress => ({
                fromAddress: port.localIp,
                toAddress,
                toPort: port.pullPort,
              })),
            ),
          )

        case ZixiMode.push:
          // Note: zixi push inputs can only be set up on VA:s. It is not possible
          // to specify 'listenAddress' or 'listenPort' per input - this information
          // is configured on the associated VA and must be retrieved via the OAM api.
          return makeServerConnectionDetails([])
      }

    case CoaxPortMode.asi: // fallthrough
    case CoaxPortMode.sdi:
      return undefined
  }
}

function getOutputConnectionDetails(output: Output, logicalPortId?: string) {
  const outputPorts = output.ports
  if (!outputPorts?.length) return undefined

  const port = logicalPortId ? outputPorts.find(port => port.id === logicalPortId) : outputPorts[0]
  const ports = logicalPortId ? [port] : outputPorts
  if (!port) {
    return undefined
  }
  switch (port.mode) {
    case IpPortMode.udp:
      return makeClientConnectionDetails(
        (ports as UdpOutputPort[]).map(port => ({
          fromAddress: port.sourceAddress,
          fromPort: port.localPort,
          toAddress: port.address,
          toPort: port.port,
        })),
      )

    case IpPortMode.rist:
      return makeClientConnectionDetails(
        (ports as RistSimpleProfileOutputPort[]).map(port => ({
          fromAddress: port.sourceAddress,
          fromPort: port.localPort,
          toAddress: port.address,
          toPort: port.port,
        })),
      )

    case IpPortMode.rtp:
      return makeClientConnectionDetails(
        (ports as RtpOutputPort[]).map(port => ({
          fromAddress: port.sourceAddress,
          fromPort: port.localPort,
          toAddress: port.address,
          toPort: port.port,
        })),
      )

    case IpPortMode.rtmp:
      return makeClientConnectionDetails(
        (ports as RtmpOutputPort[]).map(port => ({ toAddress: port.rtmpDestinationAddress })),
      )

    case IpPortMode.srt:
      switch (port.srtMode) {
        case SrtMode.caller:
          return makeClientConnectionDetails(
            (ports as SrtCallerOutputPort[]).map(port => ({
              fromPort: port.localPort,
              toAddress: port.remoteIp,
              toPort: port.remotePort,
            })),
          )

        case SrtMode.listener:
          return makeServerConnectionDetails(
            (ports as SrtListenerOutputPort[]).map(port => ({
              listenAddress: port.localIp,
              listenPort: port.localPort,
            })),
          )

        case SrtMode.rendezvous: {
          return {
            ...makeClientConnectionDetails(
              (ports as SrtRendezvousOutputPort[]).map(port => ({
                fromAddress: port.localIp,
                fromPort: port.remotePort,
                toAddress: port.remoteIp,
                toPort: port.remotePort,
              })),
            ),
            ...makeServerConnectionDetails(
              (ports as SrtRendezvousOutputPort[]).map(port => ({
                listenAddress: port.localIp,
                listenPort: port.remotePort,
              })),
            ),
            'Connection type': 'Client and server',
          }
        }
      }

    case IpPortMode.zixi:
      switch (port.zixiMode) {
        case ZixiMode.pull:
          // Note: zixi pull outputs can only be set up on VA:s. It is not possible
          // to specify 'listenAddress' or 'listenPort' per input - this information
          // is configured on the associated VA and must be retrieved via the OAM api.
          return makeServerConnectionDetails([])

        case ZixiMode.push:
          return makeClientConnectionDetails(
            (ports as ZixiPushOutputPort[]).flatMap(port =>
              [port.linkSet1, port.linkSet2]
                .flat()
                .filter(Boolean)
                .map(zixiLink => ({
                  fromAddress: zixiLink!.localIp,
                  toAddress: zixiLink!.remoteIp,
                  toPort: zixiLink!.remotePort,
                })),
            ),
          )
      }

    case CoaxPortMode.asi: // fallthrough
    case CoaxPortMode.sdi:
      return undefined
  }
}

function makeClientConnectionDetails(
  entries: Array<{ fromAddress?: string; fromPort?: number; toAddress?: string; toPort?: number }>,
) {
  const fromEntries = Array.from(
    new Set(
      entries
        .map(({ fromAddress, fromPort }) => {
          if (fromAddress && fromPort) return `${fromAddress}:${fromPort}`
          else if (fromAddress) return fromAddress
          else if (fromPort) return `port ${fromPort}`
          return undefined
        })
        .filter(Boolean),
    ),
  )

  const toEntries = Array.from(
    new Set(
      entries
        .map(({ toAddress, toPort }) => {
          if (toAddress && toPort) return `${toAddress}:${toPort}`
          else if (toAddress) return toAddress
          else if (toPort) return `port ${toPort}`
          return undefined
        })
        .filter(Boolean),
    ),
  )

  return {
    'Connection type': 'Client',
    ...(fromEntries.length ? { 'Connecting from': fromEntries.join(', ') } : undefined),
    ...(toEntries.length ? { 'Connecting to': toEntries.join(', ') } : undefined),
  }
}

function makeServerConnectionDetails(
  entries: Array<{
    listenAddress?: string
    listenPort?: number
    multicastGroupAddress?: string
    multicastSource?: string
  }>,
) {
  const listenEntries = Array.from(
    new Set(
      entries
        .map(({ listenAddress, listenPort }) => {
          if (listenAddress && listenPort) return `${listenAddress}:${listenPort}`
          else if (listenAddress) return listenAddress
          else if (listenPort) return `port ${listenPort}`
          return undefined
        })
        .filter(Boolean) as string[],
    ),
  )

  const multicastEntries = Array.from(
    new Set(
      entries
        .map(({ multicastGroupAddress, multicastSource }) => {
          if (multicastGroupAddress && multicastSource) return `${multicastGroupAddress} (source: ${multicastSource})`
          else if (multicastGroupAddress) return multicastGroupAddress
          return undefined
        })
        .filter(Boolean) as string[],
    ),
  )

  return {
    'Connection type': 'Server',
    ...(listenEntries.length ? { 'Listening on': listenEntries.join(', ') } : undefined),
    ...(multicastEntries.length
      ? { [pluralizeWord(multicastEntries.length, 'Multicast group')]: multicastEntries.join(', ') }
      : undefined),
  }
}

function makeApplianceHealthInfo(appliance: Appliance | LimitedAppliance) {
  return 'health' in appliance
    ? { Status: <ServiceOverviewApplianceHealthIndicator applianceId={appliance.id} inline={true} /> }
    : undefined
}

function makeApplianceAlarmsInfo(appliance: Appliance | LimitedAppliance, user: User) {
  return 'alarms' in appliance && hasAccessToAppliance(appliance, user)
    ? {
        Alarms: appliance.alarms.length ? (
          <Link to={routes.alarms({ applianceId: appliance.id, applianceName: appliance.name })} underline="hover">
            {pluralize(appliance.alarms.length, 'alarm')}
          </Link>
        ) : (
          'No alarms present'
        ),
      }
    : undefined
}

const InputInfo = () => {
  const { input } = useSelector(
    ({ serviceOverviewReducer }: GlobalState) => serviceOverviewReducer,
    shallowEqual,
  ) as ServiceOverviewState
  const user = useSelector(({ userReducer }: GlobalState) => userReducer.user, shallowEqual) as User
  if (!input) {
    return null
  }
  const inputId = input.id

  const inputApplianceIds = input.appliances?.map(appliance => appliance.id) || []
  const tsInfos = (input?.tsInfo || []).filter(info => inputApplianceIds.includes(info.applianceId))
  const formats = tsInfos.map(tsInfo => getFormattedTransportStreamContent(tsInfo)).join(', ') || 'N/A'
  const isInputOwner = user.group === input.owner || user.role === Role.super

  const isSuperUser = user.role == Role.super
  const metricsLinks: { text: string; url: string }[] = isSuperUser
    ? [
        {
          text: 'RIST Overview',
          url: `/grafana/d/L2I0OGbZz/rist-overview?orgId=1&refresh=10s&from=now-15m&to=now&var-inputId=${
            input.id
          }&var-input=${encodeURIComponent(input.name)}&var-type=All&var-applianceName=All`,
        },
        {
          text: 'TR 101 290',
          url: `/grafana/d/0nlYSdtWz/tr-101-290?orgId=1&refresh=10s&from=now-15m&to=now&var-inputId=${input.id}&var-applianceName=All`,
        },
      ]
    : []

  const applianceType = input.appliances?.[0]?.type
  const isVaApplianceInput = applianceType && isVaApplianceType(applianceType)
  if (isSuperUser && !isVaApplianceInput && input.ports && input.ports[0].mode == IpPortMode.srt) {
    metricsLinks.push({
      text: 'SRT Overview',
      url: `/grafana/d/B-j9lC1Gz/srt-overview?orgId=1&refresh=10s&from=now-15m&to=now&var-inputId=${input.id}&var-type=All&var-applianceName=All`,
    })
  }

  const metricsLink =
    metricsLinks.length > 0
      ? {
          Metrics: (
            <>
              {metricsLinks.map(({ url, text }) => (
                <ExternalLink href={url} underline="always" style={{ marginRight: '12px' }} key={url}>
                  {text}
                </ExternalLink>
              ))}
            </>
          ),
        }
      : {}

  return (
    <>
      <InfoSection id="paper-Information" title="Input">
        <div>
          <DataSet
            values={{
              Name: (
                <Link underline="hover" available={isInputOwner} to={routes.inputsUpdate({ id: inputId })}>
                  {input.name}
                </Link>
              ),
              Type: inputType(input).join(', '),
              Status: <InputHealthIndicator inputId={input.id} inline={true} />,
              Format: formats,
              ...getInputConnectionDetails(input),
              ...metricsLink,
            }}
          />
        </div>
      </InfoSection>
    </>
  )
}

function capitalizePortMode(portMode?: PortMode): undefined | string {
  if (portMode === undefined) {
    return
  }
  return portMode === IpPortMode.zixi ? capitalize(portMode) : portMode.toUpperCase()
}

const InputPortInfo = () => {
  const { input, selected } = useSelector(
    ({ serviceOverviewReducer }: GlobalState) => serviceOverviewReducer,
    shallowEqual,
  ) as ServiceOverviewState
  if (!input) {
    return null
  }

  const portMode = input.ports?.find(port => port.id === selected?.data?.logicalPortId)?.mode
  const showHandoverTooltip = !isRistServerPortMode(portMode)

  const srtInfo = srtInputInfo(input, selected?.data?.logicalPortId, selected?.data?.streamId)
  const zixiInfo = zixiInputInfo(input, selected?.data?.logicalPortId, selected?.data?.streamId)
  const rtmpInfo = rtmpInputInfo(input, selected?.data?.logicalPortId, selected?.data?.streamId)
  const ristInfo = ristInputInfo(input, selected?.data?.logicalPortId, selected?.data?.streamId)
  const unixInfo = unixInputInfo(input, selected?.data?.logicalPortId, selected?.data?.streamId)
  const udpInfo = udpInputInfo(input, selected?.data?.logicalPortId, selected?.data?.streamId)
  const rtpInfo = rtpInputInfo(input, selected?.data?.logicalPortId, selected?.data?.streamId)
  const mptsInfo = mptsInputInfo(input, selected?.data?.streamId)
  const vaInputPipe = input.metrics?.vaInputPipe
  const mode = input.ports?.[0]?.mode
  const inputApplianceType = input?.appliances?.[0]?.type
  const isVa = inputApplianceType && isVaApplianceType(inputApplianceType)

  return (
    <>
      {!isVa &&
      mptsInfo && ( // This is not displayed for nimbra VA
          <InfoSection title="MPTS demuxing information">
            <div>
              <DataSet values={mptsInfo} />
            </div>
          </InfoSection>
        )}

      {rtpInfo && ( // This is not displayed for nimbra VA
        <InfoSection
          title="RTP information"
          tooltip={
            showHandoverTooltip
              ? `The handover from the protocol adapter for ${capitalizePortMode(portMode)} uses RTP.`
              : undefined
          }
        >
          <div>
            <DataSet values={rtpInfo} />
          </div>
        </InfoSection>
      )}

      {!isVa &&
      zixiInfo && ( // This is not displayed for nimbra VA
          <InfoSection title="Zixi information">
            <div>
              <DataSet values={zixiInfo} />
            </div>
          </InfoSection>
        )}

      {!isVa &&
      srtInfo && ( // This is not displayed for nimbra VA
          <InfoSection title="SRT information">
            <div>
              <DataSet values={srtInfo} />
            </div>
          </InfoSection>
        )}

      {!isVa &&
      rtmpInfo && ( // This is not displayed for nimbra VA
          <InfoSection title="RTMP information">
            <div>
              <DataSet values={rtmpInfo} />
            </div>
          </InfoSection>
        )}

      {!isVa &&
      ristInfo && ( // This is not displayed for nimbra VA
          <InfoSection title="RIST information">
            <div>
              <DataSet values={ristInfo} />
            </div>
          </InfoSection>
        )}

      {udpInfo && (
        <InfoSection
          title={`UDP information`}
          tooltip={
            showHandoverTooltip
              ? `The handover from the protocol adapter for ${capitalizePortMode(portMode)} uses UDP.`
              : undefined
          }
        >
          <div>
            <DataSet values={udpInfo} />
          </div>
        </InfoSection>
      )}
      {unixInfo && (
        <InfoSection
          title={`Unix socket information`}
          tooltip={
            showHandoverTooltip
              ? `The handover from the protocol adapter for ${capitalizePortMode(portMode)} uses Unix domain sockets.`
              : undefined
          }
        >
          <div>
            <DataSet values={unixInfo} />
          </div>
        </InfoSection>
      )}

      {vaInputPipe &&
        mode &&
        !isIpcPortMode(mode) &&
        !isVideonPortMode(mode) &&
        !isMatroxPortMode(mode) &&
        !isComprimatoPortMode(mode) && (
          <InfoSection title={`VA ${mode.toUpperCase()} statistics`}>
            <div>{vaInputStatistics(mode, vaInputPipe)}</div>
          </InfoSection>
        )}
    </>
  )
}

const InputApplianceInfo = () => {
  const { input, selected, appliances } = useSelector(
    ({ serviceOverviewReducer }: GlobalState) => serviceOverviewReducer,
    shallowEqual,
  )
  const user = useSelector(({ userReducer }: GlobalState) => userReducer.user, shallowEqual) as User
  if (!selected) {
    return null
  }

  if (!selected) {
    throw new Error('No selected object')
  }

  const appliance = appliances.find(a => a.id == selected.id)
  if (!appliance) {
    return null
  }
  if (!input) {
    throw new Error('No selected input')
  }
  const inputAppliance = input.appliances![0]
  if (!inputAppliance) {
    throw new Error('No input appliance')
  }

  return (
    <InfoSection id="paper-Information" title="Input appliance">
      <DataSet
        values={{
          Name:
            (hasAccessToAppliance(appliance, user) && (
              <Link underline="hover" to={routes.appliancesUpdate({ id: selected.id })}>
                {appliance.name}
              </Link>
            )) ||
            appliance.name,
          Type: appliance.type,
          ...makeApplianceHealthInfo(appliance),
          ...makeApplianceAlarmsInfo(appliance, user),
        }}
      />
    </InfoSection>
  )
}

const CoreNodeInfo = () => {
  const { input, selected, appliances } = useSelector(
    ({ serviceOverviewReducer }: GlobalState) => serviceOverviewReducer,
    shallowEqual,
  )
  const user = useSelector(({ userReducer }: GlobalState) => userReducer.user, shallowEqual) as User
  if (!selected || !input) {
    return null
  }

  const appliance = appliances.find(a => a.id == selected.id)
  if (!appliance) {
    return null
  }

  return (
    <InfoSection id="paper-Information" title="Core node">
      <DataSet
        values={{
          Name:
            (hasAccessToAppliance(appliance, user) && (
              <Link underline="hover" to={routes.appliancesUpdate({ id: selected.id })}>
                {appliance.name}
              </Link>
            )) ||
            appliance.name,
          ...makeApplianceHealthInfo(appliance),
          ...makeApplianceAlarmsInfo(appliance, user),
        }}
      />
    </InfoSection>
  )
}

const ThumbNodeInfo = () => {
  const { input, selected, appliances } = useSelector(
    ({ serviceOverviewReducer }: GlobalState) => serviceOverviewReducer,
    shallowEqual,
  )
  const user = useSelector(({ userReducer }: GlobalState) => userReducer.user, shallowEqual) as User
  if (!selected || !input) {
    return null
  }

  const appliance = appliances.find(a => a.id == selected.id)
  if (!appliance) {
    return null
  }

  return (
    <InfoSection id="paper-Information" title="Thumb node">
      <DataSet
        values={{
          Name:
            (hasAccessToAppliance(appliance, user) && (
              <Link underline="hover" to={routes.appliancesUpdate({ id: selected.id })}>
                {appliance.name}
              </Link>
            )) ||
            appliance.name,
          ...makeApplianceHealthInfo(appliance),
        }}
      />
    </InfoSection>
  )
}

const OutputApplianceInfo = () => {
  const { selected, appliances } = useSelector(
    ({ serviceOverviewReducer }: GlobalState) => serviceOverviewReducer,
    shallowEqual,
  )

  const user = useSelector(({ userReducer }: GlobalState) => userReducer.user, shallowEqual) as User
  if (!selected) {
    return null
  }

  const appliance = appliances.find(({ id }) => id === selected.id)
  if (!appliance) return null

  return (
    <InfoSection id="paper-Information" title="Output appliance">
      <DataSet
        values={{
          Name:
            (hasAccessToAppliance(appliance, user) && (
              <Link underline="hover" to={routes.appliancesUpdate({ id: selected.id })}>
                {appliance.name}
              </Link>
            )) ||
            appliance.name,
          Type: appliance.type,
          ...makeApplianceHealthInfo(appliance),
          ...makeApplianceAlarmsInfo(appliance, user),
        }}
      />
    </InfoSection>
  )
}

function getApplianceRistMetrics(input: Input, outputs: Output[]) {
  const ristMetrics: StreamMetrics[] = [
    input.metrics?.ristMetrics || [],
    ...outputs.map(o => o.metrics?.ristMetrics || []),
  ].flat()
  return ristMetrics
}

export function getConnectionMetrics(input: Input, outputs: Output[], from: string, to: string) {
  return getApplianceRistMetrics(input, outputs).reduce(
    (a, c) => {
      if (
        (c.clientApplianceId === from && c.serverApplianceId === to) ||
        (c.serverApplianceId === from && c.clientApplianceId === to)
      ) {
        // We only want RistInputMetrics and not RistInputWindow1mMetrics (used for packet loss measurements)
        // so we apply a filtering on the metric window. This is currently not strictly necessary for rist outputs
        // since we don't have any metrics with 1m aggregation for rist outputs but this is more for clarity.
        if (isRistInputMetrics(c) && c.window == MetricWindow.s10) a.receiveMetrics = c
        if (isRistOutputMetrics(c) && c.window == MetricWindow.s10) a.sendMetrics = c
      }
      return a
    },
    {} as {
      sendMetrics?: RistOutputMetrics
      receiveMetrics?: RistInputMetrics
    },
  )
}

const ConnectionInfo = () => {
  const { input, selected, tunnels, appliances } = useSelector(
    ({ serviceOverviewReducer }: GlobalState) => serviceOverviewReducer,
    shallowEqual,
  )

  const outputs = useSelector(({ outputsReducer }: GlobalState) => outputsReducer.outputs, shallowEqual)

  if (!selected || !input) {
    return null
  }
  const [from, to] = selected.id.split('>')
  const fromAppliance = appliances.find(a => a.id == from)
  const toAppliance = appliances.find(a => a.id == to)
  if (!fromAppliance || !toAppliance) {
    return null
  }

  const { sendMetrics, receiveMetrics } = getConnectionMetrics(input, outputs, from, to)

  const sendMetric = (getter: (s: RistOutputMetrics) => any) => {
    if (!sendMetrics) {
      return 'N/A'
    }
    const val = getter(sendMetrics)
    if (typeof val === 'undefined' || Number.isNaN(val)) {
      return 'N/A'
    }
    return val
  }
  const receiveMetric = (getter: (s: RistInputMetrics) => any) => {
    if (!receiveMetrics) {
      return 'N/A'
    }
    const val = getter(receiveMetrics)
    if (typeof val === 'undefined' || Number.isNaN(val)) {
      return 'N/A'
    }
    return val
  }

  let senderTunnelInfo: { [key: string]: string } = {}
  let receiverTunnelInfo: { [key: string]: string } = {}
  const networkInfo: { [key: string]: string | React.ReactNode } = {}

  const tunnel = tunnels.find(
    t =>
      (t.client === fromAppliance.id && t.server === toAppliance.id) ||
      (t.client === toAppliance.id && t.server === fromAppliance.id),
  )
  if (tunnel) {
    const tunnelClientInfo = makeClientConnectionDetails([
      {
        fromAddress: tunnel.clientLocalIp,
        fromPort: tunnel.clientLocalPort,
        toAddress: tunnel.clientRemoteIp,
        toPort: tunnel.clientRemotePort,
      },
    ])

    const tunnelServerInfo = makeServerConnectionDetails([
      {
        listenAddress: tunnel.serverLocalIp,
        listenPort: tunnel.serverLocalPort,
      },
    ])
    // Input: Sender (tunnel client on external appliance) pushes to receiver (tunnel server on core appliance)
    // Output: Receiver (tunnel client on external appliance) pulls from sender (tunnel server on core appliance)
    senderTunnelInfo = fromAppliance.id === tunnel.client ? tunnelClientInfo : tunnelServerInfo
    receiverTunnelInfo = toAppliance.id === tunnel.client ? tunnelClientInfo : tunnelServerInfo
    if (tunnel.network)
      networkInfo['Network'] = (
        <Link to={routes.networkUpdate({ id: tunnel.network.id })} underline="always">
          {tunnel.network.name}
        </Link>
      )
    else {
      networkInfo['Network'] = TunnelLabels[tunnel.type]
    }
  }

  const inputMultipathState = receiveMetrics && receiveMetrics.multipathState
  const inputMultiPathStateData = inputMultipathState
    ? {
        'Multipath state': inputMultipathState,
      }
    : {}
  const outputMultipathState = sendMetrics && sendMetrics.multipathState
  const outputMultiPathStateData = outputMultipathState
    ? {
        'Multipath state': outputMultipathState,
      }
    : {}
  return (
    <InfoSection id="paper-Information" title={'RIST stream'}>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <h2>
            Sent from <span style={{ fontWeight: 'normal' }}>{fromAppliance.name}</span>
          </h2>
          <DataSet
            values={{
              ...senderTunnelInfo,
              ...networkInfo,
              ...outputMultiPathStateData,
              Bitrate: sendMetric(s => formatBitrate(s.sendBitrate)),
              'Packet rate': sendMetric(s => Math.round(s.sendPacketrate)),
              'Packet send errors': sendMetric(s => s.packetSendErrors),
              'Retransmission bitrate': formatBitrate(sendMetric(s => s.retransmitSendBitrate)),
              'Retransmission packet rate': sendMetric(s => Math.round(s.retransmitSendPacketrate)),
              'RTP packets sent': sendMetric(s => s.rtpPacketsSent),
              Roundtrip: sendMetric(s => applyUnit(s.roundtripMs, 'ms')),
              'Internal Stream ID': sendMetric(s => s.streamId),
            }}
          />
        </div>
        <div style={{ flex: 1 }}>
          <h2>
            Received by <span style={{ fontWeight: 'normal' }}>{toAppliance.name}</span>
          </h2>
          <DataSet
            values={{
              ...receiverTunnelInfo,
              ...networkInfo,
              ...inputMultiPathStateData,
              Bitrate: receiveMetric(s => formatBitrate(s.receiveBitrate)),
              'Packet rate': receiveMetric(s => Math.round(s.receivePacketrate)),
              'Packet send errors': receiveMetric(s => s.packetSendErrors),
              'Packets discarded': receiveMetric(s => s.packetsDiscarded),
              'Retransmission bitrate': receiveMetric(s => formatBitrate(s.retransmitReceiveBitrate)),
              'Retransmission packet rate': receiveMetric(s => Math.round(s.retransmitReceivePacketrate)),
              'RTP packets received': receiveMetric(s => s.rtpPacketsReceived),
              Roundtrip: receiveMetric(s => applyUnit(s.roundtripMs, 'ms')),
              'Propagation delay': receiveMetric(s => applyUnit(s.propagationDelayMs, 'ms')),
              'Packets lost': receiveMetric(s => s.packetsLost),
              'Longest burst loss': receiveMetric(s => s.longestBurstLoss),
              'Internal Stream ID': receiveMetric(s => s.streamId),
            }}
          />
        </div>
      </div>
    </InfoSection>
  )
}

const OutputInfo = () => {
  const { selected } = useSelector(({ serviceOverviewReducer }: GlobalState) => serviceOverviewReducer, shallowEqual)
  const outputId = selected?.id
  const outputs = useSelector(({ outputsReducer }: GlobalState) => outputsReducer.outputs, shallowEqual)
  const output = outputs.find(({ id }) => id === outputId)
  if (!output) {
    return null
  }

  const ristServerEgressMetricsPacketsLost2min = output.metrics?.ristMetrics
    .filter(
      m =>
        m.outputId == output.id &&
        m.isEgress == true &&
        m.window === MetricWindow.m1 &&
        m.sampledAt &&
        ageSeconds(m.sampledAt) <= 120,
    )
    .filter(isOutputMetricsWithPacketsLost)
    .map(m => m.packetsLost)

  const egressPacketsLost2min = ristServerEgressMetricsPacketsLost2min?.length
    ? ristServerEgressMetricsPacketsLost2min.reduce((sum, packetsLost) => sum + packetsLost, 0)
    : null

  const packetsLost =
    typeof egressPacketsLost2min === 'number'
      ? {
          'Packets Lost (last 2 minutes)': packets(egressPacketsLost2min),
        }
      : {}
  const ports = output.ports

  return (
    <>
      <InfoSection id="paper-Information" title="Output">
        <div>
          <DataSet
            values={{
              Name: (
                <Link underline="hover" to={routes.outputsUpdate({ id: outputId })}>
                  {output.name}
                </Link>
              ),
              Type: distinct(ports.map(port => outputType(port))).join(', '),
              Status: <OutputHealthIndicator outputId={output.id} inline={true} />,
              ...packetsLost,
              ...getOutputConnectionDetails(output),
            }}
          />
        </div>
      </InfoSection>
    </>
  )
}

const OutputPortInfo = () => {
  const { selected } = useSelector(({ serviceOverviewReducer }: GlobalState) => serviceOverviewReducer, shallowEqual)
  const outputId = selected?.data?.toId
  const outputs = useSelector(({ outputsReducer }: GlobalState) => outputsReducer.outputs, shallowEqual)
  const output = outputs.find(({ id }) => id === outputId)
  if (!output) {
    return null
  }

  const portMode = output.ports?.find(port => port.id === selected?.data?.logicalPortId)?.mode

  const srtInfo = srtOutputInfo(output, selected?.data?.logicalPortId, selected?.data?.streamId)
  const zixiInfo = zixiOutputInfo(output, selected?.data?.logicalPortId, selected?.data?.streamId)
  const rtmpInfo = rtmpOutputInfo(output, selected?.data?.logicalPortId, selected?.data?.streamId)
  const ristInfo = ristOutputInfo(output, selected?.data?.logicalPortId, selected?.data?.streamId)
  const udpInfo = udpOutputInfo(output, selected?.data?.logicalPortId, selected?.data?.streamId)
  const unixInfo = unixOutputInfo(output, selected?.data?.logicalPortId, selected?.data?.streamId)
  const rtpInfo = rtpOutputInfo(output, selected?.data?.logicalPortId, selected?.data?.streamId)
  const vaOutputPipe = output.metrics?.vaOutputPipe
  const outputApplianceType = output?.appliances[0].type
  const isVa = outputApplianceType && isVaApplianceType(outputApplianceType)
  const showHandoverTooltip = !isRistServerPortMode(portMode) || isVa
  return (
    <>
      {udpInfo && (
        <InfoSection
          title="UDP information"
          tooltip={
            showHandoverTooltip
              ? `The handover to the protocol adapter for ${capitalizePortMode(portMode)} uses UDP.`
              : undefined
          }
        >
          <div>
            <DataSet values={udpInfo} />
          </div>
        </InfoSection>
      )}

      {unixInfo && (
        <InfoSection
          title="Unix socket information"
          tooltip={
            showHandoverTooltip
              ? `The handover to the protocol adapter for ${capitalizePortMode(portMode)} uses Unix domain sockets.`
              : undefined
          }
        >
          <div>
            <DataSet values={unixInfo} />
          </div>
        </InfoSection>
      )}

      {rtpInfo && ( // This is not displayed for nimbra VA
        <InfoSection
          title="RTP information"
          tooltip={
            showHandoverTooltip
              ? `The handover to the protocol adapter for ${capitalizePortMode(portMode)} uses RTP.`
              : undefined
          }
        >
          <div>
            <DataSet values={rtpInfo} />
          </div>
        </InfoSection>
      )}

      {!isVa &&
      zixiInfo && ( // This is not displayed for nimbra VA
          <InfoSection title="Zixi information">
            <div>
              <DataSet values={zixiInfo} />
            </div>
          </InfoSection>
        )}

      {!isVa &&
      srtInfo && ( // This is not displayed for nimbra VA
          <InfoSection title="SRT information">
            <div>
              <DataSet values={srtInfo} />
            </div>
          </InfoSection>
        )}

      {!isVa &&
      rtmpInfo && ( // This is not displayed for nimbra VA
          <InfoSection title="RTMP information">
            <div>
              <DataSet values={rtmpInfo} />
            </div>
          </InfoSection>
        )}

      {!isVa &&
      ristInfo && ( // This is not displayed for nimbra VA
          <InfoSection title="RIST information">
            <div>
              <DataSet values={ristInfo} />
            </div>
          </InfoSection>
        )}

      {vaOutputPipe && (
        <InfoSection title={`VA ${output.ports[0].mode.toUpperCase()} statistics`}>
          <div>
            <VaOutputStatistics output={output} />
          </div>
        </InfoSection>
      )}
    </>
  )
}

interface Props {
  selected: SelectedGraphItem | undefined
}

const Info = ({ selected }: Props): JSX.Element | null => {
  if (!selected) {
    return null
  }
  return (
    <>
      {selected.type === GraphNodeType.input && <InputInfo />}
      {selected.type === GraphNodeType.inputEdgeAppliance && <InputApplianceInfo />}
      {selected.type === GraphNodeType.thumbAppliance && <ThumbNodeInfo />}
      {selected.type === GraphNodeType.inputRegionCore && <CoreNodeInfo />}
      {selected.type === GraphNodeType.outputRegionCore && <CoreNodeInfo />}
      {selected.type === GraphNodeType.inputRegionOutputEdgeAppliance && <OutputApplianceInfo />}
      {selected.type === GraphNodeType.outputRegionOutputEdgeAppliance && <OutputApplianceInfo />}
      {selected.type === GraphNodeType.output && <OutputInfo />}
      {selected.type === GraphNodeType.connection && <ConnectionInfo />}
      {selected.type === GraphNodeType.inputPort && <InputPortInfo />}
      {selected.type === GraphNodeType.outputPort && <OutputPortInfo />}
      {isGraphNodeApplianceType(selected.type) && <Tr101290Page />}
      {isGraphNodeApplianceType(selected.type) && <TsInfo />}
    </>
  )
}

export default Info
