import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { HashRouter as Router } from 'react-router-dom'
import 'typeface-roboto-slab'
import 'typeface-roboto'
import './index.css'
import App from './App'
import { ThemeSwitcher } from './ThemeSwitcher'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import store from './store'

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <Provider store={store}>
    <Router>
      <ThemeSwitcher>
        <DndProvider backend={HTML5Backend}>
          <App />
        </DndProvider>
      </ThemeSwitcher>
    </Router>
  </Provider>,
)
