import { HandoverMethod } from 'common/api/v1/types'

export const CIDRToolTip = (mandatory: boolean, entity: 'Input' | 'Output') =>
  `The whitelist CIDR block specifies which IP addresses that are allowed to connect to this ${entity}. Example: 10.0.0.0/16. ` +
  mandatory
    ? 'To allow everything use 0.0.0.0/0'
    : 'To allow everything, leave blank or use 0.0.0.0/0.'

export const handoverMethodLabel = (handoverMethod: HandoverMethod) => {
  const handoverMethodLabels: {
    [key in HandoverMethod]: string
  } = {
    [HandoverMethod.udp]: 'udp',
    [HandoverMethod.unix]: 'unix sockets',
  }
  return handoverMethodLabels[handoverMethod] || handoverMethod
}

export const handoverMethodOptions = Object.keys(HandoverMethod).map(method => ({
  name: handoverMethodLabel(method as HandoverMethod),
  value: method,
}))
