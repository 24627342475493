import Grid from '@mui/material/Grid'
import { Theme } from '@mui/material/styles'

import Wrapper from '../../common/Wrapper'
import { Paper } from '../../common/Form'
import ActiveServices from './ActiveServices'

const PADDING = 2

const classes = {
  paper: {
    padding: (theme: Theme) => theme.spacing(1, PADDING, 3, PADDING),
    overflow: 'hidden',
  },
}

const MapContainer = () => {
  return (
    <Wrapper name="Event overview">
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <Paper sx={classes.paper} title="Active services">
            <Grid item xs={12}>
              <ActiveServices />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Wrapper>
  )
}

export default MapContainer
