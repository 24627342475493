import { get } from 'lodash'
import { ListResult, GeoLocation, Entity, Longitude, Latitude } from 'common/api/v1/types'
import axios from '../../utils/edgeAxios'
import { PaginatedRequestParams } from '../nm-types'

export interface ILocationApi {
  searchLocation(params: PaginatedRequestParams): Promise<ListResult<GeoLocation & Entity>>
}

export interface LocationOSM {
  geometry: {
    coordinates: [Longitude, Latitude]
  }
  properties: {
    osm_id: number
    country: string
    osm_value: string
    name: string
    state: string
  }
}
const getOSMProp = (region: LocationOSM, propName: keyof LocationOSM['properties']) =>
  get(region, `properties.${propName}`)

export class LocationApi implements ILocationApi {
  /**
   * Using free API https://photon.komoot.de to get geoLocation for substring of place name
   * @param filter
   */
  async searchLocation({ filter = '' }: PaginatedRequestParams): Promise<ListResult<GeoLocation & Entity>> {
    if (!filter) return { items: [], total: 0 }
    const url = `https://photon.komoot.io/api/?q=${filter}&osm_tag=place:city&osm_tag=place:town&osm_tag=place:municipality&osm_tag=place:province`
    const { features }: { features: Array<LocationOSM> } = (await axios.get(url)).data
    const list = features.reduce<Array<GeoLocation & Entity>>(
      (acc, item) => [
        ...acc,
        {
          id: getOSMProp(item, 'osm_id'),
          location: [getOSMProp(item, 'name'), getOSMProp(item, 'state'), getOSMProp(item, 'country')]
            .filter(Boolean)
            .join(', '),
          coordinates: [item.geometry.coordinates[1], item.geometry.coordinates[0]],
        },
      ],
      [],
    )
    return {
      items: list,
      total: list.length,
    }
  }
}
