import { useState } from 'react'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { Theme } from '@mui/material/styles'

import { Input, InputAccessType, GroupOrGroupRecipientList } from 'common/api/v1/types'
import { Api } from '../../../../store'
import PaginatedList from '../../../common/SelfStatePaginatedList'
import { isGroup } from '../../../../utils'
import { GroupsRequestParams } from '../../../../api/nm-types'

const styles = {
  button: {
    whiteSpace: 'nowrap',
    marginLeft: (theme: Theme) => theme.spacing(1),
    '&.cancel': {
      color: (theme: Theme) => theme.palette.error.main,
      borderColor: (theme: Theme) => theme.palette.error.main,
    },
  },
}

const groupApi = Api.groupsApi

interface AvailableGroupsProps {
  input: Input
  onShare: (group: GroupOrGroupRecipientList, permission: InputAccessType) => void
  onCancel: (group: GroupOrGroupRecipientList) => void
  changes: { [key: string]: InputAccessType }
  listChanges: { [key: string]: InputAccessType }
}
const AvailableGroups = ({ input, onShare, onCancel, changes, listChanges }: AvailableGroupsProps) => {
  const [hideFilter, setHideFilter] = useState(true)

  return (
    <div data-test-id="groups-container">
      <Typography variant="h2">Add recipients</Typography>
      <PaginatedList<GroupsRequestParams, GroupOrGroupRecipientList>
        api={groupApi.getGroupsWithLists.bind(groupApi)}
        emptyMessage="no available groups"
        hideSearch={hideFilter}
        notFoundMessage="no matching groups"
        onListChange={({ filter, total = 0 }) => setHideFilter(!filter && total < 10)}
        otherParams={{ inputNotShared: input.id, userGroup: input.owner }}
        searchPlaceholder="Find specific group…"
        List={({ list }) => (
          <List data-test-id="groups">
            {list.map(group => {
              const accessType = isGroup(group) ? changes[group.id] : listChanges[group.id]
              return (
                <ListItem disableGutters key={group.id} divider>
                  <ListItemText primaryTypographyProps={{ color: changes[group.id] ? 'textSecondary' : 'textPrimary' }}>
                    <Typography component="span" data-test-id="group-name">
                      {group.name}{' '}
                    </Typography>
                    {!isGroup(group) && (
                      <Typography variant="body2" color="textSecondary" component="span">
                        (Group list)
                      </Typography>
                    )}
                  </ListItemText>
                  {accessType ? (
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => onCancel(group)}
                      sx={styles.button}
                      className="cancel"
                    >
                      {`Cancel ${accessType === InputAccessType.pull ? 'full access' : 'preview'}`}
                    </Button>
                  ) : (
                    <>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => onShare(group, InputAccessType.view)}
                        data-test-id="preview-btn"
                      >
                        Preview
                      </Button>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => onShare(group, InputAccessType.pull)}
                        sx={styles.button}
                      >
                        Full Access
                      </Button>
                    </>
                  )}
                </ListItem>
              )
            })}
          </List>
        )}
      />
    </div>
  )
}

export default AvailableGroups
