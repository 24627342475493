import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Form, Formik, FormikProps } from 'formik'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import MuiLink from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import FormControl from '@mui/material/FormControl'
import { OpenInNew } from '@mui/icons-material'
import { Theme } from '@mui/material/styles'

import {
  GlobalSettings,
  ImageName,
  LogLevel,
  Role,
  TlsCertRead,
  TlsCertWrite,
  User,
  ExpFeatures,
  KubernetesProvider,
} from 'common/api/v1/types'
import { AppDispatch, GlobalState } from '../../store'
import { getSettings, saveImages, saveSettings, saveTls, setDevMode } from '../../redux/actions/settingsActions'
import { numericEnum, usePageParams } from '../../utils'
import { getExperimentalDevFeatures, getExperimentalExternalFeatures } from '../../utils/features'
import { ButtonsPane, Paper, Select, TextInput, Checkbox } from '../common/Form'
import Pendable from '../common/Pendable'
import Wrapper from '../common/Wrapper'
import FileInput from './FileInput'
import { ensureOperatorToken } from '../../redux/actions/apiTokensActions'
import { handoverMethodOptions } from '../../texts'

const classes = {
  container: {
    width: '100%',
    borderBottom: (theme: Theme) => `1px solid ${theme.palette.divider}`,
  },
  noMarginTop: {
    marginTop: '0',
  },
  backupList: {
    padding: (theme: Theme) => theme.spacing(3),
  },
}

enum GlobalSettingsTabs {
  settings = 'settings',
  'system logo' = 'system logo',
  'tls certificate' = 'tls certificate',
  licenses = 'licenses',
  alarms = 'alarms',
}

const SettingsForm = ({ values }: FormikProps<GlobalSettings>) => {
  const { saving, devMode } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)
  const dispatch = useDispatch<AppDispatch>()

  return (
    <Grid container>
      <Grid item xs={12}>
        <Form id="settings-form" translate="no" noValidate>
          <Paper title="Settings" sx={classes.noMarginTop}>
            <Select
              label="Edge API log level"
              name="logLevel"
              options={Object.entries(numericEnum(LogLevel)).map(([name, value]) => ({ name, value }))}
            />
            <TextInput name="defaultDelay" label="Default delay" required type="number" noNegative />
            <Select
              label="Default broadcast standard"
              name="defaultBroadcastStandard"
              options={[
                { name: 'DVB', value: 'dvb' },
                { name: 'ATSC', value: 'atsc' },
              ]}
            />
            <Checkbox
              name="sslRedirectEnabled"
              label="SSL redirection enabled"
              tooltip={'Redirect from HTTP to HTTPS (if TLS is enabled).'}
            />
            <Checkbox
              name="ntpEnabled"
              label="NTP enabled"
              disabled={values.kubernetesProvider != KubernetesProvider.metal}
            />
            <TextInput name="ntpServer" label="NTP server" disabled={!values.ntpEnabled} />
            {devMode && (
              <Select name="defaultHandoverMethod" label="Default handover method" options={handoverMethodOptions} />
            )}
          </Paper>
          <Paper title="Optional features">
            {getExperimentalExternalFeatures().map(feature => (
              <Checkbox key={feature.name} label={feature.label} name={`expFeatures.${feature.name}`} />
            ))}
          </Paper>
          <Paper title="Backups">
            <Box sx={classes.backupList}>
              <Typography component="span">Config Backups</Typography>
              <Tooltip title="View config backups" placement="top">
                <MuiLink href={'/backup/config'} target="_blank">
                  <IconButton edge="end" aria-label="View logs">
                    <OpenInNew />
                  </IconButton>
                </MuiLink>
              </Tooltip>
            </Box>
          </Paper>
          {devMode && (
            <Paper title="Experimental dev features">
              <FormControl margin="normal">
                <Button onClick={() => dispatch(setDevMode(false))} id="disable-dev-mode-button" variant="outlined">
                  Disable dev mode
                </Button>
                <Button
                  style={{ marginTop: 16 }}
                  onClick={() => void dispatch(ensureOperatorToken())}
                  id="ensure-operator-token-button"
                  variant="contained"
                >
                  Ensure Edge operator token
                </Button>
                {getExperimentalDevFeatures().map(feature => (
                  <Checkbox key={feature.name} label={feature.label} name={`expFeatures.${feature.name}`} />
                ))}
              </FormControl>
            </Paper>
          )}
          <ButtonsPane
            main={{
              Save: { primary: true, savingState: saving, type: 'submit' },
            }}
          />
        </Form>
      </Grid>
    </Grid>
  )
}

const TlsForm = () => {
  const { savingTls } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)

  return (
    <Grid container>
      <Grid item xs={12}>
        <Form translate="no" noValidate>
          <Paper title="Tls certificate" sx={classes.noMarginTop}>
            <TextInput name="fingerprint" label="Fingerprint" disabled />
            <TextInput
              name="key"
              label="Key"
              required
              multiline
              tooltip="The server's secret key in plain-text (PEM format)"
            />
            <TextInput
              name="cert"
              label="Certificate"
              required
              multiline
              tooltip="The certificate from the Certificate Authority (CA) in plain-text (PEM format)"
            />
          </Paper>
          <ButtonsPane
            main={{
              Save: { primary: true, savingState: savingTls, type: 'submit' },
            }}
          />
        </Form>
      </Grid>
    </Grid>
  )
}

const LicenseForm = () => {
  const { saving } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)

  return (
    <Grid container>
      <Grid item xs={12}>
        <Form id="licenses-form" translate="no" noValidate>
          <Paper title="Licenses" sx={classes.noMarginTop}>
            <TextInput name="zixiFeederKey" label="Zixi feeder key" />
            <TextInput name="zixiReceiverKey" label="Zixi receiver key" />
          </Paper>

          <ButtonsPane
            main={{
              Save: { primary: true, savingState: saving, type: 'submit' },
            }}
          />
        </Form>
      </Grid>
    </Grid>
  )
}

const AlarmsForm = () => {
  const { saving } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)

  return (
    <Grid container>
      <Grid item xs={12}>
        <Form id="alarms-form" translate="no" noValidate>
          <Paper title="Alarms" sx={classes.noMarginTop}>
            <Checkbox
              name="showAllNimbra400Alarms"
              label="Show all Nimbra 400 alarms"
              tooltip="Include alarms for objects on connected Nimbra 400-series appliances not directly related to Nimbra Edge"
            />
          </Paper>

          <ButtonsPane
            main={{
              Save: { primary: true, savingState: saving, type: 'submit' },
            }}
          />
        </Form>
      </Grid>
    </Grid>
  )
}

const defaultExpFeatures = Object.keys(ExpFeatures).reduce((a, key) => {
  return { ...a, [key]: false }
}, {})

export const Settings = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [{ settingsTab = GlobalSettingsTabs.settings }, setPageParams] = usePageParams()
  useEffect(() => {
    dispatch(getSettings())
  }, [])
  const { settings, user, tls } = useSelector(
    ({ settingsReducer, userReducer }: GlobalState) => ({
      settings: settingsReducer.settings,
      tls: settingsReducer.tls,
      user: userReducer.user as User,
    }),
    shallowEqual,
  )
  const onSubmit = (settingsPayload: GlobalSettings) => {
    dispatch(saveSettings(settingsPayload))
  }
  const onImagesSubmit = () => {
    dispatch(saveImages())
  }
  const onTlsSubmit = (tlsPayload: TlsCertWrite) => {
    dispatch(saveTls(tlsPayload))
  }
  if (user && user.role !== Role.super) return null

  return (
    <Wrapper name="Global settings">
      <Paper sx={{ marginBottom: 0, paddingBottom: 0 }}>
        <Tabs
          value={settingsTab}
          variant="fullWidth"
          onChange={(_, val) => {
            setPageParams({ settingsTab: val })
          }}
          sx={classes.container}
          textColor="inherit"
        >
          {Object.values(GlobalSettingsTabs).map(v => (
            <Tab key={`tab-${v}`} label={v} value={v} />
          ))}
        </Tabs>
      </Paper>
      <Pendable pending={!settings}>
        {settingsTab === GlobalSettingsTabs.settings && (
          <Formik
            onSubmit={(values, actions) => {
              actions.setSubmitting(false)
              onSubmit(values)
            }}
            initialValues={
              {
                ...settings,
                expFeatures: Object.assign({}, defaultExpFeatures, settings?.expFeatures),
              } as GlobalSettings
            }
            component={SettingsForm}
          />
        )}
        {settingsTab === GlobalSettingsTabs['tls certificate'] && (
          <Formik
            onSubmit={({ key, cert }, actions) => {
              actions.setSubmitting(false)
              onTlsSubmit({ key, cert })
            }}
            enableReinitialize={true}
            initialValues={{ ...tls, cert: '', key: '' } as TlsCertWrite & TlsCertRead}
            component={TlsForm}
          />
        )}
        {settingsTab === GlobalSettingsTabs['system logo'] && (
          <>
            <Paper title="System Logo" sx={classes.noMarginTop}>
              <Grid item xs={12}>
                <FileInput label="Login page logo" name={ImageName.product} id="login-page-logo-file-picker" />
                <FileInput
                  label="Top left logo"
                  name={ImageName.serviceProvider}
                  text="Best ratio is 5:3"
                  id="left-corner-file-picker"
                />
                <FileInput label="Favicon" name={ImageName.favicon} id="favicon-file-picker" />
              </Grid>
            </Paper>
            <ButtonsPane
              main={{
                Save: { primary: true, onClick: onImagesSubmit, id: 'image-save' },
              }}
            />
          </>
        )}
        {settingsTab === GlobalSettingsTabs.licenses && (
          <Formik
            onSubmit={(values, actions) => {
              actions.setSubmitting(false)
              onSubmit(values)
            }}
            initialValues={
              {
                ...settings,
              } as GlobalSettings
            }
            component={LicenseForm}
          />
        )}
        {settingsTab === GlobalSettingsTabs.alarms && (
          <Formik
            onSubmit={(values, actions) => {
              actions.setSubmitting(false)
              onSubmit(values)
            }}
            initialValues={
              {
                ...settings,
              } as GlobalSettings
            }
            component={AlarmsForm}
          />
        )}
      </Pendable>
    </Wrapper>
  )
}
