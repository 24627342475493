import { RistserverLogLevel } from './api/v1/types'

export interface AddInputStreamUnix {
    channelId: number
    streamId: number
    localPath: string
}

export interface AddInputStreamUdp {
    channelId: number
    streamId: number
    localIp: string
    localPort: number
    multicastAddress?: string
    sourceAddresses?: string[]
    useExtendedSequenceNumber: boolean
    parseAsTs: boolean
    failoverPriority?: number
    packetFormat?: 'rtp'
}

export interface AddInputStreamRtp {
    channelId: number
    streamId: number
    localIp: string
    localPort: number
    multicastAddress?: string
    sourceAddresses?: string[]
}

export interface AddInputStreamRist {
    /** Common properties **/
    channelId: number
    streamId: number
    localIp: string
    localPort: number
    failoverPriority?: number

    /// Note: When useReducedOverhead is set to True, localIp and multicastAddress shall not be set!
    useReducedOverhead: false

    /// Set to true at ingress point when integrating with external RIST implementations
    insertOriginTime: boolean

    /** Main profile properties **/
    /// undefined for simple profile, required for main profile
    tunnelId?: number
}

export interface RemoveStream {
    channelId: number
    streamId: number
}

export enum Importance {
    vital = 'vital',
    nonvital = 'nonvital',
}

export interface AddOutputStreamUdp {
    channelId: number
    streamId: number
    localIp?: string
    localPort: number
    ttl?: number
    remoteIp: string
    remotePort: number
    delayMs: number
    delayMode: DelayMode
    parseAsTs: boolean
    importance?: Importance

    packetFormat?: 'payload' | 'rtp'
}

export interface AddOutputStreamUnix {
    channelId: number
    streamId: number
    delayMs: number
    delayMode: DelayMode
    remotePath: string
    importance?: Importance
}

export interface AddOutputStreamRtp {
    channelId: number
    streamId: number
    localIp?: string
    localPort: number
    remoteIp: string
    remotePort: number
    ttl?: number
}

export interface AddOutputStreamRist {
    /** Common properties **/
    channelId: number
    streamId: number
    localIp?: string
    localPort: number
    remoteIp: string
    remotePort: number
    useOriginTiming: boolean
    /// Note: When useReducedOverhead is set to True, localIp and remoteIp shall not be set!
    useReducedOverhead: false

    /** Main profile properties **/
    /// undefined for simple profile, required for main profile
    tunnelId?: number
}

export enum DelayMode {
    basedOnRtpAndArrivalTime = 0,
    basedOnArrivalTime = 1,
    basedOnOriginTime = 2,
}

export interface AddChannel {
    channelId: number
    description: string
    bufferDurationMs: number
    inputMultipathPolicy: RistMultipathPolicy
}

export interface SetInputMultipathParams {
    channelId: number
    multipathPolicy: RistMultipathPolicy
}

export interface SetOutputStreamMaxBitrate {
    channelId: number
    streamId: number
    bitsPerSecond: number | null
}

export enum RistMultipathPolicy {
    failover = 'failover', // This is not used anymore, instead propagate is used which is failover + propagation to source
    redundancy = 'redundancy',
    propagate = 'propagate',
}

export interface RemoveChannel {
    channelId: number
}

export type Statistics = {}

export type DeltaStatistics = {}

export type Status = {}

export type SetLoggingOptions = {
    level: RistserverLogLevel
}

export interface UpdateEncryptionKey {
    channelId: string
    key: string
}

export interface JsonRpcResponse<T> {
    id: number
    jsonrpc: '2.0'
    method: string
    result: T
}

export interface RistPmStats {
    unavailableSeconds?: number
    severelyErroredSeconds?: number
    erroredSeconds?: number
}

export enum RistInputMultipathState {
    active = 'active',
    standby = 'standby',
}

export enum RistOutputMultipathState {
    active = 'active',
    inhibited = 'inhibited',
}

export interface RistInputStats extends RistPmStats {
    bytesReceived: number
    receiveBitrate?: number
    malformedRtcpPacketsReceived: number
    malformedRtcpReceivePacketrate?: number
    packetsLost: number
    lostPacketrate?: number
    longestBurstLoss?: number
    packetsReceived: number
    receivePacketrate?: number
    packetSendErrors: number
    packetsDiscarded: number
    packetsDropped: number
    propagationDelayMs: number
    retransmitBytesReceived: number
    retransmitReceiveBitrate?: number
    retransmitPacketsReceived: number
    retransmitReceivePacketrate?: number
    retransmitPacketsRequested: number
    roundtripMs: number
    rtpPacketsReceived: number
    rtpBytesReceived: number
    rtpPacketsInhibited: number
    rtcpPacketsReceived: number
    rtcpReceivePacketrate?: number
    rtcpPacketsSent: number
    rtcpSendPacketrate?: number
    streamId: number
    unsupportedRtcpPacketsReceived: number
    timestampViolations: number

    multipathState?: RistInputMultipathState
}

export interface RistOutputStats {
    bytesSent: number
    sendBitrate?: number
    packetSendErrors: number
    malformedRtcpPacketsReceived: number
    malformedRtcpReceivePacketrate?: number
    packetsSent: number
    sendPacketrate?: number
    retransmitBytesSent: number
    retransmitSendBitrate?: number
    retransmitPacketsSent: number
    retransmitSendPacketrate?: number
    roundtripMs: number
    rtcpPacketsReceived: number
    rtcpReceivePacketrate?: number
    rtcpPacketsSent: number
    rtcpSendPacketrate?: number
    rtpBytesSent: number
    rtpPacketsInhibited: number
    rtpPacketsSent: number
    unsupportedRtcpPacketsReceived: number
    reportedPacketLossPercent: number
    rtpPacketsDroppedBecauseOfBitrateLimit: number
    streamId: number

    multipathState?: RistOutputMultipathState
}

export interface UdpInputStats {
    // Number of malformed *RTP* packets. Not included in `packetsReceived`.
    packetsDiscarded: number
    // Number of packets discarded because the input wasn't active.
    packetsWhileInactive: number
    bytesReceived: number
    receiveBitrate?: number
    packetsReceived: number
    receivePacketrate?: number
    packetsLost: number
    streamId: number

    status?: UdpInputStatusCode
}

export interface UnixInputStats extends UdpInputStats {}

export interface UdpOutputStats extends RistPmStats {
    bytesSent: number
    sendBitrate?: number
    packetsLost: number
    lostPacketrate?: number
    packetsRecovered: number
    packetsSent: number
    playoutMarginMs: number
    sendPacketrate?: number
    streamId: number
    /// 'status' applies only to UDP outputs with delayMode: 2 (basedOnOriginTiming)
    status?: UdpOutputStatusCode
    delayMode: string
    isTr101290Stream: boolean
    packetsDropped: number
}

export interface UnixOutputStats extends UdpOutputStats {}

export interface RtpInputStats {
    bytesReceived: number
    receiveBitrate?: number
    packetsReceived: number
    receivePacketrate?: number
    streamId: number
    packetsDiscarded: number
    packetsDropped: number
    packetsLost: number
    lostPacketrate?: number
    timestampViolations: number
    status?: RtpInputStatusCode
    ssrc?: number
}

export interface RtpOutputStats extends Omit<UdpOutputStats, 'status' | 'delayMode' | 'isTr101290Stream'> {}

export interface ChannelStats {
    channelId: number
    reorderSectionMs: number
    resetCount: number
    retransmitBudget: number
    retransmitIntervalMs: number
    retransmitRoundtripMs: number
    ageOfOldestHoleMs?: number // TODO: should we create a MergedChannelStats and MergedRistStatisticsResponse instead?
    maxReorderTimeMs?: number // TODO: should we create a MergedChannelStats and MergedRistStatisticsResponse instead?
    ristInput: RistInputStats[]
    ristOutput: RistOutputStats[]
    udpInput: UdpInputStats[]
    udpOutput: UdpOutputStats[]
    unixInput: UnixInputStats[]
    unixOutput: UnixOutputStats[]
    rtpInput: RtpInputStats[]
    rtpOutput: RtpOutputStats[]
}

export interface ChannelDeltaStats {
    channelId: number
    ageOfOldestHoleMs: number
    maxReorderTimeMs: number
    ristInputs: RistInputDeltaStats[]
    ristOutputs: RistOutputDeltaStats[]
    rtpInputs: RtpInputDeltaStats[]
    rtpOutputs: RtpOutputDeltaStats[]
    udpInputs: UdpInputDeltaStats[]
    udpOutputs: UdpOutputDeltaStats[]
}

export interface TunnelDeltaStats {
    tunnelId: number
    maxEncryptionLatencyMs: number
}

export interface ThreadDeltaStats {
    threadId: string
    longestWorkMs: number
    maxDelayedTimerMs: number
}

export interface RistInputDeltaStats {
    streamId: number
    longestBurstLoss: number
    maxPathDiffDelayMs: number | null
    maxReceptionLatencyMs: number
    maxRoundtripMs: number | null
    pm: RistPmStats
}

export interface RistOutputDeltaStats {
    streamId: number
    maxRoundtripMs: number | null
}

export interface UdpOutputDeltaStats {
    streamId: number
    shortestPlayoutMarginMs: number | null
    pm: RistPmStats
}

export interface UdpInputDeltaStats {
    streamId: number
    maxReceptionLatencyMs: number
}

export interface RtpInputDeltaStats {
    streamId: number
    pm: RistPmStats
    longestBurstLoss: number
    maxReceptionLatencyMs: number
    maxPathDiffDelayMs: number | null
    maxRoundtripMs: number | null
}

export interface RtpOutputDeltaStats {
    streamId: number
    maxRoundtripMs: number | null
}

export interface TunnelRxErrors {
    badAddress: number
    bufferFull: number
    cryptoFailure: number
    malformed: number
    notIpv4: number
    notUdp: number
    socketError: number
}

export interface TunnelRxUdp {
    source: string
    destination: string
    bytes: number
    bitrate?: number
    packets: number
    packetRate?: number
}

export interface TunnelRx {
    bytes: number
    bitrate?: number
    error: TunnelRxErrors
    packets: number
    packetRate?: number
    keepAlivePackets: number
    keepAlivePacketrate?: number
    udp: TunnelRxUdp[] // Not saved to Influx at the moment
}

export interface TunnelTxErrors {
    configurationError: number
    cryptoFailure: number
    notConnected: number
    socketError: number
}

export interface TunnelTx {
    bytes: number
    bitrate?: number
    error: TunnelTxErrors
    packets: number
    packetRate?: number
}

export interface TunnelStats {
    rx: TunnelRx
    tunnelId: number
    tx: TunnelTx
}

export interface RistStatisticsResponse {
    deadlinesMissed: number
    channels: ChannelStats[]
    tunnels: TunnelStats[]
    threads: RistStatsThreadStatus
}

export interface RistStatsThreadStatus {
    hardwareConcurrency: number
    maxStreamerThreads: number
    numStreamerThreads: number
    threads: ThreadStats[]
}

export interface ThreadStats {
    cpuPercentage: number
    threadId: string
    osThreadId: number
    runqueuePercentage: number
    longestWorkMs: number
    maxDelayedTimerMs: number
}

export interface RistDeltaStatisticsResponse {
    channels: ChannelDeltaStats[]
    timeOfLastQuery: string
    threads: ThreadDeltaStats[]
    tunnels: TunnelDeltaStats[]
}

export interface TunnelStatus {
    remoteEndpoint: string
    rxBitsPerSecond: number
    status: TunnelStatusCode
    threadId: string
    tunnelId: number
    txBitsPerSecond: number
}

export enum TunnelStatusCode {
    established = 'established',
    noSession = 'no_session',
    verificationFailure = 'verification_failure',
    handshakeError = 'handshake_error',
}

export interface RistStatusResponse {
    channels: ChannelStatus[]
    threads: ThreadStatus
    tunnels: TunnelStatus[]
}

export interface ChannelStatus {
    channelId: number
    threadId: string
    ristInput: RistInputStatus[]
    ristOutput: RistOutputStatus[]
    rtpInput: RtpInputStatus[]
    udpInput: UdpInputStatus[]
    udpOutput: UdpOutputStatus[]
    unixInput: UdpInputStatus[]
    unixOutput: UdpOutputStatus[]
}

export interface RistInputStatus {
    multipathState: RistInputMultipathState
    originTime: RistInputOriginTimeStatus
    sequenceNumberBits: number | null // 32
    ssrc: number | null
    streamId: number
}

export enum RistInputOriginTimeStatus {
    none = 'none',
    receiving = 'receiving',
    inserting = 'inserting',
    overriding = 'overriding',
}

export interface RistOutputStatus {
    multipathState: RistOutputMultipathState
    streamId: number
}

export interface RtpInputStatus {
    streamId: number
    status: RtpInputStatusCode
    ssrc: number
}

export enum RtpInputStatusCode {
    ok = 'ok',
    oddSsrc = 'odd_ssrc',
    unknown = 'unknown',
}

export interface UdpOutputStatus {
    streamId: number
    status: UdpOutputStatusCode
    bitsPerSecond: number
}
export interface UdpInputStatus {
    streamId: number
    bitsPerSecond: number
    status: UdpInputStatusCode
}

export interface UnixInputStatus extends UdpInputStatus {}
export interface UnixOutputStatus extends UdpOutputStatus {}

export enum UdpInputStatusCode {
    active = 'active',
    standby = 'standby',
}

export enum UdpOutputStatusCode {
    playing = 'playing',
    noData = 'no_data',
    noOriginTime = 'no_origin_time',
    noCommonTimeBase = 'no_common_time_base',
    lagging = 'lagging',
}

export interface ThreadStatus {
    hardwareConcurrency: number
    maxStreamerThreads: number
    numStreamerThreads: number
    threads: Thread[]
}

export interface Thread {
    cpuPercentage: number
    threadId: string
    osThreadId: number
    runqueuePercentage: number
}

export interface RistTunnelServer {
    tunnelId: number
    secret: string
    localIp: string
    localPort: number
    authentication: 'keepAlive'
}

export interface RistTunnelClient extends RistTunnelServer {
    remoteIp: string
    remotePort: number
}

export interface RemoveTunnel {
    tunnelId: number
}

export interface RemoveInputStream {
    channelId: number
    streamId: number
}

export interface RemoveOutputStream {
    channelId: number
    streamId: number
}
