import { useEffect, useCallback, useState } from 'react'
import { RouteComponentProps, Redirect } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Form, Formik, FormikErrors } from 'formik'
import Grid from '@mui/material/Grid'

import {
  clearAppliance,
  getAppliance,
  updateAppliance,
  registerApplianceObserver,
  unregisterApplianceObserver,
  restartAppliance,
  removeAppliance,
  recreateTunnels,
} from '../../../redux/actions/applianceActions'
import {
  Appliance,
  ApplianceSettings,
  ApplianceType,
  Group,
  InputAdminStatus,
  Output,
  OutputAdminStatus,
  Role,
  UpdateAppliancePayload,
} from 'common/api/v1/types'
import { Api, AppDispatch, GlobalState } from '../../../store'
import { alarmsThatDisablePort, formTransform, getApplianceOwnerId, useUser } from '../../../utils'
import routes from '../../../utils/routes'
import Pendable from '../../common/Pendable'
import Wrapper from '../../common/Wrapper'
import { ConfirmationDialogResult, useConfirmationDialog } from '../../common/ConfirmDialog'
import Interfaces, { ISharedPort } from './ApplianceForm/Interfaces'
import Settings from './ApplianceForm/Settings/Settings'
import { ButtonsPane, SafeRouting } from '../../common/Form'
import Meta from './ApplianceForm/Meta'
import { RemoveDialog } from './RemoveDialog'
import InputsOutputs from './ApplianceForm/InputsOutputs'
import ApplicationError from '../../common/ApplicationError'
import { Affected, AffectedOutput, ChangeRegionDialog } from './ChangeRegionDialog'
import { enqueueErrorSnackbar } from '../../../redux/actions/notificationActions'
import { ChangeSecondaryRegionDialog } from './ChangeSecondaryRegionDialog'
import { RemoveSecondaryRegionDialog } from './RemoveSecondaryRegionDialog'

export const Edit = ({ history, match }: RouteComponentProps<{ id: string }>) => {
  const user = useUser()
  const dispatch = useDispatch<AppDispatch>()
  const appliance = useSelector(({ appliancesReducer }: GlobalState) => appliancesReducer.appliance, shallowEqual)
  const { saving, restarting, loading, error, recreatingTunnels } = useSelector(
    ({ appliancesReducer }: GlobalState) => appliancesReducer,
    shallowEqual,
  )
  const { showConfirmation, showConfirmationAsync, hideConfirmation } = useConfirmationDialog()

  const { devMode } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)

  const [isFetchingOutputsAffectedByRegionChange, setIsFetchingOutputsAffectedByRegionChange] = useState(false)

  const applianceId = match.params.id

  useEffect(() => {
    dispatch(registerApplianceObserver({ applianceId }))
    applianceId && dispatch(getAppliance(applianceId))
    return () => {
      dispatch(unregisterApplianceObserver({ applianceId }))
      dispatch(clearAppliance())
    }
  }, [])

  const fetchOutputsAffectedByRegionChange = useCallback(
    async (limit: number) => {
      // Get all inputs on the appliance. No limit, to check if any input has output(s) connected to it
      const inputsRes = await Api.inputApi.getBareInputs({
        applianceId: applianceId,
        adminStatus: InputAdminStatus.on,
      })

      const inputs = inputsRes.items

      // Get outputs on the appliance. Limited
      const outputsRes = await Api.outputApi.getBareOutputs(
        {
          appliance: applianceId,
          hasInput: true,
          adminStatus: OutputAdminStatus.on,
        },
        limit,
      )

      const outputs = outputsRes.items

      let hasMoreOutputs = outputsRes.items.length < outputsRes.total

      // Get outputs connected to any input on the appliance. Limited
      const inputIds = inputs.map(input => input.id)
      let outputsOnInput: Output[] = []
      if (inputIds.length > 0 && outputs.length < limit) {
        // Only fetch as many outputs necessary to reach the max outputs limit to show in confirm dialog
        const limitLeft = limit - outputs.length
        const inputIds = inputs.map(input => input.id)
        const outputsOnInputRes = await Api.outputApi.getBareOutputs(
          {
            belongingToInputIds: inputIds,
            adminStatus: OutputAdminStatus.on,
          },
          limitLeft,
        )

        outputsOnInput = outputsOnInputRes.items

        hasMoreOutputs = hasMoreOutputs || outputsOnInputRes.items.length < outputsOnInputRes.total
      }

      const affectedOutputs: AffectedOutput[] = outputs.concat(outputsOnInput).map(x => {
        return { id: x.id, name: x.name }
      })
      const affected: Affected = {
        outputs: affectedOutputs,
        hasMoreOutputs: hasMoreOutputs,
      }

      return affected
    },
    [applianceId],
  )

  const onSubmit = async (
    appliance: Appliance,
    transformedValues: Pick<UpdateAppliancePayload, 'region' | 'secondaryRegion' | 'acceptDisruption'> & {
      ports: Array<ISharedPort>
    },
  ) => {
    const values = {
      ...transformedValues,
      ports: transformedValues.ports.map(port => ({ ...port, networks: port._networks?.map(n => n.id) })),
    }

    const region = values.region
    if (region === undefined) {
      // Region is required
      return
    }

    const hasRegionChanged = initialValues!.region?.id !== region.id

    const initSecondaryRegion = initialValues!.secondaryRegion
    const secondareRegionWasInitiallySet = initSecondaryRegion !== undefined && initSecondaryRegion !== null
    const hasSecondaryRegionChanged =
      secondareRegionWasInitiallySet && initSecondaryRegion.id !== values.secondaryRegion?.id

    // If region has changed. Fetch affected outputs and show confirmation dialog
    if (hasRegionChanged) {
      // Limit for number of outputs to fetch (and show in confirm dialog)
      const limit = 10

      let affected: Affected | undefined = undefined
      try {
        setIsFetchingOutputsAffectedByRegionChange(true)
        affected = await fetchOutputsAffectedByRegionChange(limit)
      } catch (err) {
        enqueueErrorSnackbar({ error: err, operation: 'Failed to fetch inputs and outputs affected by region change' })
        return
      } finally {
        setIsFetchingOutputsAffectedByRegionChange(false)
      }

      // Show confirmation dialog if any output is affected
      if (affected.outputs.length > 0) {
        const result = await showConfirmationAsync(
          <ChangeRegionDialog applianceName={appliance.name} newRegionName={region.name} affected={affected} />,
          { ok: { text: 'Accept' } },
        )
        if (result !== ConfirmationDialogResult.OK) {
          // Cancel submit
          return
        }
      }

      // Send all affected outputs in the update request to make sure conditions haven't changed
      // For example if another user creates another output on this appliance while the confirmation dialog is shown
      const acceptDisruptionsOutputIds = affected.outputs.map(x => x.id)
      values.acceptDisruption = {
        outputs: acceptDisruptionsOutputIds,
      }
    } else if (hasSecondaryRegionChanged) {
      // If secondary region has changed but not primary warn about reduced redundancy
      const secondaryRegionName = values.secondaryRegion?.name ?? null

      // Different dialogs depending on change or remove
      if (secondaryRegionName !== null) {
        const result = await showConfirmationAsync(
          <ChangeSecondaryRegionDialog applianceName={appliance.name} newSecondaryRegionName={secondaryRegionName} />,
          { ok: { text: 'Change secondary region' } },
        )
        if (result !== ConfirmationDialogResult.OK) {
          // Cancel submit
          return
        }
      } else {
        const result = await showConfirmationAsync(<RemoveSecondaryRegionDialog applianceName={appliance.name} />, {
          ok: { text: 'Remove secondary region' },
        })
        if (result !== ConfirmationDialogResult.OK) {
          // Cancel submit
          return
        }
      }
    }

    // If owner of a port has changed. Show confirmation dialog
    const hasAnyPortOwnerChanged = values.ports.some(port => port.owner !== port._owner.id)
    if (hasAnyPortOwnerChanged) {
      const result = await showConfirmationAsync(
        'You are changing owner of an interface. All inputs and outputs on this interface will be cleared. Are you sure you want to proceed?',
      )
      if (result !== ConfirmationDialogResult.OK) {
        // Cancel submit
        return
      }
    }

    // Send request to update appliance. Redirect to appliance list
    try {
      await dispatch(updateAppliance({ appliance, values: values, redirect: true }))
    } catch (err) {
      enqueueErrorSnackbar({ error: err, operation: 'Failed to update appliance' })
    }
  }

  const onDelete = (appliance: Appliance) => {
    showConfirmation(
      () => performDelete(appliance),
      <RemoveDialog appliance={appliance} hideConfirmDialog={hideConfirmation} />,
      {
        ok: { text: 'Delete', variant: 'outlined' },
        cancel: { variant: 'contained' },
      },
    )
  }

  const performRestartAppliance = (appliance: Appliance) => {
    showConfirmation(
      () => void dispatch(restartAppliance({ id: appliance.id, showSuccessSnackbar: true })),
      'Restarting will affect all video streams on the appliance. After restart the appliance will run the latest software version. Do you want to continue?',
    )
  }

  const performRecreateTunnels = (appliance: Appliance) => {
    showConfirmation(
      () => void dispatch(recreateTunnels({ id: appliance.id })),
      'Recreating tunnels will affect all video streams on the appliance that involve RIST tunnels. Do you want to continue?',
    )
  }

  const performDelete = (appliance: Appliance) => {
    dispatch(removeAppliance(appliance.id))
    history.push(routes.appliances())
  }

  if (appliance && user.group && (appliance.owner as Group).id !== user.group && user.role !== Role.super) {
    return <Redirect to={routes.appliances()} />
  }

  const isCoreNode = appliance?.type === ApplianceType.core
  const isThumbNode = appliance?.type === ApplianceType.thumb
  const isApplianceOwner = user.group === getApplianceOwnerId(appliance)
  let showDeleteButton = false
  if (user.role === Role.super) {
    showDeleteButton = true
  } else if (!isCoreNode && !isThumbNode && user.role === Role.admin && isApplianceOwner) {
    showDeleteButton = true
  }

  const ports: ISharedPort[] = (appliance?._physicalPorts || []).map(
    ({ id: port, name, _owner, owner, addresses, networks }) => ({
      port,
      name,
      owner,
      networks: networks?.map(network => network.id),
      _networks: networks,
      _address: addresses[0]?.address,
      _publicAddress: addresses[0]?.publicAddress,
      _interRegionPublicAddress: addresses[0]?.interRegionPublicAddress,
      _owner,
      _alarmsThatDisablePort: alarmsThatDisablePort(appliance, port),
    }),
  )

  const restartAction = (appliance: Appliance) => ({
    'Restart appliance': {
      onClick: () => performRestartAppliance(appliance),
      savingState: !!restarting,
    },
  })

  const recreateTunnelsAction = (appliance: Appliance) => {
    const recreate = {
      'Recreate tunnels': {
        onClick: () => performRecreateTunnels(appliance),
        savingState: !!recreatingTunnels,
      },
    } as const
    if (devMode) {
      return recreate
    } else {
      return {}
    }
  }

  const initialValues: undefined | (UpdateAppliancePayload & { id: string }) = appliance
    ? {
        id: appliance.id,
        geoLocation: appliance.geoLocation || null,
        logLevel: appliance.logLevel,
        ristserverLogLevel: appliance.ristserverLogLevel,
        ports: ports,
        region: appliance.region || null,
        secondaryRegion: appliance.secondaryRegion || null,
        settings: {
          ...appliance.settings,
          // EDGE-3200: Ensure 'formik.values.useDynamicTunnelClientSourceAddress' has a default value of type boolean (else Formik will populate it as a string array when toggling the checkbox).
          useDynamicTunnelClientSourceAddress: appliance.settings.useDynamicTunnelClientSourceAddress ?? false,
        },
      }
    : undefined

  const savingState = saving === true || isFetchingOutputsAffectedByRegionChange

  return (
    <Wrapper name="Appliances" entityName={appliance?.name}>
      <Grid container spacing={0}>
        <Pendable pending={loading}>
          {initialValues && appliance && (
            <Formik
              onSubmit={async values => {
                const transformed = formTransform(values, {
                  secondaryRegion: {
                    _transform: (v: string) => {
                      if (v == '' || v == null) {
                        return null
                      }
                      return v
                    },
                  },
                })
                if (values.geoLocation === null) {
                  transformed['geoLocation'] = null
                }

                await onSubmit(appliance, transformed)
              }}
              initialValues={initialValues}
              validate={values => {
                const errors: FormikErrors<UpdateAppliancePayload> = {}

                if (values.secondaryRegion?.id === values.region?.id) {
                  errors.secondaryRegion = "Can't be same as Region"
                }

                return errors
              }}
            >
              {formik => (
                <Grid container>
                  <Grid item xs={12}>
                    <SafeRouting enabled={formik.dirty && !formik.isSubmitting} />
                    <Form translate="no" id="appliance-form" noValidate>
                      <Meta appliance={appliance} />
                      <Settings
                        formik={formik}
                        secondaryRegion={appliance.secondaryRegion}
                        region={appliance.region}
                        geoLocation={appliance.geoLocation}
                        type={appliance.type}
                        settings={formik.values.settings as ApplianceSettings}
                      />
                      <Interfaces formik={formik} ports={ports} appliance={appliance} />
                      {appliance.type !== ApplianceType.thumb && <InputsOutputs appliance={appliance} />}
                      <ButtonsPane
                        main={{
                          Cancel: {
                            onClick: () => {
                              history.push(routes.appliances())
                            },
                          },
                          Save: { primary: true, savingState: savingState, type: 'submit' },
                        }}
                        secondary={
                          showDeleteButton
                            ? {
                                ...restartAction(appliance),
                                'Delete appliance': { onClick: () => onDelete(appliance) },
                                ...recreateTunnelsAction(appliance),
                              }
                            : restartAction(appliance)
                        }
                      />
                    </Form>
                  </Grid>
                </Grid>
              )}
            </Formik>
          )}
          {!appliance && error && !loading && (
            <ApplicationError error={error} onOKButtonClicked={() => history.goBack()} />
          )}
        </Pendable>
      </Grid>
    </Wrapper>
  )
}
