import React, { useEffect, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import { styled, Theme } from '@mui/material/styles'

import nimbraEdgeLogo from '../../img/netinsight-logo-color-white@3x.png'
import faviconLogo from '../../img/favicon.png'
import { ImageName } from 'common/api/v1/types'
import { Api } from '../../store'
import { GlobalState } from '../../store'

const styles = {
  topLeftContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '50px',
    height: '30px',
    position: 'relative',
    overflow: 'hidden',
  },
  faviconContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '32px',
    height: '32px',
    position: 'relative',
    overflow: 'hidden',
  },
  topLeftDefault: {
    fontFamily: 'Roboto slab',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '90%',
  },
  loginContainer: {
    display: 'block',
    position: 'relative',
    width: '396px',
    margin: (theme: Theme) => theme.spacing(2, 0),
  },
  draftImg: {
    background: (theme: Theme) => theme.palette.background.default,
  },
}

const LoginImg = styled('img')({
  height: '44px',
  margin: '0 auto',
  display: 'block',
})

const api = Api
/**
 * hook to fetch the logo
 * @param name - logo type
 */
export const useFetchedImage = (name: ImageName) => {
  const [source, setSource] = useState<string | null>('')
  const [type, setType] = useState<string>('')
  useEffect(() => {
    api.imageApi
      .getImageDetails(name)
      .then(data => {
        setSource(`/api/media/${data.id}`)
        setType(data.type)
      })
      .catch(err => {
        if (err?.errorInfo?.errorCode == 404) setSource(null)
      })
  }, [])

  return { source, type }
}

interface LogoProps {
  image?: string
  isDraft?: boolean
}

/**
 * Component to show fetched/default left top corner logo
 * @param image - base64 image
 * @param isDraft - whether it is editing component, otherwise it just shows the logo
 */
export const TopLeftLogo: React.FunctionComponent<LogoProps> = ({ image, isDraft }) => {
  const { source } = useFetchedImage(ImageName.serviceProvider)
  const { serviceProvider: deleted } = useSelector(
    ({ settingsReducer }: GlobalState) => settingsReducer.toDelete,
    shallowEqual,
  )

  if (source === '') return null // fetching not complete yet

  const showDefault = (isDraft && deleted) || (source === null && !image)
  const showSource = (!!source || !!image) && (!deleted || !isDraft)
  return (
    <Box
      sx={isDraft ? { ...styles.topLeftContainer, ...styles.draftImg } : styles.topLeftContainer}
      data-test-id="corner-logo"
    >
      <img
        height="100%"
        src={image || source || undefined}
        alt="Nimbra Edge"
        style={{ display: showSource ? 'block' : 'none' }}
      />
      <Box sx={styles.topLeftDefault} style={{ display: showDefault ? 'block' : 'none' }}>
        Nimbra Edge
      </Box>
    </Box>
  )
}
/**
 * Component to show fetched/default logo for the login page
 * @param image - base64 image
 * @param isDraft - whether it is editing component, otherwise it just shows the logo
 */
export const LoginLogo: React.FunctionComponent<LogoProps> = ({ image, isDraft }) => {
  const { source } = useFetchedImage(ImageName.product)
  const { product: deleted } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer.toDelete, shallowEqual)

  if (source === '') return null // fetching not complete yet

  const showDefault = (isDraft && deleted) || (source === null && !image)
  const showSource = (!!source || !!image) && (!deleted || !isDraft)
  return (
    <Box sx={isDraft ? { ...styles.loginContainer, ...styles.draftImg } : styles.loginContainer}>
      <LoginImg
        src={image || source || undefined}
        alt="Nimbra Edge"
        style={{ display: showSource ? 'block' : 'none' }}
        data-test-id="service-provider-logo"
      />
      <LoginImg
        src={nimbraEdgeLogo}
        alt="Edge Network Manager logo"
        style={{ display: showDefault ? 'block' : 'none' }}
        data-test-id="default-logo"
      />
    </Box>
  )
}

export const FaviconLogo: React.FunctionComponent<LogoProps> = ({ image, isDraft }) => {
  const { source } = useFetchedImage(ImageName.favicon)
  const { favicon: deleted } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer.toDelete, shallowEqual)

  if (source === '') return null // fetching not complete yet

  // show original if no image provided by user, no image uploaded before, or user clicked restore default
  const providedSource = source || image
  const restoreDefault = deleted && isDraft
  const showDefault = restoreDefault || !providedSource

  return (
    <Box sx={isDraft ? { ...styles.faviconContainer, ...styles.draftImg } : styles.faviconContainer}>
      <img
        height="100%"
        src={showDefault ? faviconLogo : providedSource}
        alt={source ? 'Nimbra Edge Favicon' : 'Edge Network Manager favicon'}
        data-test-id="favicon-logo"
      />
    </Box>
  )
}
