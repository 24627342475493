import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import { HighlightOff } from '@mui/icons-material'
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { FilterProps } from './utils'
import { usePageParams } from '../../../utils'

/**
 * Date filter, setting both date and time
 * @param paramName - parameter name
 * @param label - input label to show
 */
export const DateTimePicker = ({
  paramName,
  label,
  minDate = new Date('2020-01-01'),
  maxDate = new Date(),
}: FilterProps) => {
  const [params, setPageParams] = usePageParams()
  const value = params[paramName] ?? new Date().toISOString()

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} key={`filter-${paramName}`}>
      <MobileDateTimePicker<Date>
        label={label}
        inputFormat="yyyy-MM-dd hh:mm a"
        value={new Date(value)}
        onChange={value => value && setPageParams({ [paramName]: value.toISOString(), pageNumber: '0' })}
        minDate={minDate}
        maxDate={maxDate}
        disableFuture
        renderInput={({ onClick, ...params }) => (
          <TextField
            {...params}
            InputProps={{
              endAdornment: (
                <IconButton aria-label="clear" onClick={() => setPageParams({ [paramName]: undefined })} edge="end">
                  <HighlightOff />
                </IconButton>
              ),
            }}
          />
        )}
      />
    </LocalizationProvider>
  )
}
