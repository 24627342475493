import { useCallback, useEffect } from 'react'
import { FormikProps } from 'formik'
import Button from '@mui/material/Button'

import { AudioCodec, AudioStream, AudioSystemStandard, sdiAudioPairs, SupportedAudioCodec } from 'common/api/v1/types'
import { GridItem, Paper, Select } from '../../common/Form'
import { RichOption } from '../../common/Form/Select'

interface AudioStreamFormProps {
  audioStream: AudioStream
  namePrefix: string
  supportedCodecs: SupportedAudioCodec[]
  onRemove: () => void
  setFieldValue: FormikProps<any>['setFieldValue']
}
const AudioStreamForm = ({
  audioStream,
  namePrefix,
  supportedCodecs,
  onRemove,
  setFieldValue,
}: AudioStreamFormProps) => {
  const codecKey = `${namePrefix}.codec`
  const selectedCodec = audioStream.codec
  const codecFeatures = supportedCodecs.find(({ name }: SupportedAudioCodec) => name === selectedCodec)
  const codecOptions: RichOption[] = supportedCodecs.map(c => ({ name: c.name, value: c.name }))
  useEffect(() => ensureSelectedValueIsAvailable(codecKey, selectedCodec, codecOptions), [selectedCodec, codecOptions])

  const bitrateKey = `${namePrefix}.bitrate`
  const selectedBitrate = audioStream.bitrate
  const bitrateOptions: RichOption[] = (codecFeatures?.bitratesKbps ?? []).map(b => ({ name: b.toString(), value: b }))
  useEffect(() => ensureSelectedValueIsAvailable(bitrateKey, selectedBitrate, bitrateOptions), [
    selectedBitrate,
    bitrateOptions,
  ])

  const bitDepthKey = `${namePrefix}.bitDepth`
  const selectedBitDepth = audioStream.bitDepth
  const bitDepthOptions: RichOption[] = (codecFeatures?.bitDepth ?? []).map(b => ({ name: b.toString(), value: b }))
  useEffect(() => ensureSelectedValueIsAvailable(bitDepthKey, selectedBitDepth, bitDepthOptions), [
    selectedBitDepth,
    bitDepthOptions,
  ])

  const ensureSelectedValueIsAvailable = useCallback(
    (formikFieldKey: string, currentValue: RichOption['value'], availableValues: RichOption[]) => {
      const isCurrentlySelectedOptionAvailable = availableValues
        .filter(o => !o.disabled)
        .map(o => o.value)
        .includes(currentValue)
      const firstAvailableValue = availableValues[0]?.value
      if (!isCurrentlySelectedOptionAvailable && firstAvailableValue !== undefined) {
        setFieldValue(formikFieldKey, firstAvailableValue, false)
      }
    },
    [setFieldValue],
  )

  return (
    <Paper>
      {codecOptions.length > 0 && (
        <Select
          label="Audio Codec"
          name={codecKey}
          required
          options={codecOptions}
          disabled={!!selectedCodec && codecOptions.length === 1}
        />
      )}

      <Select label="Audio Pair" name={`${namePrefix}.pair`} required options={sdiAudioPairs} />

      {bitrateOptions.length > 0 && (
        <Select
          label="Audio Bitrate (Kbps)"
          name={bitrateKey}
          required
          options={bitrateOptions}
          disabled={!!selectedBitrate && bitrateOptions.length === 1}
        />
      )}

      {bitDepthOptions.length > 0 && (
        <Select
          label="Audio Bit depth"
          name={bitDepthKey}
          required
          options={bitDepthOptions}
          disabled={!!selectedBitDepth && bitDepthOptions.length === 1}
        />
      )}

      {selectedCodec === AudioCodec.ac3pt && (
        <Select
          label="System standard"
          name={`${namePrefix}.systemStandard`}
          required
          options={Object.values(AudioSystemStandard)}
        />
      )}
      <GridItem newLine>
        <Button variant="outlined" color="primary" onClick={onRemove}>
          Remove Audio Stream
        </Button>
      </GridItem>
    </Paper>
  )
}

export default AudioStreamForm
