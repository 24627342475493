import React from 'react'
import Box from '@mui/material/Box'
import MuiLink from '@mui/material/Link'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import { OpenInNew, ErrorOutline } from '@mui/icons-material'
import { Theme } from '@mui/material/styles'
import { format } from 'date-fns'

import { Appliance, ApplianceType, Role, User } from 'common/api/v1/types'
import { DATE_FORMAT_LONG, getProductName } from 'common/api/v1/helpers'
import { isEditableGroup, pluralize, runningDifferentSoftwareVersion, useUser } from '../../../../utils'
import routes, { api } from '../../../../utils/routes'
import { GridItem, Paper } from '../../../common/Form'
import DataSet from '../../../common/DataSet'
import { isVa, isVaApplianceType } from '../../utils'
import { ExternalLink, Link } from '../../../common/Link'
import { ApplianceHealthIndicator } from '../../../common/Indicator'
import { defaultRegionId } from 'common/constants'
import { shallowEqual, useSelector } from 'react-redux'
import { GlobalState } from '../../../../store'

const styles = {
  link: {
    display: 'flex',
    transition: (theme: Theme) => theme.transitions.create('color'),
    paddingTop: (theme: Theme) => theme.spacing(1),
  },
  text: {
    marginRight: (theme: Theme) => theme.spacing(1),
  },
  warningIcon: {
    verticalAlign: 'top',
    marginRight: '4px',
  },
  warningText: {
    marginLeft: (theme: Theme) => theme.spacing(1),
    color: (theme: Theme) => theme.palette.warning.light,
  },
  versionChipsContainer: {
    '& div': {
      marginRight: (theme: Theme) => theme.spacing(1),
    },
  },
}

export interface MetaProps {
  appliance: Appliance
}

const ApplianceVersionInfo = (applianceType: ApplianceType, imageVersion?: string, softwareVersion?: string) => {
  const { buildInfo } = useSelector(({ buildInfoReducer }: GlobalState) => buildInfoReducer, shallowEqual)

  return (
    <>
      <Box sx={styles.versionChipsContainer}>
        {!isVaApplianceType(applianceType) && <Chip size="small" label={`Image: ${imageVersion || 'N/A'}`} />}
        <Chip size="small" label={`Software: ${softwareVersion || 'N/A'}`} />
      </Box>
      {runningDifferentSoftwareVersion(softwareVersion, buildInfo) && (
        <Box sx={styles.warningText}>
          <ErrorOutline sx={styles.warningIcon} />
          Software version is outdated, restart appliance to update
        </Box>
      )}
    </>
  )
}

const ApplianceOwnerLink = (appliance: Appliance, user: User) =>
  typeof appliance.owner === 'string' ? (
    ''
  ) : (
    <Link
      to={routes.groupsUpdate({ id: appliance.owner.id })}
      available={isEditableGroup(appliance.owner.id, user)}
      underline="hover"
    >
      {appliance.owner.name}
    </Link>
  )

const Meta: React.FunctionComponent<MetaProps> = ({ appliance }) => {
  const user = useUser()
  const controlImageVersion = appliance.version.controlImageVersion
  const controlSoftwareVersion = appliance.version.controlSoftwareVersion
  const dataImageVersion = appliance.version.dataImageVersion
  const dataSoftwareVersion = appliance.version.dataSoftwareVersion
  const linkIsShown = isVa(appliance)

  const metrics =
    user.role == Role.super &&
    appliance.region.id == defaultRegionId &&
    [ApplianceType.core, ApplianceType.thumb].includes(appliance.type)
      ? {
          Metrics: (
            <ExternalLink
              underline="always"
              href={`/grafana/d/000000127/system-resource-usage?orgId=1&refresh=1m&from=now-15m&to=now&var-datasource=InfluxDB_v2&var-inter=10s&var-server=${appliance.hostname}&var-mountpoint=All&var-cpu=All&var-disk=All&var-netif=All`}
            >
              Resource Usage
            </ExternalLink>
          ),
        }
      : {}
  const meta = {
    'Node name': appliance.name,
    Hostname: appliance.hostname,
    Contact: appliance.contact,
    'Product name': getProductName(appliance.type),
    'Node serial number': appliance.serial,
    ...(isVa(appliance) ? { 'Running VA software': appliance.version.vaVersion } : {}),
    'Running control software': ApplianceVersionInfo(appliance.type, controlImageVersion, controlSoftwareVersion),
    'Running data software': ApplianceVersionInfo(appliance.type, dataImageVersion, dataSoftwareVersion),
    Status: <ApplianceHealthIndicator applianceId={appliance.id} inline={true} />,
    'Running since': (appliance.lastRegisteredAt && format(appliance.lastRegisteredAt, DATE_FORMAT_LONG)) || 'N/A',
    ...metrics,
    Alarms: appliance.alarms.length ? (
      <Link to={routes.alarms({ applianceId: appliance.id, applianceName: appliance.name })} underline="hover">
        {pluralize(appliance.alarms.length, 'alarm')}
      </Link>
    ) : (
      'No alarms present'
    ),
    Owner: ApplianceOwnerLink(appliance, user),
  }

  return (
    <Paper
      title="Meta data"
      collapsible
      actionsPane={[
        ...(user.role === Role.super
          ? [
              <Typography key="config-button" variant="body1" sx={styles.link}>
                <Link underline="always" to={routes.appliancesConfig({ id: appliance.id })}>
                  Show configuration
                </Link>
              </Typography>,
            ]
          : []),
        ...(linkIsShown
          ? [
              <MuiLink
                href={api.appliance({ id: appliance.id })}
                key="appliance-link"
                sx={styles.link}
                underline="always"
                target="_blank"
              >
                <Typography variant="body1" component="span" sx={styles.text}>
                  Manage appliance
                </Typography>
                <OpenInNew />
              </MuiLink>,
            ]
          : []),
      ]}
    >
      <GridItem lg={12} xl={12}>
        <DataSet values={meta} />
      </GridItem>
      {linkIsShown && <Divider style={{ width: '100%' }} />}
    </Paper>
  )
}

export default Meta
