import { useEffect } from 'react'
import { FormikProps } from 'formik'

import { Address, ApplianceType, Input, InputAdminStatus } from 'common/api/v1/types'

import { Select, TextInput } from '../../../../common/Form'
import { createDefaultFiledValues, makeAddressOptions } from '../../../../../utils'
import { get } from 'lodash'
import { isVaApplianceType } from '../../../../appliances/utils'
import { OccupiedPort } from 'common/ports'
import { CIDRToolTip } from '../../../../../texts'

export enum RtmpFields {
  address = 'address',
  port = 'port',
  whitelistCidrBlock = 'whitelistCidrBlock',
}
export const rtmpDefaults = createDefaultFiledValues(Object.keys(RtmpFields), [])

interface RtmpFormProps {
  form: FormikProps<Input>
  addresses: Array<Address>
  namePrefix: string
  occupiedPorts: OccupiedPort[]
  adminStatus: InputAdminStatus
  applianceType: ApplianceType
  allocatedPort?: { addresses: Address[]; portNumber: number }
}

const RtmpForm = ({
  form,
  addresses,
  namePrefix,
  occupiedPorts,
  adminStatus,
  applianceType,
  allocatedPort,
}: RtmpFormProps) => {
  const addressSelector = `${namePrefix}.${RtmpFields.address}`
  const disableLocalPortFields = !!allocatedPort

  useEffect(() => {
    if (allocatedPort) {
      if (get(form.values, `${namePrefix}.${RtmpFields.port}`) != allocatedPort.portNumber) {
        form.setFieldValue(`${namePrefix}.${RtmpFields.port}`, allocatedPort.portNumber, false)
      }
      const allocatedAddress = allocatedPort.addresses[0].address
      if (get(form.values, addressSelector) != allocatedAddress) {
        form.setFieldValue(addressSelector, allocatedAddress, false)
      }
    }
  }, [allocatedPort, form])
  return (
    <>
      <Select
        name={addressSelector}
        label="Address"
        required
        disabled={disableLocalPortFields}
        options={makeAddressOptions(get(form.values, addressSelector), addresses)}
        newLine
        validators={{
          addressIn: { addresses },
        }}
      />
      <TextInput
        name={`${namePrefix}.${RtmpFields.port}`}
        label="Port"
        required
        disabled={disableLocalPortFields}
        type="number"
        noNegative
        validators={{
          port: { disallowInternal: true },
          isPortAvailable: {
            occupiedPorts,
            isMulticast: false,
            isPortDisabled: adminStatus === InputAdminStatus.off,
          },
        }}
      />
      {!isVaApplianceType(applianceType) && (
        <TextInput
          name={`${namePrefix}.${RtmpFields.whitelistCidrBlock}`}
          label="Whitelist CIDR block"
          tooltip={CIDRToolTip(applianceType === ApplianceType.core, 'Input')}
          required={applianceType === ApplianceType.core}
          validators={{
            ipv4CidrBlock: {},
          }}
        />
      )}
    </>
  )
}

export default RtmpForm
