import { useEffect, useState } from 'react'
import { FormikProps } from 'formik'
import { get } from 'lodash'
import MuiCheckbox from '@mui/material/Checkbox'

import { Address, ApplianceType, Input, InputAdminStatus } from 'common/api/v1/types'
import { Checkbox, Select, TextInput } from '../../../../common/Form'
import { createDefaultFiledValues, makeAddressOptions } from '../../../../../utils'
import { isVaApplianceType } from '../../../../appliances/utils'
import { OccupiedPort } from 'common/ports'
import { CIDRToolTip } from '../../../../../texts'

export enum UdpFields {
  address = 'address',
  port = 'port',
  multicastAddress = 'multicastAddress',
  multicastSource = 'multicastSource',
  whitelistCidrBlock = 'whitelistCidrBlock',
}
export const udpDefaults = createDefaultFiledValues(Object.keys(UdpFields), [])

interface UdpFormProps {
  form: FormikProps<Input>
  addresses: Array<Address>
  namePrefix: string
  applianceType: ApplianceType
  occupiedPorts: OccupiedPort[]
  adminStatus: InputAdminStatus
  allocatedPort?: { addresses: Address[]; portNumber: number }
}

const UdpForm = ({
  form: { setFieldValue, values },
  addresses,
  namePrefix,
  applianceType,
  occupiedPorts,
  adminStatus,
  allocatedPort,
}: UdpFormProps) => {
  const getMulticast = () => ({
    address: get(values, `${[namePrefix]}.multicastAddress`),
    source: get(values, `${[namePrefix]}.multicastSource`),
  })
  const { address, source } = getMulticast()
  const [isMulticast, setIsMulticast] = useState(!!(address || source))
  const [multicast, setMulticast] = useState(getMulticast())

  const addressSelector = `${namePrefix}.${UdpFields.address}`
  const selectedSourceAddress = get(values, addressSelector)
  const portSelector = `${namePrefix}.${UdpFields.port}`
  const selectedPortNumber = get(values, portSelector)

  const allocatedAddress = allocatedPort?.addresses[0].address
  const allocatedPortNumber = allocatedPort?.portNumber
  useEffect(() => {
    if (allocatedAddress && selectedSourceAddress !== allocatedAddress) {
      setFieldValue(addressSelector, allocatedAddress, false)
    }
    if (allocatedPortNumber !== undefined && selectedPortNumber !== allocatedPortNumber) {
      setFieldValue(portSelector, allocatedPortNumber, false)
    }
  }, [
    allocatedAddress,
    selectedSourceAddress,
    addressSelector,
    allocatedPortNumber,
    selectedPortNumber,
    portSelector,
    setFieldValue,
  ])

  useEffect(() => {
    if (!isMulticast) {
      setMulticast(getMulticast())
      setFieldValue(`${namePrefix}.${UdpFields.multicastAddress}`, '', false)
      setFieldValue(`${namePrefix}.${UdpFields.multicastSource}`, '', false)
    } else {
      setFieldValue(`${namePrefix}.${UdpFields.multicastAddress}`, multicast.address, false)
      setFieldValue(`${namePrefix}.${UdpFields.multicastSource}`, multicast.source, false)
    }
  }, [isMulticast, setFieldValue, namePrefix])
  return (
    <>
      <Select
        name={addressSelector}
        label="Address"
        required
        disabled={!!allocatedPort}
        options={makeAddressOptions(selectedSourceAddress, addresses)}
        newLine
        validators={{
          addressIn: { addresses },
        }}
      />
      <TextInput
        name={portSelector}
        label="Port"
        required
        disabled={!!allocatedPort}
        type="number"
        noNegative
        validators={{
          port: { disallowInternal: true },
          isPortAvailable: {
            occupiedPorts,
            isMulticast: !!address,
            isPortDisabled: adminStatus === InputAdminStatus.off,
          },
        }}
      />
      <Checkbox
        control={<MuiCheckbox checked={isMulticast} onChange={e => setIsMulticast(e.target.checked)} id="multicast" />}
        label="Is this port multicast?"
        newLine
      />
      {isMulticast && (
        <>
          <TextInput
            name={`${namePrefix}.${UdpFields.multicastAddress}`}
            label="Multicast address"
            validators={{
              multicastAddress: {},
            }}
            newLine
          />

          <TextInput
            name={`${namePrefix}.${UdpFields.multicastSource}`}
            validators={{
              ip: {},
            }}
            label="Source specific multicast address"
          />
        </>
      )}{' '}
      {!isVaApplianceType(applianceType) && (
        <TextInput
          name={`${namePrefix}.${UdpFields.whitelistCidrBlock}`}
          label="Whitelist CIDR block"
          tooltip={CIDRToolTip(applianceType === ApplianceType.core, 'Input')}
          required={applianceType === ApplianceType.core}
          validators={{
            ipv4CidrBlock: {},
          }}
        />
      )}
    </>
  )
}

export default UdpForm
