import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

import { GraphNodeType, Output, Pid } from 'common/api/v1/types'
import DataSet from '../../common/DataSet'
import { pluralize } from '../../../utils'
import { formatBitrate } from 'common/api/v1/helpers'
import { Service as TsService } from 'common/tr101Types'
import { InfoSection } from './InfoSection'
import { shallowEqual, useSelector } from 'react-redux'
import { GlobalState } from '../../../store'
import { ServiceOverviewState } from '../../../redux/reducers/serviceOverviewReducers'
import { isApplianceStandby } from './utils'

export const integer = (val?: number | null) => (val == null ? null : String(Math.round(val)))
export const packets = (value?: number | null) =>
  value === undefined || value === null ? '-' : pluralize(value, 'packet')

const VideoStreams = ({ videoStreams }: { videoStreams: Pid[] }) => {
  const tsInfos = videoStreams
  if (tsInfos.length === 0) return null
  return (
    <>
      <Typography variant="h3">Video streams</Typography>
      {videoStreams.map((pid, i) => {
        return (
          <div key={`${pid.id}-video`}>
            <DataSet
              values={{
                Pid: pid?.id,
                Type: pid?.description,
                'Min bitrate': formatBitrate(pid?.bitrate?.minimum),
                'Max bitrate': formatBitrate(pid?.bitrate?.maximum),
                'Avg bitrate': formatBitrate(pid?.bitrate?.average),
                Width: pid?.streamInfo?.video?.videoSize?.horizontal,
                Height: pid?.streamInfo?.video?.videoSize?.vertical,
                ['Frame rate']: pid?.streamInfo?.video?.frameRate?.toFixed(2),
                Interlaced: pid?.streamInfo?.video?.interlaced,
              }}
            />
            {i != videoStreams.length - 1 && <Divider />}
          </div>
        )
      })}
    </>
  )
}

const AudioStreams = ({ audioStreams }: { audioStreams: Pid[] }) => {
  const tsInfos = audioStreams
  if (tsInfos.length === 0) return null
  return (
    <>
      <Typography variant="h3">Audio streams</Typography>
      {tsInfos.map((pid, i) => {
        return (
          <div key={`${pid.id}-audio`}>
            <DataSet
              values={{
                Pid: pid?.id,
                Type: pid?.description,
                'Min bitrate': formatBitrate(pid?.bitrate?.minimum),
                'Max bitrate': formatBitrate(pid?.bitrate?.maximum),
                'Avg bitrate': formatBitrate(pid?.bitrate?.average),
                'Sample rate': pid?.streamInfo?.audio?.sampleRate,
              }}
            />
            {i != tsInfos.length - 1 && <Divider />}
          </div>
        )
      })}
    </>
  )
}

const Services = () => {
  const { input, selected } = useSelector(
    ({ serviceOverviewReducer }: GlobalState) => serviceOverviewReducer,
    shallowEqual,
  ) as ServiceOverviewState

  if (!input || !selected) {
    return null
  }

  const applianceId = selected.id

  const tsInfo = (input.tsInfo || []).find(tsInfo => tsInfo.applianceId === applianceId)
  const services = tsInfo?.services
  if (!tsInfo || !services || services.length === 0) return null
  const pids: Pid[] | undefined = tsInfo.pids?.filter(p => typeof p !== 'undefined').map(p => p as Pid)

  return (
    <>
      <Typography variant="h3">Programs</Typography>
      {services.map((service, i) => {
        const pidsInService = pidsForService(pids, service)
        const videoStreams = pidsInService.filter(isVideoPid)
        const audioStreams = pidsInService.filter(isAudioPid)
        return (
          <div key={`${service.id}-service`}>
            <DataSet
              values={{
                Id: service?.id,
                Name: service?.name ?? '-',
                Type: service?.type?.description ?? '-',
                'Avg bitrate': formatBitrate(service?.averageBitrate),
              }}
            />
            {videoStreams.length || audioStreams.length ? (
              <details style={{ marginBottom: '16px' }}>
                <summary>
                  <Typography component="span" style={{ fontWeight: 'bold' }}>
                    Contents
                  </Typography>
                </summary>
                <div style={{ display: 'flex', paddingTop: '16px' }}>
                  <div style={{ marginRight: '40px' }}>
                    <VideoStreams videoStreams={videoStreams} />
                  </div>
                  <div style={{ marginRight: '40px' }}>
                    <AudioStreams audioStreams={audioStreams} />
                  </div>
                </div>
              </details>
            ) : null}

            {i != services.length - 1 && <Divider />}
          </div>
        )
      })}
    </>
  )
}

function isVideoPid(pid: Pid): boolean {
  return !!pid.streamInfo?.video
}

function isAudioPid(pid: Pid): boolean {
  return !!pid.streamInfo?.audio
}

export function pidsForService(pids: Pid[] | undefined, service: TsService) {
  if (!service.pids || !pids) {
    return []
  }
  return pids.filter(p => service.pids?.includes(p.id!))
}

export const TsInfo = () => {
  const { input, selected } = useSelector(
    ({ serviceOverviewReducer }: GlobalState) => serviceOverviewReducer,
    shallowEqual,
  ) as ServiceOverviewState
  const outputs = useSelector(({ outputsReducer }: GlobalState) => outputsReducer.outputs, shallowEqual)
  if (!input || !selected) {
    return null
  }
  if (!input.tr101290Enabled) {
    return <InfoSection title="TS info disabled"></InfoSection>
  }
  if (isApplianceStandby(selected.id, input, outputs)) {
    return <InfoSection title="TS info not available in standby"></InfoSection>
  }
  return (
    <InfoSection title="TS info">
      <div style={{ display: 'flex', paddingTop: '16px' }}>
        <div>
          <Services />
        </div>
      </div>
    </InfoSection>
  )
}

export function isGraphNodeApplianceType(type: GraphNodeType) {
  return [
    GraphNodeType.inputEdgeAppliance,
    GraphNodeType.inputRegionCore,
    GraphNodeType.outputRegionCore,
    GraphNodeType.inputRegionOutputEdgeAppliance,
    GraphNodeType.outputRegionOutputEdgeAppliance,
  ].includes(type)
}

export interface OutputStatistics {
  output: Output
}
