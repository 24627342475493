import {
  AllocatedPhysicalPort,
  AllocatePortRequest,
  Appliance,
  Group,
  Input,
  ListResult,
  Output,
  PhysicalPort,
  PortFilter,
  SharedPort,
} from 'common/api/v1/types'
import { getApplianceOwnerId } from '../../utils'
import {
  EnrichedApplianceWithOwner,
  EnrichedPhysicalPort,
  singleSortQueryFromPaginatedRequestParams,
  PortsRequestParams,
} from '../nm-types'
import { EdgeClient } from 'common/generated/edgeClient'

export interface IPortsApi {
  getPort(id: PhysicalPort['id']): Promise<EnrichedPhysicalPort>
  getPorts(params: PortsRequestParams): Promise<ListResult<EnrichedPhysicalPort>>
  updatePort(id: Appliance['id'], sharedPorts: Array<SharedPort>): Promise<Array<SharedPort>>
  requestRegionalPort(params: AllocatePortRequest): Promise<AllocatedPhysicalPort>
}

export class PortsApi implements IPortsApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  async updatePort(id: Appliance['id'], sharedPorts: Array<SharedPort>): Promise<Array<SharedPort>> {
    return await this.edgeClient.setAppliancePorts(id, sharedPorts)
  }

  /**
   * Returns interface with populated appliance and owner
   * @param portId
   */
  async getPort(portId: string): Promise<EnrichedPhysicalPort> {
    const port = await this.edgeClient.getPort(portId)
    const barePortAppliance = await this.edgeClient.getAppliance(port.appliance.id)

    const groupIds = [port.owner, barePortAppliance.owner].filter(id => !!id) as string[]
    const groups = (await this.edgeClient.listGroups({ filter: { ids: groupIds } })).items

    const portOwner = groups.find(group => group.id == port.owner) as Group

    const portApplianceOwner = groups.find(group => group.id == barePortAppliance.owner) as Group
    const enrichedPortAppliance: EnrichedApplianceWithOwner = {
      ...barePortAppliance,
      _owner: portApplianceOwner,
    }
    return {
      ...port,
      _owner: portOwner,
      _appliance: enrichedPortAppliance,
    }
  }

  /**
   * Returns ListResult of interfaces with appliance, owner, inputs and output using it populated
   * @param owner - to show only those belonging to this group
   * @param portType - to show only those of this PortType
   * @param applianceType - to show only those of this ApplianceType
   * @param appliance - to show only those of this appliance
   * @param searchName - term to search for
   * @param params - pagination parameters
   */
  async getPorts({
    owner,
    portType,
    applianceType,
    appliance,
    filter: searchName,
    ...params
  }: PortsRequestParams): Promise<ListResult<EnrichedPhysicalPort>> {
    const filter: PortFilter = {
      owner,
      appliance,
      applianceType,
      portType,
      searchName,
    }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    const ports = await this.edgeClient.listPorts(query)

    const { inputIds, applianceIds, groupIds, outputIds } = ports.items.reduce<{
      inputIds: Array<string>
      outputIds: Array<string>
      groupIds: Array<string>
      applianceIds: Array<string>
    }>(
      (acc, port) => {
        if (port.inputs) acc.inputIds = acc.inputIds.concat(port.inputs)
        if (port.outputs) acc.outputIds = acc.outputIds.concat(port.outputs)
        acc.groupIds.push(port.owner)
        acc.applianceIds.push(port.appliance.id)
        return acc
      },
      {
        inputIds: [],
        outputIds: [],
        groupIds: [],
        applianceIds: [],
      },
    )

    const inputs =
      inputIds.length > 0
        ? (await this.edgeClient.listInputs({ filter: { ids: Array.from(new Set(inputIds)) } })).items
        : []
    const outputs =
      outputIds.length > 0
        ? (await this.edgeClient.listOutputs({ filter: { ids: Array.from(new Set(outputIds)) } })).items
        : []
    const appliances =
      applianceIds.length > 0
        ? (await this.edgeClient.listAppliances({ filter: { ids: Array.from(new Set(applianceIds)) } })).items
        : []
    const groupsToFetch = Array.from(new Set([...groupIds, ...appliances.map(a => getApplianceOwnerId(a))])).filter(
      id => !!id,
    )
    const groups =
      groupsToFetch.length > 0 ? (await this.edgeClient.listGroups({ filter: { ids: groupsToFetch } })).items : []

    return {
      ...ports,
      items: ports.items.map(port => {
        const portAppliance = appliances.find(({ id }) => id === port.appliance.id)
        const portApplianceOwner = groups.find(g => g.id == portAppliance?.owner)
        const enrichedAppliance: EnrichedApplianceWithOwner | undefined = portAppliance &&
          portApplianceOwner && {
            ...portAppliance,
            _owner: portApplianceOwner,
          }
        const enrichedPort = {
          ...port,
          _appliance: enrichedAppliance,
          _owner: groups.find(({ id }) => id === port.owner) as Group,
          _inputs:
            port.inputs &&
            port.inputs.map(id => inputs.find(({ id: inputId }) => inputId === id) as Input).filter(Boolean),
          _outputs:
            port.outputs &&
            port.outputs.map(id => outputs.find(({ id: outputId }) => outputId === id) as Output).filter(Boolean),
        }
        return enrichedPort
      }),
    }
  }

  requestRegionalPort(params: AllocatePortRequest): Promise<AllocatedPhysicalPort> {
    return this.edgeClient.createAllocatePort(params)
  }
}
