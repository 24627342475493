import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { Form, Formik, FormikProps } from 'formik'
import Grid from '@mui/material/Grid'

import { updateService } from '../../redux/actions/serviceActions'
import { Service } from 'common/api/v1/types'
import { Api, AppDispatch } from '../../store'
import { ButtonsPane, Paper, SafeRouting, Checkbox, TextInput } from '../common/Form'
import Pendable from '../common/Pendable'
import Wrapper from '../common/Wrapper'
import { formTransform } from '../../utils'
import { Meta } from './meta'
import routes from '../../utils/routes'
import { useAsync } from 'react-async-hook'
import { FailedFetchingContent } from '../common/FailedFetchingContent'

const { serviceApi } = Api

const getInitialState = (service: Service) => ({
  ...service,
})

const ServiceForm = (formik: FormikProps<Service>, history: RouteComponentProps['history']) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <SafeRouting enabled={formik.dirty && !formik.isSubmitting} />
        <Form id="service-form" translate="no" noValidate>
          <Meta service={formik.values} />
          <Paper title="Settings">
            <Checkbox
              name="collectLogs"
              label="Collect logs"
              tooltip="Enabling log collection will ensure the logs are collected, indexed and stored up to seven days"
              tooltipStyle={{ marginTop: 14, marginLeft: 0 }}
            />
            {formik.values.allowsVolumeAdministration && (
              <TextInput
                name="desiredVolumeCapacity"
                label="Desired volume capacity"
                tooltip={
                  "Increase the volume capacity of all service instances in Kubernetes (if it's enabled by the Kubernetes provider). The volume capacity cannot be decreased."
                }
              />
            )}
            {formik.values.allowsRetentionPeriodAdministration && (
              <TextInput
                name="desiredRetentionPeriod"
                type="number"
                label="Desired retention period (days)"
                tooltip={'Set the retention period in days. 0 means no retention.'}
                validators={{
                  number: {
                    onlyInteger: true,
                    greaterThanOrEqualTo: 0,
                    message: 'Must be a positive integer',
                  },
                }}
              />
            )}
          </Paper>

          <ButtonsPane
            main={{
              Cancel: {
                onClick: () => {
                  history.push(routes.services())
                },
              },
              Save: { savingState: formik.isSubmitting, primary: true, type: 'submit' },
            }}
          />
        </Form>
      </Grid>
    </Grid>
  )
}

export const Edit = ({ history, match }: RouteComponentProps<{ id?: string }>) => {
  const dispatch = useDispatch<AppDispatch>()

  const onSubmit = async (service: Service) => {
    await updateService(service, { dispatch, serviceApi: Api.serviceApi, history })
  }

  const { result: serviceToEdit, loading, error } = useAsync(async () => serviceApi.getService(match.params.id!), [
    match.params.id,
  ])

  return (
    <Wrapper name="Services" entityName={serviceToEdit ? serviceToEdit.purpose : ' '}>
      <Grid container spacing={0}>
        {error ? (
          <FailedFetchingContent message={'Failed to fetch service'} />
        ) : (
          <Pendable pending={loading}>
            <Formik
              onSubmit={values => {
                return onSubmit(formTransform(values))
              }}
              initialValues={getInitialState(serviceToEdit!)}
            >
              {formik => ServiceForm(formik, history)}
            </Formik>
          </Pendable>
        )}
      </Grid>
    </Wrapper>
  )
}
