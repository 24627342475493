import { FC, ReactNode } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { alpha, Theme } from '@mui/material/styles'

const styles = {
  container: {
    padding: (theme: Theme) => theme.spacing(2),
  },
  coverContainer: {
    position: 'relative',
    minHeight: '78px',
    width: '100%',
  },
  cover: {
    position: 'absolute',
    inset: 0,
    background: (theme: Theme) => alpha(theme.palette.background.paper, 0.5),
    transition: 'all .2s',
  },
  progress: {
    position: 'sticky',
    top: '20px',
  },
}

export interface PendableProps {
  pending: boolean
  children: ReactNode
  cover?: boolean
  id?: string
}
/**
 * Component to display 'loading' state on component (we wrap component with it)
 * @param pending - when we should show the 'loading' state
 * @param children - component to wrap around
 * @param cover - whether we should show component covered with shadow and 'loading' spinner on it, otherwise spinner replaces component
 * @param id
 */
const Pendable: FC<PendableProps> = ({ pending, children, cover, id }) => {
  return !cover ? (
    pending ? (
      <Grid container justifyContent="center">
        <Grid item sx={styles.container} data-test-id={id ? `pendable-${id}` : undefined}>
          <CircularProgress data-test-id="progress" />
        </Grid>
      </Grid>
    ) : (
      <>{children}</>
    )
  ) : (
    <Box sx={styles.coverContainer}>
      {children}
      <Grid container justifyContent="center" sx={{ ...styles.cover, visibility: pending ? 'visible' : 'hidden' }}>
        <Grid item sx={styles.container} data-test-id={id ? `pendable-${id}` : undefined}>
          <CircularProgress sx={styles.progress} data-test-id="progress" />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Pendable
