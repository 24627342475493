import { Appliance, ApplianceType } from 'common/api/v1/types'

export const isVa = ({ type }: Appliance) => isVaApplianceType(type)
export const isVaApplianceType = (type: ApplianceType) =>
  [
    ApplianceType.nimbraVAdocker,
    ApplianceType.nimbra410,
    ApplianceType.nimbra412,
    ApplianceType.nimbra414,
    ApplianceType.nimbraVA225,
    ApplianceType.nimbraVA220,
  ].includes(type)

export const isMatroxApplianceType = (type: ApplianceType) =>
  [
    ApplianceType.matroxMonarchEdgeD4,
    ApplianceType.matroxMonarchEdgeE4_8Bit,
    ApplianceType.matroxMonarchEdgeE4_10Bit,
  ].includes(type)
