import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'

import { ListResult } from 'common/api/v1/types'
import Pendable from '../common/Pendable'
import { Pagination } from './Table'
import SearchInput from '../common/SearchInput'
import { DEFAULT_PAGINATION, useSelfStatePagination } from '../../utils'
import { PaginatedRequestParams } from '../../api/nm-types'

const styles = {
  searchClass: {
    margin: (theme: Theme) => theme.spacing(2, 0, 2),
  },
  empty: {
    margin: (theme: Theme) => theme.spacing(2, 0),
  },
}

interface PaginatedListProps<TParams extends PaginatedRequestParams, TEntity, TOthers> {
  api: (params: TParams) => Promise<ListResult<TEntity>>
  emptyMessage: string
  hideSearch?: boolean
  notFoundMessage: string
  onListChange?: (values: Partial<ListResult<TEntity> & { loading: boolean } & TParams>) => void
  otherParams?: TOthers
  searchPlaceholder?: string
  updateOn?: boolean
  List: (props: { list: Array<TEntity> }) => React.ReactNode
  hidePagination?: boolean | ((options: { total: number; items: TEntity[]; loading: boolean }) => boolean)
}

/**
 * We use it to show paginated list of entities if we don't put it in the Redux store but rather have component's own state
 * For example we use it in modals
 * @param api - the api to get the list of entities
 * @param emptyMessage - if the list is empty
 * @param hideSearch - if we don't want to have filter search input on top of the list
 * @param notFoundMessage - if search with filter returned no matching entities
 * @param onListChange - callback to call if the list is changed (mostly we filtered)
 * @param otherParams - other params to call the api with (not pagination parameters)
 * @param searchPlaceholder - the placeholder to show inside empty search string
 * @param updateOn - forces to refetch on its change
 * @param List - the list component to show the entities (with single parameter 'list': Array<TEntity>)
 * @constructor
 */
const PaginatedList = <
  TParams extends PaginatedRequestParams,
  TEntity,
  TOthers = Partial<Omit<TParams, keyof PaginatedRequestParams>>
>({
  api,
  emptyMessage,
  hideSearch,
  notFoundMessage,
  onListChange,
  otherParams,
  searchPlaceholder,
  updateOn,
  List,
  hidePagination,
}: PaginatedListProps<TParams, TEntity, TOthers>) => {
  const [params, setParams] = useState<TParams>({
    pageNumber: DEFAULT_PAGINATION.pageNumber,
    rowsPerPage: DEFAULT_PAGINATION.rowsPerPage,
    filter: '',
    ...otherParams,
  } as TParams)

  const { loading, items, total } = useSelfStatePagination({ params, api, updateOn })
  const shouldHidePagination =
    typeof hidePagination === 'function' ? hidePagination({ loading, items, total }) : Boolean(hidePagination)
  useEffect(() => {
    if (onListChange)
      onListChange({ loading, items, total, ...params } as Partial<
        ListResult<TEntity> & { loading: boolean } & TParams
      >)
  }, [loading, total])

  if (!loading && !total && !params.filter)
    return (
      <Typography align="center" variant="body1" sx={styles.empty} color="textSecondary">
        {emptyMessage}
      </Typography>
    )

  return (
    <>
      <Box sx={styles.searchClass} data-testid="paginated-list-filter">
        {!hideSearch && (
          <SearchInput
            initialValue={params.filter}
            placeholder={searchPlaceholder || 'Search…'}
            onChange={input => setParams({ ...params, filter: input, pageNumber: '0' })}
          />
        )}
      </Box>
      <Pendable pending={loading} cover id="self-state-pending">
        {total || loading ? (
          List({ list: items })
        ) : (
          <Typography align="center" variant="body1" sx={styles.empty} color="textSecondary">
            {notFoundMessage}
          </Typography>
        )}
      </Pendable>
      {!shouldHidePagination && (
        <Pagination
          total={total}
          page={params.pageNumber}
          perPage={params.rowsPerPage}
          changePageCallback={(pageNumber: string) => setParams({ ...params, pageNumber })}
          changeRowsPerPageCallback={(rowsPerPage: string) => setParams({ ...params, rowsPerPage, pageNumber: '0' })}
        />
      )}
    </>
  )
}

export default PaginatedList
