import { useEffect, useState } from 'react'
import { FormikProps } from 'formik'
import { get } from 'lodash'
import MuiCheckbox from '@mui/material/Checkbox'

import { Address, ApplianceType, Input, InputAdminStatus, RtpInputPort } from 'common/api/v1/types'
import { Checkbox, Select, TextInput } from '../../../../common/Form'
import { createDefaultFiledValues, makeAddressOptions } from '../../../../../utils'
import { isVaApplianceType } from '../../../../appliances/utils'
import { OccupiedPort } from 'common/ports'
import { CIDRToolTip } from '../../../../../texts'

export enum RtpFields {
  address = 'address',
  port = 'port',
  fec = 'fec',
  multicastAddress = 'multicastAddress',
  multicastSource = 'multicastSource',
  whitelistCidrBlock = 'whitelistCidrBlock',
  failoverPriority = 'failoverPriority',
}

export const rtpDefaults = createDefaultFiledValues(Object.keys(RtpFields), [RtpFields.fec])

interface RtpFormProps {
  addresses: Array<Address>
  form: FormikProps<Input>
  index: number
  applianceType: ApplianceType
  namePrefix: string
  occupiedPorts: OccupiedPort[]
  adminStatus: InputAdminStatus
  allocatedPort?: { addresses: Address[]; portNumber: number }
}
const RtpForm = ({
  form: { setFieldValue, values },
  addresses,
  namePrefix,
  applianceType,
  index,
  occupiedPorts,
  adminStatus,
  allocatedPort,
}: RtpFormProps) => {
  const isEdgeConnect = applianceType == ApplianceType.edgeConnect
  const isCoreNode = applianceType == ApplianceType.core

  const portsNamePrefix = namePrefix.substr(0, namePrefix.length - 2) // _interfaceSection-xxx.ports.0 --> _interfaceSection-xxx.ports
  const ports: RtpInputPort[] = get(values, portsNamePrefix)
  const rtpInputPort: RtpInputPort = get(values, namePrefix)

  const getMulticast = () => ({
    address: rtpInputPort.multicastAddress,
    source: rtpInputPort.multicastSource,
  })
  const { address, source } = getMulticast()
  const [isMulticast, setIsMulticast] = useState(!!(address || source))
  const [multicast, setMulticast] = useState(getMulticast())

  const rtpAddresses = { ports, index }
  const disableLocalPortFields = !!allocatedPort
  const showDash7Checkbox = index > 0
  const isDash7Redundant = typeof rtpInputPort.failoverPriority !== 'number'

  const addressSelector = `${namePrefix}.${RtpFields.address}`
  const selectedSourceAddress = get(values, addressSelector)
  const portSelector = `${namePrefix}.${RtpFields.port}`
  const selectedPortNumber = get(values, portSelector)
  const allocatedAddress = allocatedPort?.addresses[0].address
  const allocatedPortNumber = allocatedPort?.portNumber
  useEffect(() => {
    if (allocatedAddress && selectedSourceAddress !== allocatedAddress) {
      setFieldValue(addressSelector, allocatedAddress, false)
    }
    if (allocatedPortNumber !== undefined && selectedPortNumber !== allocatedPortNumber) {
      setFieldValue(portSelector, allocatedPortNumber, false)
    }
  }, [
    allocatedAddress,
    selectedSourceAddress,
    addressSelector,
    allocatedPortNumber,
    selectedPortNumber,
    portSelector,
    setFieldValue,
  ])

  useEffect(() => {
    if (!isMulticast) {
      setMulticast(getMulticast())
      setFieldValue(`${namePrefix}.${RtpFields.multicastAddress}`, '', false)
      setFieldValue(`${namePrefix}.${RtpFields.multicastSource}`, '', false)
    } else {
      setFieldValue(`${namePrefix}.${RtpFields.multicastAddress}`, multicast.address, false)
      setFieldValue(`${namePrefix}.${RtpFields.multicastSource}`, multicast.source, false)
    }
  }, [isMulticast, setFieldValue])

  return (
    <>
      {showDash7Checkbox && (
        <Checkbox
          label="Identical RTP streams (SMPTE 2022-7)"
          tooltip={`Requires that the two interfaces receive binary equal streams, e.g. from the same encoder.`}
          control={
            <MuiCheckbox
              id={`${namePrefix}.${RtpFields.failoverPriority}`}
              name={`${namePrefix}.${RtpFields.failoverPriority}`}
              checked={isDash7Redundant}
              onChange={e => {
                setFieldValue(
                  portsNamePrefix,
                  ports.map((port, portIndex) => ({
                    ...port,
                    [RtpFields.failoverPriority]: e.target.checked ? undefined : portIndex,
                  })),
                  false,
                )
              }}
            />
          }
        />
      )}
      <Select
        name={addressSelector}
        label="Address"
        required
        disabled={disableLocalPortFields}
        options={makeAddressOptions(selectedSourceAddress, addresses)}
        newLine
        validators={{
          rtpAddresses,
          addressIn: { addresses },
        }}
      />
      <TextInput
        name={portSelector}
        label="Port"
        required
        disabled={disableLocalPortFields}
        type="number"
        noNegative
        validators={{
          port: { disallowInternal: true },
          rtpAddresses,
          isPortAvailable: {
            occupiedPorts,
            isMulticast: !!address,
            isPortDisabled: adminStatus === InputAdminStatus.off,
          },
        }}
      />
      {!isEdgeConnect && !isCoreNode && (
        <Checkbox name={`${namePrefix}.${RtpFields.fec}`} label="Use Forward Error Correction?" />
      )}
      <Checkbox
        control={
          <MuiCheckbox
            checked={isMulticast}
            onChange={e => setIsMulticast(e.target.checked)}
            id={`${namePrefix}.multicast`}
            name={`${namePrefix}.multicast`}
          />
        }
        label="Is this port multicast?"
        newLine
      />
      {isMulticast && (
        <>
          <TextInput
            name={`${namePrefix}.${RtpFields.multicastAddress}`}
            label="Multicast address"
            validators={{
              rtpAddresses,
              multicastAddress: {},
            }}
            newLine
          />
          <TextInput
            name={`${namePrefix}.${RtpFields.multicastSource}`}
            label="Source specific multicast address"
            validators={{
              ip: {},
            }}
          />
        </>
      )}{' '}
      {!isVaApplianceType(applianceType) && (
        <TextInput
          name={`${namePrefix}.${RtpFields.whitelistCidrBlock}`}
          label="Whitelist CIDR block"
          tooltip={CIDRToolTip(applianceType === ApplianceType.core, 'Input')}
          required={applianceType === ApplianceType.core}
          validators={{
            ipv4CidrBlock: {},
          }}
        />
      )}
    </>
  )
}

export default RtpForm
