import { GridItem, Select, TextInput } from '../../../common/Form'
import { RichOption } from '../../../common/Form/Select'
import { ComprimatoNdiOutput, Output } from 'common/api/v1/types'
import { FormikProps } from 'formik'

import { get } from 'lodash'
import { useEffect, useMemo } from 'react'

interface NdiFormProps {
  namePrefix: string
  form: FormikProps<Output>
}

const NdiForm = ({ namePrefix, form: { setFieldValue, values } }: NdiFormProps) => {
  const logicalPort: ComprimatoNdiOutput = get(values, namePrefix)

  const modeKey = `${namePrefix}.mode`
  const selectedMode = logicalPort.mode
  const modeOptions: RichOption[] = useMemo(() => [{ name: 'NDI', value: 'comprimatoNdi' }], [])
  useEffect(() => {
    const defaultMode = modeOptions[0]
    const isValidMode = modeOptions.some(m => m.value === selectedMode)
    if (!isValidMode && defaultMode) {
      setFieldValue(modeKey, defaultMode.value, false)
    }
  }, [modeKey, setFieldValue, modeOptions, selectedMode])

  return (
    <>
      <Select
        label="Mode"
        name={modeKey}
        xs={4}
        lg={4}
        xl={4}
        required
        newLine
        options={modeOptions}
        disabled={selectedMode && modeOptions.length === 1}
        validators={{
          oneOf: { validValues: new Set(modeOptions.filter(o => !o.disabled).map(({ value }) => value)) },
        }}
      />
      <GridItem xs={8} lg={8} xl={8}>
        <TextInput name={`${namePrefix}.name`} label="Name" required />
      </GridItem>
    </>
  )
}

export default NdiForm
