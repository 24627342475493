import { useEffect } from 'react'
import { FormikProps } from 'formik'
import { get } from 'lodash'

import { Address, ApplianceType, Input, InputAdminStatus, SrtInputPort, SrtMode } from 'common/api/v1/types'

import { Checkbox, Select, TextInput } from '../../../../common/Form'
import { isVaApplianceType } from '../../../../appliances/utils'
import { createDefaultFiledValues, isCoreNode, makeAddressOptions } from '../../../../../utils'
import { OccupiedPort } from 'common/ports'
import { CIDRToolTip } from '../../../../../texts'

const LATENCY_MAX = 6 * 60 * 1000
const TTL_MAX = 255
const MTU_MIN = 76

export enum SrtFields {
  srtMode = 'srtMode',
  remoteIp = 'remoteIp',
  remotePort = 'remotePort',
  localIp = 'localIp',
  localPort = 'localPort',
  latency = 'latency',
  ipttl = 'ipttl',
  mss = 'mss',
  passphrase = 'passphrase',
  streamId = 'streamId',
  reducedBitrateDetection = 'reducedBitrateDetection',
  reducedBitrateThreshold = 'reducedBitrateThreshold',
  unrecoveredPacketsDetection = 'unrecoveredPacketsDetection',
  unrecoveredPacketsThreshold = 'unrecoveredPacketsThreshold',
  whitelistCidrBlock = 'whitelistCidrBlock',
}

export const srtDefaults = createDefaultFiledValues(
  Object.keys(SrtFields),
  [SrtFields.reducedBitrateDetection, SrtFields.unrecoveredPacketsDetection],
  { [SrtFields.latency]: 120 },
)
export const getSrtFieldsToSave = (port: SrtInputPort) => [
  SrtFields.srtMode,
  SrtFields.latency,
  SrtFields.ipttl,
  SrtFields.mss,
  SrtFields.streamId,
  SrtFields.passphrase,
  SrtFields.reducedBitrateDetection,
  SrtFields.unrecoveredPacketsDetection,
  ...(port.reducedBitrateDetection ? [SrtFields.reducedBitrateThreshold] : []),
  ...(port.unrecoveredPacketsDetection ? [SrtFields.unrecoveredPacketsThreshold] : []),
  ...(function() {
    switch (port.srtMode) {
      case SrtMode.listener:
        return [SrtFields.localIp, SrtFields.localPort, SrtFields.whitelistCidrBlock]
      case SrtMode.caller:
        return [SrtFields.remoteIp, SrtFields.remotePort, SrtFields.localPort]
      default:
        return [SrtFields.remoteIp, SrtFields.remotePort, SrtFields.localIp, SrtFields.whitelistCidrBlock]
    }
  })(),
]

interface SrtFormProps {
  form: FormikProps<Input>
  addresses: Array<Address>
  namePrefix: string
  applianceType: ApplianceType
  occupiedPorts: OccupiedPort[]
  adminStatus: InputAdminStatus
  onModeChanged?: () => void
  allocatedPort?: { addresses: Address[]; portNumber: number }
}

const SrtForm = ({
  form: { setFieldValue, values },
  addresses,
  namePrefix,
  applianceType,
  occupiedPorts,
  adminStatus,
  allocatedPort,
  onModeChanged,
}: SrtFormProps) => {
  const port: SrtInputPort = get(values, namePrefix)
  const localAddressSelector = `${namePrefix}.${SrtFields.localIp}`
  const disableLocalPortFields = !!allocatedPort
  const remotePort = get(values, `${namePrefix}.${SrtFields.remotePort}`)
  const localPort = get(values, `${namePrefix}.${SrtFields.localPort}`)
  const localAddress = get(values, localAddressSelector)

  useEffect(() => {
    if (allocatedPort) {
      const shouldPopulateField = (field: SrtFields) => getSrtFieldsToSave(port).includes(field)
      if (port?.srtMode === SrtMode.rendezvous && remotePort !== allocatedPort.portNumber) {
        setFieldValue(`${namePrefix}.${SrtFields.remotePort}`, allocatedPort.portNumber, false)
      }
      if (
        port.srtMode !== SrtMode.caller &&
        shouldPopulateField(SrtFields.localPort) &&
        localPort !== allocatedPort.portNumber
      ) {
        setFieldValue(`${namePrefix}.${SrtFields.localPort}`, allocatedPort.portNumber, false)
      }

      const allocatedAddress = allocatedPort.addresses[0].address
      if (shouldPopulateField(SrtFields.localIp) && localAddress !== allocatedAddress) {
        setFieldValue(localAddressSelector, allocatedAddress, false)
      }
    }
  }, [allocatedPort, port, setFieldValue, namePrefix, remotePort, localPort, localAddress, localAddressSelector])

  useEffect(() => {
    // EDGE-2874 When switching from Regional SRT caller to Regional SRT listener/rendezvous we need to allocate new port because portNumber is 0
    const shouldAllocatePort =
      !!port?.srtMode && port?.srtMode !== SrtMode.caller && !port.region?.allocatedPort?.portNumber
    if (shouldAllocatePort) {
      onModeChanged?.()
    }
  }, [port?.srtMode])

  return (
    <>
      <Select
        name={`${namePrefix}.${SrtFields.srtMode}`}
        label="Connection mode"
        options={Object.values(SrtMode)}
        required
      />
      {[SrtMode.caller, SrtMode.rendezvous].includes(port?.srtMode) && (
        <>
          <TextInput
            name={`${namePrefix}.${SrtFields.remoteIp}`}
            label="Remote host"
            newLine
            required
            validators={{
              ipOrHostname: {},
            }}
          />
          <TextInput
            name={`${namePrefix}.${SrtFields.remotePort}`}
            label={port?.srtMode === SrtMode.rendezvous ? 'Local and remote port' : 'Remote port'}
            required
            disabled={disableLocalPortFields && port?.srtMode === SrtMode.rendezvous}
            type="number"
            noNegative
            validators={{
              port: {},
              isPortAvailable: {
                occupiedPorts: port.srtMode == SrtMode.rendezvous ? occupiedPorts : [],
                isMulticast: false,
                isPortDisabled: adminStatus === InputAdminStatus.off,
              },
            }}
          />
        </>
      )}
      {[SrtMode.listener, SrtMode.rendezvous].includes(port?.srtMode) && (
        <Select
          name={localAddressSelector}
          label="Local address"
          options={makeAddressOptions(get(values, localAddressSelector), addresses)}
          required
          disabled={disableLocalPortFields}
          newLine
          validators={{
            addressIn: { addresses },
          }}
        />
      )}
      {(port?.srtMode === SrtMode.listener || (port?.srtMode === SrtMode.caller && !disableLocalPortFields)) && (
        <TextInput
          name={`${namePrefix}.${SrtFields.localPort}`}
          label="Local port"
          required={port.srtMode == SrtMode.listener}
          disabled={disableLocalPortFields}
          type="number"
          tooltip={port.srtMode == SrtMode.caller ? 'The local outgoing port' : undefined}
          noNegative
          newLine={port?.srtMode != SrtMode.listener}
          validators={{
            port: { disallowInternal: true },
            isPortAvailable: {
              occupiedPorts,
              isMulticast: false,
              isPortDisabled: adminStatus === InputAdminStatus.off,
            },
          }}
        />
      )}
      <TextInput
        name={`${namePrefix}.${SrtFields.latency}`}
        label="Retransmission buffer (ms)"
        type="number"
        noNegative
        required
        newLine
        tooltip="The constant delay of playout of the stream that allows time for retransmissions to occur"
        validators={{
          number: {
            lessThanOrEqualTo: LATENCY_MAX,
            message: `Must be no more than ${LATENCY_MAX}`,
          },
        }}
      />
      {!isVaApplianceType(applianceType) && (
        <TextInput
          name={`${namePrefix}.${SrtFields.ipttl}`}
          label="TTL"
          type="number"
          noNegative
          tooltip='Defines IP socket "time to live" option.'
          validators={{
            number: {
              lessThanOrEqualTo: TTL_MAX,
              message: `Must be no more than ${TTL_MAX}`,
            },
          }}
        />
      )}
      {!isVaApplianceType(applianceType) && (
        <TextInput
          name={`${namePrefix}.${SrtFields.mss}`}
          label="MTU"
          type="number"
          noNegative
          newLine
          tooltip="MTU size"
          validators={{
            number: {
              greaterThanOrEqualTo: MTU_MIN,
              message: `Must be more than ${MTU_MIN}`,
            },
          }}
        />
      )}
      <TextInput
        name={`${namePrefix}.${SrtFields.passphrase}`}
        label="Passphrase"
        tooltip="Same passphrase must be configured at SRT output to encrypt the stream"
        validators={{
          alphanumeric: {},
          len: {
            minimum: 10,
          },
        }}
      />

      {port?.srtMode == SrtMode.caller && (
        <TextInput name={`${namePrefix}.${SrtFields.streamId}`} label="Stream Id" newLine tooltip="Stream ID" />
      )}
      {isVaApplianceType(applianceType) && (
        <Checkbox
          name={`${namePrefix}.${SrtFields.reducedBitrateDetection}`}
          label="Reduced bitrate alarm"
          newLine
          tooltip="Raise alarm when bitrate is below or equal."
        />
      )}

      {port.reducedBitrateDetection && (
        <TextInput
          name={`${namePrefix}.${SrtFields.reducedBitrateThreshold}`}
          label="Bitrate (kbps)"
          type="number"
          noNegative
          validators={{
            number: {
              lessThanOrEqualTo: Math.pow(2, 64),
              message: `Must be no more than ${Math.pow(2, 64)}`,
            },
          }}
        />
      )}
      {isVaApplianceType(applianceType) && (
        <Checkbox
          name={`${namePrefix}.${SrtFields.unrecoveredPacketsDetection}`}
          label="Unrecovered packets alarm"
          tooltip="Turn detection of unrecovered packets on or off, including associated alarm. Threshold is in packets per minute. Generates an alarm if unrecovered packets per minute is higher than threshold."
          newLine
        />
      )}
      {port.unrecoveredPacketsDetection && (
        <TextInput
          name={`${namePrefix}.${SrtFields.unrecoveredPacketsThreshold}`}
          label="Threshold (packets/min)"
          type="number"
          noNegative
          validators={{
            number: {
              lessThanOrEqualTo: Math.pow(2, 64),
              message: `Must be no more than ${Math.pow(2, 64)}`,
            },
          }}
        />
      )}
      {!isVaApplianceType(applianceType) && [SrtMode.listener, SrtMode.rendezvous].includes(port?.srtMode) && (
        <TextInput
          name={`${namePrefix}.${SrtFields.whitelistCidrBlock}`}
          label="Whitelist CIDR block"
          tooltip={CIDRToolTip(isCoreNode(applianceType), 'Input')}
          required={isCoreNode(applianceType)}
          validators={{
            ipv4CidrBlock: {},
          }}
        />
      )}
    </>
  )
}

export default SrtForm
