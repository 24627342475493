import { FormikProps } from 'formik'
import { Button } from '@mui/material'
import {
  Address,
  ApplianceFeatures,
  ApplianceVersion,
  Input,
  InputAdminStatus,
  LimitedPhysicalPort,
  Output,
  OutputAdminStatus,
  PortType,
  VideonPortMode,
} from 'common/api/v1/types'
import IpPortForm from './IpPortForm'
import CoaxPortForm from './CoaxPortForm'
import { GridItem } from '../../../common/Form'

import { getPortsInUseBy } from '../../../../utils'
import VideonPortForm from './VideonPortForm'
import NdiForm from './NdiForm'

interface IPortFormProps {
  inputId?: string
  form: FormikProps<Input>
  index: number
  inputPort: LimitedPhysicalPort & { _inputs?: Array<Input>; _outputs?: Array<Output> }
  allocatedPort?: { addresses: Address[]; portNumber: number }
  isModeDisabled: boolean
  onModeChanged?: () => void
  namePrefix: string
  onRemove?: (index: number) => void

  applianceFeatures?: ApplianceFeatures
  applianceVersion?: ApplianceVersion
}

export const PortForm = ({
  inputId,
  inputPort,
  allocatedPort,
  isModeDisabled,
  onModeChanged,
  namePrefix,
  form,
  index,
  onRemove,
  applianceFeatures,
  applianceVersion,
}: IPortFormProps) => {
  const otherInputsOnInterface =
    inputPort?._inputs?.filter(input => input.adminStatus == InputAdminStatus.on && input.id != inputId) || []
  const portsInUseOnSelectedInterface = getPortsInUseBy({
    inputs: otherInputsOnInterface,
    outputs:
      '_outputs' in inputPort && inputPort._outputs
        ? inputPort._outputs.filter(output => output.adminStatus == OutputAdminStatus.on)
        : [],
  })

  return (
    <>
      {inputPort.portType === PortType.ip && (
        <IpPortForm
          index={index}
          namePrefix={namePrefix}
          form={form}
          addresses={inputPort.addresses}
          applianceType={inputPort.appliance.type}
          applianceVersion={applianceVersion}
          allocatedPort={allocatedPort}
          occupiedPorts={portsInUseOnSelectedInterface}
          isModeDisabled={isModeDisabled}
          onModeChanged={onModeChanged}
        />
      )}

      {inputPort.portType === PortType.coax && applianceFeatures && (
        <CoaxPortForm namePrefix={namePrefix} form={form} applianceFeatures={applianceFeatures} />
      )}

      {inputPort.portType === PortType.videon && applianceFeatures && (
        <VideonPortForm
          namePrefix={namePrefix}
          applianceFeatures={applianceFeatures}
          limitToMode={
            otherInputsOnInterface[0] && (otherInputsOnInterface[0]?.ports?.[0]?.mode as VideonPortMode | undefined)
          }
        />
      )}

      {inputPort.portType === PortType.ndi && (
        <NdiForm form={form} namePrefix={namePrefix} applianceId={inputPort.appliance.id} />
      )}

      {onRemove && (
        <GridItem newLine>
          <Button style={{ marginTop: 12 }} variant="outlined" color="primary" onClick={() => onRemove(index)}>
            Remove interface
          </Button>
        </GridItem>
      )}
    </>
  )
}
