import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import { Delete, Stop, PlayArrow } from '@mui/icons-material'

import { Output, OutputAdminStatus } from 'common/api/v1/types'
import { pluralize } from '../../../utils'
import { AppDispatch, GlobalState } from '../../../store'
import { DraftActions, draftOutputs, enableOutputs } from '../../../redux/actions/outputsActions'

interface CommonActionsProps {
  selected: Array<Output['id']>
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
}

const BatchEnableButton = ({ selected, setSelected }: CommonActionsProps) => {
  const outputs = useSelector((state: GlobalState) => state.outputsReducer.outputs, shallowEqual)
  const dispatch = useDispatch<AppDispatch>()

  const selectedOutputs = outputs.filter(({ id }) => selected.includes(id))
  const enable = selected.length > 0 && selectedOutputs.some(({ adminStatus }) => adminStatus === OutputAdminStatus.off)

  const onEnableClick = () => {
    dispatch(enableOutputs(selected))
    dispatch(draftOutputs({ outputs: [] }))
    setSelected([])
  }

  return (
    <Tooltip title={'Enable selected outputs'}>
      <span>
        <IconButton aria-label="Enable" disabled={!enable} onClick={onEnableClick} data-test-id="batch-enable">
          <PlayArrow />
        </IconButton>
      </span>
    </Tooltip>
  )
}

const BatchDisableButton = ({ selected }: { selected: Array<Output['id']> }) => {
  const outputs = useSelector((state: GlobalState) => state.outputsReducer.outputs, shallowEqual)
  const dispatch = useDispatch<AppDispatch>()

  const selectedOutputs = outputs.filter(({ id }) => selected.includes(id))
  const enable = selected.length > 0 && selectedOutputs.some(({ adminStatus }) => adminStatus === OutputAdminStatus.on)

  const onDisableClick = () => {
    dispatch(draftOutputs({ action: DraftActions.disable, outputs: selectedOutputs }))
  }

  return (
    <Tooltip title={'Disable selected outputs'}>
      <span>
        <IconButton aria-label="Disable" disabled={!enable} onClick={onDisableClick} data-test-id="batch-disable">
          <Stop />
        </IconButton>
      </span>
    </Tooltip>
  )
}

const CommonActions = ({ selected, setSelected }: CommonActionsProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const outputs = useSelector((state: GlobalState) => state.outputsReducer.outputs, shallowEqual)
  const outputsMap = outputs.reduce<{ [key: string]: Output }>((acc, item) => ({ ...acc, [item.id]: item }), {})

  const { showButtons } = selected.reduce(
    ({ showButtons }, id) => {
      const output = outputsMap[id]
      return {
        showButtons: Boolean(showButtons && output),
      }
    },
    { showButtons: true },
  )

  const onDeleteClick = () =>
    dispatch(draftOutputs({ action: DraftActions.delete, outputs: selected.map(id => outputsMap[id]) }))

  return (
    <Toolbar disableGutters>
      {!!selected.length && (
        <>
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            {pluralize(selected.length, 'output')} selected
          </Typography>
          {showButtons && (
            <>
              <Tooltip title={'Delete selected outputs'}>
                <span>
                  <IconButton aria-label="Delete" onClick={onDeleteClick} data-test-id="delete-selected">
                    <Delete />
                  </IconButton>
                </span>
              </Tooltip>
              <BatchDisableButton selected={selected} />
              <BatchEnableButton selected={selected} setSelected={setSelected} />
            </>
          )}
        </>
      )}
    </Toolbar>
  )
}

export default CommonActions
