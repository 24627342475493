import React from 'react'
import { useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'

import { Appliance, Role } from 'common/api/v1/types'
import { pluralize, useConfirmationDialog } from '../../utils'
import { restartAppliances } from '../../redux/actions/applianceActions'
import { EnrichedApplianceWithOwner, EnrichedUser } from 'src/api/nm-types'
import { AppDispatch } from 'src/store'

const styles = {
  toolbarText: {
    flexGrow: 1,
  },
}

interface CommonActionsProps {
  appliances: EnrichedApplianceWithOwner[]
  selected: Array<Appliance['id']>
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
  user: EnrichedUser
}

const BatchRestartButton = ({ selected, setSelected }: Pick<CommonActionsProps, 'selected' | 'setSelected'>) => {
  const dispatch = useDispatch<AppDispatch>()
  const showConfirmation = useConfirmationDialog()

  const onClick = () => {
    showConfirmation(() => {
      dispatch(restartAppliances({ ids: selected }))
      setSelected([])
    }, 'Restarting will affect all video streams on the appliances. After restart the appliances will run the latest software version. Do you want to continue?')
  }

  return (
    <Tooltip title={'Restart selected appliances'}>
      <span>
        <Button variant="contained" color="primary" onClick={onClick}>
          Restart
        </Button>
      </span>
    </Tooltip>
  )
}

const CommonActions = ({ selected, setSelected, appliances, user }: CommonActionsProps) => {
  const showButtons = selected.length > 0
  const selectedAppliances = appliances.filter(({ id }) => selected.includes(id))
  const isOwnerOfAllSelected = selectedAppliances.every(({ _owner }) => _owner.id === user.group)

  return (
    <Toolbar disableGutters>
      {!!selected.length && (
        <>
          <Typography sx={styles.toolbarText} variant="subtitle1">
            {pluralize(selected.length, 'appliance')} selected
          </Typography>
          {showButtons && (
            <>
              {(user.role === Role.super || isOwnerOfAllSelected) && (
                <BatchRestartButton selected={selected} setSelected={setSelected} />
              )}
            </>
          )}
        </>
      )}
    </Toolbar>
  )
}

export default CommonActions
