import { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { Cached } from '@mui/icons-material'

import { Entity, GraphNodeType } from 'common/api/v1/types'
import { AppDispatch, GlobalState } from '../../../store'
import { clearService, getService, refresh, selectObject } from '../../../redux/actions/serviceOverviewActions'
import { setSearchVisibility } from '../../../redux/actions/uiActions'
import { usePageParams, withDefaultPagination } from '../../../utils'
import { Paper } from '../../common/Form'
import Wrapper from '../../common/Wrapper'
import { Pagination } from '../../common/Table'
import { WindowSizeSelect } from './WindowSize'
import Info from './Info'
import { SelectedGraphItem, ServiceOverviewGraph } from '../Graph/ServiceOverviewGraph'
import { ServiceOverviewState } from '../../../redux/reducers/serviceOverviewReducers'
import { styles } from '../../../Common'

const Overview = ({ match }: RouteComponentProps<Entity>) => {
  const dispatch = useDispatch<AppDispatch>()
  const inputId = match.params.id
  const [parameters] = usePageParams()
  const outputId = parameters.outputId
  const params = withDefaultPagination(parameters)
  const { input, outputs, loading, graph, selected, appliances, tr101290Window } = useSelector(
    ({ serviceOverviewReducer }: GlobalState) => serviceOverviewReducer,
    shallowEqual,
  ) as ServiceOverviewState
  const [date, setDate] = useState(new Date())

  useEffect(() => {
    if (!loading) setDate(new Date())
  }, [loading])

  const setSelectedObject = (selected: SelectedGraphItem | undefined) => void dispatch(selectObject(selected))

  if (!selected) {
    if (outputId) setSelectedObject({ id: outputId, type: GraphNodeType.output })
    else setSelectedObject({ id: inputId, type: GraphNodeType.input })
  }

  useEffect(() => {
    inputId &&
      dispatch(
        getService({
          params: parameters,
          inputId,
          outputId,
        }),
      )
  }, [dispatch, Object.values(params).join(',')])

  useEffect(() => {
    function doRefresh() {
      dispatch(refresh())
    }

    const timerId = setInterval(doRefresh, 20 * 1000)
    return function stopRefresh() {
      clearInterval(timerId)
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(setSearchVisibility(!parameters.outputId))
    return () => {
      dispatch(setSearchVisibility(false))
      dispatch(clearService())
    }
  }, [dispatch, parameters.outputId])

  return (
    <Wrapper name="Service overview" entityName={input?.name} searchbarPlaceholder={'Search output'}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            id="paper-service-overview"
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <span>Service overview </span>
                  <Typography color="textSecondary" variant="body2" component="span">
                    loaded at {format(date, 'HH:mm:ss')}
                  </Typography>
                  <IconButton onClick={() => void dispatch(refresh())}>
                    <Cached sx={loading ? styles.rotating : {}} />
                  </IconButton>
                </div>
                <div>
                  <WindowSizeSelect currentWindow={tr101290Window} />
                </div>
              </div>
            }
          >
            <Grid item xs={12}>
              {!!input && (
                <>
                  <ServiceOverviewGraph
                    selectedItem={selected}
                    onSelect={setSelectedObject}
                    graph={JSON.parse(JSON.stringify(graph))}
                    input={input}
                    outputs={outputs.items}
                    appliances={appliances}
                    outputId={parameters.outputId}
                  />
                  <Pagination total={outputs.total} useUrlSearchParams />
                </>
              )}
            </Grid>
          </Paper>
          <Info selected={selected} />
        </Grid>
      </Grid>
    </Wrapper>
  )
}

export default Overview
