import {
  ApplianceSettings,
  ApplianceType,
  MatroxE4ApplianceSettings,
  MatroxDecoderApplianceSettings,
} from 'common/api/v1/types'
import MatroxE4ApplianceSettingsComp from './MatroxE4ApplianceSettingsComp'
import MatroxDecoderApplianceSettingsComp from './MatroxDecoderApplianceSettingsComp'
import { Checkbox } from '../../../../common/Form'

interface Props {
  devMode: boolean
  type: ApplianceType
  settings: ApplianceSettings | undefined
}

const ApplianceSpecificSettings = ({ type, settings, devMode }: Props) => {
  return (
    <>
      {devMode && (
        <Checkbox
          label={'Use dynamic tunnel client source address (0.0.0.0)'}
          name={`settings.useDynamicTunnelClientSourceAddress`}
        />
      )}
      {[ApplianceType.matroxMonarchEdgeD4, ApplianceType.matroxMonarchEdgeS1].includes(type) && (
        <MatroxDecoderApplianceSettingsComp settings={settings as MatroxDecoderApplianceSettings} />
      )}
      {[ApplianceType.matroxMonarchEdgeE4_8Bit, ApplianceType.matroxMonarchEdgeE4_10Bit].includes(type) && (
        <MatroxE4ApplianceSettingsComp settings={settings as MatroxE4ApplianceSettings} />
      )}
    </>
  )
}

export default ApplianceSpecificSettings
