import Box from '@mui/material/Box'
import { Theme } from '@mui/material/styles'

import Inputs from './Inputs'
import Outputs from './Outputs'

const styles = {
  container: {
    display: 'flex',
    flexGrow: 1,
    position: 'relative',
    overflow: 'hidden',
  },
  inputs: (theme: Theme) => ({
    flexGrow: 3,
    maxWidth: '75%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
      maxWidth: '68%',
    },
    [theme.breakpoints.down('xs')]: {
      flexGrow: 1,
      maxWidth: '50%',
    },
  }),
  outputs: {
    flexGrow: 1,
    height: '100%',
    overflowY: 'auto',
  },
}

const List = () => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.inputs}>
        <Inputs />
      </Box>
      <Box sx={styles.outputs}>
        <Outputs />
      </Box>
    </Box>
  )
}

export default List
