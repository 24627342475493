import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import { Delete, Stop, PlayArrow } from '@mui/icons-material'

import { Input, InputAdminStatus, Role, User } from 'common/api/v1/types'
import { pluralize, useUser } from '../../../utils'
import { AppDispatch, GlobalState } from '../../../store'
import { DraftActions, draftInputs, enableInputs } from '../../../redux/actions/inputsActions'

interface CommonActionsProps {
  selected: Array<Input['id']>
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
  user: User
}

const BatchEnableButton = ({ selected, setSelected }: Pick<CommonActionsProps, 'selected' | 'setSelected'>) => {
  const inputs = useSelector((state: GlobalState) => state.inputsReducer.inputs, shallowEqual)
  const dispatch = useDispatch<AppDispatch>()

  const selectedInputs = inputs.filter(({ id }) => selected.includes(id))
  const enable = selected.length > 0 && selectedInputs.some(({ adminStatus }) => adminStatus === InputAdminStatus.off)

  const onEnableClick = () => {
    dispatch(enableInputs(selected))
    dispatch(draftInputs({ inputs: [] }))
    setSelected([])
  }

  return (
    <Tooltip title={'Enable selected inputs'}>
      <span>
        <IconButton aria-label="Enable" disabled={!enable} onClick={onEnableClick} data-test-id="batch-enable">
          <PlayArrow />
        </IconButton>
      </span>
    </Tooltip>
  )
}

const BatchDisableButton = ({ selected }: Pick<CommonActionsProps, 'selected'>) => {
  const inputs = useSelector((state: GlobalState) => state.inputsReducer.inputs, shallowEqual)
  const dispatch = useDispatch<AppDispatch>()

  const selectedInputs = inputs.filter(({ id }) => selected.includes(id))
  const enable = selected.length > 0

  const onDisableClick = () => {
    dispatch(draftInputs({ action: DraftActions.disable, inputs: selectedInputs }))
  }

  return (
    <Tooltip title={'Disable selected inputs'}>
      <span>
        <IconButton aria-label="Disable" disabled={!enable} onClick={onDisableClick} data-test-id="batch-disable">
          <Stop />
        </IconButton>
      </span>
    </Tooltip>
  )
}

const CommonActions = ({ selected, setSelected, user }: CommonActionsProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const { group } = useUser()
  const inputs = useSelector((state: GlobalState) => state.inputsReducer.inputs, shallowEqual)
  const inputsMap = inputs.reduce<{ [key: string]: Input }>((acc, item) => ({ ...acc, [item.id]: item }), {})

  const showButtons = user.role === Role.super || selected.every(id => inputsMap[id]?.owner === group)

  const onDeleteClick = () =>
    dispatch(draftInputs({ action: DraftActions.delete, inputs: selected.map(id => inputsMap[id]) }))

  return (
    <Toolbar disableGutters>
      {!!selected.length && (
        <>
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            {pluralize(selected.length, 'input')} selected
          </Typography>
          {showButtons && (
            <>
              <Tooltip title={'Delete selected inputs'}>
                <span>
                  <IconButton aria-label="batch delete" data-test-id="batch-delete" onClick={onDeleteClick}>
                    <Delete />
                  </IconButton>
                </span>
              </Tooltip>
              <BatchDisableButton selected={selected} />
              <BatchEnableButton selected={selected} setSelected={setSelected} />
            </>
          )}
        </>
      )}
    </Toolbar>
  )
}

export default CommonActions
