import {
    CoaxPortMode,
    ComprimatoPortMode,
    EncoderFeatures,
    EncoderSettings,
    GeneralEncoderSettings,
    IpcPortMode,
    IpPortMode,
    isGeneralEncoderSettings,
    isMatroxEncoderConfig,
    MatroxAudioChannelPair,
    MatroxEncoderAACEncoder,
    MatroxEncoderAACFormat,
    MatroxEncoderAACQualityLevel,
    MatroxEncoderBitrateControlMode,
    MatroxEncoderConfig,
    MatroxEncoderEncodingMode,
    MatroxEncoderFlipOption,
    MatroxEncoderIncludeOption,
    MatroxEncoderPivotOption,
    MatroxEncoderPixelFormat,
    MatroxEncoderProcessorAudioSourceSettings,
    MatroxEncoderProcessorFrameRate,
    MatroxEncoderProcessorSettings,
    MatroxEncoderProcessorVideoSourceSettings,
    MatroxEncoderScalingOption,
    MatroxEncoderSettings,
    MatroxEncoderVideoProfile,
    MatroxPortMode,
    PhysicalPort,
    PortMode,
    VideoCodec,
    VideonPortMode,
} from './api/v1/types'
import { BadRequestError, ErrorCode } from './errors'
import { formatBitrate } from './api/v1/helpers'
import { isVirtualAggregatedPort } from './matrox'

export function makeDefaultVaEncoderSettings(): GeneralEncoderSettings {
    return {
        videoCodec: VideoCodec.h264,
        totalBitrate: 15,
        gopSizeFrames: 150,
        audioStreams: [],
    }
}

export function makeDefaultMatroxEncoderConfig(inputSourcePortIndex: number = 0): MatroxEncoderConfig {
    return {
        type: 'matroxEncoder',

        processorSettings: {
            audioSourceSettings: {
                portIndex: inputSourcePortIndex,
            },
            inputFailSafe: false,
            followVideoSourceInput: true,
            videoLayout: 'single',
            videoSourceSettings: [makeMatroxEncoderProcessorVideoSourceSettings(inputSourcePortIndex)],
            overriddenCanvasSettings: {
                frameSize: { width: 1920, height: 1080 },
                frameRate: MatroxEncoderProcessorFrameRate.sixty,
                backgroundColor: { red: 0, green: 0, blue: 0, alpha: 255 },
                pixelFormat: MatroxEncoderPixelFormat.YUV422_10bits,
            },
        },

        encoderSettings: {
            include: MatroxEncoderIncludeOption.audioAndVideo,
            videoSettings: {
                forceEncodingSize: false,
                forcedEncodingSettings: {
                    frameSize: {
                        width: 1920,
                        height: 1080,
                    },
                    scaling: MatroxEncoderScalingOption.noScalingAtCenter,
                    pivot: MatroxEncoderPivotOption.none,
                    flip: MatroxEncoderFlipOption.none,
                },
                forcePixelFormat: false,
                forcedPixelFormat: MatroxEncoderPixelFormat.YUV422_10bits,
                // encodingProfile must be 'High YUV 4:2:2' if processorSettings.followVideoSourceInput = true
                encodingProfile: MatroxEncoderVideoProfile.High422,
                targetBitrateMbps: 15,
                useLosslessEncoding: false,

                gop: {
                    iFrameInterval: 60,
                    pFrameInterval: 1,
                    temporalIframeInterval: 0,
                },

                rateControl: {
                    mode: MatroxEncoderEncodingMode.highQuality,
                    quantizationMin: 15,
                    quantizationMax: 42,
                    bitrateControl: MatroxEncoderBitrateControlMode.variable,
                    allowFrameSkipping: false,
                    maxBitrateMbps: 22.5,
                },

                forceCAVLCEntropyEncoding: false,
            },

            audioSettings: {
                aacEncoder: MatroxEncoderAACEncoder.lc,
                bitrateKbps: 32,
                aacQuality: MatroxEncoderAACQualityLevel.high,
                useTemporalNoiseShaping: true,
                aacFormat: MatroxEncoderAACFormat.adts,
            },
        },

        tsOutputSettings: {
            selectedAudioPairs: [MatroxAudioChannelPair.channel1And2],
        },
    }
}

export function makeMatroxEncoderProcessorAudioSourceSettings(
    portIndex: number
): MatroxEncoderProcessorAudioSourceSettings {
    return {
        portIndex,
    }
}

export function makeMatroxEncoderProcessorVideoSourceSettings(
    portIndex: number
): MatroxEncoderProcessorVideoSourceSettings {
    return {
        portIndex,
        frameCaptureRate: 1,
        scaling: MatroxEncoderScalingOption.scaleToFitAllContent,
        pivot: MatroxEncoderPivotOption.none,
        flip: MatroxEncoderFlipOption.none,
        opacityPercentage: 100,
        brightness: 500,
        contrast: 500,
        hue: 0,
        saturation: 500,
    }
}

export function validateMatroxEncoderSettings(
    encoderSettings: MatroxEncoderConfig,
    physicalPort: Pick<PhysicalPort, 'index'>
) {
    const availableSources = physicalPort.index?.split(',') ?? []
    const requestedSources = [
        encoderSettings.processorSettings.audioSourceSettings.portIndex.toString(),
        ...encoderSettings.processorSettings.videoSourceSettings.map((src) => src.portIndex.toString()),
    ]
    const isRequestingUnavailableSource = requestedSources.some((requested) => !availableSources.includes(requested))
    if (isRequestingUnavailableSource) {
        throw new BadRequestError(
            ErrorCode.badRequest,
            `Attempting to ingress from sources: '${requestedSources.join(
                ','
            )}' but available sources are: '${availableSources.join(',')}'`
        )
    }

    const isVirtualPort = isVirtualAggregatedPort(physicalPort)
    if (isVirtualPort && encoderSettings.processorSettings.videoLayout !== 'quad') {
        throw new BadRequestError(ErrorCode.badRequest, `The received physical port only supports videoLayout 'quad'`)
    } else if (!isVirtualPort && encoderSettings.processorSettings.videoLayout !== 'single') {
        throw new BadRequestError(ErrorCode.badRequest, `The received physical port only supports videoLayout 'single'`)
    }
    validateMatroxEncoderConfig(encoderSettings)
}

export function getCompatibleMatroxVideoProfilesForPixelFormat(
    pixelFormat: MatroxEncoderPixelFormat
): MatroxEncoderVideoProfile[] {
    const compatibles = {
        [MatroxEncoderPixelFormat.YUV420_8bits]: [], // Compatible with all profiles
        [MatroxEncoderPixelFormat.YUV420_10bits]: [
            MatroxEncoderVideoProfile.High10Bit,
            MatroxEncoderVideoProfile.High422,
        ],
        [MatroxEncoderPixelFormat.YUV422_8bits]: [MatroxEncoderVideoProfile.High422],
        [MatroxEncoderPixelFormat.YUV422_10bits]: [MatroxEncoderVideoProfile.High422],
    }
    return compatibles[pixelFormat]
}

export function getAllowedBitratesForMatroxAACEncoder(
    aacEncoder: MatroxEncoderAACEncoder
): { minAllowedBitrateKbps: number; maxAllowedBitrateKbps: number } | undefined {
    const allowedBitratesPerEncoder = {
        [MatroxEncoderAACEncoder.lc]: { minAllowedBitrateKbps: 32, maxAllowedBitrateKbps: 576 },
        [MatroxEncoderAACEncoder.hev1]: { minAllowedBitrateKbps: 32, maxAllowedBitrateKbps: 288 },
        [MatroxEncoderAACEncoder.hev2]: { minAllowedBitrateKbps: 32, maxAllowedBitrateKbps: 144 },
    }
    return allowedBitratesPerEncoder[aacEncoder]
}

export const MATROX_ENCODER_VIDEO_CAPTURE_RATE_MIN = 1
export const MATROX_ENCODER_VIDEO_CAPTURE_RATE_MAX = 60
export const MATROX_ENCODER_VIDEO_OPACITY_PERCENTAGE_MIN = 0
export const MATROX_ENCODER_VIDEO_OPACITY_PERCENTAGE_MAX = 100
export const MATROX_ENCODER_VIDEO_BRIGHTNESS_MIN = 0
export const MATROX_ENCODER_VIDEO_BRIGHTNESS_MAX = 1000
export const MATROX_ENCODER_VIDEO_CONTRAST_MIN = 0
export const MATROX_ENCODER_VIDEO_CONTRAST_MAX = 1000
export const MATROX_ENCODER_VIDEO_HUE_MIN = 0
export const MATROX_ENCODER_VIDEO_HUE_MAX = 360
export const MATROX_ENCODER_VIDEO_SATURATION_MIN = 0
export const MATROX_ENCODER_VIDEO_SATURATION_MAX = 1000

export const MATROX_ENCODER_FRAMESIZE_MIN = 64
export const MATROX_ENCODER_FRAMESIZE_MAX = 4096
export const MATROX_ENCODER_FRAMEWIDTH_MULTIPLIER = 16
export const MATROX_ENCODER_FRAMEHEIGHT_MULTIPLIER = 2
export const MATROX_ENCODER_TARGET_BITRATE_MIN = 0.05
export const MATROX_ENCODER_MAXBITRATE_MIN = 0.1
export const MATROX_ENCODER_MAXBITRATE_MAX = 120
export const MATROX_ENCODER_QP_MIN = 0
export const MATROX_ENCODER_QP_MAX = 51
export const MATROX_ENCODER_IFRAME_INTERVAL_MIN = 1
export const MATROX_ENCODER_IFRAME_INTERVAL_MAX = 600
export const MATROX_ENCODER_IFRAME_INTERVAL_MULTIPLIER = 4
export const MATROX_ENCODER_PFRAME_INTERVAL_MIN = 1
export const MATROX_ENCODER_PFRAME_INTERVAL_MAX = 4
export const MATROX_ENCODER_MIN_ALLOWED_AUDIOSTREAMS = 1
export const MATROX_ENCODER_MAX_ALLOWED_AUDIOSTREAMS = 4

export function validateMatroxEncoderConfig(encoderConfig: MatroxEncoderConfig) {
    function validateProcessorSettings(processorSettings: MatroxEncoderProcessorSettings) {
        function validateVideoSourceSettings({
            frameCaptureRate,
            opacityPercentage,
            brightness,
            contrast,
            hue,
            saturation,
        }: MatroxEncoderProcessorVideoSourceSettings) {
            if (frameCaptureRate < MATROX_ENCODER_VIDEO_CAPTURE_RATE_MIN) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `frameCaptureRate must be greater than or equal to ${MATROX_ENCODER_VIDEO_CAPTURE_RATE_MIN}`
                )
            }
            if (frameCaptureRate > MATROX_ENCODER_VIDEO_CAPTURE_RATE_MAX) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `frameCaptureRate must be less than or equal to ${MATROX_ENCODER_VIDEO_CAPTURE_RATE_MAX}`
                )
            }

            if (opacityPercentage < MATROX_ENCODER_VIDEO_OPACITY_PERCENTAGE_MIN) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `opacityPercentage must be greater than or equal to ${MATROX_ENCODER_VIDEO_OPACITY_PERCENTAGE_MIN}`
                )
            }
            if (opacityPercentage > MATROX_ENCODER_VIDEO_OPACITY_PERCENTAGE_MAX) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `opacityPercentage must be less than or equal to ${MATROX_ENCODER_VIDEO_OPACITY_PERCENTAGE_MAX}`
                )
            }

            if (brightness < MATROX_ENCODER_VIDEO_BRIGHTNESS_MIN) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `brightness must be greater than or equal to ${MATROX_ENCODER_VIDEO_BRIGHTNESS_MIN}`
                )
            }
            if (brightness > MATROX_ENCODER_VIDEO_BRIGHTNESS_MAX) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `brightness must be less than or equal to ${MATROX_ENCODER_VIDEO_BRIGHTNESS_MAX}`
                )
            }

            if (contrast < MATROX_ENCODER_VIDEO_CONTRAST_MIN) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `contrast must be greater than or equal to ${MATROX_ENCODER_VIDEO_CONTRAST_MIN}`
                )
            }
            if (contrast > MATROX_ENCODER_VIDEO_CONTRAST_MAX) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `contrast must be less than or equal to ${MATROX_ENCODER_VIDEO_CONTRAST_MAX}`
                )
            }

            if (hue < MATROX_ENCODER_VIDEO_HUE_MIN) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `hue must be greater than or equal to ${MATROX_ENCODER_VIDEO_HUE_MIN}`
                )
            }

            if (hue > MATROX_ENCODER_VIDEO_HUE_MAX) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `hue must be less than or equal to ${MATROX_ENCODER_VIDEO_HUE_MAX}`
                )
            }

            if (saturation < MATROX_ENCODER_VIDEO_SATURATION_MIN) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `saturation must be greater than or equal to ${MATROX_ENCODER_VIDEO_SATURATION_MIN}`
                )
            }
            if (saturation > MATROX_ENCODER_VIDEO_SATURATION_MAX) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `saturation must be less than or equal to ${MATROX_ENCODER_VIDEO_SATURATION_MAX}`
                )
            }
        }
        const { followVideoSourceInput, videoLayout, videoSourceSettings, overriddenCanvasSettings } = processorSettings
        const canFollowVideoSourceInput = videoLayout === 'single'
        const numberOfVideoSources = videoLayout === 'single' ? 1 : 4

        if (!canFollowVideoSourceInput && followVideoSourceInput) {
            throw new BadRequestError(
                ErrorCode.badRequest,
                `'followVideoSourceInput' cannot be true when ingesting multiple video sources`
            )
        }

        if (videoSourceSettings?.length !== numberOfVideoSources) {
            throw new BadRequestError(
                ErrorCode.badRequest,
                `one videoSourceSettings-entry per video source must be supplied`
            )
        }
        for (const setting of videoSourceSettings) {
            validateVideoSourceSettings(setting)
        }

        if (!followVideoSourceInput) {
            if (!overriddenCanvasSettings) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `overriddenCanvasSettings must be supplied when 'followVideoSourceInput' is false`
                )
            }
            const {
                frameSize: { width, height },
                backgroundColor: { red, green, blue, alpha },
            } = overriddenCanvasSettings

            const minSize = MATROX_ENCODER_FRAMESIZE_MIN
            const maxSize = MATROX_ENCODER_FRAMESIZE_MAX
            if (width < minSize || width > maxSize) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `Frame size width must be between ${minSize}-${maxSize}`
                )
            }
            if (width % MATROX_ENCODER_FRAMEWIDTH_MULTIPLIER !== 0) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `Frame size width must be a multiple of ${MATROX_ENCODER_FRAMEWIDTH_MULTIPLIER}`
                )
            }
            if (height < minSize || height > maxSize) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `Frame size height must be between ${minSize}-${maxSize}`
                )
            }
            if (height % MATROX_ENCODER_FRAMEHEIGHT_MULTIPLIER !== 0) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `Frame size height must be a multiple of ${MATROX_ENCODER_FRAMEHEIGHT_MULTIPLIER}`
                )
            }
            const minRgb = 0
            const maxRgb = 255
            if ([red, green, blue, alpha].some((rgba) => rgba < minRgb || rgba > maxRgb)) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `backgroundColor RGBA values must be between ${minRgb}-${maxRgb}`
                )
            }
        }
    }

    function validateEncoderSettings(
        encoderSettings: MatroxEncoderSettings,
        processorPixelFormat: MatroxEncoderPixelFormat | undefined
    ) {
        function validateVideoSettings(
            videoSettings: MatroxEncoderSettings['videoSettings'],
            processorPixelFormat: MatroxEncoderPixelFormat | undefined
        ) {
            const {
                forceEncodingSize,
                forcedEncodingSettings,
                forcePixelFormat,
                forcedPixelFormat,
                encodingProfile,
                targetBitrateMbps,
                gop,
                rateControl,
            } = videoSettings

            if (forcePixelFormat) {
                if (!forcedPixelFormat) {
                    throw new BadRequestError(
                        ErrorCode.badRequest,
                        `forcedPixelFormat must be provided when 'forcePixelFormat' is true`
                    )
                }
            }
            // pixelFormat will be 'undefined' if processor::useVideoSourceSettings == true && encoder::forcePixelFormat == false.
            // If 'undefined' the pixel format being received on the SDI port will be used - we cannot validate this during creation since
            // we don't have access to that information.
            const selectedPixelFormat = forcePixelFormat ? forcedPixelFormat : processorPixelFormat
            if (selectedPixelFormat) {
                const allowedProfilesForPixelFormat = getCompatibleMatroxVideoProfilesForPixelFormat(
                    selectedPixelFormat
                )
                if (
                    allowedProfilesForPixelFormat.length > 0 &&
                    !allowedProfilesForPixelFormat.includes(encodingProfile)
                ) {
                    throw new BadRequestError(
                        ErrorCode.badRequest,
                        `The selected encoding profile '${encodingProfile}' is not compatible with the selected pixel format '${selectedPixelFormat}. Supported profiles are: ${allowedProfilesForPixelFormat.join(
                            ','
                        )}'`
                    )
                }
            } else {
                const requiredEncodingProfile = MatroxEncoderVideoProfile.High422
                if (encodingProfile !== requiredEncodingProfile) {
                    throw new BadRequestError(
                        ErrorCode.badRequest,
                        `The encoding profile '${encodingProfile}' must be: ${requiredEncodingProfile} when pixel format is unspecified (i.e. when applying the video input source settings)`
                    )
                }
            }

            if (forceEncodingSize) {
                if (!forcedEncodingSettings) {
                    throw new BadRequestError(
                        ErrorCode.badRequest,
                        `forcedEncodingSettings must be provided when 'forceEncodingSize' is true`
                    )
                }
                const {
                    frameSize: { width, height },
                } = forcedEncodingSettings
                if (width < MATROX_ENCODER_FRAMESIZE_MIN || width > MATROX_ENCODER_FRAMESIZE_MAX) {
                    throw new BadRequestError(
                        ErrorCode.badRequest,
                        `Frame size width must be between ${MATROX_ENCODER_FRAMESIZE_MIN}-${MATROX_ENCODER_FRAMESIZE_MAX}`
                    )
                }
                if (width % MATROX_ENCODER_FRAMEWIDTH_MULTIPLIER !== 0) {
                    throw new BadRequestError(
                        ErrorCode.badRequest,
                        `Frame size width must be a multiple of ${MATROX_ENCODER_FRAMEWIDTH_MULTIPLIER}`
                    )
                }
                if (height < MATROX_ENCODER_FRAMESIZE_MIN || height > MATROX_ENCODER_FRAMESIZE_MAX) {
                    throw new BadRequestError(
                        ErrorCode.badRequest,
                        `Frame size height must be between ${MATROX_ENCODER_FRAMESIZE_MIN}-${MATROX_ENCODER_FRAMESIZE_MAX}`
                    )
                }
                if (height % MATROX_ENCODER_FRAMEHEIGHT_MULTIPLIER !== 0) {
                    throw new BadRequestError(
                        ErrorCode.badRequest,
                        `Frame size height must be a multiple of ${MATROX_ENCODER_FRAMEHEIGHT_MULTIPLIER}`
                    )
                }
            }

            if (
                targetBitrateMbps < MATROX_ENCODER_TARGET_BITRATE_MIN ||
                targetBitrateMbps > MATROX_ENCODER_MAXBITRATE_MAX
            ) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `targetBitrateMbps must be between ${MATROX_ENCODER_TARGET_BITRATE_MIN}-${MATROX_ENCODER_MAXBITRATE_MAX}`
                )
            }

            const { iFrameInterval, pFrameInterval } = gop
            if (
                iFrameInterval < MATROX_ENCODER_IFRAME_INTERVAL_MIN ||
                iFrameInterval > MATROX_ENCODER_IFRAME_INTERVAL_MAX
            ) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `I-frame interval must be between ${MATROX_ENCODER_IFRAME_INTERVAL_MIN}-${MATROX_ENCODER_IFRAME_INTERVAL_MAX}`
                )
            }
            if (iFrameInterval % MATROX_ENCODER_IFRAME_INTERVAL_MULTIPLIER !== 0) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `I-frame interval must be a multiple of ${MATROX_ENCODER_IFRAME_INTERVAL_MULTIPLIER}`
                )
            }
            if (iFrameInterval < pFrameInterval) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `I-frame interval cannot be less than the P-frame interval`
                )
            }
            if (
                pFrameInterval < MATROX_ENCODER_PFRAME_INTERVAL_MIN ||
                pFrameInterval > MATROX_ENCODER_PFRAME_INTERVAL_MAX
            ) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `P-frame interval must be between ${MATROX_ENCODER_PFRAME_INTERVAL_MIN}-${MATROX_ENCODER_PFRAME_INTERVAL_MAX}`
                )
            }

            const { quantizationMin, quantizationMax, maxBitrateMbps } = rateControl
            if (quantizationMin < MATROX_ENCODER_QP_MIN) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `QP-min must be larger than or equal to ${MATROX_ENCODER_QP_MIN}`
                )
            }
            if (quantizationMax > MATROX_ENCODER_QP_MAX) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `QP-max must be smaller than or equal to ${MATROX_ENCODER_QP_MAX}`
                )
            }
            if (quantizationMin > quantizationMax) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `QP-min cannot be larger than QP-max (${quantizationMax}) and QP-max cannot be smaller than QP-min (${quantizationMin})`
                )
            }

            if (maxBitrateMbps < targetBitrateMbps) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `targetBitrateMbps cannot be greater than maxBitrateMbps`
                )
            }
            if (maxBitrateMbps < MATROX_ENCODER_MAXBITRATE_MIN || maxBitrateMbps > MATROX_ENCODER_MAXBITRATE_MAX) {
                throw new BadRequestError(
                    ErrorCode.badRequest,
                    `maxBitrateMbps must be between ${MATROX_ENCODER_MAXBITRATE_MIN}-${MATROX_ENCODER_MAXBITRATE_MAX}`
                )
            }
        }

        function validateAudioSettings(audioSettings: MatroxEncoderSettings['audioSettings']) {
            const { aacEncoder, bitrateKbps } = audioSettings

            const bitrateConstraints = getAllowedBitratesForMatroxAACEncoder(aacEncoder)
            if (bitrateConstraints) {
                const { minAllowedBitrateKbps, maxAllowedBitrateKbps } = bitrateConstraints
                if (bitrateKbps < minAllowedBitrateKbps || bitrateKbps > maxAllowedBitrateKbps) {
                    throw new BadRequestError(
                        ErrorCode.badRequest,
                        `bitrateKbps must be between ${minAllowedBitrateKbps}-${maxAllowedBitrateKbps} for aac encoder ${aacEncoder}`
                    )
                }
            }
        }
        validateVideoSettings(encoderSettings.videoSettings, processorPixelFormat)
        validateAudioSettings(encoderSettings.audioSettings)
    }

    function validateTSOutputSettings(outputStreamSettings: MatroxEncoderConfig['tsOutputSettings']) {
        if (
            outputStreamSettings.selectedAudioPairs.length < MATROX_ENCODER_MIN_ALLOWED_AUDIOSTREAMS ||
            outputStreamSettings.selectedAudioPairs.length > MATROX_ENCODER_MAX_ALLOWED_AUDIOSTREAMS
        ) {
            throw new BadRequestError(
                ErrorCode.badRequest,
                `Must select ${MATROX_ENCODER_MIN_ALLOWED_AUDIOSTREAMS}-${MATROX_ENCODER_MAX_ALLOWED_AUDIOSTREAMS} number of audio pairs`
            )
        }
    }

    validateProcessorSettings(encoderConfig.processorSettings)
    validateEncoderSettings(
        encoderConfig.encoderSettings,
        encoderConfig.processorSettings.followVideoSourceInput
            ? undefined
            : encoderConfig.processorSettings.overriddenCanvasSettings.pixelFormat
    )
    validateTSOutputSettings(encoderConfig.tsOutputSettings)
}

export function validateGeneralEncoderSettings(
    encoderFeatures: EncoderFeatures | undefined,
    encoderSettings: GeneralEncoderSettings
) {
    if (!encoderFeatures) {
        throw new BadRequestError(ErrorCode.badRequest, `Appliance does not have encoder support`)
    }
    const videoCodec = encoderSettings.videoCodec
    const supportedCodec = encoderFeatures.video.codecs.find(
        (supportedVideoCodec) => videoCodec == supportedVideoCodec.name
    )
    if (!supportedCodec) {
        throw new BadRequestError(ErrorCode.badRequest, `Appliance does not support video codec ${videoCodec}`)
    }
    if (supportedCodec.bitrate.max < encoderSettings.totalBitrate) {
        throw new BadRequestError(
            ErrorCode.badRequest,
            `Appliance supports a maximum bitrate of ${formatBitrate(
                supportedCodec.bitrate.max
            )} when using video codec ${videoCodec}`
        )
    }
    if (supportedCodec.bitrate.min > encoderSettings.totalBitrate) {
        throw new BadRequestError(
            ErrorCode.badRequest,
            `Appliance supports a minimum bitrate of ${formatBitrate(
                supportedCodec.bitrate.min
            )} when using video codec ${videoCodec}`
        )
    }

    if (encoderSettings.videoFlags) {
        for (const [videoFlag, enabled] of Object.entries(encoderSettings.videoFlags)) {
            const applianceFeatureEncoderVideoFlag = encoderFeatures.video.flags?.find(
                (encoderVideoFlag) => encoderVideoFlag.value === videoFlag
            )
            if (enabled && applianceFeatureEncoderVideoFlag?.disabledForCodecs.includes(videoCodec)) {
                throw new BadRequestError(ErrorCode.badRequest, `${videoCodec} does not support enabling ${videoFlag}.`)
            }
        }
    }

    if (encoderSettings.audioStreams.length > encoderFeatures.audio.maxAudioStreams) {
        throw new BadRequestError(
            ErrorCode.badRequest,
            `Appliance supports a maximum of ${encoderFeatures.audio.maxAudioStreams} audio streams`
        )
    }

    for (const audioStream of encoderSettings.audioStreams) {
        const supportedAudioCodec = encoderFeatures.audio.codecs.find(
            (audioCodec) => audioCodec.name == audioStream.codec
        )
        if (!supportedAudioCodec) {
            const indexOfAudioStream = encoderSettings.audioStreams.indexOf(audioStream)
            throw new BadRequestError(
                ErrorCode.badRequest,
                `Appliance does not support audio codec ${audioStream.codec} used by audio stream ${indexOfAudioStream +
                    1}`
            )
        }
        const supportedBitrates = supportedAudioCodec.bitratesKbps
        if (supportedBitrates.length > 0 && !supportedBitrates.includes(audioStream.bitrate)) {
            throw new BadRequestError(
                ErrorCode.badRequest,
                `Appliance only supports audio bitrates ${supportedBitrates.join(', ')} when using audio codec ${
                    audioStream.codec
                }`
            )
        }
        const supportedBitDepths = supportedAudioCodec.bitDepth ?? []
        if (
            audioStream.bitDepth &&
            supportedBitDepths.length > 0 &&
            !supportedBitDepths.includes(audioStream.bitDepth)
        ) {
            throw new BadRequestError(
                ErrorCode.badRequest,
                `Appliance only supports audio bit depths ${supportedBitDepths.join(', ')} when using audio codec ${
                    audioStream.codec
                }`
            )
        }
    }
}

export function isCorrectEncoderSettingsTypeForPortMode(encoderSettings: EncoderSettings | undefined, mode: PortMode) {
    if (!encoderSettings) {
        return false
    }
    switch (mode) {
        case MatroxPortMode.matroxSdi:
            return isMatroxEncoderConfig(encoderSettings)

        case CoaxPortMode.sdi: // fallthrough
        case CoaxPortMode.asi: // fallthrough
        case VideonPortMode.videonSdi: // fallthrough
        case VideonPortMode.videonHdmi: // fallthrough
        case VideonPortMode.videonAuto: // fallthrough
        case ComprimatoPortMode.comprimatoSdi:
        case ComprimatoPortMode.comprimatoNdi:
            return isGeneralEncoderSettings(encoderSettings)

        case IpPortMode.rist: // fallthrough
        case IpPortMode.udp: // fallthrough
        case IpPortMode.rtp: // fallthrough
        case IpPortMode.srt: // fallthrough
        case IpPortMode.zixi: // fallthrough
        case IpPortMode.rtmp: // fallthrough
        case IpPortMode.generator: // fallthrough
        case IpcPortMode.unix: // fallthrough
            return false
    }
}
