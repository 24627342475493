import { useEffect } from 'react'
import { FormikProps } from 'formik'
import { get } from 'lodash'
import validatejs from 'validate.js'

import { Address, ApplianceType, FecLimits, Output, OutputPortFec, RtpOutputPort } from 'common/api/v1/types'

import { Select, TextInput } from '../../../../common/Form'
import { createDefaultFiledValues, makeAddressOptions } from '../../../../../utils'
import { isVaApplianceType } from '../../../../appliances/utils'

const addFecValidator = (port: RtpOutputPort, fecMaxSize: number) => {
  validatejs.validators.fec = (value: number | '', { min, max }: { min: number; max: number; isRow: boolean }) => {
    if (value === '') return
    if (value < min || value > max) {
      return `Must be ${min} - ${max}`
    }
    if (port && port.fecRows && port.fecCols) {
      const val = port.fecRows * port.fecCols
      if (val > fecMaxSize) {
        return `FecRows multiplied by FecCols equals ${val}, which exceeds maximum value of ${fecMaxSize}`
      }
    }
    return undefined
  }
}

export enum RtpFields {
  sourceAddress = 'sourceAddress',
  address = 'address',
  port = 'port',
  fec = 'fec',
  fecRows = 'fecRows',
  fecCols = 'fecCols',
  ttl = 'ttl',
}
export const rtpDefaults = createDefaultFiledValues(Object.keys(RtpFields), [], { ttl: 64 })
export const getRtpFieldsToSave = () => [
  RtpFields.sourceAddress,
  RtpFields.address,
  RtpFields.port,
  RtpFields.fec,
  RtpFields.fecRows,
  RtpFields.fecCols,
  RtpFields.ttl,
]

interface RtpFormProps {
  form: FormikProps<Output>
  index: number
  applianceType: ApplianceType
  namePrefix: string
  addresses: Array<Address>
  allocatedPort?: { addresses: Address[]; portNumber: number }
  applianceFecLimits?: FecLimits
}
const RtpForm = ({
  form: { setFieldValue, values },
  index,
  namePrefix,
  applianceType,
  addresses,
  allocatedPort,
  applianceFecLimits,
}: RtpFormProps) => {
  const isFecSupported = isVaApplianceType(applianceType)
  const fecRowMin = applianceFecLimits ? applianceFecLimits.rowRange.min : 0
  const fecRowMax = applianceFecLimits ? applianceFecLimits.rowRange.max : 100
  const fecColMin = applianceFecLimits ? applianceFecLimits.colRange.min : 0
  const fecColMax = applianceFecLimits ? applianceFecLimits.colRange.max : 100
  const fecMaxSize = applianceFecLimits ? applianceFecLimits.maxSize : 99
  const ports: RtpOutputPort[] = get(values, namePrefix.substr(0, namePrefix.length - 2)) // _interfaceSection-xxx.ports.0 --> _interfaceSection-xxx.ports
  const rtpAddresses = { ports, index }
  addFecValidator(ports[0], fecMaxSize)

  const sourceAddressSelector = `${namePrefix}.${RtpFields.sourceAddress}`
  const selectedSourceAddress = get(values, sourceAddressSelector)

  const addressesAndEmptyAddress = [{ address: '', netmask: '' }, ...addresses]
  const addressOptions = [{ name: 'any', value: '' }, ...makeAddressOptions(selectedSourceAddress, addresses)]

  const allocatedAddress = allocatedPort?.addresses[0].address
  useEffect(() => {
    if (allocatedAddress && selectedSourceAddress !== allocatedAddress) {
      setFieldValue(sourceAddressSelector, allocatedAddress, false)
    }
  }, [setFieldValue, selectedSourceAddress, allocatedAddress, sourceAddressSelector])

  return (
    <>
      <Select
        name={sourceAddressSelector}
        label="Source Address"
        options={addressOptions}
        disabled={!!allocatedPort}
        newLine
        validators={{
          addressIn: { addresses: addressesAndEmptyAddress },
        }}
      />
      <TextInput
        name={`${namePrefix}.${RtpFields.address}`}
        label="Destination address"
        required
        type="text"
        newLine
        validators={{
          ip: {},
          rtpAddresses,
        }}
      />
      <TextInput
        name={`${namePrefix}.${RtpFields.port}`}
        label="Destination Port"
        required
        type="number"
        noNegative
        validators={{
          port: {},
          rtpAddresses,
        }}
      />
      <TextInput
        name={`${namePrefix}.${RtpFields.ttl}`}
        label="TTL"
        required
        type="number"
        validators={{ number: { greaterThanOrEqualTo: 1, lessThanOrEqualTo: 255 } }}
      />
      {isFecSupported && (
        <>
          <Select
            newLine
            label="Fec"
            name={`${namePrefix}.${RtpFields.fec}`}
            options={[{ name: 'none', value: undefined }, ...Object.values(OutputPortFec)]}
          />
          <TextInput
            newLine
            name={`${namePrefix}.${RtpFields.fecRows}`}
            label="Fec rows"
            type="number"
            validators={{
              fec: {
                min: fecRowMin,
                max: fecRowMax,
              },
            }}
          />
          <TextInput
            name={`${namePrefix}.${RtpFields.fecCols}`}
            label="Fec cols"
            type="number"
            validators={{
              fec: {
                min: fecColMin,
                max: fecColMax,
              },
            }}
          />
        </>
      )}
    </>
  )
}

export default RtpForm
