import React from 'react'

import { GridItem, Paper } from '../common/Form'
import DataSet from '../common/DataSet'
import { ExternalLink } from '../common/Link'
import { Service } from 'common/api/v1/types'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'

export interface MetaProps {
  service: Service
}

const volumeCapacityList = (capacity: string[] | undefined) => {
  if (!capacity) {
    return null
  }

  const tooltipTitle = (i: number) => 'Volume of service instance' + (capacity.length > 1 ? ` ${i + 1}` : '')
  return capacity.map((c, i) => (
    <Tooltip key={i} title={tooltipTitle(i)}>
      <Chip sx={{ marginRight: 1 }} label={c} size="small" />
    </Tooltip>
  ))
}

export const Meta: React.FunctionComponent<MetaProps> = ({ service }) => {
  const logs = {
    Logs: (
      <ExternalLink underline="always" href={service.filterUrl}>
        Logs
      </ExternalLink>
    ),
  }
  const meta = {
    Purpose: service.purpose,
    Product: service.product,
    ...logs,
    ...(service.allowsVolumeAdministration
      ? { 'Current volume capacity': volumeCapacityList(service.currentVolumeCapacity) }
      : {}),
    ...(service.allowsRetentionPeriodAdministration ? { 'Retention period': `${service.retentionPeriod} day(s)` } : {}),
  }

  return (
    <Paper title="Meta data" collapsible actionsPane={[]}>
      <GridItem lg={12} xl={12}>
        <DataSet values={meta} />
      </GridItem>
    </Paper>
  )
}
