import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import Container from '@mui/material/Container'

import { readAlarmsInBackground } from '../../redux/actions/alarmsActions'
import SidebarMenu from '../common/SidebarMenu'
import { Dashboard } from '../dashboards/serviceProvider'
import { Inputs } from '../inputs'
import { Groups } from '../groups'
import { Users } from '../users'
import { Interfaces } from '../interfaces'
import { Appliances } from '../appliances'
import { Outputs } from '../outputs'
import { Alarms } from '../alarms'
import { BillingReport } from '../billing'
import { Graphs } from '../graph'
import { Geo } from '../geo'
import { ServiceOverview } from '../serviceOverview'
import routes from '../../utils/routes'
import { Audit } from '../audit'
import { Regions } from '../regions'
import { Settings } from '../settings'
import { AddressMappings } from '../addressMappings'
import { UsageReport } from '../usage'
import ErrorBoundary from '../common/ErrorBoundary'
import { Nodes } from '../kubernetesNodes'
import { Networks } from '../networks'
import { Services } from '../services'
import { AppDispatch } from 'src/store'

export const NetworkManagerContainer = () => {
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    dispatch(readAlarmsInBackground({}))
  }, [dispatch])

  return (
    <>
      <SidebarMenu />
      <ErrorBoundary>
        <Container maxWidth={false} sx={{ maxWidth: '1920px', overflow: 'auto' }}>
          <Switch>
            <Redirect exact from="/" to={routes.overview()} />
            <Route path={routes.overview()} exact component={Dashboard} />
            <Route path={routes.graph()} exact component={Graphs} />
            <Route path={routes.geo()} exact component={Geo} />
            <Route path={routes.inputs()} component={Inputs} />
            <Route path={routes.outputs()} component={Outputs} />
            <Route path={routes.alarms()} component={Alarms} />
            <Route path={routes.service()} component={ServiceOverview} />
            <Redirect exact from={routes.settings()} to={routes.appliances()} />
            <Route path={routes.appliances()} component={Appliances} />
            <Route path={routes.users()} component={Users} />
            <Route path={routes.interfaces()} component={Interfaces} />
            <Route path={routes.networks()} component={Networks} />
            <Route path={routes.groups()} component={Groups} />
            <Route path={routes.billing()} component={BillingReport} />
            <Route path={routes.usage()} component={UsageReport} />
            <Route path={routes.audit()} component={Audit} />
            <Route path={routes.addressMappings()} component={AddressMappings} />
            <Route path={routes.regions()} component={Regions} />
            <Route path={routes.globalSettings()} component={Settings} />
            <Route path={routes.kubernetesNodes()} component={Nodes} />
            <Route path={routes.services()} component={Services} />
            <Route>
              <Redirect to={routes.overview()} />
            </Route>
          </Switch>
        </Container>
      </ErrorBoundary>
    </>
  )
}
