import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Form, FormikProps, useFormikContext } from 'formik'
import { format } from 'date-fns'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import {
  Appliance,
  EncoderFeatures,
  ExpFeatures,
  IpPortMode,
  PhysicalPort,
  PortMode,
  Region,
  VideoPreviewMode,
} from 'common/api/v1/types'

import { isEditableGroup, pluralizeWord, useUser } from '../../../utils'
import { AppDispatch, GlobalState, Api } from '../../../store'
import {
  Autocomplete,
  ButtonsPane,
  Checkbox,
  FormikErrorFocus,
  GridItem,
  Paper,
  SafeRouting,
  Select,
  TextInput,
} from '../../common/Form'

import { CheckboxProps } from '../../common/Form/Checkbox'

import { Checkbox as NonFormCheckbox } from '@mui/material'
import DataSet from '../../common/DataSet'
import { InputHealthIndicator } from '../../common/Indicator'
import { Link } from '../../common/Link'

import routes from '../../../utils/routes'
import EncoderSettings from './EncoderSettings/EncoderSettings'
import {
  CommonFields,
  generatorDefaults,
  ristDefaults,
  rtpDefaults,
  srtDefaults,
  udpDefaults,
  zixiDefaults,
} from './PortForm/IpPortForm'
import { EnrichedInputWithEnrichedPorts } from './index'
import { rtmpDefaults } from './PortForm/IpPortForm/RtmpForm'
import {
  collectInterfaceSectionEntries,
  collectPortsFromInterfaceSections,
  InterfaceSection,
  isAppliance,
  isApplianceOrRegionSelectable,
  isCoreNode,
  makeInterfaceSection,
} from '../../common/Interface/Base'
import { coreNodesList, getCoreNodesInput } from '../../common/Metadata'
import { DATE_FORMAT_LONG, getFormattedTransportStreamContent, tsInfoServiceName } from 'common/api/v1/helpers'
import { getSettings } from '../../../redux/actions/settingsActions'
import { isFeatureOn } from '../../../utils/features'
import { EnrichedApplianceWithOwner, EnrichedInput, EnrichedInputPort } from '../../../api/nm-types'
import { rerouteInput } from '../../../redux/actions/inputsActions'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { ndiDefaults } from './PortForm/NdiForm'
import { InputTransformationSection } from './InputTransformationSection'
import { enqueueErrorSnackbar } from '../../../redux/actions/notificationActions'
import * as uuid from 'uuid'
import { handoverMethodOptions } from '../../../texts'

const { inputApi } = Api

const MAX_MAX_BITRATE_MBPS = 2147

export const initialPort = ({
  physicalPortId,
  port,
  enforcedMode,
  allocatedPortId,
}: {
  physicalPortId: string
  port?: PhysicalPort
  enforcedMode?: IpPortMode
  allocatedPortId?: string
}) => ({
  ...udpDefaults,
  ...rtpDefaults,
  ...srtDefaults,
  ...ristDefaults,
  ...zixiDefaults,
  ...rtmpDefaults,
  ...generatorDefaults,
  ...ndiDefaults,
  _port: port,
  [CommonFields.mode]: enforcedMode ?? '',
  [CommonFields.physicalPort]: physicalPortId,
  [CommonFields.allocatedPortId]: allocatedPortId,
  // Provide an initial id to use as 'React.key'
  id: uuid.v4(),
})

const TsParsingMode = ({ disabled }: { disabled: boolean }) => {
  return (
    <Select
      label="Broadcast standard for transport stream analysis"
      name="broadcastStandard"
      disabled={disabled}
      options={[
        { name: 'DVB', value: 'dvb' },
        { name: 'ATSC', value: 'atsc' },
        { name: 'No analysis', value: 'none' },
      ]}
      tooltip="Choose broadcast standard for mpeg-ts parsing or disable analysis by choosing 'No analysis'."
    />
  )
}

const VideoPreviewModeSelect = () => {
  return (
    <Select
      label="Preview"
      name="videoPreviewMode"
      options={Object.values(VideoPreviewMode)}
      tooltip="'on demand' begins generating input preview when requested and is a more efficient alternative to 'always on', which constantly generates input preview and has a faster startup-time. No preview will be available if the input contains multiple video streams."
    />
  )
}

interface DeriveFromInputSelectProps {
  currentParentInput?: EnrichedInput
}

const DeriveFromInputSelect = ({ currentParentInput }: DeriveFromInputSelectProps) => {
  const form = useFormikContext<InputFormProps>()

  return (
    <Autocomplete<EnrichedInput>
      key={currentParentInput?.id}
      required={true}
      name="deriveFrom.parentInput"
      label="Input to derive from"
      formik={form}
      defaultOption={currentParentInput}
      tooltip="Search for the input to derive from. Other derived inputs are not shown here"
      api={async params => {
        // Fetch all inputs except derived ones
        const response = await inputApi.getInputs.bind(inputApi)({ ...params, derived: false })

        // Remove current form input from response
        const itemsWithoutSelf = response.items.filter(item => item.id !== form.values.id)
        return {
          items: itemsWithoutSelf,
          total: itemsWithoutSelf.length,
        }
      }}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      optionComparator={(o1, o2) => o1.id == o2.id}
      data-test-id="deriveFrom.parent"
    />
  )
}

interface DerivedInputProps {
  currentParentInput?: EnrichedInput
}

const DerivedInput = ({ currentParentInput }: DerivedInputProps) => {
  const { values } = useFormikContext<InputFormProps>()

  return (
    <>
      <Checkbox name="_derived" label="Derive from input" />
      {!!values._derived && <DeriveFromInputSelect currentParentInput={currentParentInput} />}
    </>
  )
}

const CheckBoxUncheckedWhenDisabled = (props: CheckboxProps) => {
  // If disabled show a plain checkbox not connected to formik state
  const control = props.disabled ? <NonFormCheckbox disabled checked={false} /> : undefined
  return <Checkbox {...props} control={control} />
}

export interface InputFormProps extends EnrichedInputWithEnrichedPorts {
  _derived: boolean
  _selectedApplianceOrRegionId?: string
  _selectedApplianceOrRegion?: EnrichedApplianceWithOwner | Region
}

export function getEncoderFeatures(
  appliance: Appliance | undefined,
  portMode: PortMode | undefined,
): EncoderFeatures | undefined {
  if (!portMode) {
    return undefined
  }
  return appliance?.features?.encoder?.[portMode]
}

const InputForm = (form: FormikProps<InputFormProps>) => {
  // 'setFieldValue' only triggers a re-render if the field's reference/pointer is changed/replaced (i.e. not on modifications to the existing reference)
  const { values, setStatus, setFieldValue, dirty, isSubmitting, setSubmitting, initialValues } = form
  const user = useUser()
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch<AppDispatch>()
  const { devMode, settings } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)
  const isCopyingExistingInput = match.path === routes.inputsCopy.route
  const isEditingExistingInput = !!values.id && !isCopyingExistingInput

  const [currentParentInput, setCurrentParentInput] = useState<EnrichedInput>()

  const { formErrors } = useSelector(({ inputsReducer }: GlobalState) => inputsReducer, shallowEqual)
  useEffect(() => {
    setStatus(
      Array.isArray(formErrors) ? formErrors.reduce((acc, item) => ({ ...acc, [item.name]: item.reason }), {}) : {},
    )
  }, [formErrors])

  const isSaving = useSelector(({ inputsReducer }: GlobalState) => inputsReducer.saving, shallowEqual)
  const isRerouting = useSelector(({ inputsReducer }: GlobalState) => inputsReducer.rerouting, shallowEqual)
  useEffect(() => {
    if (isSaving === false) setSubmitting(false)
  }, [isSaving])

  useEffect(() => {
    dispatch(getSettings())
  }, [])

  // Fetch current select parent input and save in local state to avoid fetching it multiple times in child components
  useEffect(() => {
    const parentInputId = form.values.deriveFrom?.parentInput
    if (!parentInputId) {
      setCurrentParentInput(undefined)
      return
    }

    let cancelled = false
    inputApi
      .getInput(parentInputId)
      .then(input => {
        if (cancelled) {
          // The useEffect cleanup has run and the component may have been unmounted
          return
        }

        setCurrentParentInput(input)
      })
      .catch(err => {
        dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch input to derive from' }))
      })

    return () => {
      cancelled = true
    }
  }, [values.deriveFrom?.parentInput, setFieldValue])

  // Switch broadcast standard to mirror the current selected parent input
  useEffect(() => {
    if (currentParentInput === undefined) {
      return
    }

    form.setFieldValue('broadcastStandard', currentParentInput.broadcastStandard)
    form.setFieldValue('handoverMethod', currentParentInput.handoverMethod)
  }, [currentParentInput, setFieldValue])

  const selectedInterfaces = collectPortsFromInterfaceSections(values)
  const coreNodes = getCoreNodesInput(values)

  const onRemoveInputAppliance = (key: string) => setFieldValue(key, undefined)
  const initialInterfaceSections = collectInterfaceSectionEntries(initialValues).map(([_k, value]) => value)
  const interfaceSectionEntries = collectInterfaceSectionEntries(values)

  const selectedInterfaceWithEncodingCapabilities = selectedInterfaces.find(
    i => getEncoderFeatures(i._port?._appliance, i.mode) !== undefined,
  )
  const encoderFeatures: EncoderFeatures | undefined = getEncoderFeatures(
    selectedInterfaceWithEncodingCapabilities?._port?._appliance,
    selectedInterfaceWithEncodingCapabilities?.mode,
  )
  const shouldShowEncoderSettings = !values._derived && selectedInterfaceWithEncodingCapabilities && encoderFeatures

  const interfaceSections = interfaceSectionEntries.map(([key, data], index) => {
    const isModeSelectionDisabled = data.ports.length > 1
    return (
      <InterfaceSection<EnrichedInputWithEnrichedPorts>
        key={key}
        namePrefix={key}
        index={index}
        initialApplianceOrRegionId={
          initialInterfaceSections[index]?.region?.id ?? initialInterfaceSections[index]?.appliance?.id
        }
        isModeSelectionDisabled={isModeSelectionDisabled}
        title={`Input appliance #${index + 1}`}
        onRemove={interfaceSectionEntries.length > 1 ? onRemoveInputAppliance : undefined}
        inputId={isEditingExistingInput ? values.id : undefined}
        outputId={undefined}
        isInputForm={true}
        enforcedPortMode={undefined}
        isEditingExistingEntity={isEditingExistingInput}
        isCopyingExistingEntity={isCopyingExistingInput}
        isApplianceOrRegionSelectable={applianceOrRegion => isApplianceOrRegionSelectable(applianceOrRegion, values)}
        onApplianceOrRegionSelected={selected => {
          if (!selected) {
            const [_, emptySection] = makeInterfaceSection({ region: undefined, appliance: undefined })
            setFieldValue(key, emptySection)
          } else if (isAppliance(selected)) {
            const [_, applianceSection] = makeInterfaceSection({ region: undefined, appliance: selected })
            setFieldValue(key, applianceSection)
          } else {
            const [_, regionalSection] = makeInterfaceSection({ region: selected, appliance: undefined })
            setFieldValue(key, regionalSection)
          }
        }}
        {...form}
      />
    )
  })

  const transportStreamContent = getFormattedTransportStreamContent((values.tsInfo || [])[0])
  const contentFormat =
    transportStreamContent == 'MPTS'
      ? 'MPTS'
      : `${transportStreamContent} (${tsInfoServiceName((values.tsInfo || [])[0])})`

  const getRedundancyTooltip = () => {
    if (isCoreNode(values)) {
      return 'Not possible to enable redundancy when using a core appliance for the input.'
    }

    if (values._derived) {
      return 'Not possible to enable redundancy for a derived input.'
    }

    return "Redundancy enabled will route the input stream through an additional path in the input appliance's region(s)."
  }

  const redundancyCheckboxDisabled = isCoreNode(values) || values._derived
  const showTransformationSection = values._derived && currentParentInput !== undefined
  const showInterfacesSection = !values._derived

  return (
    <Grid container data-test-input-id={`${values.id || ''}`}>
      <Grid item xs={12}>
        <SafeRouting enabled={dirty && !isSubmitting} />
        <Form id="input-form" translate="no" noValidate>
          <Paper className="outlined" title="Meta data" collapsible>
            <Paper>
              <TextInput name="name" label="Input name" required autoFocus />

              <Checkbox name="adminStatus" label="Enabled" />
              <CheckBoxUncheckedWhenDisabled
                name="_redundant"
                label="Redundant"
                disabled={redundancyCheckboxDisabled}
                tooltip={getRedundancyTooltip()}
                tooltipStyle={{ marginTop: 14, marginLeft: 0 }}
              />
              {devMode && (
                <Select
                  name="handoverMethod"
                  label="Handover method"
                  disabled={values._derived}
                  options={handoverMethodOptions}
                  tooltip="Select method of handing over streams between external protocol handlers and the RIST server"
                />
              )}

              <Checkbox name="thumbnailMode" label="Generate thumbnails" />
              {!!values.thumbnailMode && (
                // <Grid item xs={12}>
                <VideoPreviewModeSelect />
                // </Grid>
              )}
              <TextInput
                noNegative
                name="maxBitrateMbps"
                label="Max bitrate (Mbps)"
                validators={{
                  number: {
                    lessThanOrEqualTo: MAX_MAX_BITRATE_MBPS,
                    greaterThan: 0,
                    message: `Must be greater than zero and no more than ${MAX_MAX_BITRATE_MBPS}`,
                  },
                }}
                type="number"
                tooltip="Maximum bitrate allowed including retransmission. Packets exceeding the maximum bitrate will be dropped."
              />
              {devMode && <TextInput name="bufferSize" label="Buffer duration (ms)" required type="number" />}
              <TsParsingMode disabled={values._derived} />
              {settings && isFeatureOn(ExpFeatures.ExtMptsDerivedInput, settings) && (
                <DerivedInput currentParentInput={currentParentInput} />
              )}
            </Paper>
            {isEditingExistingInput && (
              <Paper>
                <GridItem lg={12} xl={12}>
                  <DataSet
                    values={{
                      Id: values.id,
                      Created: format(new Date(values.createdAt), DATE_FORMAT_LONG),
                      Updated: format(new Date(values.updatedAt), DATE_FORMAT_LONG),
                      Access: values.canSubscribe ? 'Full Access' : 'Preview',
                      Owner: !!values._owner?.id && (
                        <Link
                          to={routes.groupsUpdate({ id: values._owner.id })}
                          underline="hover"
                          available={isEditableGroup(values._owner.id, user)}
                        >
                          {values._owner?.name}
                        </Link>
                      ),
                      Status: <InputHealthIndicator inputId={values.id} inline />,
                      Format: contentFormat,
                      [`Core ${pluralizeWord(coreNodes.length, 'node')}`]: coreNodesList(coreNodes, user),
                    }}
                  />
                </GridItem>
              </Paper>
            )}
          </Paper>
          {showTransformationSection && <InputTransformationSection currentParentInput={currentParentInput} />}
          {showInterfacesSection && (
            <>
              {interfaceSections}
              {/* Only allow adding a second input appliance if the first one is a core node */}
              {interfaceSectionEntries.length < 2 && isCoreNode(values) && (
                <Button
                  sx={{ marginBottom: 2 }}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    const [key, value] = makeInterfaceSection({ region: undefined, appliance: undefined })
                    setFieldValue(key, value)
                  }}
                >
                  Add input appliance
                </Button>
              )}
            </>
          )}

          {shouldShowEncoderSettings && (
            <EncoderSettings
              namePrefix="encoderSettings"
              mode={selectedInterfaceWithEncodingCapabilities.mode}
              selectedInterfaces={selectedInterfaces as EnrichedInputPort[]}
              encoderSettings={values.encoderSettings}
              encoderFeatures={encoderFeatures}
              setFieldValue={setFieldValue}
            />
          )}
          <ButtonsPane
            main={{
              Cancel: {
                onClick: () => {
                  history.push(routes.inputs())
                },
              },
              Save: { savingState: isSaving, primary: true, type: 'submit' },
            }}
            secondary={
              isEditingExistingInput
                ? {
                    'Use as template': {
                      onClick: () => history.push(routes.inputsCopy({ id: values.id })),
                    },
                    ...(devMode
                      ? {
                          Reroute: {
                            onClick: () => void dispatch(rerouteInput(values.id)),
                            savingState: isRerouting,
                          },
                        }
                      : {}),
                  }
                : undefined
            }
          />
          <FormikErrorFocus />
        </Form>
      </Grid>
    </Grid>
  )
}

export default InputForm
