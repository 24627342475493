import { Autocomplete, Paper } from '../../common/Form'
import { PidMapTable } from './PidMapTable'
import { useFormikContext } from 'formik'
import { InputFormProps } from './InputForm'
import { useEffect } from 'react'
import { useState } from 'react'
import Pendable from '../../common/Pendable'
import { EnrichedInput } from '../../../api/nm-types'

interface InputTransformationSectionProps {
  currentParentInput: EnrichedInput
}

export const InputTransformationSection = ({ currentParentInput }: InputTransformationSectionProps) => {
  return (
    <Paper className="outlined" title="Transformations" collapsible>
      <Paper
        title="Service filter"
        tooltip="Services/programs selected here will be kept and all others will be removed.

      Constant bit rate transport streams will become variable bit rate transport streams when a service filter is applied."
      >
        <ServiceFilterSection currentParentInput={currentParentInput} />
      </Paper>
      <Paper
        title="PID remapping"
        tooltip="Note that PID remapping may cause constant bit rate transport streams to become variable bit rate transport streams.

      Null-packets will be kept in the stream in the absence of PID deletion rules. This means a constant bit rate
      stream will continue having constant bit rate. Note also that if this is combined with a service filter the
      null-packets will still be kept but the transport stream will have variable bit rate.
      "
      >
        <PidMapTable />
      </Paper>
    </Paper>
  )
}

interface ServiceFilterState {
  parentId: string
  tooltip: string
  defaultOptionIds: number[]
  items: ServiceFilterItem[]
}
interface ServiceFilterItem {
  name: string
  id: number
  disabled: boolean
}

interface ServiceFilterSectionProps {
  currentParentInput: EnrichedInput
}

const ServiceFilterSection = ({ currentParentInput }: ServiceFilterSectionProps) => {
  const fieldName = 'deriveFrom.ingestTransform.services'
  const form = useFormikContext<InputFormProps>()

  const [filterState, setFilterItem] = useState<ServiceFilterState>()

  useEffect(() => {
    // Create options from all services currently in tsInfo
    const inputTsInfoServices = currentParentInput.tsInfo?.[0].services ?? []
    const itemsInTsInfo: ServiceFilterItem[] = inputTsInfoServices
      .filter(x => x.id !== undefined)
      .map<ServiceFilterItem>(x => {
        const name = x.name ?? x.id!.toString()

        const res: ServiceFilterItem = {
          id: x.id!,
          name: name,
          disabled: false,
        }

        return res
      })

    // Create options for saved services not in tsInfo anymore
    const savedServiceIds = form.values?.deriveFrom?.ingestTransform?.services ?? []
    const itemsSavedButNotInTsInfo = savedServiceIds
      .filter(savedId => !itemsInTsInfo.some(x => x.id === savedId))
      .map(id => {
        const res: ServiceFilterItem = {
          id: id!,
          name: `${id} (removed)`,
          disabled: true,
        }

        return res
      })

    // ...and merge them
    const items = itemsInTsInfo.concat(itemsSavedButNotInTsInfo)

    // IDs of the currently saved services
    const defaultOptionIds = savedServiceIds

    const tooltip = itemsInTsInfo.length > 0 ? 'Choose which services to include' : 'No services found in stream'
    setFilterItem({
      parentId: currentParentInput.id,
      tooltip: tooltip,
      items: items,
      defaultOptionIds: defaultOptionIds,
    })
  }, [currentParentInput, form.setFieldValue])

  const loading = filterState === undefined

  if (loading) {
    return (
      <Pendable pending={loading}>
        <></>
      </Pendable>
    )
  }

  return (
    <Autocomplete<number>
      key={filterState.parentId}
      required={true}
      name={fieldName}
      label="Services"
      formik={form}
      tooltip={filterState.tooltip}
      defaultOption={filterState.defaultOptionIds}
      api={() =>
        Promise.resolve({
          items: filterState.items.map(x => x.id),
          total: filterState.items.length,
          filter: undefined,
        })
      }
      getOptionValue={option => option}
      getOptionLabel={option => filterState.items.find(x => x.id === option)?.name ?? ''}
      optionComparator={(o1, o2) => o1 == o2}
      getOptionDisabled={option => filterState.items.find(x => x.id === option)?.disabled ?? false}
      multiple
      xs={4}
    />
  )
}
