import Button from '@mui/material/Button'

import { ListGroupSortableField, Role } from 'common/api/v1/types'

import { pluralize, useGroupsSelector, usePageParamsFilteredSelector, isEditableGroup, useUser } from '../../utils'
import Table, { MoveToEntity, TableConfig } from '../common/Table'
import { MissingContent } from '../common/MissingContent'
import Wrapper from '../common/Wrapper'
import { Link } from '../common/Link'
import routes from '../../utils/routes'
import { EnrichedGroup } from '../../api/nm-types'

export const GroupsList = () => {
  const { groups, total, loading } = usePageParamsFilteredSelector(useGroupsSelector)
  const user = useUser()

  const tableConfig: TableConfig<EnrichedGroup, ListGroupSortableField> = [
    {
      title: 'name',
      getValue: ({ name }) => name,
      sorting: { byParameter: ListGroupSortableField.groupName },
    },
    {
      title: 'users',
      getValue: ({ id, _userNumber }) => (isEditableGroup(id, user) ? pluralize(_userNumber, 'user') : null),
      sorting: { byParameter: ListGroupSortableField.numberOfUsers, hidden: user.role !== Role.super },
    },
    {
      title: 'appliances',
      getValue: ({ id, _applianceNumber }) =>
        isEditableGroup(id, user) ? pluralize(_applianceNumber, 'appliance') : null,
      sorting: { byParameter: ListGroupSortableField.numberOfAppliances, hidden: user.role !== Role.super },
    },
    {
      title: 'interfaces',
      getValue: ({ id, _interfaceNumber }) =>
        isEditableGroup(id, user) ? pluralize(_interfaceNumber, 'interface') : null,
      sorting: { byParameter: ListGroupSortableField.numberOfPorts, hidden: user.role !== Role.super },
    },
    {
      getValue: ({ id }) => (
        <MoveToEntity url={routes.groupsUpdate({ id })} disabled={!isEditableGroup(id, user)} entityName="group" />
      ),
      props: { align: 'right' },
    },
  ]
  return (
    <Wrapper name="Groups">
      <Table<EnrichedGroup>
        emptyMessageComponent={
          <MissingContent message="No groups available" buttonText="Add group" url={routes.groupsNew()} />
        }
        config={tableConfig}
        data={groups}
        id="groups-table"
        pending={loading}
        actions={[
          <Link key="manageGroupLists" type="button" to={routes.groupLists()}>
            <Button variant="outlined" color="primary">
              Manage group lists
            </Button>
          </Link>,
        ].concat(
          user.role === Role.super
            ? [
                <Link key="addGroup" id="groups-table-create-button" to={routes.groupsNew()}>
                  <Button variant="contained" color="primary">
                    Add group
                  </Button>
                </Link>,
              ]
            : [],
        )}
        pagination={{ total, useUrlSearchParams: true }}
      />
    </Wrapper>
  )
}
