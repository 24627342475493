import { Theme } from '@mui/material/styles'

import { Paper } from '../../common/Form'
import Wrapper from '../../common/Wrapper'
import Filter from './Filter'
import Summary from './Summary'

const classes = {
  paper: {
    padding: (theme: Theme) => theme.spacing(1, 2, 3),
    marginBottom: (theme: Theme) => theme.spacing(2),
    overflowX: 'auto',
  },
}

const Report = () => {
  return (
    <Wrapper name="Billing">
      <Paper sx={classes.paper} title="Period">
        <Filter />
      </Paper>
      <Paper sx={classes.paper} title="Monthly Billing">
        <Summary />
      </Paper>
    </Wrapper>
  )
}

export default Report
