import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'

import {
  ActiveService,
  ActiveServiceInput,
  ActiveServiceOutput,
  Appliance,
  GeoCoreNode,
  Role,
  User,
} from 'common/api/v1/types'
import { setSelectedActiveServices } from '../../../redux/actions/activeServicesActions'
import { MapElement } from './utils'
import { isInput, useUser, isCoreNode } from '../../../utils'
import { AppDispatch, GlobalState } from '../../../store'
import { Link } from '../../common/Link'
import routes from '../../../utils/routes'

const styles = {
  root: {
    maxWidth: '24px',
    minWidth: '24px',
    color: (theme: Theme) => theme.palette.primary.light,
    display: 'flex',
    justifyContent: 'center',
  },
  input: {
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    border: (theme: Theme) => `3px solid ${theme.palette.primary.main}`,
    background: (theme: Theme) => theme.palette.common.white,
  },
  output: {
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    border: (theme: Theme) => `3px solid ${theme.palette.primary.light}`,
    background: (theme: Theme) => theme.palette.primary.light,
  },
}

interface PopupProps {
  elements: MapElement
  coreNodes: Array<GeoCoreNode>
}
/**
 * Popup to show input/outputs and let select it
 * @param inputs
 * @param outputs
 * @param coreNodes - if we have coreNodes on the same location (for example when inputs are direct)
 * @constructor
 */
const Popup = ({ elements: { inputs = {}, outputs = {} }, coreNodes }: PopupProps) => {
  const { selected, activeServices } = useSelector((state: GlobalState) => state.activeServiceReducer, shallowEqual)
  const dispatch = useDispatch<AppDispatch>()
  const user = useUser()
  const setSelected = (services: Array<ActiveService>) => dispatch(setSelectedActiveServices(services))
  const onOutputClick = (output: ActiveServiceOutput) => () => {
    setSelected([output])
  }
  const onInputClick = (input: ActiveServiceInput) => () => {
    setSelected(
      activeServices.filter(item => {
        if (isInput(item)) return item.id === input.id
        return item.activeServiceInput.id === input.id
      }),
    )
  }

  return (
    <List dense disablePadding>
      {Object.values(outputs).map(output => (
        <ListItem button disableGutters key={output.id} onClick={onOutputClick(output)}>
          <ListItemIcon sx={styles.root}>
            <Box sx={styles.output} />
          </ListItemIcon>
          <ListItemText>{output.name}</ListItemText>
        </ListItem>
      ))}
      {Object.values(inputs).map(input => (
        <ListItem button disableGutters key={input.id} onClick={onInputClick(input)}>
          <ListItemIcon sx={styles.root}>
            <Box sx={styles.input} />
          </ListItemIcon>
          <ListItemText>{input.name}</ListItemText>
        </ListItem>
      ))}
      {coreNodes.map(coreNode => (
        <ListItem disableGutters key={coreNode.id}>
          <ListItemIcon sx={styles.root}>
            <div />
          </ListItemIcon>
          <ListItemText>
            <Link
              to={routes.appliancesUpdate({ id: coreNode.id })}
              underline="hover"
              available={user.role === Role.super}
            >
              <Typography variant="body2">{coreNode.name}</Typography>
            </Link>
          </ListItemText>
        </ListItem>
      ))}
      {!!selected.length && (
        <Button variant="outlined" size="small" onClick={() => setSelected([])}>
          clear selected
        </Button>
      )}
    </List>
  )
}
/**
 * Showing single appliance popup
 * @param name - appliance name
 * @param id - appliance id for the link
 * @constructor
 */
export const AppliancePopup = ({ name, id, linkAvailable }: { name: string; id: string; linkAvailable: boolean }) => (
  <Link to={routes.appliancesUpdate({ id })} underline="hover" available={linkAvailable}>
    <Typography component="div" variant="body2">
      {name}
    </Typography>
  </Link>
)

const isGeoCoreNode = (appliance: Appliance | GeoCoreNode): appliance is GeoCoreNode => isCoreNode(appliance.type)

/**
 * List of appliances popup
 * @param appliances
 * @constructor
 */
export const AppliancesPopup = ({ appliances, user }: { appliances: Array<Appliance | GeoCoreNode>; user: User }) => (
  <List dense disablePadding>
    {appliances.map(appliance => {
      const { name, id } = appliance
      let hasAccessToNode = false

      if (isGeoCoreNode(appliance)) {
        hasAccessToNode = user.role === Role.super
      } else {
        const applianceOwner = typeof appliance.owner === 'string' ? appliance.owner : appliance.owner.id
        hasAccessToNode = user.role === Role.super || user.group === applianceOwner
      }

      return (
        <ListItem disableGutters key={`appliance-popup-${id}`}>
          <ListItemText>
            <AppliancePopup name={name} id={id} linkAvailable={hasAccessToNode} />
          </ListItemText>
        </ListItem>
      )
    })}
  </List>
)

export default Popup
