import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { ListResult, Output, OutputInit, OutputRecipientList, User } from 'common/api/v1/types'

import { WithRedirectBack } from '.'
import {
  EnrichedOutput,
  EnrichedOutputWithPorts,
  OutputsRequestParams,
  PaginatedRequestParams,
} from '../../api/nm-types'
import { ThunkApi } from '../../store'
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from './notificationActions'
import routes from '../../utils/routes'
import { getCurrentPage, pluralize, sleep, withDefaultPagination } from '../../utils'
import { getLocationParams } from '../../utils/params'
import { ApplicationException } from '../../components/common/ApplicationException'

export enum DraftActions {
  delete = 'delete',
  disable = 'disable',
}

export interface Draft {
  outputs: Array<Output>
  action?: DraftActions
}

export const draftOutputs = createAction<{
  action?: DraftActions
  outputs: Array<Output>
}>('outputs/draftOutputs')

export const createOutput = createAsyncThunk<void, { output: OutputInit } & WithRedirectBack, ThunkApi>(
  'outputs/createOutput',
  async ({ output, redirect }, { dispatch, extra: { api, history } }) => {
    try {
      await api.outputApi.createOutput(output)
      redirect && history().push(routes.outputs())
      dispatch(enqueueSuccessSnackbar(`Created output: ${output.name}`))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'create output' }))
      throw err
    }
  },
)

export const updateOutput = createAsyncThunk<EnrichedOutput, { output: Output } & WithRedirectBack, ThunkApi>(
  'outputs/updateOutput',
  async ({ output, redirect }, { dispatch, extra: { api, history } }) => {
    try {
      const res = await api.outputApi.updateOutput(output.id, output)
      redirect && history().push(routes.outputs())
      dispatch(enqueueSuccessSnackbar(`Edited output: ${output.name}`))
      if (getCurrentPage() === routes.outputs()) {
        dispatch(getOutputs(withDefaultPagination(getLocationParams() as Partial<OutputsRequestParams>)))
      }
      return res
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'update output' }))
      throw err
    }
  },
)

export const removeOutput = createAsyncThunk<void, { output: Output } & WithRedirectBack, ThunkApi>(
  'outputs/removeOutput',
  async ({ output, redirect }, { dispatch, extra: { api, history } }) => {
    try {
      await api.outputApi.removeOutput(output.id)
      redirect && history().push(routes.outputs())
      dispatch(enqueueSuccessSnackbar(`Removed output: ${output.name}`))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'delete output' }))
      throw err
    }
  },
)

export const removeOutputs = createAsyncThunk<void, Array<Output['id']>, ThunkApi>(
  'outputs/removeOutputs',
  async (ids, { dispatch, extra: { api } }) => {
    try {
      const deleteResult = await api.outputApi.removeOutputs(ids)
      const notDeleted = deleteResult.notDeleted.length
      if (notDeleted > 0) {
        const errorMessage = `Failed to delete ${notDeleted} ${pluralize(
          notDeleted,
          'output',
        )}. Try deleting each output individually to see the error message.`
        dispatch(
          enqueueErrorSnackbar({
            error: new ApplicationException({
              details: errorMessage,
              text: errorMessage,
              errorCode: '',
              fatal: false,
              origin: undefined,
            }),
            operation: `delete ${pluralize(notDeleted, 'output')}`,
          }),
        )
      } else {
        dispatch(enqueueSuccessSnackbar(`${pluralize(ids.length, 'Output')} removed`))
      }

      dispatch(getOutputs(withDefaultPagination(getLocationParams()) as Partial<OutputsRequestParams>))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: `delete ${pluralize(ids.length, 'output')}` }))
      throw err
    }
  },
)

let hasStartedFetchingOutputs = false
export const registerOutputObserver = createAsyncThunk<void, { outputId: string }, ThunkApi>(
  'outputs/registerOutputObserver',
  async (_, { dispatch, getState }) => {
    if (!hasStartedFetchingOutputs) {
      hasStartedFetchingOutputs = true
      // eslint-disable-next-line no-constant-condition
      while (true) {
        await sleep(10 * 1000)
        try {
          if ((getState().userReducer.user as User).id && !getState().userReducer.changingUser) {
            // Await so it doesn't queue up requests if the backend is slow, now it will sleep 10s after each fetch
            await dispatch(getBareOutputs())
          }
        } catch {
          // nop
        }
      }
    }
  },
)
export const unregisterOutputObserver = createAction<{ outputId: string }>('outputs/unregisterOutputObserver')

export const getBareOutputs = createAsyncThunk<ListResult<Output>, void, ThunkApi>(
  'outputs/getBareOutputs',
  async (_, { getState, extra: { api } }) => {
    const outputIds = Object.keys(getState().outputsReducer.outputsToObserve)
    if (outputIds.length > 0) {
      return await api.outputApi.getBareOutputs({ ids: outputIds })
    } else {
      return { total: 0, items: [] }
    }
  },
)

export const getOutputs = createAsyncThunk<ListResult<EnrichedOutput>, Partial<OutputsRequestParams>, ThunkApi>(
  'outputs/getOutputs',
  async (params, { dispatch, extra: { api } }) => {
    try {
      return await api.outputApi.getOutputs(withDefaultPagination(params))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch outputs' }))
      throw err
    }
  },
)

export const getOutputsWithLists = createAsyncThunk<
  ListResult<EnrichedOutput | OutputRecipientList>,
  Partial<PaginatedRequestParams>,
  ThunkApi
>('outputs/getOutputsWithLists', async (params, { dispatch, extra: { api } }) => {
  try {
    return await api.outputApi.getOutputsWithLists(withDefaultPagination(params))
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch outputs and lists' }))
    throw err
  }
})

export const getOutput = createAsyncThunk<EnrichedOutputWithPorts, Output['id'], ThunkApi>(
  'outputs/getOutput',
  async (id, { dispatch, extra: { api } }) => {
    try {
      return await api.outputApi.getOutput(id)
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch output' }))
      throw err
    }
  },
)

export const getTotalOutputCount = createAsyncThunk<number, void, ThunkApi>(
  'outputs/getTotalOutputCount',
  async (_, { dispatch, extra: { api } }) => {
    try {
      return await api.outputApi.getTotalOutputCount()
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch output count' }))
      throw err
    }
  },
)

export const enableOutputs = createAsyncThunk<Output[], Output['id'][], ThunkApi>(
  'outputs/enableOutputs',
  async (ids, { dispatch, extra: { api } }) => {
    try {
      const res = await api.outputApi.enableOutputs(ids)

      dispatch(enqueueSuccessSnackbar(`Output${ids.length > 1 ? 's' : ''} enabled`))
      return res
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'enable outputs' }))
      throw err
    }
  },
)

export const disableOutputs = createAsyncThunk<Output[], Output['id'][], ThunkApi>(
  'outputs/disableOutputs',
  async (ids, { dispatch, extra: { api } }) => {
    try {
      const res = await api.outputApi.disableOutputs(ids)
      dispatch(enqueueSuccessSnackbar(`Output${ids.length > 1 ? 's' : ''} disabled`))
      return res
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'disable outputs' }))
      throw err
    }
  },
)

export const setInputOfOutput = createAsyncThunk<
  EnrichedOutput,
  { output: Output; inputId: Output['input'] },
  ThunkApi
>('outputs/setInputOfOutput', async ({ output, inputId }, { dispatch, extra: { api } }) => {
  try {
    const res = await api.outputApi.setInputOfOutput(output.id, inputId)
    const msg = inputId ? `Switched input of '${output.name}'` : `Cleared input of '${output.name}'`
    dispatch(enqueueSuccessSnackbar(msg))
    return res
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'switch input of output' }))
    throw err
  }
})

export const clearOutput = createAction('outputs/clearOutput')
export const clearOutputs = createAction('outputs/clearOutputs')
