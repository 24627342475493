import { useEffect, useRef, useState } from 'react'
import { AreaSeries, Hint, LineSeries, makeWidthFlexible, MarkSeries, XYPlot } from 'react-vis'
import { format } from 'date-fns'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { useTheme, Theme } from '@mui/material/styles'

import { BitrateSample, MaxBitrates, Role } from 'common/api/v1/types'
import { Api } from '../../../store'
import { NimbraBlue } from '../../../themes/common'

import Pendable from '../../common/Pendable'
import { useUser } from '../../../utils'

const HEIGHT = 140

let maxIngressVal = 10
let maxEgressVal = 10

const transformData = ({ inputMaxBitrates, outputMaxBitrates }: MaxBitrates) => {
  const egress = outputMaxBitrates.map(([x, y]) => {
    if (y > maxEgressVal) maxEgressVal = y
    return { x: Number(new Date(x)), y, y0: 0 }
  })
  const ingress = inputMaxBitrates.map(([x, y]) => {
    if (y > maxIngressVal) maxIngressVal = y
    return { x: Number(new Date(x)), y: -y, y0: 0 }
  })
  return { ingress, egress }
}

const transformBitsToMegabits = (bits: number) => bits / 1024 / 1024
const getHoverValue = (p: DataPoint, reverse: boolean) =>
  `${format(new Date(p.x), 'MMM dd HH:mm')} — ${transformBitsToMegabits(p.y * (reverse ? -1 : 1)).toFixed(3)} Mbps`

const styles = {
  empty: {
    margin: 'auto',
    padding: (theme: Theme) => theme.spacing(8, 0, 6),
  },
  container: {
    position: 'relative',
    width: '100%',
    height: `${HEIGHT}px`,
  },
  hintContent: {
    background: (theme: Theme) => theme.palette.background.default,
    padding: (theme: Theme) => theme.spacing(0, 1.5),
    lineHeight: '1.2rem',
    borderRadius: '2px',
    fontSize: (theme: Theme) => theme.typography.caption.fontSize,
  },
}

const FlexibleWidthXYPlot = makeWidthFlexible(XYPlot)

type DataPoint = { x: number; y: BitrateSample[1] }
type Data = {
  egress: Array<DataPoint>
  ingress: Array<DataPoint>
}
const Graph = () => {
  const user = useUser()
  const titlePrefix = user.role === Role.super ? 'Total' : 'Group'
  const [hoveredEgress, setHoveredEgress] = useState<false | DataPoint>(false)
  const [hoveredIngress, setHoveredIngress] = useState<false | DataPoint>(false)
  const [data, setData] = useState<false | Data>(false)
  const timer = useRef<NodeJS.Timeout>()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Api.bitratesApi.readBitrates()
        setData(transformData(res))
      } catch (error) {
        setData(false)
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    timer.current = setInterval(fetchData, 1000 * 60)
    fetchData()
    return () => {
      if (timer.current) clearInterval(timer.current)
    }
  }, [])
  const theme = useTheme()
  const egressColor = theme.palette.primary.main
  const ingressColor = NimbraBlue[100]

  return data && !data.egress.length && !data.ingress.length ? (
    <Box sx={styles.empty}>
      <Typography variant="h2" align="center">
        Bitrate overview
      </Typography>
      <Typography variant="body1" align="center">
        There is currently no ingress or egress related to this group.
      </Typography>
    </Box>
  ) : (
    <>
      <Toolbar disableGutters variant="dense">
        <Typography variant="h2">
          {data && data.egress && !data.egress.length ? 'Currently no egress' : `${titlePrefix} egress`}
        </Typography>
      </Toolbar>

      <Box sx={styles.container}>
        <Pendable pending={!data}>
          {data && (
            <FlexibleWidthXYPlot
              height={HEIGHT}
              margin={{ left: 0, right: 0, top: 0, bottom: 0 }}
              onMouseLeave={() => {
                setHoveredEgress(false)
                setHoveredIngress(false)
              }}
              yDomain={[-maxIngressVal, maxEgressVal]}
            >
              <AreaSeries
                onNearestX={(d: DataPoint) => setHoveredEgress(d)}
                data={data.egress}
                style={{
                  fill: egressColor,
                  fillOpacity: 0.5,
                  stroke: egressColor,
                  strokeWidth: 2,
                }}
              />
              <AreaSeries
                onNearestX={(d: DataPoint) => setHoveredIngress(d)}
                data={data.ingress}
                style={{
                  fill: ingressColor,
                  fillOpacity: 0.5,
                  stroke: ingressColor,
                  strokeWidth: 2,
                }}
              />
              {hoveredEgress && (
                <MarkSeries data={[hoveredEgress]} size="3" fill={egressColor} stroke={theme.palette.common.white} />
              )}
              {hoveredEgress && (
                <LineSeries data={[hoveredEgress, { x: hoveredEgress.x, y: 0 }]} stroke={egressColor} strokeWidth="1" />
              )}
              {hoveredEgress && (
                <Hint value={hoveredEgress} align={{ horizontal: 'auto', vertical: 'top' }}>
                  <Box sx={styles.hintContent}>{getHoverValue(hoveredEgress, false)}</Box>
                </Hint>
              )}
              {hoveredIngress && (
                <MarkSeries data={[hoveredIngress]} size="3" fill={ingressColor} stroke={theme.palette.common.white} />
              )}
              {hoveredIngress && (
                <LineSeries
                  data={[hoveredIngress, { x: hoveredIngress.x, y: 0 }]}
                  stroke={ingressColor}
                  strokeWidth="1"
                />
              )}
              {hoveredIngress && (
                <Hint value={hoveredIngress} align={{ horizontal: 'auto', vertical: 'bottom' }}>
                  <Box sx={styles.hintContent}>{getHoverValue(hoveredIngress, true)}</Box>
                </Hint>
              )}
            </FlexibleWidthXYPlot>
          )}
        </Pendable>
      </Box>

      <Toolbar disableGutters variant="dense">
        <Typography variant="h2">
          {data && data.ingress && !data.ingress.length ? 'Currently no ingress' : `${titlePrefix} ingress`}
        </Typography>
      </Toolbar>
    </>
  )
}

export default Graph
