import {
    Alarm,
    ApplianceVersion,
    BroadcastStandard,
    CoaxPortMode,
    GeneratorFrameRate,
    GeneratorResolutionPreset,
    GeneratorScanMode,
    GeneratorTimestampResolution,
    IpPortMode,
    MatroxDecoderConfig,
    MatroxPortMode,
    IpcPortMode,
    Output3gLevel,
    OutputPortFec,
    RistMetricType,
    RistOutputMetricType,
    RistProfile,
    RistSimpleProfileMetricType,
    SrtKeylen,
    SrtMode,
    SrtRateLimiting,
    Tr101290,
    VaObject,
    VideonPortMode,
    ZixiDecryptType,
    ZixiFeederSinkStats,
    ZixiLinkMode,
    ZixiLinkSet,
    ZixiMode,
    ComprimatoPortMode,
    IngestTransform,
} from './api/v1/types'
import { ApplianceConfiguration, BackendIoType } from './messages'

import { UdpOutputStatusCode } from './rist'
import { TransportStream } from './tr101Types'
import { SrtStats, SrtStreamTags } from './srt'
import { RtmpInputStats, RtmpOutputStats, RtmpStreamTags } from './rtmp'
import { RequestResult } from './network/request-options'

export interface Message<T, Type extends string> {
    type: Type
    data: T
}

export type RtmpInputStatsMessage = Message<{ tags: RtmpStreamTags; stats: RtmpInputStats }, 'rtmpInputStats'>
export type RtmpOutputStatsMessage = Message<{ tags: RtmpStreamTags; stats: RtmpOutputStats }, 'rtmpOutputStats'>

export type SrtStatsMessage = Message<{ tags: SrtStreamTags; stats: SrtStats }, 'srtStats'>
export type IpConfigMessage = Message<Array<VaIpConfig | IfConfig>, 'ipConfig'>
export type BasicInfoMessage = Message<Partial<VAProductInfo>, 'basicInfo'>

export interface ZixiFeederChannelStats {
    channelId: number
    // zixiFeederInput: ZixiFeederInputStats
    zixiFeederOutput: ZixiFeederOutputStats[]
}

export interface ZixiReceiverChannelStats {
    channelId: number
    zixiReceiverInput: ZixiReceiverInputStats[]
}

export interface ZixiFeederOutputStats {
    streamId: number
    outputId: string
    bitrate: number
    connectionStatus: 'Connected' | 'Connecting'
    error: string
    sinkStats: ZixiFeederSinkStats
}

export interface ZixiReceiverInputStats {
    streamId: number
    bitrate: number
    rtt?: number
    connectionStatus: 'Online' | 'Reconnecting'
    error: string
}

export interface ZixiFeederStats {
    channels: ZixiFeederChannelStats[]
}
export interface ZixiReceiverStats {
    channels: ZixiReceiverChannelStats[]
}

export type ApplianceConfigurationMessage = Message<ApplianceConfiguration, 'nodeConfiguration'>

export interface ApplianceRegistrationResponse {
    id: string
    zixiFeederKey: string
    zixiReceiverKey: string
}

// Many of these features require the kernel to support Linux capabilities.
// To check for support, you can use the docker info command.
// If a capability is disabled in your kernel, you may see a warning at the end of the output like the following: "WARNING: No swap limit support"
export interface DockerMemoryConfig {
    // Hard limit. The maximum amount of memory the container can use. The minimum allowed value is 6 megabytes.
    memory?: string
    // The amount of memory this container is allowed to swap to disk.
    memorySwap?: string
    // The host kernel can swap out a percentage of anonymous pages used by a container.
    memorySwappinessPercentage?: string
    // A soft limit (i.e. no guarantees) smaller than --memory which is activated when Docker detects contention or low memory on the host machine.
    memoryReservation?: string
    // The maximum amount of kernel memory the container can use. The minimum allowed value is 4m.
    kernelMemory?: string
    // Set this flag to prevent the kernel from killing processes in the container. Only disable the OOM killer on containers where you have also set the -m/--memory option.
    oomKillDisable?: boolean
}
export interface DockerConfig {
    registryUrl: string
    dataImage: string
    dataVersion: string
    memoryConfig?: DockerMemoryConfig
}

export type ConfigMessage = ApplianceConfigurationMessage

export type ApplianceCommandResult = ApplianceCommandSuccessResult | ApplianceCommandErrorResult
export interface ApplianceCommandSuccessResult {
    success: true
    result: any
    command: ApplianceCommand
}
export interface ApplianceCommandErrorResult {
    success: false
    errorMessage: string
    command: ApplianceCommand
}
export interface ApplianceCommandBase<T extends string> {
    id: string
    name: T
}
export interface UpgradeCommand extends ApplianceCommandBase<'upgrade'> {
    name: 'upgrade'
    updateUrl: string
}

export interface RunToolCommand extends ApplianceCommandBase<'runTool'> {
    name: 'runTool'
    command: string
    arguments: string[]
}

export interface OpenRpcChannelCommand extends ApplianceCommandBase<'openRpcChannel'> {
    name: 'openRpcChannel'
}

export interface RestartEdgeControlCommand extends ApplianceCommandBase<'restartEdgeControl'> {
    name: 'restartEdgeControl'
}

export interface RestartEdgeDataCommand extends ApplianceCommandBase<'restartEdgeData'> {
    name: 'restartEdgeData'
}

export type ApplianceCommand =
    | UpgradeCommand
    | RunToolCommand
    | OpenRpcChannelCommand
    | RestartEdgeControlCommand
    | RestartEdgeDataCommand

export type ApplianceCommandType = ApplianceCommand['name']

export interface ApplianceCommandInit {
    name: ApplianceCommandType
}

export interface VAProductInfo {
    serial: string
    prodName: string
    prodVersion: string | ApplianceVersion
    prodReadableName: string
    name: string
    location: string
    contact: string
}

export interface PortDbConfigurationBase {
    copies?: number
    priority?: number
}

export interface UdpPortDbConfigurationBase extends PortDbConfigurationBase {
    mode: IpPortMode
    localIp: string
    localPort: number
    publicIp?: string
    multicastAddress?: string
    multicastSource?: string
}

export interface RistSimpleProfileOutputPortDbConfiguration extends UdpPortDbConfigurationBase {
    mode: IpPortMode.rist
    profile: RistProfile.simple
    remoteIp: string
    remotePort: number
    region?: { id: string; name: string }
}

export interface RistSimpleProfileInputPortDbConfiguration extends UdpPortDbConfigurationBase {
    mode: IpPortMode.rist
    profile: RistProfile.simple
    whitelistCidrBlock?: string
    region?: { id: string; name: string }
}

export interface GeneratorInputPortDbConfiguration extends Omit<UdpPortDbConfigurationBase, 'localPort'> {
    mode: IpPortMode.generator
    audioOnly?: boolean
    frameRate?: GeneratorFrameRate
    resolution?: GeneratorResolutionPreset
    scanMode?: GeneratorScanMode
    timestampResolution?: GeneratorTimestampResolution
    localPort?: number
}

export interface UdpInputPortDbConfiguration extends UdpPortDbConfigurationBase {
    mode: IpPortMode.udp
    whitelistCidrBlock?: string
    region?: { id: string; name: string }
    ingestTransform?: IngestTransform
    failoverPriority?: number
}

export interface RtmpInputPortDbConfiguration extends UdpPortDbConfigurationBase {
    mode: IpPortMode.rtmp
    whitelistCidrBlock?: string
    region?: { id: string; name: string }
}

export interface UnixInputPortDbConfiguration extends PortDbConfigurationBase {
    mode: IpcPortMode.unix
    localPath: string
    ingestTransform?: { type: 'mpts-demux'; services: number[] }
}

export interface UnixOutputPortDbConfiguration extends PortDbConfigurationBase {
    mode: IpcPortMode.unix
    remotePath: string
}

export interface UdpOutputPortDbConfiguration extends UdpPortDbConfigurationBase {
    mode: IpPortMode.udp
    ttl?: number
    remoteIp: string
    remotePort: number
    region?: { id: string; name: string }
    services?: number[]
    mptsDestination?: string
}

export interface RtmpOutputPortDbConfiguration extends PortDbConfigurationBase {
    mode: IpPortMode.rtmp
    rtmpDestinationAddress: string
    region?: { id: string; name: string }
}

export interface RtpInputPortDbConfiguration extends UdpPortDbConfigurationBase {
    mode: IpPortMode.rtp
    rtp?: boolean
    fec?: boolean
    whitelistCidrBlock?: string

    // Used to distinguish priority between non-binary-equal RTP streams.
    // If omitted the streams must be binary-equal and will be used for Dash7 redundancy.
    failoverPriority?: number
}

export interface RtpOutputPortDbConfiguration extends Omit<UdpOutputPortDbConfiguration, 'mode'> {
    mode: IpPortMode.rtp
    fec?: OutputPortFec
    fecRows?: number
    fecColumns?: number
    region?: { id: string; name: string }
}

export interface SrtPortDbConfigurationBase extends PortDbConfigurationBase {
    mode: IpPortMode.srt
    srtMode: SrtMode
    latency: number
    ipttl?: number
    mss?: number
    passphrase?: string
    region?: { id: string; name: string }
}

export interface SrtOutputPortDbConfigurationBase extends SrtPortDbConfigurationBase {
    pbkeylen: SrtKeylen
    rateLimiting: SrtRateLimiting
    inputBw?: number
    maxBw?: number
    oheadBw?: number
}

export interface SrtListenerOutputPortDbConfiguration extends SrtOutputPortDbConfigurationBase {
    srtMode: SrtMode.listener
    localIp: string
    localPort: number
    whitelistCidrBlock?: string
}
export interface SrtCallerOutputPortDbConfiguration extends SrtOutputPortDbConfigurationBase {
    srtMode: SrtMode.caller
    localPort?: number
    remoteIp: string
    remotePort: number
    streamId?: string
}
export interface SrtRendezvousOutputPortDbConfiguration extends SrtOutputPortDbConfigurationBase {
    srtMode: SrtMode.rendezvous
    localIp: string
    remoteIp: string
    remotePort: number
    whitelistCidrBlock?: string
}

export type SrtOutputPortDbConfiguration =
    | SrtListenerOutputPortDbConfiguration
    | SrtCallerOutputPortDbConfiguration
    | SrtRendezvousOutputPortDbConfiguration

export const isSrtListenerOutputPortDbConfiguration = (
    cfg: SrtOutputPortDbConfiguration
): cfg is SrtListenerOutputPortDbConfiguration => cfg.srtMode === SrtMode.listener
export const isSrtCallerOutputPortDbConfiguration = (
    cfg: SrtOutputPortDbConfiguration
): cfg is SrtCallerOutputPortDbConfiguration => cfg.srtMode === SrtMode.caller
export const isSrtRendezvousOutputPortDbConfiguration = (
    cfg: SrtOutputPortDbConfiguration
): cfg is SrtRendezvousOutputPortDbConfiguration => cfg.srtMode === SrtMode.rendezvous

// VaSrtInputPortDbConfiguration contains fields only used in VAs...
export interface VaSrtInputPortDbConfiguration extends SrtPortDbConfigurationBase {
    reducedBitrateDetection: boolean
    reducedBitrateThreshold?: number
    unrecoveredPacketsDetection: boolean
    unrecoveredPacketsThreshold?: number
}

export interface SrtListenerInputPortDbConfiguration extends VaSrtInputPortDbConfiguration {
    srtMode: SrtMode.listener
    localIp: string
    localPort: number
    whitelistCidrBlock?: string
}
export interface SrtCallerInputPortDbConfiguration extends VaSrtInputPortDbConfiguration {
    srtMode: SrtMode.caller
    // Used to enforce the local outgoing port
    localPort?: number
    remoteIp: string
    remotePort: number
    // Currently only used for EdgeConnect
    streamId?: string
}
export interface SrtRendezvousInputPortDbConfiguration extends VaSrtInputPortDbConfiguration {
    srtMode: SrtMode.rendezvous
    localIp: string
    remoteIp: string
    remotePort: number
    whitelistCidrBlock?: string
}

export interface MatroxSdiInputPortDbConfiguration extends PortDbConfigurationBase {
    mode: MatroxPortMode.matroxSdi
}

export type MatroxInputPortDbConfiguration = MatroxSdiInputPortDbConfiguration

export interface MatroxSdiOutputPortDbConfiguration extends PortDbConfigurationBase {
    mode: MatroxPortMode.matroxSdi
    decoderSettings: MatroxDecoderConfig
}

export type MatroxOutputPortDbConfiguration = MatroxSdiOutputPortDbConfiguration

export interface VideonInputPortDbConfigurationBase<T extends VideonPortMode> extends PortDbConfigurationBase {
    mode: T
}

export interface VideonSdiInputPortDbConfiguration
    extends VideonInputPortDbConfigurationBase<VideonPortMode.videonSdi> {}
export interface VideonHdmiInputPortDbConfiguration
    extends VideonInputPortDbConfigurationBase<VideonPortMode.videonHdmi> {}
export interface VideonAutoInputPortDbConfiguration
    extends VideonInputPortDbConfigurationBase<VideonPortMode.videonAuto> {}

export type VideonInputPortDbConfiguration =
    | VideonSdiInputPortDbConfiguration
    | VideonHdmiInputPortDbConfiguration
    | VideonAutoInputPortDbConfiguration

export interface ComprimatoInputPortDbConfigurationBase<T extends ComprimatoPortMode> extends PortDbConfigurationBase {
    mode: T
}

export interface ComprimatoSdiInputPortDbConfiguration
    extends ComprimatoInputPortDbConfigurationBase<ComprimatoPortMode.comprimatoSdi> {}
export interface ComprimatoNdiInputPortDbConfiguration
    extends ComprimatoInputPortDbConfigurationBase<ComprimatoPortMode.comprimatoNdi> {
    name: string
}
export type ComprimatoInputPortDbConfiguration =
    | ComprimatoSdiInputPortDbConfiguration
    | ComprimatoNdiInputPortDbConfiguration

export interface ComprimatoOutputPortDbConfigurationBase<T extends ComprimatoPortMode> extends PortDbConfigurationBase {
    mode: T
}

export interface ComprimatoNdiOutputPortDbConfiguration
    extends ComprimatoOutputPortDbConfigurationBase<ComprimatoPortMode.comprimatoNdi> {
    name: string
}

export interface ComprimatoSdiOutputPortDbConfiguration
    extends ComprimatoOutputPortDbConfigurationBase<ComprimatoPortMode.comprimatoSdi> {}

export type ComprimatoOutputPortDbConfiguration =
    | ComprimatoSdiOutputPortDbConfiguration
    | ComprimatoNdiOutputPortDbConfiguration

export type SrtInputPortDbConfiguration =
    | SrtListenerInputPortDbConfiguration
    | SrtCallerInputPortDbConfiguration
    | SrtRendezvousInputPortDbConfiguration

export const isSrtListenerInputPortDbConfiguration = (
    cfg: SrtInputPortDbConfiguration
): cfg is SrtListenerInputPortDbConfiguration => cfg.srtMode === SrtMode.listener
export const isSrtCallerInputPortDbConfiguration = (
    cfg: SrtInputPortDbConfiguration
): cfg is SrtCallerInputPortDbConfiguration => cfg.srtMode === SrtMode.caller
export const isSrtRendezvousInputPortDbConfiguration = (
    cfg: SrtInputPortDbConfiguration
): cfg is SrtRendezvousInputPortDbConfiguration => cfg.srtMode === SrtMode.rendezvous

export interface ZixiPortDbConfigurationBase extends PortDbConfigurationBase {
    mode: IpPortMode.zixi
    zixiMode: ZixiMode
    streamId: string
    password?: string
    unrecoveredPacketsDetection: boolean
    unrecoveredPacketsThreshold?: number
}

export interface ZixiInputPortDbConfigurationBase extends ZixiPortDbConfigurationBase {
    decryptKey?: string
    decryptType: ZixiDecryptType
    reducedBitrateDetection: boolean
    reducedBitrateThreshold?: number
}

interface ZixiFecSettingsDbConfiguration {
    fecLatency?: number
    optimizeFec?: boolean
    maxFecOverhead?: number
}
export interface ZixiPullInputPortDbConfiguration
    extends ZixiInputPortDbConfigurationBase,
        ZixiFecSettingsDbConfiguration {
    zixiMode: ZixiMode.pull
    retransmitBuf: number
    remotePrimaryIp: string
    remoteSecondaryIp?: string
    localIp?: string
    pullPort: number
    region?: { id: string; name: string }
}
export interface ZixiPushInputPortDbConfiguration extends ZixiInputPortDbConfigurationBase {
    zixiMode: ZixiMode.push
}

export interface ZixiPullOutputPortDbConfiguration extends ZixiPortDbConfigurationBase {
    zixiMode: ZixiMode.pull
}
export interface ZixiPushOutputPortDbConfiguration extends ZixiPortDbConfigurationBase, ZixiFecSettingsDbConfiguration {
    zixiMode: ZixiMode.push
    linkMode: ZixiLinkMode
    linkSet1: ZixiLinkSet
    linkSet2?: ZixiLinkSet
    retransmitBuf: number
    // maxBitrateMbps is only applicable for zixi-feeder-push outputs, which can currently only be set up on core appliances
    maxBitrateMbps?: number
    region?: { id: string; name: string }
}

export type ZixiInputPortDbConfiguration = ZixiPullInputPortDbConfiguration | ZixiPushInputPortDbConfiguration
export type ZixiOutputPortDbConfiguration = ZixiPullOutputPortDbConfiguration | ZixiPushOutputPortDbConfiguration

export type UdpPortDbConfiguration = UdpInputPortDbConfiguration | UdpOutputPortDbConfiguration
export type SrtPortDbConfiguration = VaSrtInputPortDbConfiguration | SrtOutputPortDbConfiguration

export type SdiInputDbConfiguration = SdiDbConfiguration
export interface SdiOutputDbConfiguration extends SdiDbConfiguration {
    output3gLevel?: Output3gLevel
}
export interface SdiDbConfiguration extends PortDbConfigurationBase {
    mode: CoaxPortMode.sdi
}
export type AsiInputDbConfiguration = AsiDbConfiguration
export type AsiOutputDbConfiguration = AsiDbConfiguration
export interface AsiDbConfiguration extends PortDbConfigurationBase {
    mode: CoaxPortMode.asi
}

export type CoaxOutputPortDbConfiguration = AsiOutputDbConfiguration | SdiOutputDbConfiguration

export type IpcOutputPortDbConfiguraiton = UnixOutputPortDbConfiguration

export type IpOutputPortDbConfiguration =
    | UdpOutputPortDbConfiguration
    | RtpOutputPortDbConfiguration
    | SrtOutputPortDbConfiguration
    | ZixiOutputPortDbConfiguration
    | RistSimpleProfileOutputPortDbConfiguration

export type OutputPortDbConfiguration =
    | CoaxOutputPortDbConfiguration
    | IpOutputPortDbConfiguration
    | RtmpOutputPortDbConfiguration
    | MatroxOutputPortDbConfiguration
    | ComprimatoOutputPortDbConfiguration
    | IpcOutputPortDbConfiguraiton

export type CoaxInputPortDbConfiguration = AsiInputDbConfiguration | SdiInputDbConfiguration

export type IpInputPortDbConfiguration =
    | UdpInputPortDbConfiguration
    | RtpInputPortDbConfiguration
    | RtmpInputPortDbConfiguration
    | SrtInputPortDbConfiguration
    | ZixiInputPortDbConfiguration
    | RistSimpleProfileInputPortDbConfiguration
    | GeneratorInputPortDbConfiguration

export type IpcInputPortDbConfiguration = UnixInputPortDbConfiguration

export type InputPortDbConfiguration =
    | CoaxInputPortDbConfiguration
    | IpInputPortDbConfiguration
    | IpcInputPortDbConfiguration
    | VideonInputPortDbConfiguration
    | MatroxInputPortDbConfiguration
    | ComprimatoInputPortDbConfiguration

type StringPropertiesAsNumbers<T extends object> = {
    [k in keyof T]: T[k] extends object ? StringPropertiesAsNumbers<T[k]> : T[k] extends string ? number : T[k]
}

export interface Tr101290Statistics extends StringPropertiesAsNumbers<Tr101290> {
    channelId: number
    type: string
}

export interface TsInfo {
    channelId: number
    type: BackendIoType
    broadcastStandard: BroadcastStandard
    stream: TransportStream
}

export interface VaEthMedia {
    active: string
    current: string
}

export interface VaDhcp {
    curNetmask: string
    curInet: string
}

interface IfConfigAddress {
    address: string
    netmask?: string
    publicAddress?: string
    interRegionPublicAddress?: string
    internalAddress?: string
}
export interface IfConfig {
    name: string
    mac: string
    addresses: IfConfigAddress[]
}

export enum PortDirection {
    input = 'input',
    output = 'output',
}
export interface MatroxCoaxPortConfiguration {
    isVirtualPort?: boolean
    direction: PortDirection
}
export interface CoaxPort<T = any> {
    modes: {
        type: CoaxPortMode
        name: string
        encode?: boolean
    }[]
    name: string
    portIndex: string
    configuration?: T
}

export interface VaIpConfig {
    adminStatus: string
    name: string
    mac: string
    operStatus: string
    media: VaEthMedia
    mtu: string
    dhcp: VaDhcp
    addresses: { address: string; netmask: string; publicAddress?: string }[]
    id: string
}

export interface ObjectStats {
    objects: VaObject[]
    alarms: Alarm[]
}

export type JsonRpcMessage = JsonRpcRequest | JsonRpcResponse | JsonRpcNotification
export interface JsonRpcRequest {
    id: number
    method: string
    params: any
}
export type WithJsonRpcId<T extends Omit<JsonRpcRequest, 'id'>> = T & Pick<JsonRpcRequest, 'id'>
export interface JsonRpcNotification {
    method: string
    params: any
}

export type JsonRpcResponse = JsonRpcSuccessResponse | JsonRpcErrorResponse
export interface JsonRpcErrorResponse {
    id: number
    error: {
        code: number
        message: string
        data?: any
    }
}

export enum JsonRpcErrorResponseCode {
    unknownMethod = 1,
    requestError = 2,
    applianceNotConnected = 20,
    invalidMethod = 10,
    noSuchBrowserSession = 30,
}

export interface JsonRpcSuccessResponse {
    id: number
    result: RequestResult<any>
}

export interface InfluxRistChannelStatsResult {
    sampledAt: Date
    applianceName: string
    applianceType: string
    applianceId: string
    type: RistMetricType | RistSimpleProfileMetricType
    inputId: string
    clientApplianceId?: string
    serverApplianceId?: string
}

export interface InfluxRistChannelOutputStatsResult extends InfluxRistChannelStatsResult {
    outputId?: string
    isTr101290Stream?: 'true' | 'false'
    isEgress?: 'true' | 'false'
    bytesSent: number
    packetsSent: number
    packetsLost: number
    sendBitrate: number
    streamId: string
    type: RistOutputMetricType
    udpOutputStatus?: UdpOutputStatusCode
}

export interface MptsDemuxStatusDiff {
    streams: StreamStatusDiff[]
}

export interface OutputStatusDiff {
    outputId: number
    counters: OutputCounters
}

interface OutputCounters {
    udpPackets: number
    tsPackets: number
    udpPacketsRate: number
    tsPacketsRate: number
}

export interface StreamStatusDiff {
    streamId: number
    counters: StreamCounters
    outputs: OutputStatusDiff[]
}

interface StreamCounters {
    udpPackets: number
    truncatedBytes: number
    tsPackets: number
    udpPacketsRate: number
    truncatedBytesRate: number
    tsPacketsRate: number
}
