import { canonicalize } from './canonicalize'

import { VaPipeBase } from './messages'
import {
    AncSettings,
    Appliance,
    ApplianceType,
    InputInit,
    InputUpdate,
    Smpte2038Configuration,
    Tr101290 as EdgeTr101290,
} from './api/v1/types'
import { VaSmpte2038Configuration } from './oamTypes'
import { toBooleanString } from './util'
import { exec, ExecOptions } from 'child_process'

// 1 = TS_sync_loss, 2 = Sync_byte_error, 3 = PAT_error, 5 = Continuity_count_error, 6 = PMT_error, 8 = PID_error
type VaTr101Prio1Indicator = 1 | 2 | 3 | 5 | 6 | 8
type VaTr101290Prio1 = { [k in VaTr101Prio1Indicator]: VaTr101290Counter }

// 1 = Transport_error, 2 = CRC_error, 3 = PCR_error, 6 = PCR_accuracy_error, 7 = PTS_error, 8 = CAT_error
type VaTr101Prio2Indicator = 1 | 2 | 3 | 6 | 7 | 8
type VaTr101290Prio2 = { [k in VaTr101Prio2Indicator]: VaTr101290Counter }

interface VaTr101290Counter {
    indicator: string
    count: number
    timestamp: string
}

export interface VaTr101290 {
    prio1: VaTr101290Prio1
    prio2: VaTr101290Prio2
}

export function inputRedundancy({ ports }: Pick<InputInit | InputUpdate, 'ports'>) {
    return ((ports && ports[0] && ports[0].copies) || 1) > 1
}

export function edgifyVaTr101(tr101: VaTr101290): EdgeTr101290 {
    return {
        prio1: {
            TS_sync_loss: tr101.prio1['1'].count,
            Sync_byte_error: tr101.prio1['2'].count,
            PAT_error: tr101.prio1['3'].count,
            Continuity_count_error: tr101.prio1['5'].count,
            PMT_error: tr101.prio1['6'].count,
            PID_error: tr101.prio1['8'].count,
        },
        prio2: {
            Transport_error: tr101.prio2['1'].count,
            CRC_error: tr101.prio2['2'].count,
            PCR_error: tr101.prio2['3'].count,
            PCR_accuracy_error: tr101.prio2['6'].count,
            PTS_error: tr101.prio2['7'].count,
            CAT_error: tr101.prio2['8'].count,
        },
    }
}

export async function sleep(ms: number) {
    return new Promise<void>((res) => {
        setTimeout(res, ms)
    })
}

export function group<T>(items: T[], selector: (t: T) => string): { [key: string]: T[] } {
    return items.reduce((acc, cur) => {
        const v = selector(cur)
        if (typeof acc[v] === 'undefined') {
            acc[v] = []
        }
        acc[v].push(cur)
        return acc
    }, {} as { [key: string]: T[] })
}

export function canonicalizePortConfig(p: VaPipeBase) {
    return canonicalize({ ...p, meta: void 0 })
}

export function isVaAppliance(appliance: Appliance) {
    return isVaApplianceType(appliance.type)
}

export type VaApplianceType =
    | ApplianceType.nimbraVA220
    | ApplianceType.nimbraVA225
    | ApplianceType.nimbra410
    | ApplianceType.nimbra412
    | ApplianceType.nimbra414
    | ApplianceType.nimbraVAdocker

export function isVaApplianceType(applianceType: ApplianceType): applianceType is VaApplianceType {
    return [
        ApplianceType.nimbraVA220,
        ApplianceType.nimbraVA225,
        ApplianceType.nimbra410,
        ApplianceType.nimbra412,
        ApplianceType.nimbra414,
        ApplianceType.nimbraVAdocker,
    ].includes(applianceType)
}

export function isMatroxApplianceType(
    applianceType: ApplianceType
): applianceType is
    | ApplianceType.matroxMonarchEdgeE4_8Bit
    | ApplianceType.matroxMonarchEdgeE4_10Bit
    | ApplianceType.matroxMonarchEdgeD4
    | ApplianceType.matroxMonarchEdgeS1 {
    return [
        ApplianceType.matroxMonarchEdgeE4_8Bit,
        ApplianceType.matroxMonarchEdgeE4_10Bit,
        ApplianceType.matroxMonarchEdgeD4,
        ApplianceType.matroxMonarchEdgeS1,
    ].includes(applianceType)
}

export function toVaBooleanString(b: boolean) {
    if (b) {
        return 'yes'
    }
    return 'no'
}

export function formatVaSmpte2038(smpte2038: Smpte2038Configuration): VaSmpte2038Configuration {
    const anc = (Object.keys(smpte2038) as Array<keyof Smpte2038Configuration>).reduce((acc, setting) => {
        acc[setting] = (Object.keys(smpte2038[setting]) as Array<keyof AncSettings>).reduce((settingObj, flag) => {
            settingObj[flag] = toBooleanString(smpte2038[setting][flag])
            return settingObj
        }, {} as { [key in keyof AncSettings]: 'yes' | 'no' })
        return acc
    }, {} as { [key in keyof Smpte2038Configuration]: { [key in keyof AncSettings]: 'yes' | 'no' } })
    return anc
}

export function pick<T, K extends keyof T>(t: T, ...pickKeys: K[]) {
    if (!t) {
        return t
    }
    const o: Partial<T> = {}
    for (const k of pickKeys) {
        o[k] = t[k]
    }
    return o as Pick<T, K>
}

export function pickNonNullish<T, K extends keyof T>(t: T, ...pickKeys: K[]) {
    const o: Partial<T> = {}
    for (const k of pickKeys) {
        const val = t[k]
        if (val == null) {
            continue
        }
        o[k] = t[k]
    }
    return o as Pick<T, K>
}

type RequiredKeys<T> = { [K in keyof T]-?: {} extends Pick<T, K> ? never : K }[keyof T]
export function valuesToString<T>(t: T) {
    const v = {} as Record<RequiredKeys<T>, string>
    for (const k of Object.keys(t) as Array<keyof typeof v>) {
        v[k] = `${t[k]}`
    }
    return v
}

export type StringifyValues<T> = {
    [Property in keyof T]: string
}

export function generateRandomMacAddress() {
    return 'XX:XX:XX:XX:XX:XX'.replace(/X/g, () => Math.floor(Math.random() * 16).toString(16))
}

export type CommandResult<T> = SuccessResult<T> | ErrorResult
export type SuccessResult<T> = [null, T]
export type ErrorResult = [Error, null]

function maybeBufferToUtf8(b: Buffer | string) {
    if (Buffer.isBuffer(b)) {
        return b.toString('utf8')
    }
    return b
}

export function execAsync(cmd: string, opts: ExecOptions = {}) {
    return new Promise<CommandResult<{ stderr: string; stdout: string }>>((res) => {
        exec(cmd, opts, (err, stdout, stderr) => {
            if (err) {
                return res([err, null])
            }
            return res([null, { stdout: maybeBufferToUtf8(stdout), stderr: maybeBufferToUtf8(stderr) }])
        })
    })
}

export const distinct = <T>(array: T[], equals: (v1: T, v2: T) => boolean = (v1, v2) => v1 === v2): T[] => {
    return array.reduce((acc, cur) => {
        if (!acc.find((v) => equals(v, cur))) {
            acc.push(cur)
        }
        return acc
    }, [] as T[])
}
