import { ExpFeatures, GlobalSettings } from 'common/api/v1/types'

const featureNames: { [k in ExpFeatures]: string } = {
  ExtMptsDerivedInput: 'Create derived inputs',
  OASResponseValidation: 'OAS response validation',
}

export function getExperimentalDevFeatures(): { name: string; label: string }[] {
  return Object.keys(ExpFeatures)
    .filter(key => !key.startsWith('Ext'))
    .map(key => ({
      name: key,
      label: key,
    }))
}

export function getExperimentalExternalFeatures(): { name: string; label: string }[] {
  return Object.keys(ExpFeatures)
    .filter(key => key.startsWith('Ext'))
    .map(key => ({
      name: key,
      label: featureNames[key as ExpFeatures] || key.replace(/^Ext/, ''),
    }))
}

export function isFeatureOn(feature: ExpFeatures, settings: GlobalSettings): boolean {
  return !!settings.expFeatures[feature]
}
