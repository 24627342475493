import IconButton from '@mui/material/IconButton'
import MuiLink from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import { OpenInNew, FiberManualRecord as Circle, HelpOutline } from '@mui/icons-material'
import { Theme } from '@mui/material/styles'

import { Service, ListServiceSortableField } from 'common/api/v1/types'
import { usePageParamsFiltered } from '../../utils'
import Table, { MoveToEntity, TableConfig } from '../common/Table'
import { MissingContent } from '../common/MissingContent'
import Wrapper from '../common/Wrapper'
import routes from '../../utils/routes'
import { useAsync } from 'react-async-hook'
import { getServices } from '../../redux/actions/serviceActions'
import { ServicesRequestParams } from '../../api/nm-types'
import { useDispatch } from 'react-redux'
import { Api, AppDispatch } from '../../store'

const { serviceApi } = Api

const Toggle = ({ value }: { value: boolean }) => {
  return value ? <Circle /> : null
}

const styles = {
  icon: {
    marginLeft: (theme: Theme) => theme.spacing(1),
    transform: 'translateY(3px)',
  },
}

export const ListView = () => {
  const dispatch = useDispatch<AppDispatch>()
  const searchParams = usePageParamsFiltered<ServicesRequestParams>()
  const services = useAsync(() => getServices(searchParams, { dispatch, serviceApi }), [JSON.stringify(searchParams)])

  const tableConfig: TableConfig<Service, ListServiceSortableField> = [
    {
      title: 'purpose',
      getValue: ({ purpose, description }) => (
        <div>
          {purpose}
          {description && (
            <Tooltip title={description} placement="top">
              <HelpOutline sx={styles.icon} />
            </Tooltip>
          )}
        </div>
      ),
      sorting: { byParameter: ListServiceSortableField.purpose },
    },
    {
      title: 'product',
      getValue: ({ product }) => product,
      sorting: { byParameter: ListServiceSortableField.product },
    },
    {
      title: 'collect logs',
      getValue: ({ collectLogs }) => <Toggle value={collectLogs} />,
    },
    {
      getValue: svc => {
        return (
          <div style={{ display: 'flex' }}>
            <MoveToEntity url={routes.servicesUpdate({ id: svc.id })} entityName="service" />

            {svc.filterUrl && (
              <Tooltip title="View logs" placement="top">
                <span>
                  <MuiLink href={svc.filterUrl} target="_blank">
                    <IconButton edge="end" aria-label="View logs">
                      <OpenInNew />
                    </IconButton>
                  </MuiLink>
                </span>
              </Tooltip>
            )}
          </div>
        )
      },
      props: { align: 'right' },
    },
  ]

  return (
    <Wrapper name="Services">
      <Table<Service>
        emptyMessageComponent={<MissingContent message="No services available" />}
        config={tableConfig}
        data={services.result?.items ?? []}
        id="services-table"
        pending={services.loading}
        actions={[]}
        pagination={{ total: services.result?.total ?? 0, useUrlSearchParams: true }}
      />
    </Wrapper>
  )
}
