import validatejs, { single } from 'validate.js'
import { capitalize } from '@mui/material'
import { REGEX_ALPHANUMERIC, validatePassword, validateUsername } from 'common/api/v1/helpers'
import { Address, RtpInputPort, RtpOutputPort } from 'common/api/v1/types'
import { OccupiedPort } from 'common/ports'

validatejs.validators.username = validateUsername
validatejs.validators.pwd = validatePassword
validatejs.validators.presence.message = "Can't be blank"

/**
 * Validator to use instead of numericality
 */
// eslint-disable-next-line
validatejs.validators.number = (value: number | '', constraints: { [key: string]: any }) => {
  if (value === '') return
  const err = single(value, {
    numericality: { ...constraints, noStrings: false },
  })
  return Array.isArray(err) ? err.join(', ') : err
}

// eslint-disable-next-line
validatejs.validators.pid = (value: number | '') => {
  if (value === '') return

  const MIN = 32 // 1-31 are reserved
  const MAX = 8190 // 8191 (0x1FFF) is Null packets
  const RESERVED = [8187] // 0x1FFB. See https://en.wikipedia.org/wiki/MPEG_transport_stream
  const err = single(value, {
    numericality: {
      greaterThanOrEqualTo: MIN,
      lessThanOrEqualTo: MAX,
      message: `Must be between ${MIN} - ${MAX}`,
    },
    exclusion: {
      within: RESERVED,
      message: '%{value} is reserved',
    },
  })

  return Array.isArray(err) ? err.join(', ') : err
}

/**
 * Validator to use for IP fields
 */
// eslint-disable-next-line
validatejs.validators.ip = (value: string = '') => {
  if (value === '') return
  if (!value.match(/^(?:(?:^|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d)){4}$/gm)) return 'Must be valid ipv4 address'
  return
}

/**
 * Validator to use for multicast address fields
 */
// eslint-disable-next-line
validatejs.validators.multicastAddress = (value: string = '') => {
  if (value === '') return
  const ipError = validatejs.validators.ip(value)
  const firstPart = parseInt(value.split('.')[0], 10)
  if (ipError || !(224 <= firstPart && firstPart <= 239)) {
    return 'Multicast address must be in range 224.0.0.0/4 (224.0.0.0 to 239.255.255.255)'
  }

  return
}

validatejs.validators.ipv4CidrBlock = (value = '') => {
  if (value === '') return
  if (
    !value.match(
      /^(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d\d?)(\/([0-9]|[1-2][0-9]|3[0-2]))$/,
    )
  )
    return 'Must be valid CIDR block such as 127.0.0.1/24'
  return
}

/**
 * Validator to use for string fields
 */
// eslint-disable-next-line
validatejs.validators.format = (value: string = '', constraints: { prefixes: string[] }) => {
  if (value === '') return
  if (constraints.prefixes) {
    const match = constraints.prefixes.find(prefix => value.startsWith(prefix))
    if (!match) {
      return `Must start with ${constraints.prefixes.join(' or ')}`
    }
  }
}

/**
 * Validator to use for IP/hostname fields
 */
// eslint-disable-next-line
validatejs.validators.ipOrHostname = (value: string = '') => {
  if (value === '') return
  const validHostnamePattern = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/gm
  const validIpPattern = /^(?:(?:^|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d)){4}$/gm
  if (!value.match(validIpPattern) && !value.match(validHostnamePattern)) return 'Must be valid ipv4 address or FQDN'
  return
}

export const RISTSERVER_HANDOVER_RANGE_START = 30000
export const RISTSERVER_HANDOVER_RANGE_END = 30999

/**
 * Validator to use for port fields
 */
// eslint-disable-next-line
validatejs.validators.port = (value: string = '', constraints: { even?: boolean; disallowInternal?: boolean }) => {
  if (value === '') return
  if (!value?.toString().match(/^[0-9]+$/)) return 'Must be a valid port number'

  const MAX = 65535
  const MIN = 1024
  const INTERNAL_RANGE_START = 20000
  const INTERNAL_RANGE_END = 20999

  const even = (constraints && constraints.even) || void 0
  const evenMessage = even ? 'an even number in the range ' : ''
  const err = single(value, {
    numericality: {
      greaterThanOrEqualTo: MIN,
      lessThanOrEqualTo: MAX,
      message: `Must be ${evenMessage}${MIN} - ${MAX}`,
      even,
    },
  })
  const port = parseInt(value)
  const isInInternalRangeError =
    constraints.disallowInternal && INTERNAL_RANGE_START <= port && port <= INTERNAL_RANGE_END
      ? `${INTERNAL_RANGE_START} to ${INTERNAL_RANGE_END} is reserved for internal use`
      : undefined
  const errorMessage = Array.isArray(err) ? err.join(', ') : err
  if (isInInternalRangeError) {
    return isInInternalRangeError
  }
  const isInHandoverRangeError =
    constraints.disallowInternal && RISTSERVER_HANDOVER_RANGE_START <= port && port <= RISTSERVER_HANDOVER_RANGE_END
      ? `${RISTSERVER_HANDOVER_RANGE_START} to ${RISTSERVER_HANDOVER_RANGE_END} is reserved for internal use`
      : undefined
  if (isInHandoverRangeError) {
    return isInHandoverRangeError
  }

  return errorMessage
}

/**
 * Validator to use for field's value length
 * @param value
 * @param constraints - {minimum: min length, tooShort: text for error, maximum: max length, tooLong: text for error}
 */
// eslint-disable-next-line
validatejs.validators.len = (value: string = '', constraints: { [key: string]: any }) => {
  if (value === '') return
  if (constraints.minimum && value.length < constraints.minimum)
    return constraints.tooShort || `Must be at least ${constraints.minimum} characters long`
  if (constraints.maximum && value.length > constraints.maximum)
    return constraints.tooLong || `Must be no more than ${constraints.maximum} characters long`
  return
}

/**
 * Validator to use for hexadecimal fields
 */
// eslint-disable-next-line
validatejs.validators.hexadecimal = (value: string = '') => {
  if (value === '') return
  if (!value.match(/^[\d\sa-fA-F]+$/gm)) return 'Must be valid hexadecimal'
  return
}

/**
 * Validator to use for alphanumeric fields
 */
// eslint-disable-next-line
validatejs.validators.alphanumeric = (value: string = '') => {
  if (value === '') return
  if (!value.match(REGEX_ALPHANUMERIC)) return 'Must be alphanumeric'
  return
}

/**
 * Validator to use in rtp port fields to make sure no ports are equal for the same input/output
 * @param value
 * @param ports - input's/output's ports to compare
 * @param index - index of the current port to validate
 */
validatejs.validators.rtpAddresses = (
  // eslint-disable-next-line
  value: string = '',
  { ports, index }: { ports: Array<RtpOutputPort | RtpInputPort>; index: number },
) => {
  if (value === '' || ports.length < 2) return
  const otherPorts = [...ports]
  const currentPort = otherPorts.splice(index, 1)[0]
  if (
    otherPorts.some(
      ({ address, port, physicalPort, multicastAddress }) =>
        physicalPort === currentPort.physicalPort &&
        address === currentPort.address &&
        port === currentPort.port &&
        multicastAddress == currentPort.multicastAddress,
    )
  )
    return 'Address/port(/multicast group) should be different for different interfaces'
}

/**
 * Validator to verify that the received address is in the list of valid addresses,
 * e.g. used for validating that a selected address belongs to the selected interface
 * @param value - the value to check
 * @param addresses - a list of valid addresses
 */
validatejs.validators.addressIn = (value = '', { addresses }: { addresses: Address[] }) => {
  const validAddresses = (addresses || []).reduce((acc, address) => {
    acc.add(address.address)
    address.publicAddress && acc.add(address.publicAddress)
    address.internalAddress && acc.add(address.internalAddress)
    address.interRegionPublicAddress && acc.add(address.interRegionPublicAddress)
    return acc
  }, new Set<string>())

  if (!validAddresses.has(value)) {
    const formattedValidAddresses = Array.from(validAddresses)
      .map(address => `'${address || 'any'}'`)
      .sort()
    return `Must be ${formattedValidAddresses.join(' or ')}`
  }
}

/**
 * Validator to verify that the received value is one of the set of valid values,
 * e.g. used for validating that a selected value belongs to a certain enum
 * @param value - the value to check
 * @param validValues - a set of valid values
 */
validatejs.validators.oneOf = (value = '', { validValues }: { validValues: Set<string> }) => {
  if (validValues.size > 0 && !validValues.has(value)) {
    const prefix = `Must be ${validValues.size === 1 ? '' : 'one of:'}`
    return `${prefix} ${Array.from(validValues).join(', ')}`
  }
}

/**
 * Validator to verify that the received port number is not in the received list of occupied ports,
 * @param value - the port number to check
 * @param occupiedPorts - a list of occupied ports
 * @param isMulticast - if the received port number will be used in a multicast setup
 * @param isPortDisabled - if the received interface is disabled (i.e. the owning input/output is disabled)
 * @param isSimpleRistInput - if editing simple rist input
 */
validatejs.validators.isPortAvailable = (
  value = '',
  {
    occupiedPorts,
    isMulticast,
    isPortDisabled,
    isSimpleRistInput,
  }: { occupiedPorts: OccupiedPort[]; isMulticast: boolean; isPortDisabled: boolean; isSimpleRistInput?: boolean },
) => {
  if (isPortDisabled) return
  if (isMulticast) return
  const occupant = occupiedPorts.find(o => o.portNumber == Number(value))
  if (occupant) {
    let occupantName = 'another input or output'
    if (occupant.input) occupantName = `input "${occupant.input.name}"`
    if (occupant.output) occupantName = `output "${occupant.output.name}"`
    return `${capitalize(
      occupant.protocol,
    )} port ${value} on the selected interface is already in use by ${occupantName}`
  }
  if (isSimpleRistInput) {
    const occupantOnExtraPort = occupiedPorts.find(o => o.portNumber == Number(value) + 1)
    if (occupantOnExtraPort) {
      let occupantName = 'another input or output'
      if (occupantOnExtraPort.input) occupantName = `input "${occupantOnExtraPort.input.name}"`
      if (occupantOnExtraPort.output) occupantName = `output "${occupantOnExtraPort.output.name}"`
      return `${capitalize(occupantOnExtraPort.protocol)} port ${Number(value) +
        1} on the selected interface is already in use by ${occupantName}, but required by the selected protocol.`
    }
  }
}

/**
 * Function to run upon the field's value with those constraints
 * @param constraints
 */
export const validate = (constraints: { [key: string]: any }) => (value?: unknown) => {
  if (typeof value === 'string' && value.match(/(^\s|\s$)/)) {
    return 'Leading and trailing spaces are not allowed.'
  }

  const err = single(value, {
    ...constraints,
    presence: constraints.presence ? { ...constraints.presence, allowEmpty: false } : undefined,
    numericality: constraints.numericality
      ? {
          ...constraints.numericality,
          notValid: ' ',
        }
      : undefined,
  })
  return Array.isArray(err) ? err.join(', ') : err
}
