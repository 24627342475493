import { useDispatch } from 'react-redux'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MuiSelect from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import { MetricWindow } from 'common/api/v1/types'
import { selectTr101290Window } from '../../../redux/actions/serviceOverviewActions'
import { AppDispatch } from 'src/store'

export const WindowSizeSelect = (props: { currentWindow: MetricWindow }) => {
  const prettyFormatWindow = (window: MetricWindow) => {
    switch (window) {
      case MetricWindow.s10:
        return 'Last 10 seconds'
      case MetricWindow.m1:
        return 'Last minute'
      case MetricWindow.m15:
        return 'Last 15 minutes'
      case MetricWindow.h1:
        return 'Last hour'
      case MetricWindow.h24:
        return 'Last 24 hours'
    }
  }
  const dispatch = useDispatch<AppDispatch>()
  return (
    <FormControl variant="outlined" style={{ minWidth: '150px' }}>
      <InputLabel>TR 101 290 analysis</InputLabel>
      <MuiSelect
        autoWidth
        value={props.currentWindow}
        label={'TR 101 290 analysis'}
        onChange={({ target }) => {
          dispatch(selectTr101290Window(target.value as MetricWindow))
        }}
      >
        {[MetricWindow.m1, MetricWindow.m15, MetricWindow.h1, MetricWindow.h24].map(window => (
          <MenuItem value={window} key={`menu-item-${window}`}>
            {prettyFormatWindow(window)}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  )
}
