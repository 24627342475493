import { useState } from 'react'
import { useFormikContext } from 'formik'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'

import { Output, OutputOperStatus, OutputRecipientList, OutputRecipientListUpdate } from 'common/api/v1/types'
import { getOutputOperState } from 'common/api/v1/helpers'
import { Api } from '../../../store'
import PaginatedList from '../../common/SelfStatePaginatedList'
import { Table } from '../../common/Table'
import { Link } from '../../common/Link'
import routes from '../../../utils/routes'
import { OutputHealthIndicator as HealthIndicator } from '../../common/Indicator'
import { useUser } from '../../../utils'
import { OutputsRequestParams } from '../../../api/nm-types'

const styles = {
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minWidth: '140px',
  },
  button: {
    color: (theme: Theme) => theme.palette.error.main,
    borderColor: (theme: Theme) => theme.palette.error.main,
  },
}

const { outputListsApi, outputApi } = Api

const AvailableOutputs = () => {
  const [hideFilter, setHideFilter] = useState(true)
  const { values, setFieldValue } = useFormikContext<
    OutputRecipientList & Pick<OutputRecipientListUpdate, 'addOutputs'>
  >()
  const user = useUser()
  const listGroup = values.group ? values.group : user?.group

  const onAdd = (id: Output['id']) => setFieldValue('addOutputs', values.addOutputs.concat(id))
  const onCancel = (id: Output['id']) =>
    setFieldValue(
      'addOutputs',
      values.addOutputs.filter(outputId => outputId !== id),
    )

  return (
    <Grid item xs={12}>
      <PaginatedList<OutputsRequestParams, Output>
        api={
          values.id
            ? function(params) {
                return outputListsApi.getOutputs(values.id, false, { group: listGroup, ...params })
              }
            : outputApi.getOutputs.bind(outputApi)
        }
        emptyMessage="no available outputs"
        hideSearch={hideFilter}
        notFoundMessage="no matching outputs"
        onListChange={({ filter, total = 0 }) => setHideFilter(!filter && total < 10)}
        searchPlaceholder="Find specific output…"
        List={({ list }) => (
          <Table
            data={values.group ? list.filter((output: Output) => output.group === values.group) : list}
            isSmall
            hasBorders
            noHeader
            config={[
              {
                getValue: ({ name }) => <Typography>{name}</Typography>,
              },
              {
                getValue: output =>
                  getOutputOperState(output).state === OutputOperStatus.outputError ? (
                    <Link type="button" to={routes.alarms()}>
                      <HealthIndicator outputId={output.id} />
                    </Link>
                  ) : (
                    <HealthIndicator outputId={output.id} />
                  ),
              },
              {
                getValue: ({ id }) => (
                  <Box sx={styles.actions}>
                    {values.addOutputs.includes(id) ? (
                      <Button sx={styles.button} onClick={() => onCancel(id)} variant="outlined" size="small">
                        Cancel adding
                      </Button>
                    ) : (
                      <Button onClick={() => onAdd(id)} variant="outlined" size="small">
                        Add
                      </Button>
                    )}
                  </Box>
                ),
                props: {
                  align: 'right',
                },
              },
            ]}
          />
        )}
      />
    </Grid>
  )
}

export default AvailableOutputs
