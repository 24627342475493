import { useEffect, useReducer, useState } from 'react'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Toolbar from '@mui/material/Toolbar'
import { Theme } from '@mui/material/styles'

import { Input, Output, OutputRecipientList, OutputRedundancyMode } from 'common/api/v1/types'
import { useConfirmationDialog, useOutputsSelector, usePageParamsFilteredSelector, useUser } from '../../../utils'
import routes from '../../../utils/routes'

import { MissingContent } from '../../common/MissingContent'
import Wrapper from '../../common/Wrapper'
import { Link } from '../../common/Link'
import Table from '../../common/Table'

import getConfig from './tableConfig'

import { SelectOutputListDialog } from './SelectOutputListDialog'
import { updateOutputList } from '../../../redux/actions/outputListsActions'
import { SelectInputDialog } from '../SelectInputDialog'
import { EnrichedOutput } from '../../../api/nm-types'
import { setInputOfOutput } from '../../../redux/actions/outputsActions'
import DeleteDialog from './DeleteDialog'
import { RouteComponentProps } from 'react-router-dom'
import CommonActions from './CommonActions'
import { inputRedundancy } from 'common/utils'
import { AppDispatch } from 'src/store'

const styles = {
  right: {
    marginLeft: 'auto',
    '& > *': {
      marginLeft: (theme: Theme) => theme.spacing(2),
    },
  },
}

export enum OutputListDialog {
  selectInput,
  addToOutputList,
}

function dialogReducer(
  state: { output?: Output; dialog?: OutputListDialog; isOpen: boolean },
  action: { type: OutputListDialog; output: Output } | { type: 'close' },
) {
  if (action.type === 'close')
    return {
      ...state,
      isOpen: false,
    }
  return {
    isOpen: true,
    output: action.output,
    type: action.type,
  }
}

export const List = ({ history }: RouteComponentProps) => {
  const { outputs, loading, total } = usePageParamsFilteredSelector(useOutputsSelector)

  const user = useUser()
  const dispatch = useDispatch<AppDispatch>()
  const assignInputDialog = useConfirmationDialog()
  const [selected, setSelected] = useState<Array<Output['id']>>([])
  useEffect(() => {
    setSelected([])
  }, [outputs.map(i => i.id).join(',')])
  const [dialog, dispatchDialogAction] = useReducer(dialogReducer, { isOpen: false })

  const closeDialog = () => dispatchDialogAction({ type: 'close' })
  const showDialog = (output: Output, type: OutputListDialog) => dispatchDialogAction({ type, output })

  const handleSelect = (id: Output['id']) => {
    if (selected.includes(id)) {
      setSelected([...selected.filter(item => item !== id)])
    } else {
      setSelected([...selected, id])
    }
  }

  const handleSelectAll = () => {
    if (selected.length === outputs.length) {
      setSelected([])
    } else {
      setSelected(outputs.reduce<Array<Output['id']>>((acc, { id }) => acc.concat(id), []))
    }
  }

  const onOutputListSelect = (output: Output, outputList: OutputRecipientList) => {
    dispatch(
      updateOutputList({
        outputList: {
          ...outputList,
          addOutputs: [output.id],
          removeOutputs: [],
        },
        withRedirection: false,
      }),
    )
  }

  const onInputSelect = (output: Output, input: Input) => {
    // EDGE-2774: Use 'setInputOfOutput' instead of 'updateOutput' since it generates a more detailed audit-log message
    const doUpdateOutput = () => dispatch(setInputOfOutput({ output, inputId: input.id }))

    const isMultiApplianceInputWorkaroundRequired = (input.appliances || []).length > 1
    const isRedundantOutput = output.redundancyMode != OutputRedundancyMode.none
    const isRedundantInput = inputRedundancy(input)
    if (isMultiApplianceInputWorkaroundRequired) {
      assignInputDialog(
        () => history.push(routes.outputsUpdate({ id: output.id })),
        'The selected input has multiple input appliances and must be assigned to the output from the "Edit Output" page. Proceed?',
      )
    } else if (isRedundantOutput && !isRedundantInput) {
      assignInputDialog(
        doUpdateOutput,
        'You have selected a non-redundant input - output redundancy will be disabled. Proceed?',
      )
    } else {
      doUpdateOutput()
    }
  }

  const onClearInput = (output: EnrichedOutput) => dispatch(setInputOfOutput({ output, inputId: undefined }))

  const isDialogOpen = (type: OutputListDialog) => dialog.isOpen && dialog.type === type

  return (
    <Wrapper name="Outputs">
      <Toolbar disableGutters>
        <Box sx={styles.right}>
          <Link type="button" to={routes.outputLists()}>
            <Button variant="outlined" color="primary">
              Manage output lists
            </Button>
          </Link>
          <Link type="button" to={routes.outputsNew()}>
            <Button id="outputs-table-create-button" variant="contained" color="primary">
              Create output
            </Button>
          </Link>
        </Box>
      </Toolbar>
      <CommonActions selected={selected} setSelected={setSelected} />
      <Table<Output>
        id="outputs-table"
        emptyMessageComponent={
          <MissingContent message="No outputs available" buttonText="Create output" url={routes.outputsNew()} />
        }
        config={getConfig({
          dispatch,
          outputs,
          selected,
          handleSelect,
          handleSelectAll,
          showDialog,
          user,
        })}
        rowProps={({ id, name }) => {
          const isItemSelected = Boolean(id && selected.includes(id))
          return {
            onDoubleClick: () => history.push(routes.outputsUpdate({ id })),
            'aria-checked': isItemSelected,
            tabIndex: -1,
            selected: isItemSelected,
            'data-output-name': name,
          }
        }}
        data={outputs}
        pagination={{ useUrlSearchParams: true, total }}
        pending={loading}
      />

      {dialog.output && (
        <SelectInputDialog
          show={isDialogOpen(OutputListDialog.selectInput)}
          output={dialog.output}
          closeDialog={closeDialog}
          onInputSelect={onInputSelect}
          onClearInput={onClearInput}
        />
      )}
      {dialog.output && (
        <SelectOutputListDialog
          output={dialog.output}
          show={isDialogOpen(OutputListDialog.addToOutputList)}
          onListSelect={onOutputListSelect}
          closeDialog={closeDialog}
        />
      )}
      <DeleteDialog setSelected={setSelected} />
    </Wrapper>
  )
}
