import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import { GetApp } from '@mui/icons-material'
import { Theme } from '@mui/material/styles'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { parseISO } from 'date-fns'

import { api } from '../../../utils/routes'
import { usePageParams } from '../../../utils'
import { endOfDayUTC, startOfDayUTC, startOfMonthUTC } from 'common/api/v1/helpers'
import Select from '../../common/Filters/Select'
import { FilterType } from '../../common/Filters'

const styles = {
  buttonContainer: {
    margin: '0 0 0 auto',
  },
  input: {
    marginRight: (theme: Theme) => theme.spacing(2),
  },
  buttonText: (theme: Theme) => ({
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  }),
  icon: (theme: Theme) => ({
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  }),
}

enum DateField {
  'startDate' = 'startDate',
  'endDate' = 'endDate',
}
const getRequestParams = (from: Date, to: Date) => ({
  [DateField.startDate]: from,
  [DateField.endDate]: to,
})

const UsageFilter = () => {
  const [pageParams, setPageParams] = usePageParams()
  const [openFrom, setOpenFrom] = useState(false)
  const [openTo, setOpenTo] = useState(false)
  const { startDate, endDate, type } = pageParams
  const from = startDate ? parseISO(startDate) : startOfMonthUTC(new Date())
  const to = endDate ? parseISO(endDate) : endOfDayUTC(new Date())

  const setDate = (dateField: DateField, value: Date | null) => {
    if (!value || !value.getTime()) return
    if (dateField === DateField.endDate) {
      value = endOfDayUTC(value)
    } else {
      value = startOfDayUTC(value)
    }
    const params = {
      [DateField.startDate]: dateField === DateField.startDate ? value.toISOString() : startDate,
      [DateField.endDate]: dateField === DateField.endDate ? value.toISOString() : endDate,
      pageNumber: '0',
    }
    setPageParams(params)
  }

  return (
    <>
      <Grid item>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker<Date>
            open={openFrom}
            onOpen={() => setOpenFrom(true)}
            onClose={() => setOpenFrom(false)}
            label="From date:"
            inputFormat="yyyy MMM dd"
            value={startOfDayUTC(from)}
            onChange={value => setDate(DateField.startDate, value)}
            minDate={new Date('2020-01-01')}
            maxDate={to}
            disableFuture
            renderInput={params => (
              <TextField {...params} variant="standard" sx={styles.input} onClick={() => setOpenFrom(true)} />
            )}
          />
          <DatePicker<Date>
            open={openTo}
            onOpen={() => setOpenTo(true)}
            onClose={() => setOpenTo(false)}
            label="To date:"
            inputFormat="yyyy MMM dd"
            value={startOfDayUTC(to)}
            onChange={value => setDate(DateField.endDate, value)}
            minDate={from}
            maxDate={new Date()}
            disableFuture
            renderInput={params => (
              <TextField {...params} variant="standard" sx={styles.input} onClick={() => setOpenTo(true)} />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item>
        <Select
          type={FilterType.select}
          paramName={'type'}
          label={'Type'}
          options={[
            { name: 'Ingress', value: 'ingress' },
            { name: 'Egress', value: 'egress' },
          ]}
        />
      </Grid>
      <Grid item sx={styles.buttonContainer}>
        <Link
          underline="none"
          href={api.usage({
            ...getRequestParams(from, to),
            format: 'csv',
            type: type as 'ingress' | 'egress' | undefined,
            rowsPerPage: '1', // ignored when using format csv
            pageNumber: '0', // ignored when using format csv
          })}
        >
          <Button variant="outlined">
            <Box sx={styles.buttonText}>Download</Box>
            <GetApp sx={styles.icon} />
          </Button>
        </Link>
      </Grid>
    </>
  )
}

export default UsageFilter
