import { Service } from 'common/api/v1/types'

import { ServicesRequestParams } from '../../api/nm-types'
import { AppDispatch } from '../../store'
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from './notificationActions'
import { withDefaultPagination } from '../../utils'
import routes from '../../utils/routes'
import { IServiceApi } from '../../api/service/api'
import { RouteComponentProps } from 'react-router-dom'

export const updateService = async (
  service: Service,
  {
    dispatch,
    serviceApi,
    history,
  }: { dispatch: AppDispatch; serviceApi: IServiceApi; history: RouteComponentProps['history'] },
) => {
  try {
    await serviceApi.updateService(service)
    history.push(routes.services())
    dispatch(enqueueSuccessSnackbar(`Edited Service: ${service.purpose}`))
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'update service' }))
    throw err
  }
}

export const getServices = async (
  params: Partial<ServicesRequestParams>,
  { dispatch, serviceApi }: { dispatch: AppDispatch; serviceApi: IServiceApi },
) => {
  try {
    return await serviceApi.getServices(withDefaultPagination(params))
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch services' }))
    throw err
  }
}
