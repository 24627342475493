import { useEffect, useContext, useState, useRef, useDebugValue, useCallback } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { GlobalState, ThunkApi, AppDispatch } from '../store'
import { setSearchVisibility } from '../redux/actions/uiActions'
import { enqueueErrorSnackbar } from '../redux/actions/notificationActions'
import { withDefaultPagination } from '.'
import { ConfirmDialogContext } from '../components/common/ConfirmDialog'
import { Appliance, ListResult } from 'common/api/v1/types'
import { clearInputs, getInputs } from '../redux/actions/inputsActions'
import { clearOutputs, getOutputs, getOutputsWithLists } from '../redux/actions/outputsActions'
import { clearGroups, getGroups } from '../redux/actions/groupActions'
import { clearPorts, getPorts } from '../redux/actions/portActions'
import { clearAppliances, getAppliances } from '../redux/actions/applianceActions'
import { clearActiveServices, getActiveServices } from '../redux/actions/activeServicesActions'
import { clearOutputLists, getOutputLists } from '../redux/actions/outputListsActions'
import { getGroupLists } from '../redux/actions/groupListsActions'
import { getAudit } from '../redux/actions/auditActions'
import { getRegions, clearRegions } from '../redux/actions/regionsActions'
import { ApplicationException } from '../components/common/ApplicationException'
import {
  AlarmsRequestParams,
  AppliancesRequestParams,
  EnrichedPhysicalPort,
  EnrichedUser,
  GroupsRequestParams,
  InputsRequestParams,
  KubernetesNodesRequestParams,
  NetworksRequestParams,
  OutputsRequestParams,
  PaginatedRequestParams,
  PortsRequestParams,
} from '../api/nm-types'
import { AsyncThunk } from '@reduxjs/toolkit'
import { parseSearchParams } from './params'
import { clearIpMappings, getIpMappings } from '../redux/actions/addressMappingActions'
import { IPortsApi } from '../api/ports/api'
import { clearAlarms, getAlarms } from '../redux/actions/alarmsActions'
import { clearKubernetesNodes, getKubernetesNodes } from '../redux/actions/k8sActions'
import { getNetworks } from '../redux/actions/networkActions'
import { getSettings } from '../redux/actions/settingsActions'
import { readUsage } from '../redux/actions/usageActions'
import { PortDirection } from 'common/types'

/**
 * returns the function to be called to invoke common confirmation dialog from the context
 * function parameters are
 *  callback: callback to call on OK click,
 *  comp: component to show inside the dialog (confirm text),
 *  buttonProps?: {ok: {text, ...other button props}, cancel: {text, ...other button props}},
 *  cancelCallback?: callback to call on Cancel click,
 */
export const useConfirmationDialog = () => {
  const { showConfirmation } = useContext(ConfirmDialogContext)
  return showConfirmation
}

export function usePageParams<PageParameters extends Record<string, string> = Record<string, string>>() {
  const history = useHistory()
  const location = useLocation()
  const pageParams: PageParameters = parseSearchParams(location.search)
  const setPageParams = (params: Record<string, string | undefined> | undefined) => {
    if (!params) return history.replace(location.pathname)
    const updatedParams = new URLSearchParams(location.search)
    for (const key in params) {
      const value = params[key]
      if (!value) updatedParams.delete(key)
      else updatedParams.set(key, value)
    }
    const search = updatedParams.toString()
    if (!search) return
    return history.replace(location.pathname + '?' + search)
  }

  return [pageParams, setPageParams] as [PageParameters, typeof setPageParams]
}

// Non-react-js debounce version
// Return the latest value if it's been more than <delay>ms since it was last called.
// Otherwise, it will return the previous value.
export function useDebounce<S>(value: S, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    if (delay > 0) {
      const handler = setTimeout(() => setDebouncedValue(value), delay)
      return () => clearTimeout(handler)
    } else {
      setDebouncedValue(value)
    }
  }, [value])

  return debouncedValue
}

// Call the passed function when it hasn't been called for the wait period
export const useDebouncedCallback = (func: Function, delay = 200) => {
  const timeout = useRef<NodeJS.Timeout>()

  return useCallback(
    (...args: any[]) => {
      const later = () => {
        clearTimeout(timeout.current)
        func(...args)
      }

      clearTimeout(timeout.current)
      timeout.current = setTimeout(later, delay)
    },
    [func, delay],
  )
}

/**
 * Adding the debounce on input change (mostly used for filter)
 * @param filter: the new value from changed input
 * @param onChange: if provided, invokes on debounced value changed
 * @param onInit: if we should invoke callback on init
 */
export const useDebouncedFilter = (filter: string, onChange?: (debounced: string) => void, onInit = false) => {
  const [debouncedFilter, setDebouncedFilter] = useState<string>(filter)
  const timer = useRef<NodeJS.Timeout>()
  const clearTimer = () => timer.current && clearTimeout(timer.current)
  useEffect(() => {
    clearTimer()
    timer.current = setTimeout(() => setDebouncedFilter(filter), 500)
    return clearTimer
  }, [filter])

  const [initial, setInitial] = useState(!onInit)
  useEffect(() => {
    onChange && !initial && onChange(debouncedFilter)
    setInitial(false)
  }, [debouncedFilter])

  return debouncedFilter
}

/**
 * helper to get the entity selector based on api requests
 * @param action: action to dispatch to get entities
 * @param reducer: reducer name from the redux store to get values from
 * @param requestParams: api request params to dispatch action with
 */
const useEntitySelector = <TReducer extends keyof GlobalState, TParams extends PaginatedRequestParams>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: AsyncThunk<any, any, ThunkApi>,
  reducer: TReducer,
  requestParams: TParams,
) => {
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    switch (reducer) {
      case 'inputsReducer':
        dispatch(clearInputs())
        break
      case 'outputsReducer':
        dispatch(clearOutputs())
        break
      case 'groupsReducer':
        dispatch(clearGroups())
        break
      case 'alarmsReducer':
        dispatch(clearAlarms())
        break
      case 'portsReducer':
        dispatch(clearPorts())
        break
      case 'appliancesReducer':
        dispatch(clearAppliances())
        break
      case 'activeServiceReducer':
        dispatch(clearActiveServices())
        break
      case 'outputListsReducer':
        dispatch(clearOutputLists())
        break
      case 'regionsReducer':
        dispatch(clearRegions())
        break
      case 'addressMappingsReducer':
        dispatch(clearIpMappings())
        break
      case 'k8sReducer':
        dispatch(clearKubernetesNodes())
        break
      default:
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(action(requestParams))
  }, [dispatch, Object.entries(requestParams).join(',')])
  return useSelector((state: GlobalState) => state[reducer], shallowEqual)
}

export const useInputsSelector = (requestParams: InputsRequestParams) =>
  useEntitySelector(getInputs, 'inputsReducer', requestParams)
export const useOutputsSelector = (requestParams: OutputsRequestParams) =>
  useEntitySelector(getOutputs, 'outputsReducer', requestParams)
export const useGroupsSelector = (requestParams: GroupsRequestParams) =>
  useEntitySelector(getGroups, 'groupsReducer', requestParams)
export const useSettingsSelector = () =>
  useEntitySelector(getSettings, 'settingsReducer', { pageNumber: '0', rowsPerPage: '0' })
export const useAlarmsSelector = (requestParams: AlarmsRequestParams) =>
  useEntitySelector(getAlarms, 'alarmsReducer', requestParams)
export const useInterfacesSelector = (requestParams: PortsRequestParams) =>
  useEntitySelector(getPorts, 'portsReducer', requestParams)
export const useApplianceSelector = (requestParams: AppliancesRequestParams) =>
  useEntitySelector(getAppliances, 'appliancesReducer', requestParams)
export const useActiveServiceSelector = (requestParams: PaginatedRequestParams) =>
  useEntitySelector(getActiveServices, 'activeServiceReducer', requestParams)
export const useOutputListsSelector = (requestParams: PaginatedRequestParams) =>
  useEntitySelector(getOutputLists, 'outputListsReducer', requestParams)
export const useOutputsWithListsSelector = (requestParams: PaginatedRequestParams) =>
  useEntitySelector(getOutputsWithLists, 'outputsReducer', requestParams)
export const useGroupListsSelector = (requestParams: PaginatedRequestParams) =>
  useEntitySelector(getGroupLists, 'groupListsReducer', requestParams)
export const useAuditSelector = (requestParams: PaginatedRequestParams) =>
  useEntitySelector(getAudit, 'auditReducer', requestParams)
export const useRegionsSelector = (requestParams: PaginatedRequestParams) =>
  useEntitySelector(getRegions, 'regionsReducer', requestParams)
export const useAddressMappingSelector = (requestParams: PaginatedRequestParams) =>
  useEntitySelector(getIpMappings, 'addressMappingReducer', requestParams)
export const useKubernetesNodesSelector = (requestParams: KubernetesNodesRequestParams) =>
  useEntitySelector(getKubernetesNodes, 'k8sReducer', requestParams)
export const useNetworksSelector = (requestParams: NetworksRequestParams) =>
  useEntitySelector(getNetworks, 'networksReducer', requestParams)
export const useUsageSelector = (requestParams: PaginatedRequestParams) =>
  useEntitySelector(readUsage, 'usageReducer', requestParams)

/**
 * @deprecated Do not call react-hooks inside a callback
 * helper to show the page filter and invoke api requests on filter change
 * @param selectorHook: the hook from the upper ones to use on filter changes
 * @param showFilter: should we show the right top filter
 */
export const usePageParamsFilteredSelector = <TState, TParams extends PaginatedRequestParams>(
  selectorHook: (params: TParams) => TState,
  showFilter = true,
  initialPageParams: { [key: string]: string } = {},
) => {
  const dispatch = useDispatch<AppDispatch>()
  const [params, setPageParams] = usePageParams()
  useEffect(() => {
    dispatch(setSearchVisibility(showFilter))
    setPageParams(initialPageParams)
    return () => {
      dispatch(setSearchVisibility(false))
    }
  }, [dispatch])
  return selectorHook(withDefaultPagination(Object.assign(params, initialPageParams)) as TParams)
}

export const usePageParamsFiltered = <TParams extends PaginatedRequestParams>(
  initialPageParams: { [key: string]: string } = {},
): TParams => {
  const dispatch = useDispatch<AppDispatch>()
  const [params, setPageParams] = usePageParams()
  useEffect(() => {
    dispatch(setSearchVisibility(true))
    setPageParams(initialPageParams)
    return () => {
      dispatch(setSearchVisibility(false))
    }
  }, [])
  return withDefaultPagination(Object.assign(params, initialPageParams)) as TParams
}

/**
 * Creates own pagination getting state, returns state
 * @param params: params to send request with
 * @param api: api helper to make request
 * @param updateOn: if switches from false to true or back, forces to update state
 * @param ignore: condition whether we should stop requesting on parameters change
 */
export const useSelfStatePagination = <TEntity, TParams extends PaginatedRequestParams>({
  params,
  api,
  updateOn,
  ignore = false,
}: {
  params: Partial<TParams>
  api: (params: TParams) => Promise<ListResult<TEntity>>
  updateOn?: boolean
  ignore?: boolean
}) => {
  const dispatch = useDispatch<AppDispatch>()
  const { filter = '', ...rest } = params
  const debouncedFilter = useDebouncedFilter(filter)
  const [state, setState] = useState<ListResult<TEntity>>({ items: [], total: 0 })
  const [loading, setLoading] = useState(false)
  const [updateFlag, setUpdateFlag] = useState(false)
  const [isFirst, setIsFirst] = useState(true)

  useEffect(() => {
    if (updateOn && !isFirst) setUpdateFlag(!updateFlag)
    setIsFirst(false)
  }, [updateOn])

  const fetchPage = async () => {
    setLoading(true)
    try {
      const parameters = { filter: debouncedFilter, ...rest }
      const withDefault = withDefaultPagination(parameters) as TParams
      const res = await api(withDefault)
      setState(res)
    } catch (error) {
      dispatch(
        enqueueErrorSnackbar({
          error: new ApplicationException({
            fatal: true,
            errorCode: 'http_get_general_error',
            text: 'Unable to fetch',
            details: 'Http Request Failed',
            origin: error.response,
          }),
        }),
      )
    }
    setLoading(false)
  }

  useEffect(() => {
    // Prevent multiple changes (i.e. change of filter + something else) to fire 2 requests: 1 immediately and the second one after debounce :
    // This effect is triggered one or more properties have changed, e.g. either filter, pageNumber, rowsPerPage, etc.
    // If something other than the filter has changed, e.g. pageNumber, then we go ahead and fetch.
    // If the filter has changed (either alone or together with other properties, e.g. pageNumber) we wait until the debounce.
    const shouldWaitForDebounce = debouncedFilter != filter
    if (shouldWaitForDebounce) return

    !ignore && fetchPage()
  }, [
    Object.values({ ...rest, updateFlag })
      .concat(debouncedFilter)
      .join(','),
  ])

  return { ...state, loading }
}

/**
 * Returns current user
 */
export const useUser = (): EnrichedUser => {
  return useSelector(({ userReducer }: GlobalState) => userReducer.user as EnrichedUser, shallowEqual)
}

// Copied from https://usehooks-typescript.com/react-hook/use-interval
export const useInterval = (callback: () => Promise<void>, delay: number | null) => {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return
    }

    const id = setInterval(() => {
      savedCallback.current()
    }, delay)

    return () => clearInterval(id)
  }, [delay])
}

export const useAppliancePorts = (
  selectedAppliance: Pick<Appliance, 'id' | 'name'> | undefined,
  portsApi: IPortsApi,
  direction: PortDirection,
): EnrichedPhysicalPort[] | undefined => {
  useDebugValue(selectedAppliance?.name)
  const dispatch = useDispatch<AppDispatch>()
  const [appliancePorts, setAppliancePorts] = useState(undefined as EnrichedPhysicalPort[] | undefined)

  useEffect(() => {
    let isMounted = true
    setAppliancePorts(undefined)
    if (!selectedAppliance) return
    portsApi
      .getPorts({
        appliance: selectedAppliance.id,
        pageNumber: '0',
        rowsPerPage: '150',
      })
      .then(ports => {
        if (isMounted) {
          setAppliancePorts(ports.items.filter(p => !p.direction || p.direction === direction))
        }
      })
      .catch(error => {
        dispatch(enqueueErrorSnackbar({ error: error, operation: `fetch interfaces for ${selectedAppliance.name}` }))
      })

    return () => {
      isMounted = false
    }
  }, [selectedAppliance, dispatch])

  return appliancePorts
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

export function useMobile() {
  const windowDimensions = useWindowDimensions()
  return { isMobile: windowDimensions.width < 768 }
}
