import { createSlice } from '@reduxjs/toolkit'

import { Appliance, Input, InputGraph, LimitedAppliance, MetricWindow, ServiceOverview } from 'common/api/v1/types'
import { getService, clearService, selectObject, selectTr101290Window } from '../actions/serviceOverviewActions'
import { logoutUser } from '../actions/userActions'
import { SelectedGraphItem } from '../../components/serviceOverview/Graph/ServiceOverviewGraph'

export interface ServiceOverviewState {
  input?: Input
  loading: boolean
  graph: InputGraph
  appliances: (Appliance | LimitedAppliance)[]
  tunnels: ServiceOverview['objects']['tunnels']
  outputs: ServiceOverview['objects']['outputs']
  selected?: SelectedGraphItem
  tr101290Window: MetricWindow
}
export const initialStateServiceOverview: ServiceOverviewState = {
  loading: false,
  graph: {
    nodes: {},
    edges: [],
  },
  appliances: [],
  tunnels: [],
  outputs: { items: [], total: 0 },
  tr101290Window: MetricWindow.m15,
}

const serviceOverviewSlice = createSlice({
  name: 'serviceOverview',
  initialState: initialStateServiceOverview,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getService.pending, state => ({ ...state, loading: true }))
      .addCase(
        getService.fulfilled,
        (
          state,
          {
            payload: {
              input,
              graph,
              objects: { tunnels, appliances, outputs },
            },
          },
        ) => ({
          ...state,
          input: input || state.input,
          loading: false,
          graph,
          appliances,
          tunnels,
          outputs,
        }),
      )
      .addCase(getService.rejected, state => ({ ...state, loading: false }))
      .addCase(selectObject, (state, { payload: selected }) => ({ ...state, selected }))
      .addCase(selectTr101290Window.pending, (state, { meta: { arg: tr101290Window } }) => ({
        ...state,
        tr101290Window,
      }))
      .addCase(clearService, prevState => ({
        ...initialStateServiceOverview,
        tr101290Window: prevState.tr101290Window,
      }))
      .addCase(logoutUser.fulfilled, () => initialStateServiceOverview)
  },
})

export default serviceOverviewSlice.reducer
