import { Fragment } from 'react'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import Grid from '@mui/material/Grid'
import MuiList from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

import { Appliance, Group, PhysicalPort, User, ListResult, Role } from 'common/api/v1/types'
import { Paper } from '../../common/Form'
import { Link } from '../../common/Link'
import PaginatedList from '../../common/SelfStatePaginatedList'
import { getInterfaceName, useUser } from '../../../utils'
import routes from '../../../utils/routes'
import { Api } from '../../../store'
import { AppliancesRequestParams, PortsRequestParams, UsersRequestParams } from '../../../api/nm-types'

const styles = {
  main: {
    flexGrow: 1,
  },
}

interface ListProps<T, TParams> {
  api: (params: TParams) => Promise<ListResult<T>>
  getLeftInfo: (ent: T) => string
  getRightInfo?: (ent: T) => string
  name: string
  owner: Group['id']
  canEdit: (ent: T) => boolean
}
const List = <
  T extends Appliance | PhysicalPort | User,
  TParams extends AppliancesRequestParams | UsersRequestParams | PortsRequestParams
>({
  api,
  getLeftInfo,
  getRightInfo,
  name,
  owner,
  canEdit,
}: ListProps<T, TParams>) => {
  return (
    <Paper title={`${name[0].toUpperCase()}${name.slice(1)}s`} collapsible>
      <Grid item xs={12}>
        <MuiList>
          <PaginatedList<TParams & { owner: Group['id'] }, T, Pick<TParams, 'owner'>>
            api={api}
            emptyMessage={`No ${name}s yet`}
            notFoundMessage={`No matching ${name}s`}
            otherParams={{ owner } as Pick<TParams, 'owner'>}
            hideSearch
            List={({ list }) => (
              <>
                {list.map(ent => (
                  <Fragment key={ent.id}>
                    {canEdit(ent) ? (
                      <Link to={routes[`${name}sUpdate` as keyof typeof routes]({ id: ent.id })}>
                        <ListItem disableGutters button>
                          <Typography sx={styles.main}>{getLeftInfo(ent)}</Typography>
                          {getRightInfo !== undefined && <Typography>{getRightInfo(ent)}</Typography>}
                        </ListItem>
                      </Link>
                    ) : (
                      <ListItem disableGutters>
                        <Typography sx={styles.main}>{getLeftInfo(ent)}</Typography>
                        {getRightInfo !== undefined && <Typography>{getRightInfo(ent)}</Typography>}
                      </ListItem>
                    )}
                    <Divider />
                  </Fragment>
                ))}
              </>
            )}
          />
        </MuiList>
      </Grid>
    </Paper>
  )
}

const { userApi, portsApi, appliancesApi } = Api

interface ListsProps {
  groupId: Group['id']
}
const Lists = ({ groupId }: ListsProps) => {
  const currentUser = useUser()

  return (
    <>
      <List<User, UsersRequestParams>
        api={userApi.getUsers.bind(userApi)}
        getLeftInfo={({ username }) => username}
        getRightInfo={({ role }) => role}
        name="user"
        owner={groupId}
        canEdit={({ role, group }) =>
          currentUser.role === Role.super ||
          (currentUser.role === Role.admin && currentUser.group === group && role !== Role.super)
        }
      />
      <List<Appliance, AppliancesRequestParams>
        api={appliancesApi.getAppliances.bind(appliancesApi)}
        getLeftInfo={({ name }) => name}
        getRightInfo={({ lastMessageAt = '' }) =>
          lastMessageAt && `last seen ${formatDistanceToNow(new Date(lastMessageAt))} ago`
        }
        name="appliance"
        owner={groupId}
        canEdit={() => true}
      />
      <List<PhysicalPort, PortsRequestParams>
        api={portsApi.getPorts.bind(portsApi)}
        name="interface"
        getLeftInfo={port => getInterfaceName(port)}
        owner={groupId}
        canEdit={() => true}
      />
    </>
  )
}

export default Lists
