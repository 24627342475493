import { useEffect } from 'react'
import { FormikProps } from 'formik'
import { EncoderSettings, MatroxEncoderConfig, MatroxPortMode, PortMode, EncoderFeatures } from 'common/api/v1/types'
import MatroxEncoderSettingsComp from './MatroxEncoderSettings'
import GeneralEncoderDecoderSettings from '../../../common/GeneralEncoderDecoderSettings'
import {
  isCorrectEncoderSettingsTypeForPortMode,
  makeDefaultMatroxEncoderConfig,
  makeDefaultVaEncoderSettings,
} from 'common/encoderSettingsUtil'
import { EnrichedInputPort } from '../../../../api/nm-types'

interface Props {
  namePrefix: string
  mode: PortMode
  selectedInterfaces: EnrichedInputPort[]
  encoderSettings?: EncoderSettings
  encoderFeatures: EncoderFeatures
  setFieldValue: FormikProps<any>['setFieldValue']
}
const EncoderSettings = ({
  namePrefix,
  mode,
  selectedInterfaces,
  encoderSettings,
  encoderFeatures,
  setFieldValue,
}: Props) => {
  useEffect(() => {
    if (!isCorrectEncoderSettingsTypeForPortMode(encoderSettings, mode)) {
      const newEncoderSettings =
        mode === MatroxPortMode.matroxSdi ? makeDefaultMatroxEncoderConfig() : makeDefaultVaEncoderSettings()
      setFieldValue('encoderSettings', newEncoderSettings, false)
    }
  }, [encoderSettings, mode])

  if (!encoderFeatures) {
    return null
  }
  if (!isCorrectEncoderSettingsTypeForPortMode(encoderSettings, mode)) {
    return null
  }

  return (
    <>
      {mode !== MatroxPortMode.matroxSdi && (
        <GeneralEncoderDecoderSettings
          namePrefix={namePrefix}
          encoderFeatures={encoderFeatures}
          setFieldValue={setFieldValue}
        />
      )}
      {mode === MatroxPortMode.matroxSdi && (
        <MatroxEncoderSettingsComp
          namePrefix={namePrefix}
          selectedInterfaces={selectedInterfaces.map(enriched => enriched._port)}
          matroxSettings={encoderSettings as MatroxEncoderConfig}
          setFieldValue={setFieldValue}
        />
      )}
    </>
  )
}

export default EncoderSettings
